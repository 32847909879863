/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/** @jsx jsx */

import { jsx } from '@emotion/react'
import { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiAccordion,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLink,
  EuiSkeletonText,
  EuiSpacer,
  useGeneratedHtmlId,
} from '@elastic/eui'

import type {
  Region as AvailableRegion,
  GlobalDeploymentTemplateInfo,
  DeploymentTemplateInfoV2,
} from '@modules/cloud-api/v1/types'
import type {
  VersionNumber,
  Region,
  RegionId,
  PlatformId,
  StackDeploymentCreateRequest,
} from '@modules/ui-types'
import { getPlatform } from '@modules/utils/platform'
import { useProfile } from '@modules/profile-lib/hooks'

import SelectCloudPlatform from './SelectCloudPlatform'
import SelectHardwareProfile from './SelectHardwareProfile'
import SelectRegion from './SelectRegion'
import SelectVersion from './SelectVersion/SelectVersion'
import SettingSummary from './SettingSummary'

import type { FunctionComponent } from 'react'
// import type { RegionState } from '@/reducers/providers'

export interface Props {
  availablePlatforms: PlatformId[]
  availableRegions: AvailableRegion[] | null
  availableVersions: string[]
  deploymentTemplates: DeploymentTemplateInfoV2[] | null
  disabledControls?: boolean
  editorState: StackDeploymentCreateRequest
  globalDeploymentTemplates: GlobalDeploymentTemplateInfo[]
  onChangePlatform: (platform: string) => void
  onChangeRegion: (regionId: string) => void
  onChangeTemplate: (template: DeploymentTemplateInfoV2) => void
  regionId?: RegionId
  region?: Region
  setVersion: (version: VersionNumber) => void
  trialMaxedOut?: boolean
  version: string | null
  whitelistedVersions: string[]
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

const AdvancedSettings: FunctionComponent<Props> = (props) => {
  const {
    availablePlatforms,
    availableRegions,
    availableVersions,
    deploymentTemplates,
    editorState,
    globalDeploymentTemplates,
    onChangePlatform,
    onChangeRegion,
    onChangeTemplate,
    regionId,
    region,
    setVersion,
    trialMaxedOut,
    version,
    whitelistedVersions,
    isOpen,
    setIsOpen,
  } = props

  const hiddenAccordionId = useGeneratedHtmlId({ prefix: 'hiddenAccordion' })

  const platform = getPlatform(regionId)
  const profile = useProfile()

  if (!version || !regionId) {
    return <EuiSkeletonText data-test-id='advanced-settings-skeleton' />
  }

  const { deploymentTemplate } = editorState

  const disabledProvider =
    profile?.domain === 'gcp' || profile?.domain === 'azure' || profile?.domain === 'aws'

  return (
    <EuiFlexGroup direction='row' gutterSize='s'>
      {!isOpen && (
        <EuiFlexItem>
          <AdvancedSettingsHeaderTitle
            region={region}
            version={version}
            availablePlatforms={availablePlatforms}
            availableRegions={availableRegions}
            editorState={editorState}
            isOpen={isOpen}
          />
        </EuiFlexItem>
      )}
      <EuiFlexItem>
        <EuiAccordion
          buttonContent={AdvancedSettingsHeaderAction({ isOpen })}
          id={hiddenAccordionId}
          onToggle={() => setIsOpen(true)}
          initialIsOpen={isOpen}
          arrowDisplay='none'
        >
          <Fragment>
            <EuiSpacer size='s' />
            <EuiFlexGroup
              data-test-id='advanced-settings-content'
              gutterSize='m'
              direction='column'
            >
              <EuiFlexItem>
                <SelectCloudPlatform
                  prepend={true}
                  restoreFromSnapshot={false}
                  platform={platform}
                  availablePlatforms={availablePlatforms}
                  onChange={onChangePlatform}
                  disabled={trialMaxedOut || disabledProvider}
                />
              </EuiFlexItem>
              <EuiFlexItem>
                <SelectRegion
                  prepend={true}
                  regionId={regionId}
                  restoreFromSnapshot={false}
                  availableRegions={availableRegions}
                  onChange={onChangeRegion}
                  disabled={trialMaxedOut}
                />
              </EuiFlexItem>
              <EuiFlexItem>
                <SelectHardwareProfile
                  onChange={onChangeTemplate}
                  version={version}
                  currentTemplate={deploymentTemplate}
                  stackTemplates={globalDeploymentTemplates}
                  deploymentTemplates={deploymentTemplates}
                />
              </EuiFlexItem>
              <EuiFlexItem>
                <SelectVersion
                  version={version}
                  availableVersions={availableVersions}
                  whitelistedVersions={whitelistedVersions}
                  setVersion={setVersion}
                  disabled={trialMaxedOut}
                  editorState={editorState}
                />
              </EuiFlexItem>
            </EuiFlexGroup>
          </Fragment>
        </EuiAccordion>
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}

const AdvancedSettingsHeaderAction: FunctionComponent<{
  isOpen: boolean
}> = ({ isOpen }) => {
  if (isOpen) {
    return null
  }

  return (
    <EuiLink>
      <FormattedMessage id='accordion-button.expand' defaultMessage='Edit settings' />
    </EuiLink>
  )
}

export default AdvancedSettings

const AdvancedSettingsHeaderTitle: FunctionComponent<{
  region?: Region
  version: string | null
  availablePlatforms: PlatformId[]
  editorState: StackDeploymentCreateRequest
  isOpen: boolean
  availableRegions: AvailableRegion[] | null
}> = ({ region, version, availablePlatforms, editorState, isOpen, availableRegions }) => {
  const { deploymentTemplate } = editorState

  if (!version || !region || !availableRegions) {
    return <EuiSkeletonText />
  }

  if (!isOpen) {
    return (
      <SettingSummary
        deploymentTemplate={deploymentTemplate}
        region={region}
        version={version}
        availableRegions={availableRegions}
        availablePlatforms={availablePlatforms}
      />
    )
  }

  return null
}
