/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/*!
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@                                         @@@@
 *!   @@@   THIS FILE WAS AUTOGENERATED BY TOOL   @@@@
 *!   @@@   CHANGES MUST TARGET SCRIPTS/SWAGGER   @@@@
 *!   @@@                                         @@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!
 */

import {
  _buildQueryString,
  _emptyParams,
  _encode,
  _getBasePath,
  _getRawBasePath,
} from './urls-utils'

/**
 * GET /api/v1/deployments/extensions
 * Operation: `list-extensions`
 * Retrieves all of the available extensions.
 * @param {string} params.organizationId When specified, filters extensions returned to those owned by the given organization.
 * @see 200 The extensions that are available. See {@link Extensions}
 * @return {string} A fully-interpolated and escaped URL
 */
export function listExtensionsUrl(params: ListExtensionsUrlParams = _emptyParams): string {
  const queryString = _buildQueryString({
    organization_id: params.organizationId,
  })
  const path = `/deployments/extensions`
  return `${_getRawBasePath()}${path}${queryString}`
}

export interface ListExtensionsUrlParams {
  organizationId?: string | null
}

/**
 * POST /api/v1/deployments/extensions
 * Operation: `create-extension`
 * Creates the extension.  The extensions API supports two types of usage patterns.  A). Specify a `download_url`, http or https URL,  where the extension is currently hosted. This will result in extension being copied to elastic repository.  B). Create only the extension metadata using the `POST` endpoint and then use `PUT` to upload the extension file. Leave the `download_url` unspecified in this case. .
 * @see Accepts a request payload of type {@link CreateExtensionRequest}
 * @see 201 The extension that was just created. See {@link Extension}
 * @see 400 Could not download the extension from the specified URL. (code: `extensions.request_execution_failed`). See {@link BasicFailedReply}
 * @see 404 Your current session does not have a user id associated with it. (code: `extensions.no_user_id`). See {@link BasicFailedReply}
 * @see 409 An extension already exists with the generated id. Please try again. (code: `extensions.id_already_exists`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function createExtensionUrl(): string {
  const path = `/deployments/extensions`
  return `${_getRawBasePath()}${path}`
}

/**
 * GET /api/v1/deployments/extensions/{extension_id}
 * Operation: `get-extension`
 * Retrieves an extension.
 * @param {string} params.extensionId Id of an extension.
 * @param {boolean} params.includeDeployments Include deployments referencing this extension. Up to only 10000 deployments will be included.
 * @see 200 The Extension. See {@link Extension}
 * @see 404 The extension requested does not exist. (code: `extensions.not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getExtensionUrl(params: GetExtensionUrlParams): string {
  const queryString = _buildQueryString({
    include_deployments:
      params.includeDeployments === undefined ? false : params.includeDeployments,
  })
  const path = _encode`/deployments/extensions/${params.extensionId}`
  return `${_getRawBasePath()}${path}${queryString}`
}

export interface GetExtensionUrlParams {
  extensionId: string
  includeDeployments?: boolean | null
}

/**
 * POST /api/v1/deployments/extensions/{extension_id}
 * Operation: `update-extension`
 * Updates an extension.
 * @param {string} params.extensionId Id of an extension.
 * @see Accepts a request payload of type {@link UpdateExtensionRequest}
 * @see 200 Extension updated successfully. See {@link Extension}
 * @see 400 Could not download the extension from the specified URL. (code: `extensions.request_execution_failed`). See {@link BasicFailedReply}
 * @see 401 * The extension does not belong to you. (code: `extensions.unauthorised`)
 * Your current session does not have a user id associated with it. (code: `extensions.no_user_id`). See {@link BasicFailedReply}
 * @see 404 The extension you want does not exist. (code: `extensions.not_found`). See {@link BasicFailedReply}
 * @see 409 The version you sent does not match the persisted version. (code: `extensions.version_conflict`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function updateExtensionUrl(params: UpdateExtensionUrlParams): string {
  const path = _encode`/deployments/extensions/${params.extensionId}`
  return `${_getRawBasePath()}${path}`
}

export interface UpdateExtensionUrlParams {
  extensionId: string
}

/**
 * PUT /api/v1/deployments/extensions/{extension_id}
 * Operation: `upload-extension`
 * Uploads archive for an extension.
 * @param {string} params.extensionId Id of an extension.
 * @see Accepts a request payload of type {@link File}
 * @see 200 Archive uploaded successfully. See {@link Extension}
 * @see 400 Could not accept the extensions file. (code: `extensions.request_execution_failed`). See {@link BasicFailedReply}
 * @see 401 * The extension does not belong to you. (code: `extensions.unauthorised`)
 * Your current session does not have a user id associated with it. (code: `extensions.no_user_id`). See {@link BasicFailedReply}
 * @see 404 The extension you want does not exist. (code: `extensions.not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function uploadExtensionUrl(params: UploadExtensionUrlParams): string {
  const path = _encode`/deployments/extensions/${params.extensionId}`
  return `${_getRawBasePath()}${path}`
}

export interface UploadExtensionUrlParams {
  extensionId: string
}

/**
 * DELETE /api/v1/deployments/extensions/{extension_id}
 * Operation: `delete-extension`
 * Deletes a Extension.
 * @param {string} params.extensionId Id of an extension.
 * @see 200 Extension deleted successfully.
 * @see 401 * The extension does not belong to you. (code: `extensions.unauthorised`)
 * Your current session does not have a user id associated with it. (code: `extensions.no_user_id`). See {@link BasicFailedReply}
 * @see 404 The extension requested does not exist. (code: `extensions.not_found`). See {@link BasicFailedReply}
 * @see 409 The version you sent does not match the persisted version. (code: `extensions.version_conflict`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function deleteExtensionUrl(params: DeleteExtensionUrlParams): string {
  const path = _encode`/deployments/extensions/${params.extensionId}`
  return `${_getRawBasePath()}${path}`
}

export interface DeleteExtensionUrlParams {
  extensionId: string
}
