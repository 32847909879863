/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/*!
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@                                         @@@@
 *!   @@@   THIS FILE WAS AUTOGENERATED BY TOOL   @@@@
 *!   @@@   CHANGES MUST TARGET SCRIPTS/SWAGGER   @@@@
 *!   @@@                                         @@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!
 */

import {
  _buildQueryString,
  _emptyParams,
  _encode,
  _getBasePath,
  _getRawBasePath,
} from './urls-utils'

/**
 * GET /api/v1/regions/{region_id}/comments/{resource_type}/{resource_id}
 * Operation: `list-comment`
 * Retrieves all the comments for a given Resource, in reverse modified time order.
 * @param {string} params.resourceType The kind of Resource that a Comment belongs to. Should be one of [elasticsearch, kibana, apm, appsearch, enterprise_search, integrations_server, allocator, constructor, runner, proxy].
 * @param {string} params.resourceId Id of the Resource that a Comment belongs to.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 The Comments. See {@link CommentsWithMetas}
 * @return {string} A fully-interpolated and escaped URL
 */
export function listCommentUrl(params: ListCommentUrlParams): string {
  const path = _encode`/${params.regionId}/comments/${params.resourceType}/${params.resourceId}`
  return `${_getBasePath()}${path}`
}

export interface ListCommentUrlParams {
  resourceType: string
  resourceId: string
  regionId: string
}

/**
 * POST /api/v1/regions/{region_id}/comments/{resource_type}/{resource_id}
 * Operation: `create-comment`
 * Creates a comment for the given Resource.
 * @param {string} params.resourceType The kind of Resource that a Comment belongs to. Should be one of [elasticsearch, kibana, apm, appsearch, enterprise_search, integrations_server, allocator, constructor, runner, proxy].
 * @param {string} params.resourceId Id of the Resource that a Comment belongs to.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see Accepts a request payload of type {@link CommentCreateRequest}
 * @see 201 The Comment that was just created. See {@link Comment}
 * @see 401 Your current session does not have a user id associated with it. (code: `comments.no_user_id`). See {@link BasicFailedReply}
 * @see 404 No Resource of the given type and id exist. (code: `comments.resource_does_not_exist`). See {@link BasicFailedReply}
 * @see 409 A Comment already exists with the generated id. Please try again. (code: `comments.id_already_exists`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function createCommentUrl(params: CreateCommentUrlParams): string {
  const path = _encode`/${params.regionId}/comments/${params.resourceType}/${params.resourceId}`
  return `${_getBasePath()}${path}`
}

export interface CreateCommentUrlParams {
  resourceType: string
  resourceId: string
  regionId: string
}

/**
 * GET /api/v1/regions/{region_id}/comments/{resource_type}/{resource_id}/{comment_id}
 * Operation: `get-comment`
 * Retrieves a Comment.
 * @param {string} params.resourceType The kind of Resource that a Comment belongs to. Should be one of [elasticsearch, kibana, apm, appsearch, enterprise_search, integrations_server, allocator, constructor, runner, proxy].
 * @param {string} params.resourceId Id of the Resource that a Comment belongs to.
 * @param {string} params.commentId Id of a Comment.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 The Comment. See {@link Comment}
 * @see 404 The Comment you want does not exist. (code: `comments.comment_does_not_exist`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getCommentUrl(params: GetCommentUrlParams): string {
  const path = _encode`/${params.regionId}/comments/${params.resourceType}/${params.resourceId}/${params.commentId}`
  return `${_getBasePath()}${path}`
}

export interface GetCommentUrlParams {
  resourceType: string
  resourceId: string
  commentId: string
  regionId: string
}

/**
 * PUT /api/v1/regions/{region_id}/comments/{resource_type}/{resource_id}/{comment_id}
 * Operation: `update-comment`
 * Updates a Comment.
 * @param {string} params.resourceType The kind of Resource that a Comment belongs to. Should be one of [elasticsearch, kibana, apm, appsearch, enterprise_search, integrations_server, allocator, constructor, runner, proxy].
 * @param {string} params.resourceId Id of the Resource that a Comment belongs to.
 * @param {string} params.commentId Id of a Comment.
 * @param {string} params.version If specified then checks for conflicts against the version stored in the persistent store (returned in 'x-cloud-resource-version' of the GET request).
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see Accepts a request payload of type {@link CommentUpdateRequest}
 * @see 200 Comment updated successfully. See {@link Comment}
 * @see 401 * The Comment does not belong to you. (code: `comments.unauthorised`)
 * Your current session does not have a user id associated with it. (code: `comments.no_user_id`). See {@link BasicFailedReply}
 * @see 404 The Comment you want does not exist. (code: `comments.comment_does_not_exist`). See {@link BasicFailedReply}
 * @see 409 The version you sent does not match the persisted version. (code: `comments.version_conflict`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function updateCommentUrl(params: UpdateCommentUrlParams): string {
  const queryString = _buildQueryString({
    version: params.version,
  })
  const path = _encode`/${params.regionId}/comments/${params.resourceType}/${params.resourceId}/${params.commentId}`
  return `${_getBasePath()}${path}${queryString}`
}

export interface UpdateCommentUrlParams {
  resourceType: string
  resourceId: string
  commentId: string
  version?: string | null
  regionId: string
}

/**
 * DELETE /api/v1/regions/{region_id}/comments/{resource_type}/{resource_id}/{comment_id}
 * Operation: `delete-comment`
 * Deletes a Comment.
 * @param {string} params.resourceType The kind of Resource that a Comment belongs to. Should be one of [elasticsearch, kibana, apm, appsearch, enterprise_search, integrations_server, allocator, constructor, runner, proxy].
 * @param {string} params.resourceId Id of the Resource that a Comment belongs to.
 * @param {string} params.commentId Id of a Comment.
 * @param {string} params.version If specified then checks for conflicts against the version stored in the persistent store (returned in 'x-cloud-resource-version' of the GET request).
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 Comment deleted successfully.
 * @see 401 * The Comment does not belong to you. (code: `comments.unauthorised`)
 * Your current session does not have a user id associated with it. (code: `comments.no_user_id`). See {@link BasicFailedReply}
 * @see 404 The Comment you want does not exist. (code: `comments.comment_does_not_exist`). See {@link BasicFailedReply}
 * @see 409 The version you sent does not match the persisted version. (code: `comments.version_conflict`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function deleteCommentUrl(params: DeleteCommentUrlParams): string {
  const queryString = _buildQueryString({
    version: params.version,
  })
  const path = _encode`/${params.regionId}/comments/${params.resourceType}/${params.resourceId}/${params.commentId}`
  return `${_getBasePath()}${path}${queryString}`
}

export interface DeleteCommentUrlParams {
  resourceType: string
  resourceId: string
  commentId: string
  version?: string | null
  regionId: string
}
