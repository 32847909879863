/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/*!
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@                                         @@@@
 *!   @@@   THIS FILE WAS AUTOGENERATED BY TOOL   @@@@
 *!   @@@   CHANGES MUST TARGET SCRIPTS/SWAGGER   @@@@
 *!   @@@                                         @@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!
 */

import {
  _buildQueryString,
  _emptyParams,
  _encode,
  _getBasePath,
  _getRawBasePath,
} from './urls-utils'

/**
 * GET /api/v1/platform/configuration/templates/deployments/global
 * Operation: `get-global-deployment-templates`
 * Global deployment template endpoint which fetches the deployment templates across all region services.
 * @see 200 The deployment templates were returned successfully. See {@link Array<GlobalDeploymentTemplateInfo>}
 * @see 204 There are no deployment templates available.
 * @return {string} A fully-interpolated and escaped URL
 */
export function getGlobalDeploymentTemplatesUrl(): string {
  const path = `/platform/configuration/templates/deployments/global`
  return `${_getRawBasePath()}${path}`
}
