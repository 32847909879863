/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { find } from 'lodash'

import { setApmUserContext } from '@modules/utils/apm'
import MfaForm from '@modules/mfa-login/MfaForm'
import type { SaasAuthMfaDeviceResponse, SaasAuthResponse } from '@modules/cloud-api/v1/types'

import { UserSubscriptionValues } from '@/lib/urlUtils'
import { marketplaceDisplayName } from '@/lib/marketPlace'
import MarketplaceExistingUserNotice from '@/components/UserAuthNotice/MarketplaceExistingUserNotice'
import { applyGoogleTrackingV4FormSubmission } from '@/apps/userconsole/lib/googleTracking'

import LandingPageContainer from '../../../../../../components/LandingPageContainer'
import UserRegistrationForm from '../../../../../../components/UserRegistration/Form'

import ClearPartnerSignupLocationStateOnRefresh from './ClearPartnerSignupLocationStateOnRefresh'

import type { AllProps } from './types'

class PartnerSignupRegistration extends Component<AllProps> {
  render(): JSX.Element {
    const { mfa, partner, redirectTo } = this.props

    return (
      <Fragment>
        <LandingPageContainer
          pageContext={{
            name: 'signup',
            contextSwitchLocationState: this.getContextSwitchLocationState(),
          }}
          title={this.renderFormTitle()}
          subtitle={this.renderFormSubTitle()}
        >
          <MarketplaceExistingUserNotice partner={partner} />
          {mfa.mfa_required ? (
            this.renderMfa()
          ) : (
            <UserRegistrationForm
              createUser={this.convertUser}
              redirectTo={redirectTo}
              isMarketplace={true}
            />
          )}
        </LandingPageContainer>

        <ClearPartnerSignupLocationStateOnRefresh />
      </Fragment>
    )
  }

  renderMfa(): JSX.Element | undefined {
    const { mfa, checkForOktaAndHandleLogin, redirectTo } = this.props

    if (mfa.state_id) {
      return (
        <MfaForm
          devices={mfa.mfa_devices as SaasAuthMfaDeviceResponse[]}
          stateId={mfa.state_id}
          isFlowV2={false}
          onSuccess={(data: SaasAuthResponse) => {
            const {
              token,
              okta_session_id,
              session_expiration_time,
              require_email_verification,
              email_verified,
            } = data
            checkForOktaAndHandleLogin({
              token,
              okta_session_id,
              session_expiration_time,
              require_email_verification,
              email_verified,
              redirectTo,
            })
          }}
        />
      )
    }

    return undefined
  }

  renderFormTitle(): JSX.Element | null {
    const { mfa } = this.props

    if (mfa.state_id) {
      return null
    }

    return (
      <FormattedMessage
        id='marketplace-registration-signup-title'
        defaultMessage='Create your account'
      />
    )
  }

  renderFormSubTitle(): JSX.Element | null {
    const { partner, mfa } = this.props

    if (mfa.state_id) {
      return null
    }

    const displayName = find(UserSubscriptionValues, (key) => key === partner)
    const partnerDisplayName = displayName ? marketplaceDisplayName(displayName) : partner

    return (
      <FormattedMessage
        id='marketplace-registration-signup-subtitle'
        defaultMessage='Elastic Cloud powered by {partner} marketplace'
        values={{
          partner: partnerDisplayName,
        }}
      />
    )
  }

  getContextSwitchLocationState = (): { hasExistingSubscription: boolean } | undefined => {
    const { history } = this.props
    const {
      location: { state: locationState },
    } = history

    return locationState
  }

  convertUser = ({ email, password, captcha_token }) => {
    const { convertUser, redirectTo, loginAndRedirect, googleTrackingIdV4Enabled } = this.props

    return convertUser({ email, password, captcha_token }).then(({ payload }) => {
      if (!password) {
        return
      }

      if (googleTrackingIdV4Enabled) {
        applyGoogleTrackingV4FormSubmission({
          email,
          formId: 'cloud-signup-form',
          userId: payload?.user.user_id.toString(),
        })
      }

      setApmUserContext(String(payload?.user?.user_id), email)

      return loginAndRedirect({
        oktaRedirectUrl: redirectTo,
        credentials: { email, password },
      })
    })
  }
}

export default PartnerSignupRegistration
