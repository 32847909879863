/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/*!
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@                                         @@@@
 *!   @@@   THIS FILE WAS AUTOGENERATED BY TOOL   @@@@
 *!   @@@   CHANGES MUST TARGET SCRIPTS/SWAGGER   @@@@
 *!   @@@                                         @@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!
 */

import {
  _buildQueryString,
  _emptyParams,
  _encode,
  _getBasePath,
  _getRawBasePath,
} from './urls-utils'

/**
 * GET /api/v1/deployments/traffic-filter/associations/{association_type}/{associated_entity_id}/rulesets
 * Operation: `get-traffic-filter-deployment-ruleset-associations`
 * Retrieves the rulesets associated with a deployment.
 * @param {string} params.associationType Association type.
 * @param {string} params.associatedEntityId Associated entity ID.
 * @see 200 Rulesets in the deployment were successfully returned. See {@link TrafficFilterSettings}
 * @see 400 Invalid association type. (code: `traffic_filter.invalid_association_type`). See {@link BasicFailedReply}
 * @see 500 Error fetching traffic filter rulesets. (code: `traffic_filter.request_execution_failed`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getTrafficFilterDeploymentRulesetAssociationsUrl(
  params: GetTrafficFilterDeploymentRulesetAssociationsUrlParams,
): string {
  const path = _encode`/deployments/traffic-filter/associations/${params.associationType}/${params.associatedEntityId}/rulesets`
  return `${_getRawBasePath()}${path}`
}

export interface GetTrafficFilterDeploymentRulesetAssociationsUrlParams {
  associationType: string
  associatedEntityId: string
}

/**
 * GET /api/v1/regions/{region_id}/deployments/traffic-filter/link-ids
 * Operation: `get-traffic-filter-claimed-link-ids`
 * List all of the traffic filter claimed link id.
 * @param {string} params.region If provided limits the claimed id to that region only.
 * @param {string} params.organizationId Retrieves a list of resources that are associated to the specified organization ID. It only takes effect if the user is an admin.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 The collection of traffic filter claimed link id. See {@link TrafficFilterClaimedLinkIds}
 * @see 500 Error fetching traffic filter claimed link ids. (code: `traffic_filter_claimed_link_id.request_execution_failed`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getTrafficFilterClaimedLinkIdsUrl(
  params: GetTrafficFilterClaimedLinkIdsUrlParams,
): string {
  const queryString = _buildQueryString({
    region: params.region,
    organization_id: params.organizationId,
  })
  const path = _encode`/${params.regionId}/deployments/traffic-filter/link-ids`
  return `${_getBasePath()}${path}${queryString}`
}

export interface GetTrafficFilterClaimedLinkIdsUrlParams {
  region?: string | null
  organizationId?: string | null
  regionId: string
}

/**
 * POST /api/v1/regions/{region_id}/deployments/traffic-filter/link-ids/_claim
 * Operation: `claim-traffic-filter-link-id`
 * Claim the ownership of a link id.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see Accepts a request payload of type {@link TrafficFilterClaimedLinkIdRequest}
 * @see 201 The ruleset definition is valid and the creation has started. See {@link TrafficFilterClaimedLinkIdInfo}
 * @see 500 Error creating the traffic filter ruleset. (code: `traffic_filter_claimed_link_id.request_execution_failed`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function claimTrafficFilterLinkIdUrl(params: ClaimTrafficFilterLinkIdUrlParams): string {
  const path = _encode`/${params.regionId}/deployments/traffic-filter/link-ids/_claim`
  return `${_getBasePath()}${path}`
}

export interface ClaimTrafficFilterLinkIdUrlParams {
  regionId: string
}

/**
 * POST /api/v1/regions/{region_id}/deployments/traffic-filter/link-ids/_unclaim
 * Operation: `unclaim-traffic-filter-link-id`
 * Unclaims the ownership of a link id.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see Accepts a request payload of type {@link TrafficFilterClaimedLinkIdRequest}
 * @see 200 The claimed link id was successfully deleted.
 * @see 400 Error validating the request. (code: `traffic_filter_claimed_link_id.invalid_input`). See {@link BasicFailedReply}
 * @see 500 Error deleting the traffic filter claimed link id. (code: `traffic_filter_claimed_link_id.request_execution_failed`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function unclaimTrafficFilterLinkIdUrl(params: UnclaimTrafficFilterLinkIdUrlParams): string {
  const path = _encode`/${params.regionId}/deployments/traffic-filter/link-ids/_unclaim`
  return `${_getBasePath()}${path}`
}

export interface UnclaimTrafficFilterLinkIdUrlParams {
  regionId: string
}

/**
 * GET /api/v1/deployments/traffic-filter/rulesets
 * Operation: `get-traffic-filter-rulesets`
 * List all of the traffic filter rulesets.
 * @param {boolean} params.includeAssociations Retrieves a list of resources that are associated to the specified ruleset.
 * @param {string} params.region If provided limits the rulesets to that region only.
 * @param {string} params.organizationId Retrieves a list of resources that are associated to the specified organization ID. It only takes effect if the user is an admin.
 * @see 200 The collection of traffic filter routes. See {@link TrafficFilterRulesets}
 * @see 500 Error fetching traffic filter rulesets. (code: `traffic_filter.request_execution_failed`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getTrafficFilterRulesetsUrl(
  params: GetTrafficFilterRulesetsUrlParams = _emptyParams,
): string {
  const queryString = _buildQueryString({
    include_associations:
      params.includeAssociations === undefined ? false : params.includeAssociations,
    region: params.region,
    organization_id: params.organizationId,
  })
  const path = `/deployments/traffic-filter/rulesets`
  return `${_getRawBasePath()}${path}${queryString}`
}

export interface GetTrafficFilterRulesetsUrlParams {
  includeAssociations?: boolean | null
  region?: string | null
  organizationId?: string | null
}

/**
 * POST /api/v1/deployments/traffic-filter/rulesets
 * Operation: `create-traffic-filter-ruleset`
 * Creates a ruleset that consists of a set of rules.
 * @see Accepts a request payload of type {@link TrafficFilterRulesetRequest}
 * @see 201 The ruleset definition is valid and the creation has started. See {@link TrafficFilterRulesetResponse}
 * @see 500 Error creating the traffic filter ruleset. (code: `traffic_filter.request_execution_failed`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function createTrafficFilterRulesetUrl(): string {
  const path = `/deployments/traffic-filter/rulesets`
  return `${_getRawBasePath()}${path}`
}

/**
 * GET /api/v1/deployments/traffic-filter/rulesets/{ruleset_id}
 * Operation: `get-traffic-filter-ruleset`
 * Retrieves a list of resources that are associated to the specified ruleset.
 * @param {string} params.rulesetId The mandatory ruleset ID.
 * @param {boolean} params.includeAssociations Retrieves a list of resources that are associated to the specified ruleset.
 * @see 200 The container for a set of traffic filter rules. See {@link TrafficFilterRulesetInfo}
 * @see 404 The traffic filter ruleset specified by {ruleset_id} cannot be found. (code: `traffic_filter.not_found`). See {@link BasicFailedReply}
 * @see 500 Error reading the traffic filter ruleset. (code: `traffic_filter.request_execution_failed`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getTrafficFilterRulesetUrl(params: GetTrafficFilterRulesetUrlParams): string {
  const queryString = _buildQueryString({
    include_associations:
      params.includeAssociations === undefined ? false : params.includeAssociations,
  })
  const path = _encode`/deployments/traffic-filter/rulesets/${params.rulesetId}`
  return `${_getRawBasePath()}${path}${queryString}`
}

export interface GetTrafficFilterRulesetUrlParams {
  rulesetId: string
  includeAssociations?: boolean | null
}

/**
 * PUT /api/v1/deployments/traffic-filter/rulesets/{ruleset_id}
 * Operation: `update-traffic-filter-ruleset`
 * Updates the ruleset with the definition.
 * @param {string} params.rulesetId The mandatory ruleset ID.
 * @see Accepts a request payload of type {@link TrafficFilterRulesetRequest}
 * @see 200 The ruleset definition was valid and the update has started. See {@link TrafficFilterRulesetResponse}
 * @see 404 The traffic filter ruleset specified by {ruleset_id} cannot be found. (code: `traffic_filter.not_found`). See {@link BasicFailedReply}
 * @see 500 Error reading the traffic filter ruleset. (code: `traffic_filter.request_execution_failed`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function updateTrafficFilterRulesetUrl(params: UpdateTrafficFilterRulesetUrlParams): string {
  const path = _encode`/deployments/traffic-filter/rulesets/${params.rulesetId}`
  return `${_getRawBasePath()}${path}`
}

export interface UpdateTrafficFilterRulesetUrlParams {
  rulesetId: string
}

/**
 * DELETE /api/v1/deployments/traffic-filter/rulesets/{ruleset_id}
 * Operation: `delete-traffic-filter-ruleset`
 * Deletes the ruleset by ID.
 * @param {string} params.rulesetId The mandatory ruleset ID.
 * @param {boolean} params.ignoreAssociations When true, ignores the associations and deletes the ruleset. When false, recognizes the associations, which prevents the deletion of the rule set.
 * @see 200 The traffic filter ruleset was successfully deleted.
 * @see 404 The traffic filter ruleset specified by {ruleset_id} cannot be found. (code: `traffic_filter.not_found`). See {@link BasicFailedReply}
 * @see 500 Error deleting the traffic filter ruleset. (code: `traffic_filter.request_execution_failed`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function deleteTrafficFilterRulesetUrl(params: DeleteTrafficFilterRulesetUrlParams): string {
  const queryString = _buildQueryString({
    ignore_associations:
      params.ignoreAssociations === undefined ? false : params.ignoreAssociations,
  })
  const path = _encode`/deployments/traffic-filter/rulesets/${params.rulesetId}`
  return `${_getRawBasePath()}${path}${queryString}`
}

export interface DeleteTrafficFilterRulesetUrlParams {
  rulesetId: string
  ignoreAssociations?: boolean | null
}

/**
 * GET /api/v1/deployments/traffic-filter/rulesets/{ruleset_id}/associations
 * Operation: `get-traffic-filter-ruleset-deployment-associations`
 * Retrieves a list of deployments that are associated to the specified ruleset.
 * @param {string} params.rulesetId The mandatory ruleset ID.
 * @see 200 Associations referred by traffic filter rulesets were successfully returned. See {@link RulesetAssociations}
 * @see 500 Error fetching deployments. (code: `traffic_filter.request_execution_failed`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getTrafficFilterRulesetDeploymentAssociationsUrl(
  params: GetTrafficFilterRulesetDeploymentAssociationsUrlParams,
): string {
  const path = _encode`/deployments/traffic-filter/rulesets/${params.rulesetId}/associations`
  return `${_getRawBasePath()}${path}`
}

export interface GetTrafficFilterRulesetDeploymentAssociationsUrlParams {
  rulesetId: string
}

/**
 * POST /api/v1/deployments/traffic-filter/rulesets/{ruleset_id}/associations
 * Operation: `create-traffic-filter-ruleset-association`
 * Applies the ruleset to the specified deployment.
 * @param {string} params.rulesetId The mandatory ruleset ID.
 * @see Accepts a request payload of type {@link FilterAssociation}
 * @see 200 Create association request was valid and the association already exists
 * @see 201 Create association request was valid and the association has been created
 * @see 404 The traffic filter ruleset specified by {ruleset_id} cannot be found. (code: `traffic_filter.not_found`). See {@link BasicFailedReply}
 * @see 500 Creation failed. (code: `traffic_filter.request_execution_failed`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function createTrafficFilterRulesetAssociationUrl(
  params: CreateTrafficFilterRulesetAssociationUrlParams,
): string {
  const path = _encode`/deployments/traffic-filter/rulesets/${params.rulesetId}/associations`
  return `${_getRawBasePath()}${path}`
}

export interface CreateTrafficFilterRulesetAssociationUrlParams {
  rulesetId: string
}

/**
 * DELETE /api/v1/deployments/traffic-filter/rulesets/{ruleset_id}/associations/{association_type}/{associated_entity_id}
 * Operation: `delete-traffic-filter-ruleset-association`
 * Deletes the traffic rules in the ruleset from the deployment.
 * @param {string} params.rulesetId The mandatory ruleset ID.
 * @param {string} params.associationType Association type.
 * @param {string} params.associatedEntityId Associated entity ID.
 * @see 200 Delete association request was valid and the association has been deleted
 * @see 404 The traffic filter ruleset association cannot be found. (code: `traffic_filter.not_found`). See {@link BasicFailedReply}
 * @see 500 Deletion failed. (code: `traffic_filter.request_execution_failed`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function deleteTrafficFilterRulesetAssociationUrl(
  params: DeleteTrafficFilterRulesetAssociationUrlParams,
): string {
  const path = _encode`/deployments/traffic-filter/rulesets/${params.rulesetId}/associations/${params.associationType}/${params.associatedEntityId}`
  return `${_getRawBasePath()}${path}`
}

export interface DeleteTrafficFilterRulesetAssociationUrlParams {
  rulesetId: string
  associationType: string
  associatedEntityId: string
}

/**
 * GET /api/v1/regions/{region_id}/deployments/traffic-filter/tokens/{deployment_id}/{resource_kind}/{ref_id}
 * Operation: `get-traffic-filter-token`
 * Creates a fresh bypass token for the specified deployment resource.
 * @param {string} params.deploymentId Identifier for the Deployment.
 * @param {string} params.resourceKind The kind of resource.
 * @param {string} params.refId User-specified RefId for the Resource (or '_main' if there is only one).
 * @param {string} params.duration For how long the token will remain valid. Minimum 1 minute and maximum 72 hours. Example: 5m, 3h or 2 days.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 The token for the resource. See {@link TrafficFilterTokenResponse}
 * @see 400 * The given duration was longer than 72 hours. (code: `traffic_filter_token.duration_too_long`)
 * The given duration was shorter than 1 minute. (code: `traffic_filter_token.duration_too_short`). See {@link BasicFailedReply}
 * @see 403 Insufficient credentials. (code: `traffic_filter.invalid_principal`). See {@link BasicFailedReply}
 * @see 404 * The Deployment specified by {deployment_id} cannot be found. (code: `deployments.deployment_not_found`)
 * The Resource specified by {ref_id} cannot be found. (code: `deployments.deployment_resource_not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getTrafficFilterTokenUrl(params: GetTrafficFilterTokenUrlParams): string {
  const queryString = _buildQueryString({
    duration: params.duration,
  })
  const path = _encode`/${params.regionId}/deployments/traffic-filter/tokens/${params.deploymentId}/${params.resourceKind}/${params.refId}`
  return `${_getBasePath()}${path}${queryString}`
}

export interface GetTrafficFilterTokenUrlParams {
  deploymentId: string
  resourceKind: string
  refId: string
  duration?: string | null
  regionId: string
}
