/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { useMutation } from 'react-query'

import {
  patchElasticsearchProject,
  patchObservabilityProject,
  patchSecurityProject,
} from '@modules/project-user-api/v1/callers'
import type {
  PatchElasticsearchProjectRequest,
  PatchObservabilityProjectRequest,
  PatchSecurityProjectRequest,
  ProjectID,
} from '@modules/project-user-api/v1/types'
import type { QueryFunctionReturnType } from '@modules/query/types'
import type { AnyUserProject } from '@modules/project-user-api/types'
import { queryClient } from '@modules/query'
import type { ProjectApiError, ProjectType } from '@modules/ui-types/projects'

import { makeQueryKey } from './helpers'

export function usePatchProjectMutation(type: ProjectType) {
  const elasticsearchMutation = usePatchElasticsearchProjectMutation()
  const observabilityMutation = usePatchObservabilityProjectMutation()
  const securityMutation = usePatchSecurityProjectMutation()

  return {
    elasticsearch: elasticsearchMutation,
    observability: observabilityMutation,
    security: securityMutation,
  }[type]
}

export const usePatchElasticsearchProjectMutation = () =>
  useMutation<
    QueryFunctionReturnType<typeof patchElasticsearchProject>,
    ProjectApiError,
    { id: ProjectID; body: PatchElasticsearchProjectRequest }
  >({
    mutationFn: ({ id, body }) =>
      patchElasticsearchProject({
        pathParameters: { id },
        body,
      }),
    onSuccess: onProjectPatched,
  })

export const usePatchObservabilityProjectMutation = () =>
  useMutation<
    QueryFunctionReturnType<typeof patchObservabilityProject>,
    ProjectApiError,
    { id: ProjectID; body: PatchObservabilityProjectRequest }
  >({
    mutationFn: ({ id, body }) =>
      patchObservabilityProject({
        pathParameters: { id },
        body,
      }),
    onSuccess: onProjectPatched,
  })

export const usePatchSecurityProjectMutation = () =>
  useMutation<
    QueryFunctionReturnType<typeof patchSecurityProject>,
    ProjectApiError,
    { id: ProjectID; body: PatchSecurityProjectRequest }
  >({
    mutationFn: ({ id, body }) =>
      patchSecurityProject({
        pathParameters: { id },
        body,
      }),
    onSuccess: onProjectPatched,
  })

const onProjectPatched = (project: AnyUserProject) => {
  const listQueryKey = makeQueryKey(
    (
      {
        elasticsearch: 'listElasticsearchProjects',
        observability: 'listObservabilityProjects',
        security: 'listSecurityProjects',
      } as const
    )[project.type],
  )
  queryClient.invalidateQueries(listQueryKey)

  const detailQueryKey = makeQueryKey(
    (
      {
        elasticsearch: 'getElasticsearchProject',
        observability: 'getObservabilityProject',
        security: 'getSecurityProject',
      } as const
    )[project.type],
    project.id,
  )
  queryClient.setQueryData(detailQueryKey, project)
}
