/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { EuiButton, EuiToolTip } from '@elastic/eui'

import history from '@modules/utils/history'
import { resourceCreationManagedUrl } from '@modules/project-creation-lib/urls'
import usePermissions from '@modules/permissions-lib/hooks'
import { useProfile } from '@modules/profile-lib/hooks'
import { useConfig } from '@modules/cui/ConfigContext'

import type { FC, ReactElement } from 'react'

export interface Props {
  canCreateProject: boolean
  hasAccentColor?: boolean
  isDisabled?: boolean
  onClickFallback?: () => void
  size?: 's' | 'm'
}

interface PermissionsTooltipProps {
  children: ReactElement
  hasPermissions: boolean
}

const PermissionsTooltip: FC<PermissionsTooltipProps> = ({ children, hasPermissions }) => {
  const { formatMessage } = useIntl()

  if (hasPermissions) {
    return children
  }

  return (
    <EuiToolTip
      position='top'
      content={formatMessage({
        id: 'no-permission-to-create-projects',
        defaultMessage: `You don't have permissions to create projects`,
      })}
    >
      {children}
    </EuiToolTip>
  )
}

const CreateProjectButtonBase: FC<Props> = ({
  canCreateProject,
  hasAccentColor,
  isDisabled,
  onClickFallback,
  size = 'm',
}) => {
  const profile = useProfile()
  const { hasPermissions } = usePermissions(
    [
      { type: 'project-elasticsearch', action: 'create' },
      { type: 'project-observability', action: 'create' },
      { type: 'project-security', action: 'create' },
    ],
    'some',
  )

  const isBorealisThemeEnabled = useConfig('FEATURE_ENABLE_BOREALIS_THEME')
  const accentColor = isBorealisThemeEnabled ? 'accentSecondary' : 'success'

  return (
    <PermissionsTooltip hasPermissions={hasPermissions}>
      <EuiButton
        color={hasAccentColor ? accentColor : 'primary'}
        fill={true}
        size={size}
        isDisabled={isDisabled || (!!profile?.organization_id && !hasPermissions)}
        onClick={
          canCreateProject ? () => history.push(resourceCreationManagedUrl()) : onClickFallback
        }
        data-test-id='create-project-link'
      >
        <FormattedMessage
          id='portal-projects.create-link'
          defaultMessage='Create serverless project'
        />
      </EuiButton>
    </PermissionsTooltip>
  )
}

export default CreateProjectButtonBase
