/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import { useConfig } from '@modules/cui/ConfigContext'
import { hasUnexpiredSession } from '@modules/auth/auth'
import { useAdminConsoleLogout } from '@modules/logout-admin/hooks'
import { useSaasUserLogout } from '@modules/logout-user/hooks'

import type { UseMutateFunction, UseMutationOptions } from 'react-query'

const retryNTimes =
  ({ retryTimes }: { retryTimes: number }) =>
  (failureCount: number) => {
    if (failureCount > retryTimes) {
      return false
    }

    return true
  }

export const useServerLogout = (
  options: UseMutationOptions,
): {
  serverLogout: UseMutateFunction
  isLoading: boolean
} => {
  const isSessionExpired = !hasUnexpiredSession()
  const retryTimes = isSessionExpired ? 0 : 3
  const isAdminConsole = useConfig('APP_NAME') === 'adminconsole'
  const { mutate: adminConsoleServerLogout, isLoading: isAcLogoutLoading } = useAdminConsoleLogout({
    retry: retryNTimes({ retryTimes }),
    ...options,
  })
  const { mutate: saasUserServerLogout, isLoading: isUcLogoutLoading } = useSaasUserLogout({
    retry: retryNTimes({ retryTimes }),
    ...options,
  })

  return {
    serverLogout: isAdminConsole ? adminConsoleServerLogout : saasUserServerLogout,
    isLoading: isAdminConsole ? isAcLogoutLoading : isUcLogoutLoading,
  }
}
