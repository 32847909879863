/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/*!
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@                                         @@@@
 *!   @@@   THIS FILE WAS AUTOGENERATED BY TOOL   @@@@
 *!   @@@   CHANGES MUST TARGET SCRIPTS/SWAGGER   @@@@
 *!   @@@                                         @@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!
 */

import {
  _buildQueryString,
  _emptyParams,
  _encode,
  _getBasePath,
  _getRawBasePath,
} from './urls-utils'

/**
 * GET /api/v1/regions/{region_id}/aliases/{alias}
 * Operation: `get-deployment-alias`
 * Return the deployment alias information for the given alias.
 * @param {string} params.alias The deployment alias to be transferred.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 The deployment alias information for the given deployment alias. See {@link DeploymentAliasResponse}
 * @see 404 The alias does not exist in this region. (code: `deployment_alias.alias_not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getDeploymentAliasUrl(params: GetDeploymentAliasUrlParams): string {
  const path = _encode`/${params.regionId}/aliases/${params.alias}`
  return `${_getBasePath()}${path}`
}

export interface GetDeploymentAliasUrlParams {
  alias: string
  regionId: string
}

/**
 * PUT /api/v1/regions/{region_id}/aliases/{alias}/transfer
 * Operation: `transfer-deployment-alias-ownership`
 * Transfer the ownership of an inactive deployment alias from one organization to another.
 * @param {string} params.alias The deployment alias to be transferred.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see Accepts a request payload of type {@link DeploymentAliasTransferRequest}
 * @see 200 The transferred Deployment Alias. See {@link DeploymentAliasResponse}
 * @see 400 You cannot modify an alias that is actively in use. (code: `deployment_alias.active_alias`). See {@link BasicFailedReply}
 * @see 404 The alias does not exist in this region. (code: `deployment_alias.alias_not_found`). See {@link BasicFailedReply}
 * @see 409 The alias was modified by another process. Refresh and try again. (code: `deployment_alias.alias_modified_concurrently`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function transferDeploymentAliasOwnershipUrl(
  params: TransferDeploymentAliasOwnershipUrlParams,
): string {
  const path = _encode`/${params.regionId}/aliases/${params.alias}/transfer`
  return `${_getBasePath()}${path}`
}

export interface TransferDeploymentAliasOwnershipUrlParams {
  alias: string
  regionId: string
}

/**
 * GET /api/v1/deployments
 * Operation: `list-deployments`
 * List Deployments.
 * @param {string} params.deploymentIds Optional comma separated list of deployment IDs to return in the list response.
 * @see 200 The list of deployments that belong to the authenticated user. See {@link DeploymentsListResponse}
 * @see 401 You are not authorized to perform this action. See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function listDeploymentsUrl(params: ListDeploymentsUrlParams = _emptyParams): string {
  const queryString = _buildQueryString({
    deployment_ids: params.deploymentIds,
  })
  const path = `/deployments`
  return `${_getRawBasePath()}${path}${queryString}`
}

export interface ListDeploymentsUrlParams {
  deploymentIds?: string | null
}

/**
 * POST /api/v1/deployments
 * Operation: `create-deployment`
 * Creates a Deployment.
 * @param {string} params.requestId An optional idempotency token - if two create requests share the same request_id token (min size 32 characters, max 128) then only one deployment will be created, the second request will return the info of that deployment (in the same format described below, but with blanks for auth-related fields).
 * @param {boolean} params.validateOnly If true, will just validate the Deployment definition but will not perform the creation.
 * @param {string} params.templateId An optional template id - if present, the referenced template will be used to fill in the resources field of the deployment creation request. If any resources are present in the request together with the template, the ones coming in the request will prevail and no merging with the template will be performed.
 * @see Accepts a request payload of type {@link DeploymentCreateRequest}
 * @see 200 The request was valid (used when validate_only is true). See {@link DeploymentCreateResponse}
 * @see 201 The request was valid and a new deployment was created. See {@link DeploymentCreateResponse}
 * @see 202 The request was valid and deployment creation had already been started. See {@link DeploymentCreateResponse}
 * @see 400 The deployment request had errors. See {@link BasicFailedReply}
 * @see 401 You are not authorized to perform this action. See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function createDeploymentUrl(params: CreateDeploymentUrlParams = _emptyParams): string {
  const queryString = _buildQueryString({
    request_id: params.requestId,
    validate_only: params.validateOnly === undefined ? false : params.validateOnly,
    template_id: params.templateId,
  })
  const path = `/deployments`
  return `${_getRawBasePath()}${path}${queryString}`
}

export interface CreateDeploymentUrlParams {
  requestId?: string | null
  validateOnly?: boolean | null
  templateId?: string | null
}

/**
 * POST /api/v1/regions/{region_id}/deployments/_resync
 * Operation: `resync-deployments`
 * Resynchronizes the search index for all the deployments.
 * @param {boolean} params.skipMatchingVersion When true, skips the document indexing when the version matches the in-memory copy. Default: true
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 The deployments resync operation executed successfully. See {@link IndexSynchronizationResults}
 * @see 500 The deployments resync operation failed. (code: `deployments.resync_failed`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function resyncDeploymentsUrl(params: ResyncDeploymentsUrlParams): string {
  const queryString = _buildQueryString({
    skip_matching_version:
      params.skipMatchingVersion === undefined ? true : params.skipMatchingVersion,
  })
  const path = _encode`/${params.regionId}/deployments/_resync`
  return `${_getBasePath()}${path}${queryString}`
}

export interface ResyncDeploymentsUrlParams {
  skipMatchingVersion?: boolean | null
  regionId: string
}

/**
 * POST /api/v1/deployments/_search
 * Operation: `search-deployments`
 * Retrieves the information for all of the deployments that match the specified query.
 * @param {string} params.minimalMetadata Comma separated list of attributes to include in response for deployments found. Useful for reducing response size when retrieving many deployments. Use of this parameter moves the result to the minimal_metadata section of the response.
 * @see Accepts an optional request payload of type {@link SearchRequest}
 * @see 200 The list of deployments that match the specified query and belong to the authenticated user. See {@link DeploymentsSearchResponse}
 * @return {string} A fully-interpolated and escaped URL
 */
export function searchDeploymentsUrl(params: SearchDeploymentsUrlParams = _emptyParams): string {
  const queryString = _buildQueryString({
    minimal_metadata: params.minimalMetadata,
  })
  const path = `/deployments/_search`
  return `${_getRawBasePath()}${path}${queryString}`
}

export interface SearchDeploymentsUrlParams {
  minimalMetadata?: string | null
}

/**
 * GET /api/v1/deployments/byok-configuration-parameters
 * Operation: `get-byok-configuration-parameters`
 * Get BYOK configuration parameters. Currently unavailable in self-hosted ECE.
 * @param {string} params.region Region of the BYOK configuration parameters.
 * @see 200 The BYOK configuration parameters for the specified region. See {@link ByokConfigurationParameters}
 * @see 400 The requested region is not supported. (code: `byok_configuration.region_not_found`). See {@link BasicFailedReply}
 * @see 401 You are not authorized to perform this action. See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getByokConfigurationParametersUrl(
  params: GetByokConfigurationParametersUrlParams,
): string {
  const queryString = _buildQueryString({
    region: params.region,
  })
  const path = `/deployments/byok-configuration-parameters`
  return `${_getRawBasePath()}${path}${queryString}`
}

export interface GetByokConfigurationParametersUrlParams {
  region: string
}

/**
 * POST /api/v1/deployments/eligible-remote-clusters
 * Operation: `search-eligible-remote-clusters`
 * Returns the list of deployments which contain eligible remote clusters for a specific version.
 * @param {string} params.version The version of the Elasticsearch cluster cluster that will potentially be configured to have remote clusters.
 * @see Accepts an optional request payload of type {@link SearchRequest}
 * @see 200 List of deployments which contains eligible remote clusters for a specific version. See {@link DeploymentsSearchResponse}
 * @see 400 The provided version is invalid. (code: `stackpack.invalid_version`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function searchEligibleRemoteClustersUrl(
  params: SearchEligibleRemoteClustersUrlParams,
): string {
  const queryString = _buildQueryString({
    version: params.version,
  })
  const path = `/deployments/eligible-remote-clusters`
  return `${_getRawBasePath()}${path}${queryString}`
}

export interface SearchEligibleRemoteClustersUrlParams {
  version: string
}

/**
 * GET /api/v1/deployments/{deployment_id}
 * Operation: `get-deployment`
 * Retrieves information about a Deployment.
 * @param {string} params.deploymentId Identifier for the Deployment.
 * @param {boolean} params.showSecurity Whether to include the Elasticsearch 2.x security information in the response - can be large per cluster and also include credentials.
 * @param {boolean} params.showMetadata Whether to include the full cluster metadata in the response - can be large per cluster and also include credentials.
 * @param {boolean} params.showPlans Whether to include the full current and pending plan information in the response - can be large per cluster. Default: true
 * @param {boolean} params.showPlanLogs Whether to include with the current and pending plan information the attempt log - can be very large per cluster.
 * @param {boolean} params.showPlanHistory Whether to include the plan history with the current and pending plan information. The results can be very large per cluster.
 By default, if a given resource kind (e.g. Elasticsearch, Kibana, etc.) has more than 100 plans
 (which should be very rare, most likely caused by a bug) only 100 plans are returned for the given resource type:
 The first 10 plans, and the last 90 plans for that resource type.
 If ALL of the plans are desired, pass the `force_all_plan_history` parameter with a value of `true`.
.
 * @param {boolean} params.showPlanDefaults If showing plans, whether to show values that are left at their default value (less readable but more informative).
 * @param {boolean} params.convertLegacyPlans If showing plans, whether to leave pre-2.0.0 plans in their legacy format (the default), or whether to update them to 2.0.x+ format (if 'true').
 * @param {integer} params.showSystemAlerts Number of system alerts (such as forced restarts due to memory limits) to be included in the response - can be large per cluster. Negative numbers or 0 will not return field.
 * @param {boolean} params.showSettings Whether to show cluster settings in the response.
 * @param {boolean} params.showInstanceMetrics Whether to show resources instance metrics in the response. Default: true
 * @param {boolean} params.showInstanceConfigurations If true, will return details for each instance configuration referenced by the deployment. Default: true
 * @param {boolean} params.enrichWithTemplate If showing plans, whether to enrich the plan by including the missing elements from the deployment template it is based on. Default: true
 * @param {boolean} params.forceAllPlanHistory Force show the entire plan history no matter how long.
 As noted in the `show_plan_history` parameter description, by default, a maximum of 100 plans are shown per resource. 
 If `true`, this parameter overrides the default, and ALL plans are returned.
 Use with care as the plan history can be VERY large. Consider pairing with `show_plan_logs=false`.
 .
 * @param {boolean} params.clearTransient If set (defaults to false) then removes the transient section from all child resources, making it safe to reapply via an update.
 * @see 200 The Deployment info response. See {@link DeploymentGetResponse}
 * @see 401 You are not authorized to perform this action. See {@link BasicFailedReply}
 * @see 404 The Deployment specified by {deployment_id} cannot be found. (code: `deployments.deployment_not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getDeploymentUrl(params: GetDeploymentUrlParams): string {
  const queryString = _buildQueryString({
    show_security: params.showSecurity === undefined ? false : params.showSecurity,
    show_metadata: params.showMetadata === undefined ? false : params.showMetadata,
    show_plans: params.showPlans === undefined ? true : params.showPlans,
    show_plan_logs: params.showPlanLogs === undefined ? false : params.showPlanLogs,
    show_plan_history: params.showPlanHistory === undefined ? false : params.showPlanHistory,
    show_plan_defaults: params.showPlanDefaults === undefined ? false : params.showPlanDefaults,
    convert_legacy_plans:
      params.convertLegacyPlans === undefined ? false : params.convertLegacyPlans,
    show_system_alerts: params.showSystemAlerts === undefined ? 0 : params.showSystemAlerts,
    show_settings: params.showSettings === undefined ? false : params.showSettings,
    show_instance_metrics:
      params.showInstanceMetrics === undefined ? true : params.showInstanceMetrics,
    show_instance_configurations:
      params.showInstanceConfigurations === undefined ? true : params.showInstanceConfigurations,
    enrich_with_template:
      params.enrichWithTemplate === undefined ? true : params.enrichWithTemplate,
    force_all_plan_history:
      params.forceAllPlanHistory === undefined ? false : params.forceAllPlanHistory,
    clear_transient: params.clearTransient === undefined ? false : params.clearTransient,
  })
  const path = _encode`/deployments/${params.deploymentId}`
  return `${_getRawBasePath()}${path}${queryString}`
}

export interface GetDeploymentUrlParams {
  deploymentId: string
  showSecurity?: boolean | null
  showMetadata?: boolean | null
  showPlans?: boolean | null
  showPlanLogs?: boolean | null
  showPlanHistory?: boolean | null
  showPlanDefaults?: boolean | null
  convertLegacyPlans?: boolean | null
  showSystemAlerts?: number | null
  showSettings?: boolean | null
  showInstanceMetrics?: boolean | null
  showInstanceConfigurations?: boolean | null
  enrichWithTemplate?: boolean | null
  forceAllPlanHistory?: boolean | null
  clearTransient?: boolean | null
}

/**
 * PUT /api/v1/deployments/{deployment_id}
 * Operation: `update-deployment`
 * Updates a Deployment.
 * @param {string} params.deploymentId Identifier for the Deployment.
 * @param {boolean} params.hidePrunedOrphans Whether or not to hide orphaned resources that were shut down (relevant if prune on the request is true).
 * @param {boolean} params.skipSnapshot Whether or not to skip snapshots before shutting down orphaned resources (relevant if prune on the request is true).
 * @param {boolean} params.validateOnly If true, will just validate the Deployment definition but will not perform the update.
 * @param {string} params.version If specified then checks for conflicts against the version stored in the persistent store (returned in 'x-cloud-resource-version' of the GET request).
 * @see Accepts a request payload of type {@link DeploymentUpdateRequest}
 * @see 200 The request was valid and the deployment was updated. See {@link DeploymentUpdateResponse}
 * @see 400 The deployment request had errors. See {@link BasicFailedReply}
 * @see 401 You are not authorized to perform this action. See {@link BasicFailedReply}
 * @see 404 The Deployment specified by {deployment_id} cannot be found. (code: `deployments.deployment_not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function updateDeploymentUrl(params: UpdateDeploymentUrlParams): string {
  const queryString = _buildQueryString({
    hide_pruned_orphans: params.hidePrunedOrphans === undefined ? false : params.hidePrunedOrphans,
    skip_snapshot: params.skipSnapshot === undefined ? false : params.skipSnapshot,
    validate_only: params.validateOnly === undefined ? false : params.validateOnly,
    version: params.version,
  })
  const path = _encode`/deployments/${params.deploymentId}`
  return `${_getRawBasePath()}${path}${queryString}`
}

export interface UpdateDeploymentUrlParams {
  deploymentId: string
  hidePrunedOrphans?: boolean | null
  skipSnapshot?: boolean | null
  validateOnly?: boolean | null
  version?: string | null
}

/**
 * DELETE /api/v1/deployments/{deployment_id}
 * Operation: `delete-deployment`
 * Deletes a Deployment and all its resources.
 * @param {string} params.deploymentId Identifier for the Deployment.
 * @see 200 The request was valid and the deployment was deleted. See {@link DeploymentDeleteResponse}
 * @see 400 The Deployment resources have not been shutdown yet. See {@link BasicFailedReply}
 * @see 401 You are not authorized to perform this action. See {@link BasicFailedReply}
 * @see 404 The Deployment specified by {deployment_id} cannot be found. (code: `deployments.deployment_not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function deleteDeploymentUrl(params: DeleteDeploymentUrlParams): string {
  const path = _encode`/deployments/${params.deploymentId}`
  return `${_getRawBasePath()}${path}`
}

export interface DeleteDeploymentUrlParams {
  deploymentId: string
}

/**
 * POST /api/v1/regions/{region_id}/deployments/{deployment_id}/_migrate
 * Operation: `migrate-deployment`
 * > WARNING > This endpoint is deprecated and scheduled to be removed in the next major version. Use [migrate deployment template](#migrate-deployment-template) instead.  Returns a deployment update request that would transform this deployment from its template to the provided one.
 * @param {string} params.deploymentId Identifier for the Deployment.
 * @param {string} params.template The ID of the deployment template to migrate to.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 The request was valid and the deployment can be migrated to the template with the provided id. See {@link DeploymentTemplateMigrateResponse}
 * @see 400 The deployment cannot be successfully migrated to the template with the provided id. See {@link BasicFailedReply}
 * @see 401 You are not authorized to perform this action. See {@link BasicFailedReply}
 * @see 404 The Deployment specified by {deployment_id} cannot be found. (code: `deployments.deployment_not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function migrateDeploymentUrl(params: MigrateDeploymentUrlParams): string {
  const queryString = _buildQueryString({
    template: params.template,
  })
  const path = _encode`/${params.regionId}/deployments/${params.deploymentId}/_migrate`
  return `${_getBasePath()}${path}${queryString}`
}

export interface MigrateDeploymentUrlParams {
  deploymentId: string
  template: string
  regionId: string
}

/**
 * POST /api/v1/regions/{region_id}/deployments/{deployment_id}/_notify-revoked-key
 * Operation: `notify-revoked-key`
 * Stops all the Running instances of all resources(elasticsearch, kibana and apm) of this deployment and locks the directory, if deployment is BYOK enabled.
 * @param {string} params.deploymentId Identifier for the Deployment.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 202 All running instances stopped and data locked. See {@link DeploymentResourceCommandResponse}
 * @see 403 The notifyRevokedKey command prohibited for the given delpoyment. (code: `deployments.instance_update_prohibited_error`). See {@link BasicFailedReply}
 * @see 404 * The Deployment specified by {deployment_id} cannot be found. (code: `deployments.deployment_not_found`)
 * One or more resource under deployment specified by {deployment_id} are missing. (code: `deployments.deployment_resource_not_found`)
 * One or more instances of resource under deployment specified by {deployment_id} are missing. (code: `deployments.instances_missing_on_update_error`). See {@link BasicFailedReply}
 * @see 500 Update of One or more resource belonging to deployment: deployment_id failed. (code: `deployments.deployment_update_failed_on_resource_update`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function notifyRevokedKeyUrl(params: NotifyRevokedKeyUrlParams): string {
  const path = _encode`/${params.regionId}/deployments/${params.deploymentId}/_notify-revoked-key`
  return `${_getBasePath()}${path}`
}

export interface NotifyRevokedKeyUrlParams {
  deploymentId: string
  regionId: string
}

/**
 * POST /api/v1/deployments/{deployment_id}/_restore
 * Operation: `restore-deployment`
 * Restores all resources in a Deployment.
 * @param {string} params.deploymentId Identifier for the Deployment.
 * @param {boolean} params.restoreSnapshot Whether or not to restore a snapshot for those resources that allow it.
 * @see 200 The request was valid and the resources of the deployment were restored. See {@link DeploymentRestoreResponse}
 * @see 400 There are Elasticsearch resources in the deployment which are not shut down. See {@link BasicFailedReply}
 * @see 401 You are not authorized to perform this action. See {@link BasicFailedReply}
 * @see 404 The Deployment specified by {deployment_id} cannot be found. (code: `deployments.deployment_not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function restoreDeploymentUrl(params: RestoreDeploymentUrlParams): string {
  const queryString = _buildQueryString({
    restore_snapshot: params.restoreSnapshot === undefined ? false : params.restoreSnapshot,
  })
  const path = _encode`/deployments/${params.deploymentId}/_restore`
  return `${_getRawBasePath()}${path}${queryString}`
}

export interface RestoreDeploymentUrlParams {
  deploymentId: string
  restoreSnapshot?: boolean | null
}

/**
 * POST /api/v1/regions/{region_id}/deployments/{deployment_id}/_resync
 * Operation: `resync-deployment`
 * Immediately resynchronizes the search index for the selected deployment.
 * @param {string} params.deploymentId Identifier for the Deployment.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 The deployment resync operation executed successfully. See {@link IndexSynchronizationResults}
 * @see 500 The deployment resync operation failed for deployment {deployment_id}. (code: `deployments.resync_failed`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function resyncDeploymentUrl(params: ResyncDeploymentUrlParams): string {
  const path = _encode`/${params.regionId}/deployments/${params.deploymentId}/_resync`
  return `${_getBasePath()}${path}`
}

export interface ResyncDeploymentUrlParams {
  deploymentId: string
  regionId: string
}

/**
 * POST /api/v1/deployments/{deployment_id}/_shutdown
 * Operation: `shutdown-deployment`
 * Shuts down all resources in a Deployment.
 * @param {string} params.deploymentId Identifier for the Deployment.
 * @param {boolean} params.hide Whether or not to hide the deployment and its resources.Only applicable for Platform administrators.
 * @param {boolean} params.skipSnapshot Whether or not to skip snapshots before shutting down the resources.
 * @see 200 The request was valid and the resources of the deployment were shutdown. See {@link DeploymentShutdownResponse}
 * @see 400 Parameter is restricted and can only be set by a Platform administrator. (code: `deployments.restricted_parameter`). See {@link BasicFailedReply}
 * @see 401 You are not authorized to perform this action. See {@link BasicFailedReply}
 * @see 404 The Deployment specified by {deployment_id} cannot be found. (code: `deployments.deployment_not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function shutdownDeploymentUrl(params: ShutdownDeploymentUrlParams): string {
  const queryString = _buildQueryString({
    hide: params.hide,
    skip_snapshot: params.skipSnapshot === undefined ? false : params.skipSnapshot,
  })
  const path = _encode`/deployments/${params.deploymentId}/_shutdown`
  return `${_getRawBasePath()}${path}${queryString}`
}

export interface ShutdownDeploymentUrlParams {
  deploymentId: string
  hide?: boolean | null
  skipSnapshot?: boolean | null
}

/**
 * GET /api/v1/regions/{region_id}/deployments/{deployment_id}/apm/{ref_id}
 * Operation: `get-deployment-apm-resource-info`
 * Get info about an APM Resource belonging to a given Deployment.
 * @param {string} params.deploymentId Identifier for the Deployment.
 * @param {string} params.refId User-specified RefId for the Resource (or '_main' if there is only one).
 * @param {boolean} params.showMetadata Whether to include the full cluster metadata in the response - can be large per cluster and also include credentials.
 * @param {boolean} params.showPlans Whether to include the full current and pending plan information in the response - can be large per cluster. Default: true
 * @param {boolean} params.showPlanLogs Whether to include with the current and pending plan information the attempt log - can be very large per cluster.
 * @param {boolean} params.showPlanHistory Whether to include with the current and pending plan information the plan history- can be very large per cluster.
 * @param {boolean} params.showPlanDefaults If showing plans, whether to show values that are left at their default value (less readable but more informative).
 * @param {boolean} params.showSettings Whether to show cluster settings in the response.
 * @param {boolean} params.clearTransient If set (defaults to false) then removes the transient section from all child resources, making it safe to reapply via an update.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 Standard response. See {@link ApmResourceInfo}
 * @see 404 The Deployment specified by {deployment_id} cannot be found. (code: `deployments.deployment_not_found`). See {@link BasicFailedReply}
 * @see 500 We have failed you. (code: `deployments.deployment_resource_no_longer_exists`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getDeploymentApmResourceInfoUrl(
  params: GetDeploymentApmResourceInfoUrlParams,
): string {
  const queryString = _buildQueryString({
    show_metadata: params.showMetadata === undefined ? false : params.showMetadata,
    show_plans: params.showPlans === undefined ? true : params.showPlans,
    show_plan_logs: params.showPlanLogs === undefined ? false : params.showPlanLogs,
    show_plan_history: params.showPlanHistory === undefined ? false : params.showPlanHistory,
    show_plan_defaults: params.showPlanDefaults === undefined ? false : params.showPlanDefaults,
    show_settings: params.showSettings === undefined ? false : params.showSettings,
    clear_transient: params.clearTransient === undefined ? false : params.clearTransient,
  })
  const path = _encode`/${params.regionId}/deployments/${params.deploymentId}/apm/${params.refId}`
  return `${_getBasePath()}${path}${queryString}`
}

export interface GetDeploymentApmResourceInfoUrlParams {
  deploymentId: string
  refId: string
  showMetadata?: boolean | null
  showPlans?: boolean | null
  showPlanLogs?: boolean | null
  showPlanHistory?: boolean | null
  showPlanDefaults?: boolean | null
  showSettings?: boolean | null
  clearTransient?: boolean | null
  regionId: string
}

/**
 * POST /api/v1/deployments/{deployment_id}/apm/{ref_id}/_reset-token
 * Operation: `deployment-apm-reset-secret-token`
 * Reset the token of an APM resource.
 * @param {string} params.deploymentId Identifier for the Deployment.
 * @param {string} params.refId User-specified RefId for the Resource (or '_main' if there is only one).
 * @see 202 Response containing the new secret token, plan to apply it starts. See {@link ApmCrudResponse}
 * @see 400 Reset token is not supported when APM is managed by Elastic Agent. (code: `clusters.cluster_plan_state_error`). See {@link BasicFailedReply}
 * @see 404 * The Deployment specified by {deployment_id} cannot be found. (code: `deployments.deployment_not_found`)
 * The Resource specified by {ref_id} cannot be found. (code: `deployments.deployment_resource_not_found`). See {@link BasicFailedReply}
 * @see 500 We have failed you. (code: `deployments.deployment_resource_no_longer_exists`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function deploymentApmResetSecretTokenUrl(
  params: DeploymentApmResetSecretTokenUrlParams,
): string {
  const path = _encode`/deployments/${params.deploymentId}/apm/${params.refId}/_reset-token`
  return `${_getRawBasePath()}${path}`
}

export interface DeploymentApmResetSecretTokenUrlParams {
  deploymentId: string
  refId: string
}

/**
 * GET /api/v1/regions/{region_id}/deployments/{deployment_id}/appsearch/{ref_id}
 * Operation: `get-deployment-appsearch-resource-info`
 * Get info about an App Search Resource belonging to a given Deployment.
 * @param {string} params.deploymentId Identifier for the Deployment.
 * @param {string} params.refId User-specified RefId for the Resource (or '_main' if there is only one).
 * @param {boolean} params.showMetadata Whether to include the full cluster metadata in the response - can be large per cluster and also include credentials.
 * @param {boolean} params.showPlans Whether to include the full current and pending plan information in the response - can be large per cluster. Default: true
 * @param {boolean} params.showPlanLogs Whether to include with the current and pending plan information the attempt log - can be very large per cluster.
 * @param {boolean} params.showPlanHistory Whether to include with the current and pending plan information the plan history- can be very large per cluster.
 * @param {boolean} params.showPlanDefaults If showing plans, whether to show values that are left at their default value (less readable but more informative).
 * @param {boolean} params.showSettings Whether to show cluster settings in the response.
 * @param {boolean} params.clearTransient If set (defaults to false) then removes the transient section from all child resources, making it safe to reapply via an update.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 Standard response. See {@link AppSearchResourceInfo}
 * @see 404 The Deployment specified by {deployment_id} cannot be found. (code: `deployments.deployment_not_found`). See {@link BasicFailedReply}
 * @see 500 We have failed you. (code: `deployments.deployment_resource_no_longer_exists`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getDeploymentAppsearchResourceInfoUrl(
  params: GetDeploymentAppsearchResourceInfoUrlParams,
): string {
  const queryString = _buildQueryString({
    show_metadata: params.showMetadata === undefined ? false : params.showMetadata,
    show_plans: params.showPlans === undefined ? true : params.showPlans,
    show_plan_logs: params.showPlanLogs === undefined ? false : params.showPlanLogs,
    show_plan_history: params.showPlanHistory === undefined ? false : params.showPlanHistory,
    show_plan_defaults: params.showPlanDefaults === undefined ? false : params.showPlanDefaults,
    show_settings: params.showSettings === undefined ? false : params.showSettings,
    clear_transient: params.clearTransient === undefined ? false : params.clearTransient,
  })
  const path = _encode`/${params.regionId}/deployments/${params.deploymentId}/appsearch/${params.refId}`
  return `${_getBasePath()}${path}${queryString}`
}

export interface GetDeploymentAppsearchResourceInfoUrlParams {
  deploymentId: string
  refId: string
  showMetadata?: boolean | null
  showPlans?: boolean | null
  showPlanLogs?: boolean | null
  showPlanHistory?: boolean | null
  showPlanDefaults?: boolean | null
  showSettings?: boolean | null
  clearTransient?: boolean | null
  regionId: string
}

/**
 * GET /api/v1/deployments/{deployment_id}/appsearch/{ref_id}/read_only_mode
 * Operation: `get-appsearch-read-only-mode`
 * Enable/Disable read-only mode on the given App Search resource.
 * @param {string} params.deploymentId Identifier for the Deployment.
 * @param {string} params.refId User-specified RefId for the Resource (or '_main' if there is only one).
 * @see 200 Standard response. See {@link ReadOnlyResponse}
 * @see 404 The Resource specified by ref_id cannot be found. (code: `deployments.deployment_resource_not_found`). See {@link BasicFailedReply}
 * @see 500 We have failed you. (code: `deployments.deployment_resource_no_longer_exists`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getAppsearchReadOnlyModeUrl(params: GetAppsearchReadOnlyModeUrlParams): string {
  const path = _encode`/deployments/${params.deploymentId}/appsearch/${params.refId}/read_only_mode`
  return `${_getRawBasePath()}${path}`
}

export interface GetAppsearchReadOnlyModeUrlParams {
  deploymentId: string
  refId: string
}

/**
 * PUT /api/v1/deployments/{deployment_id}/appsearch/{ref_id}/read_only_mode
 * Operation: `set-appsearch-read-only-mode`
 * Enable/Disable read-only mode on the given App Search resource.
 * @param {string} params.deploymentId Identifier for the Deployment.
 * @param {string} params.refId User-specified RefId for the Resource (or '_main' if there is only one).
 * @see Accepts a request payload of type {@link ReadOnlyRequest}
 * @see 200 Standard response. See {@link ReadOnlyResponse}
 * @see 404 The Resource specified by ref_id cannot be found. (code: `deployments.deployment_resource_not_found`). See {@link BasicFailedReply}
 * @see 500 We have failed you. (code: `deployments.deployment_resource_no_longer_exists`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function setAppsearchReadOnlyModeUrl(params: SetAppsearchReadOnlyModeUrlParams): string {
  const path = _encode`/deployments/${params.deploymentId}/appsearch/${params.refId}/read_only_mode`
  return `${_getRawBasePath()}${path}`
}

export interface SetAppsearchReadOnlyModeUrlParams {
  deploymentId: string
  refId: string
}

/**
 * GET /api/v1/deployments/{deployment_id}/certificate-authority
 * Operation: `get-deployment-certificate-authority`
 * Returns the certificate authority used to sign the certificates of this deployment.
 * @param {string} params.deploymentId Identifier for the Deployment.
 * @see 200 The certificate authority used to sign the certificates of this deployment. See {@link CertificateAuthority}
 * @see 404 * The Deployment specified by {deployment_id} cannot be found. (code: `deployments.deployment_not_found`)
 * The Elasticsearch Resource cannot be found. (code: `deployments.deployment_resource_not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getDeploymentCertificateAuthorityUrl(
  params: GetDeploymentCertificateAuthorityUrlParams,
): string {
  const path = _encode`/deployments/${params.deploymentId}/certificate-authority`
  return `${_getRawBasePath()}${path}`
}

export interface GetDeploymentCertificateAuthorityUrlParams {
  deploymentId: string
}

/**
 * GET /api/v1/regions/{region_id}/deployments/{deployment_id}/elasticsearch/{ref_id}
 * Operation: `get-deployment-es-resource-info`
 * Get info about an Elasticsearch Resource belonging to a given Deployment.
 * @param {string} params.deploymentId Identifier for the Deployment.
 * @param {string} params.refId User-specified RefId for the Resource (or '_main' if there is only one).
 * @param {boolean} params.showSecurity Whether to include the Elasticsearch 2.x security information in the response - can be large per cluster and also include credentials.
 * @param {boolean} params.showMetadata Whether to include the full cluster metadata in the response - can be large per cluster and also include credentials.
 * @param {boolean} params.showPlans Whether to include the full current and pending plan information in the response - can be large per cluster. Default: true
 * @param {boolean} params.showPlanLogs Whether to include with the current and pending plan information the attempt log - can be very large per cluster.
 * @param {boolean} params.showPlanHistory Whether to include with the current and pending plan information the plan history- can be very large per cluster.
 * @param {boolean} params.showPlanDefaults If showing plans, whether to show values that are left at their default value (less readable but more informative).
 * @param {boolean} params.convertLegacyPlans If showing plans, whether to leave pre-2.0.0 plans in their legacy format (the default), or whether to update them to 2.0.x+ format (if 'true').
 * @param {integer} params.showSystemAlerts Number of system alerts (such as forced restarts due to memory limits) to be included in the response - can be large per cluster. Negative numbers or 0 will not return field.
 * @param {boolean} params.showSettings Whether to show cluster settings in the response.
 * @param {boolean} params.enrichWithTemplate If showing plans, whether to enrich the plan by including the missing elements from the deployment template it is based on. Default: true
 * @param {boolean} params.clearTransient If set (defaults to false) then removes the transient section from all child resources, making it safe to reapply via an update.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 Standard response. See {@link ElasticsearchResourceInfo}
 * @see 404 The Deployment specified by {deployment_id} cannot be found. (code: `deployments.deployment_not_found`). See {@link BasicFailedReply}
 * @see 500 We have failed you. (code: `deployments.deployment_resource_no_longer_exists`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getDeploymentEsResourceInfoUrl(
  params: GetDeploymentEsResourceInfoUrlParams,
): string {
  const queryString = _buildQueryString({
    show_security: params.showSecurity === undefined ? false : params.showSecurity,
    show_metadata: params.showMetadata === undefined ? false : params.showMetadata,
    show_plans: params.showPlans === undefined ? true : params.showPlans,
    show_plan_logs: params.showPlanLogs === undefined ? false : params.showPlanLogs,
    show_plan_history: params.showPlanHistory === undefined ? false : params.showPlanHistory,
    show_plan_defaults: params.showPlanDefaults === undefined ? false : params.showPlanDefaults,
    convert_legacy_plans:
      params.convertLegacyPlans === undefined ? false : params.convertLegacyPlans,
    show_system_alerts: params.showSystemAlerts === undefined ? 0 : params.showSystemAlerts,
    show_settings: params.showSettings === undefined ? false : params.showSettings,
    enrich_with_template:
      params.enrichWithTemplate === undefined ? true : params.enrichWithTemplate,
    clear_transient: params.clearTransient === undefined ? false : params.clearTransient,
  })
  const path = _encode`/${params.regionId}/deployments/${params.deploymentId}/elasticsearch/${params.refId}`
  return `${_getBasePath()}${path}${queryString}`
}

export interface GetDeploymentEsResourceInfoUrlParams {
  deploymentId: string
  refId: string
  showSecurity?: boolean | null
  showMetadata?: boolean | null
  showPlans?: boolean | null
  showPlanLogs?: boolean | null
  showPlanHistory?: boolean | null
  showPlanDefaults?: boolean | null
  convertLegacyPlans?: boolean | null
  showSystemAlerts?: number | null
  showSettings?: boolean | null
  enrichWithTemplate?: boolean | null
  clearTransient?: boolean | null
  regionId: string
}

/**
 * POST /api/v1/deployments/{deployment_id}/elasticsearch/{ref_id}/_enable-ccr
 * Operation: `enable-deployment-resource-ccr`
 * Migrates the specified Elasticsearch resource and its associated Kibana to enable CCR (if supported).
 * @param {string} params.deploymentId Identifier for the Deployment.
 * @param {string} params.refId User-specified RefId for the Resource (or '_main' if there is only one).
 * @param {boolean} params.validateOnly When `true`, will not enable CCR but returns warnings if any elements may lose availability during CCR enablement.
 * @see 200 Standard response. See {@link DeploymentResourceCommandResponse}
 * @see 404 * The Deployment specified by {deployment_id} cannot be found. (code: `deployments.deployment_not_found`)
 * The Resource specified by {ref_id} cannot be found. (code: `deployments.deployment_resource_not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function enableDeploymentResourceCcrUrl(
  params: EnableDeploymentResourceCcrUrlParams,
): string {
  const queryString = _buildQueryString({
    validate_only: params.validateOnly === undefined ? false : params.validateOnly,
  })
  const path = _encode`/deployments/${params.deploymentId}/elasticsearch/${params.refId}/_enable-ccr`
  return `${_getRawBasePath()}${path}${queryString}`
}

export interface EnableDeploymentResourceCcrUrlParams {
  deploymentId: string
  refId: string
  validateOnly?: boolean | null
}

/**
 * POST /api/v1/deployments/{deployment_id}/elasticsearch/{ref_id}/_enable-ilm
 * Operation: `enable-deployment-resource-ilm`
 * Migrates the specified Elasticsearch resource to use ILM.
 * @param {string} params.deploymentId Identifier for the Deployment.
 * @param {string} params.refId User-specified RefId for the Resource (or '_main' if there is only one).
 * @param {boolean} params.validateOnly When `true`, does not enable ILM but returns warnings if any applications may lose availability during ILM migration.
 * @see Accepts a request payload of type {@link EnableIlmRequest}
 * @see 200 Standard response. See {@link DeploymentResourceCommandResponse}
 * @see 404 * The Deployment specified by {deployment_id} cannot be found. (code: `deployments.deployment_not_found`)
 * The Resource specified by {ref_id} cannot be found. (code: `deployments.deployment_resource_not_found`). See {@link BasicFailedReply}
 * @see 500 We have failed you. (code: `deployments.deployment_resource_no_longer_exists`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function enableDeploymentResourceIlmUrl(
  params: EnableDeploymentResourceIlmUrlParams,
): string {
  const queryString = _buildQueryString({
    validate_only: params.validateOnly === undefined ? false : params.validateOnly,
  })
  const path = _encode`/deployments/${params.deploymentId}/elasticsearch/${params.refId}/_enable-ilm`
  return `${_getRawBasePath()}${path}${queryString}`
}

export interface EnableDeploymentResourceIlmUrlParams {
  deploymentId: string
  refId: string
  validateOnly?: boolean | null
}

/**
 * POST /api/v1/deployments/{deployment_id}/elasticsearch/{ref_id}/_enable-slm
 * Operation: `enable-deployment-resource-slm`
 * Migrates the specified Elasticsearch resource to use SLM.
 * @param {string} params.deploymentId Identifier for the Deployment.
 * @param {string} params.refId User-specified RefId for the Resource (or '_main' if there is only one).
 * @param {boolean} params.validateOnly When `true`, does not enable SLM but returns warnings if any applications may lose availability during SLM migration.
 * @see 200 Standard response. See {@link DeploymentResourceCommandResponse}
 * @see 404 * The Deployment specified by {deployment_id} cannot be found. (code: `deployments.deployment_not_found`)
 * The Resource specified by {ref_id} cannot be found. (code: `deployments.deployment_resource_not_found`). See {@link BasicFailedReply}
 * @see 500 We have failed you. (code: `deployments.deployment_resource_no_longer_exists`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function enableDeploymentResourceSlmUrl(
  params: EnableDeploymentResourceSlmUrlParams,
): string {
  const queryString = _buildQueryString({
    validate_only: params.validateOnly === undefined ? false : params.validateOnly,
  })
  const path = _encode`/deployments/${params.deploymentId}/elasticsearch/${params.refId}/_enable-slm`
  return `${_getRawBasePath()}${path}${queryString}`
}

export interface EnableDeploymentResourceSlmUrlParams {
  deploymentId: string
  refId: string
  validateOnly?: boolean | null
}

/**
 * POST /api/v1/deployments/{deployment_id}/elasticsearch/{ref_id}/_lock
 * Operation: `lock-deployment`
 * Users cannot initiate configuration changes when deployments are locked.
 * @param {string} params.deploymentId Identifier for the Deployment.
 * @param {string} params.refId User-specified RefId for the Resource (or '_main' if there is only one).
 * @see Accepts a request payload of type {@link DeploymentLockRequest}
 * @see 200 Standard response. See {@link DeploymentResourceCommandResponse}
 * @see 404 * The Deployment specified by {deployment_id} cannot be found. (code: `deployments.deployment_not_found`)
 * The Resource specified by {ref_id} cannot be found. (code: `deployments.deployment_resource_not_found`). See {@link BasicFailedReply}
 * @see 500 We have failed you. (code: `deployments.deployment_resource_no_longer_exists`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function lockDeploymentUrl(params: LockDeploymentUrlParams): string {
  const path = _encode`/deployments/${params.deploymentId}/elasticsearch/${params.refId}/_lock`
  return `${_getRawBasePath()}${path}`
}

export interface LockDeploymentUrlParams {
  deploymentId: string
  refId: string
}

/**
 * POST /api/v1/deployments/{deployment_id}/elasticsearch/{ref_id}/_reset-password
 * Operation: `reset-elasticsearch-user-password`
 * Resets the password of the 'elastic' user.
 * @param {string} params.deploymentId Identifier for the Deployment.
 * @param {string} params.refId User-specified RefId for the Resource (or '_main' if there is only one).
 * @param {boolean} params.checkCompletion If true, will not reset elastic user password and instead will return a status code signaling whether or not the current credentials are ready to use (eg from creation or the last call to _reset_password).
 * @see 200 The password reset was out carried successfully. See {@link ElasticsearchElasticUserPasswordResetResponse}
 * @see 404 * The Deployment specified by {deployment_id} cannot be found. (code: `deployments.deployment_not_found`)
 * The Resource specified by {ref_id} cannot be found. (code: `deployments.deployment_resource_not_found`). See {@link BasicFailedReply}
 * @see 500 Failed to reset the 'elastic' user's password. (code: `deployments.elasticsearch.password_reset_error`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function resetElasticsearchUserPasswordUrl(
  params: ResetElasticsearchUserPasswordUrlParams,
): string {
  const queryString = _buildQueryString({
    check_completion: params.checkCompletion === undefined ? false : params.checkCompletion,
  })
  const path = _encode`/deployments/${params.deploymentId}/elasticsearch/${params.refId}/_reset-password`
  return `${_getRawBasePath()}${path}${queryString}`
}

export interface ResetElasticsearchUserPasswordUrlParams {
  deploymentId: string
  refId: string
  checkCompletion?: boolean | null
}

/**
 * POST /api/v1/deployments/{deployment_id}/elasticsearch/{ref_id}/_restart
 * Operation: `restart-deployment-es-resource`
 * Restarts an Elasticsearch Resource. If a Resource is active: this command re-applies the existing plan but applies a "cluster_reboot", which issues a restart command and waits for it to complete. If a Resource is inactive: this command starts it up with the most recent successful plan.
 * @param {string} params.deploymentId Identifier for the Deployment.
 * @param {string} params.refId User-specified RefId for the Resource (or '_main' if there is only one).
 * @param {boolean} params.restoreSnapshot When set to true and restoring from shutdown, then will restore the cluster from the last snapshot (if available). Default: true
 * @param {boolean} params.skipSnapshot If true, will not take a snapshot of the cluster before restarting. Default: true
 * @param {boolean} params.cancelPending If true, cancels any pending plans before restarting. If false and there are pending plans, returns an error.
 * @param {string} params.groupAttribute Indicates the property or properties used to divide the list of instances to restart in groups. Valid options are: '__all__' (restart all at once), '__zone__' by logical zone, '__name__' one instance at a time, or a comma-separated list of attributes of the instances. Default: __zone__
 * @param {integer} params.shardInitWaitTime The time, in seconds, to wait for shards that show no progress of initializing, before rolling the next group (default: 10 minutes). Default: 600
 * @see 202 The restart command was issued successfully. See {@link DeploymentResourceCommandResponse}
 * @see 404 * The Deployment specified by {deployment_id} cannot be found. (code: `deployments.deployment_not_found`)
 * The Resource specified by {ref_id} cannot be found. (code: `deployments.deployment_resource_not_found`). See {@link BasicFailedReply}
 * @see 422 The command sent to a Resource found the Resource in an illegal state, the error message gives more details. (code: `deployments.deployment_resource_plan_change_error`). See {@link BasicFailedReply}
 * @see 500 A Resource that was previously stored no longer exists. (code: `deployments.deployment_resource_no_longer_exists`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function restartDeploymentEsResourceUrl(
  params: RestartDeploymentEsResourceUrlParams,
): string {
  const queryString = _buildQueryString({
    restore_snapshot: params.restoreSnapshot === undefined ? true : params.restoreSnapshot,
    skip_snapshot: params.skipSnapshot === undefined ? true : params.skipSnapshot,
    cancel_pending: params.cancelPending === undefined ? false : params.cancelPending,
    group_attribute: params.groupAttribute === undefined ? `__zone__` : params.groupAttribute,
    shard_init_wait_time: params.shardInitWaitTime === undefined ? 600 : params.shardInitWaitTime,
  })
  const path = _encode`/deployments/${params.deploymentId}/elasticsearch/${params.refId}/_restart`
  return `${_getRawBasePath()}${path}${queryString}`
}

export interface RestartDeploymentEsResourceUrlParams {
  deploymentId: string
  refId: string
  restoreSnapshot?: boolean | null
  skipSnapshot?: boolean | null
  cancelPending?: boolean | null
  groupAttribute?: string | null
  shardInitWaitTime?: number | null
}

/**
 * POST /api/v1/deployments/{deployment_id}/elasticsearch/{ref_id}/_shutdown
 * Operation: `shutdown-deployment-es-resource`
 * Shutdown Elasticsearch Resource belonging to a given Deployment.
 * @param {string} params.deploymentId Identifier for the Deployment.
 * @param {string} params.refId User-specified RefId for the Resource (or '_main' if there is only one).
 * @param {boolean} params.hide Hide cluster on shutdown. Hidden clusters are not listed by default. Only applicable for Platform administrators.
 * @param {boolean} params.skipSnapshot If true, will skip taking a snapshot of the cluster before shutting the cluster down (if even possible).
 * @see 200 Standard response. See {@link DeploymentResourceCommandResponse}
 * @see 400 Parameter is restricted and can only be set by a Platform administrator. (code: `deployments.restricted_parameter`). See {@link BasicFailedReply}
 * @see 404 * The Deployment specified by {deployment_id} cannot be found. (code: `deployments.deployment_not_found`)
 * The Resource specified by {ref_id} cannot be found. (code: `deployments.deployment_resource_not_found`). See {@link BasicFailedReply}
 * @see 500 A Resource that was previously stored no longer exists. (code: `deployments.deployment_resource_no_longer_exists`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function shutdownDeploymentEsResourceUrl(
  params: ShutdownDeploymentEsResourceUrlParams,
): string {
  const queryString = _buildQueryString({
    hide: params.hide,
    skip_snapshot: params.skipSnapshot === undefined ? false : params.skipSnapshot,
  })
  const path = _encode`/deployments/${params.deploymentId}/elasticsearch/${params.refId}/_shutdown`
  return `${_getRawBasePath()}${path}${queryString}`
}

export interface ShutdownDeploymentEsResourceUrlParams {
  deploymentId: string
  refId: string
  hide?: boolean | null
  skipSnapshot?: boolean | null
}

/**
 * POST /api/v1/deployments/{deployment_id}/elasticsearch/{ref_id}/eligible-remote-clusters
 * Operation: `get-deployment-es-resource-eligible-remote-clusters`
 * Returns the list of deployments which contain eligible remote clusters for the elasticsearch resource.
 * @param {string} params.deploymentId Identifier for the Deployment.
 * @param {string} params.refId User-specified RefId for the Resource (or '_main' if there is only one).
 * @see Accepts an optional request payload of type {@link SearchRequest}
 * @see 200 List of deployments which contains eligible remote clusters for the resource. See {@link DeploymentsSearchResponse}
 * @see 400 The resource specified doesn't yet have a valid version for the current plan or the pending plan. (code: `deployments.resource_does_not_have_a_valid_version`). See {@link BasicFailedReply}
 * @see 404 * The Deployment specified by {deployment_id} cannot be found. (code: `deployments.deployment_not_found`)
 * The Resource specified by {ref_id} cannot be found. (code: `deployments.deployment_resource_not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getDeploymentEsResourceEligibleRemoteClustersUrl(
  params: GetDeploymentEsResourceEligibleRemoteClustersUrlParams,
): string {
  const path = _encode`/deployments/${params.deploymentId}/elasticsearch/${params.refId}/eligible-remote-clusters`
  return `${_getRawBasePath()}${path}`
}

export interface GetDeploymentEsResourceEligibleRemoteClustersUrlParams {
  deploymentId: string
  refId: string
}

/**
 * POST /api/v1/deployments/{deployment_id}/elasticsearch/{ref_id}/instances/{instance_ids}/_move
 * Operation: `move-deployment-elasticsearch-resource-instances`
 * Moves one or more instances belonging to the given Elasticsearch resource to a different allocator.
 * @param {string} params.deploymentId Identifier for the Deployment.
 * @param {string} params.refId User-specified RefId for the Resource (or '_main' if there is only one).
 * @param {array} params.instanceIds A comma-separated list of instance identifiers.
 * @param {boolean} params.ignoreMissing When `true` and the instance does not exist, proceeds to the next instance, or treats the instance as an error.
 * @param {boolean} params.forceUpdate When `true`, cancels and overwrites the pending plans, or treats the instance as an error.
 * @param {boolean} params.instancesDown When `true`, the instances specified by `instance_ids` are considered permanently shut down for data migration logic.
 * @param {boolean} params.moveOnly When `true`, moves the specified instances and ignores the changes for the cluster state. Default: true
 * @param {boolean} params.forceMove When `true`, moves instance data at file-system level (not via Elasticsearch), and re-creates instances on target allocator(s).
 * @param {boolean} params.validateOnly When `true`, validates the move request, then returns the calculated plan without applying the plan.
 * @see Accepts an optional request payload of type {@link TransientElasticsearchPlanConfiguration}
 * @see 200 If `validate_only` is `true`, the calculated plan is returned. See {@link TransientElasticsearchPlanConfiguration}
 * @see 202 The move command was issued successfully. Use the "GET" command on the /{deployment_id} resource to monitor progress
 * @see 404 * The Deployment specified by {deployment_id} cannot be found. (code: `deployments.deployment_not_found`)
 * The Elasticsearch Resource specified by {ref_id} cannot be found. (code: `deployments.deployment_resource_not_found`)
 * One or more instances of the given resource type are missing. (code: `deployments.instances_missing_on_update_error`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function moveDeploymentElasticsearchResourceInstancesUrl(
  params: MoveDeploymentElasticsearchResourceInstancesUrlParams,
): string {
  const queryString = _buildQueryString({
    ignore_missing: params.ignoreMissing === undefined ? false : params.ignoreMissing,
    force_update: params.forceUpdate === undefined ? false : params.forceUpdate,
    instances_down: params.instancesDown === undefined ? false : params.instancesDown,
    move_only: params.moveOnly === undefined ? true : params.moveOnly,
    force_move: params.forceMove === undefined ? false : params.forceMove,
    validate_only: params.validateOnly === undefined ? false : params.validateOnly,
  })
  const path = _encode`/deployments/${params.deploymentId}/elasticsearch/${params.refId}/instances/${params.instanceIds}/_move`
  return `${_getRawBasePath()}${path}${queryString}`
}

export interface MoveDeploymentElasticsearchResourceInstancesUrlParams {
  deploymentId: string
  refId: string
  instanceIds: string[]
  ignoreMissing?: boolean | null
  forceUpdate?: boolean | null
  instancesDown?: boolean | null
  moveOnly?: boolean | null
  forceMove?: boolean | null
  validateOnly?: boolean | null
}

/**
 * GET /api/v1/deployments/{deployment_id}/elasticsearch/{ref_id}/keystore
 * Operation: `get-deployment-es-resource-keystore`
 * Fetches the current fields and metadata (but not the values) of the keystore for the Elasticsearch resource.
 * @param {string} params.deploymentId Identifier for the Deployment.
 * @param {string} params.refId User-specified RefId for the Resource (or '_main' if there is only one).
 * @see 200 The contents of the Elasticsearch keystore, with values redacted. See {@link KeystoreContents}
 * @see 404 * The Deployment specified by {deployment_id} cannot be found. (code: `deployments.deployment_not_found`)
 * The Resource specified by {ref_id} cannot be found. (code: `deployments.deployment_resource_not_found`). See {@link BasicFailedReply}
 * @see 500 We have failed you. (code: `deployments.metadata_internal_error`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getDeploymentEsResourceKeystoreUrl(
  params: GetDeploymentEsResourceKeystoreUrlParams,
): string {
  const path = _encode`/deployments/${params.deploymentId}/elasticsearch/${params.refId}/keystore`
  return `${_getRawBasePath()}${path}`
}

export interface GetDeploymentEsResourceKeystoreUrlParams {
  deploymentId: string
  refId: string
}

/**
 * PATCH /api/v1/deployments/{deployment_id}/elasticsearch/{ref_id}/keystore
 * Operation: `set-deployment-es-resource-keystore`
 * Adds the specified values to the Elasticsearch keystore, or removes the keys for the unspecified values.
 * @param {string} params.deploymentId Identifier for the Deployment.
 * @param {string} params.refId User-specified RefId for the Resource (or '_main' if there is only one).
 * @see Accepts a request payload of type {@link KeystoreContents}
 * @see 200 The new contents of the Elasticsearch keystore. See {@link KeystoreContents}
 * @see 404 * The Deployment specified by {deployment_id} cannot be found. (code: `deployments.deployment_not_found`)
 * The Resource specified by {ref_id} cannot be found. (code: `deployments.deployment_resource_not_found`). See {@link BasicFailedReply}
 * @see 500 We have failed you. (code: `deployments.metadata_internal_error`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function setDeploymentEsResourceKeystoreUrl(
  params: SetDeploymentEsResourceKeystoreUrlParams,
): string {
  const path = _encode`/deployments/${params.deploymentId}/elasticsearch/${params.refId}/keystore`
  return `${_getRawBasePath()}${path}`
}

export interface SetDeploymentEsResourceKeystoreUrlParams {
  deploymentId: string
  refId: string
}

/**
 * GET /api/v1/deployments/{deployment_id}/elasticsearch/{ref_id}/logs/_download
 * Operation: `download-resource-logs`
 * Download logs for the given Elasticsearch resource. 24 hours of logs for the given Elasticsearch resource will be returned from the given start date.
 * @param {string} params.deploymentId Identifier for the Deployment.
 * @param {string} params.refId User-specified RefId for the Resource (or '_main' if there is only one).
 * @param {string} params.date The log retrieval start date in standard format. For example, `YYYY-MM-DD [THH [:mm] ]`.
 * @see 200 A zip archive of the given resources logs. See {@link string}
 * @see 404 * The Deployment specified by {deployment_id} cannot be found. (code: `deployments.deployment_not_found`)
 * The Resource specified by {ref_id} cannot be found. (code: `deployments.deployment_resource_not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function downloadResourceLogsUrl(params: DownloadResourceLogsUrlParams): string {
  const queryString = _buildQueryString({
    date: params.date,
  })
  const path = _encode`/deployments/${params.deploymentId}/elasticsearch/${params.refId}/logs/_download`
  return `${_getRawBasePath()}${path}${queryString}`
}

export interface DownloadResourceLogsUrlParams {
  deploymentId: string
  refId: string
  date: string
}

/**
 * GET /api/v1/deployments/{deployment_id}/elasticsearch/{ref_id}/logs/_search
 * Operation: `search-deployment-es-resource-logs`
 * Search across last 24h of Elasticsearch logs.
 * @param {string} params.deploymentId Identifier for the Deployment.
 * @param {string} params.refId User-specified RefId for the Resource (or '_main' if there is only one).
 * @param {integer} params.limit (Optional) maximum results in a page. Default: 20
 * @param {integer} params.offset (Optional) increment offset to scroll through pages of results.
 * @param {string} params.q (Optional) search query.
 * @param {string} params.filter (Optional) comma-separated list of filters for instance name and/or log level.
 * @see 200 Log lines from the last 24h of the Elasticsearch resource based on the search pattern. Maximum of 20 logs per page. See {@link ElasticsearchLogsSearchResponse}
 * @see 401 You are not authorized to perform this action. See {@link BasicFailedReply}
 * @see 404 * The Deployment specified by {deployment_id} cannot be found. (code: `deployments.deployment_not_found`)
 * The Resource specified by {ref_id} cannot be found. (code: `deployments.deployment_resource_not_found`). See {@link BasicFailedReply}
 * @see 500 We have failed you. (code: `deployments.metadata_internal_error`). See {@link BasicFailedReply}
 * @see 503 Logging cluster is unavailable. (code: `deployments.logging_cluster_data_fetch_error`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function searchDeploymentEsResourceLogsUrl(
  params: SearchDeploymentEsResourceLogsUrlParams,
): string {
  const queryString = _buildQueryString({
    limit: params.limit === undefined ? 20 : params.limit,
    offset: params.offset === undefined ? 0 : params.offset,
    q: params.q,
    filter: params.filter,
  })
  const path = _encode`/deployments/${params.deploymentId}/elasticsearch/${params.refId}/logs/_search`
  return `${_getRawBasePath()}${path}${queryString}`
}

export interface SearchDeploymentEsResourceLogsUrlParams {
  deploymentId: string
  refId: string
  limit?: number | null
  offset?: number | null
  q?: string | null
  filter?: string | null
}

/**
 * GET /api/v1/deployments/{deployment_id}/elasticsearch/{ref_id}/performance-metrics
 * Operation: `get-elasticsearch-performance-metrics`
 * Fetch the deployment's Elasticsearch performance metrics in the last 24h.
 * @param {string} params.deploymentId Identifier for the Deployment.
 * @param {string} params.refId User-specified RefId for the Resource.
 * @see 200 An aggregation of elasticsearch metrics. See {@link SimpleElasticsearchMetrics}
 * @see 404 * The Deployment specified by {deployment_id} cannot be found. (code: `deployments.deployment_not_found`)
 * The Resource specified by {ref_id} cannot be found. (code: `deployments.deployment_resource_not_found`). See {@link BasicFailedReply}
 * @see 503 Metric cluster is unavailable. (code: `logging_and_metrics.metrics_cluster_data_fetch_error`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getElasticsearchPerformanceMetricsUrl(
  params: GetElasticsearchPerformanceMetricsUrlParams,
): string {
  const path = _encode`/deployments/${params.deploymentId}/elasticsearch/${params.refId}/performance-metrics`
  return `${_getRawBasePath()}${path}`
}

export interface GetElasticsearchPerformanceMetricsUrlParams {
  deploymentId: string
  refId: string
}

/**
 * GET /api/v1/deployments/{deployment_id}/elasticsearch/{ref_id}/remote-clusters
 * Operation: `get-deployment-es-resource-remote-clusters`
 * Returns the list of certificate based remote clusters for the elasticsearch resource.
 * @param {string} params.deploymentId Identifier for the Deployment.
 * @param {string} params.refId User-specified RefId for the Resource (or '_main' if there is only one).
 * @see 200 List of certificate based remote clusters for the resource. See {@link RemoteResources}
 * @see 404 * The Deployment specified by {deployment_id} cannot be found. (code: `deployments.deployment_not_found`)
 * The Resource specified by {ref_id} cannot be found. (code: `deployments.deployment_resource_not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getDeploymentEsResourceRemoteClustersUrl(
  params: GetDeploymentEsResourceRemoteClustersUrlParams,
): string {
  const path = _encode`/deployments/${params.deploymentId}/elasticsearch/${params.refId}/remote-clusters`
  return `${_getRawBasePath()}${path}`
}

export interface GetDeploymentEsResourceRemoteClustersUrlParams {
  deploymentId: string
  refId: string
}

/**
 * PUT /api/v1/deployments/{deployment_id}/elasticsearch/{ref_id}/remote-clusters
 * Operation: `set-deployment-es-resource-remote-clusters`
 * Overwrites or creates the list of certificate based remote clusters for the elasticsearch resource.
 * @param {string} params.deploymentId Identifier for the Deployment.
 * @param {string} params.refId User-specified RefId for the Resource (or '_main' if there is only one).
 * @see Accepts a request payload of type {@link RemoteResources}
 * @see 202 The Remote Clusters were updated
 * @see 404 * The Deployment specified by {deployment_id} cannot be found. (code: `deployments.deployment_not_found`)
 * The Resource specified by {ref_id} cannot be found. (code: `deployments.deployment_resource_not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function setDeploymentEsResourceRemoteClustersUrl(
  params: SetDeploymentEsResourceRemoteClustersUrlParams,
): string {
  const path = _encode`/deployments/${params.deploymentId}/elasticsearch/${params.refId}/remote-clusters`
  return `${_getRawBasePath()}${path}`
}

export interface SetDeploymentEsResourceRemoteClustersUrlParams {
  deploymentId: string
  refId: string
}

/**
 * GET /api/v1/deployments/{deployment_id}/elasticsearch/{ref_id}/snapshot
 * Operation: `get-deployment-es-resource-snapshot`
 * Returns the current configuration of the ESS bucket management.
 * @param {string} params.deploymentId Identifier for the Deployment.
 * @param {string} params.refId User-specified RefId for the Resource (or '_main' if there is only one).
 * @see 200 Snapshot bucket configuration for this cluster. See {@link SnapshotConfiguration}
 * @see 404 * The Deployment specified by {deployment_id} cannot be found. (code: `deployments.deployment_not_found`)
 * The Resource specified by {ref_id} cannot be found. (code: `deployments.deployment_resource_not_found`). See {@link BasicFailedReply}
 * @see 500 We have failed you. (code: `deployments.metadata_internal_error`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getDeploymentEsResourceSnapshotUrl(
  params: GetDeploymentEsResourceSnapshotUrlParams,
): string {
  const path = _encode`/deployments/${params.deploymentId}/elasticsearch/${params.refId}/snapshot`
  return `${_getRawBasePath()}${path}`
}

export interface GetDeploymentEsResourceSnapshotUrlParams {
  deploymentId: string
  refId: string
}

/**
 * GET /api/v1/deployments/{deployment_id}/elasticsearch/{ref_id}/snapshot/dependency
 * Operation: `get-deployment-es-resource-snapshot-dependency`
 * Fetches the current dependencies of the snapshot for the Elasticsearch resource.
 * @param {string} params.deploymentId Identifier for the Deployment.
 * @param {string} params.refId User-specified RefId for the Resource (or '_main' if there is only one).
 * @see 200 List of snapshot dependency. See {@link SnapshotDependencies}
 * @see 404 * The Deployment specified by {deployment_id} cannot be found. (code: `deployments.deployment_not_found`)
 * The Resource specified by {ref_id} cannot be found. (code: `deployments.deployment_resource_not_found`). See {@link BasicFailedReply}
 * @see 500 We have failed you. (code: `deployments.metadata_internal_error`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getDeploymentEsResourceSnapshotDependencyUrl(
  params: GetDeploymentEsResourceSnapshotDependencyUrlParams,
): string {
  const path = _encode`/deployments/${params.deploymentId}/elasticsearch/${params.refId}/snapshot/dependency`
  return `${_getRawBasePath()}${path}`
}

export interface GetDeploymentEsResourceSnapshotDependencyUrlParams {
  deploymentId: string
  refId: string
}

/**
 * DELETE /api/v1/deployments/{deployment_id}/elasticsearch/{ref_id}/snapshot/dependency/{resource_id}
 * Operation: `delete-deployment-es-resource-snapshot-dependency`
 * Delete the dependency of a Elasticsearch resource snapshot. Doing so will cause the cloned snapshot to be inaccessible.
 * @param {string} params.deploymentId Identifier for the Deployment.
 * @param {string} params.refId User-specified RefId for the Resource (or '_main' if there is only one).
 * @param {string} params.resourceId System generated UUID for the Resource (Elasticsearch).
 * @see 200
 * @see 404 * The Deployment specified by {deployment_id} cannot be found. (code: `deployments.deployment_not_found`)
 * The Resource specified by {ref_id} cannot be found. (code: `deployments.deployment_resource_not_found`). See {@link BasicFailedReply}
 * @see 500 We have failed you. (code: `deployments.metadata_internal_error`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function deleteDeploymentEsResourceSnapshotDependencyUrl(
  params: DeleteDeploymentEsResourceSnapshotDependencyUrlParams,
): string {
  const path = _encode`/deployments/${params.deploymentId}/elasticsearch/${params.refId}/snapshot/dependency/${params.resourceId}`
  return `${_getRawBasePath()}${path}`
}

export interface DeleteDeploymentEsResourceSnapshotDependencyUrlParams {
  deploymentId: string
  refId: string
  resourceId: string
}

/**
 * GET /api/v1/regions/{region_id}/deployments/{deployment_id}/elasticsearch/{ref_id}/users
 * Operation: `get-deployment-es-resource-users`
 * Returns the list of users for the elasticsearch resource.
 * @param {string} params.deploymentId Identifier for the Deployment.
 * @param {string} params.refId User-specified RefId for the Resource (or '_main' if there is only one).
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 List of users for the resource. See {@link DeploymentUsers}
 * @see 404 * The Deployment specified by {deployment_id} cannot be found. (code: `deployments.deployment_not_found`)
 * The Resource specified by {ref_id} cannot be found. (code: `deployments.deployment_resource_not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getDeploymentEsResourceUsersUrl(
  params: GetDeploymentEsResourceUsersUrlParams,
): string {
  const path = _encode`/${params.regionId}/deployments/${params.deploymentId}/elasticsearch/${params.refId}/users`
  return `${_getBasePath()}${path}`
}

export interface GetDeploymentEsResourceUsersUrlParams {
  deploymentId: string
  refId: string
  regionId: string
}

/**
 * GET /api/v1/regions/{region_id}/deployments/{deployment_id}/elasticsearch/{ref_id}/users/{user_name}
 * Operation: `get-deployment-es-resource-user`
 * Returns the requested user for the elasticsearch resource.
 * @param {string} params.deploymentId Identifier for the Deployment.
 * @param {string} params.refId User-specified RefId for the Resource (or '_main' if there is only one).
 * @param {string} params.userName Name of the user to create, delete or modify.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 Requested user for the resource. See {@link DeploymentUser}
 * @see 404 * The Deployment specified by {deployment_id} cannot be found. (code: `deployments.deployment_not_found`)
 * The Resource specified by {ref_id} cannot be found. (code: `deployments.deployment_resource_not_found`)
 * The user specifiec by {user_name} cannot be found. (code: `deployments.deployment_user_not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getDeploymentEsResourceUserUrl(
  params: GetDeploymentEsResourceUserUrlParams,
): string {
  const path = _encode`/${params.regionId}/deployments/${params.deploymentId}/elasticsearch/${params.refId}/users/${params.userName}`
  return `${_getBasePath()}${path}`
}

export interface GetDeploymentEsResourceUserUrlParams {
  deploymentId: string
  refId: string
  userName: string
  regionId: string
}

/**
 * PUT /api/v1/regions/{region_id}/deployments/{deployment_id}/elasticsearch/{ref_id}/users/{user_name}
 * Operation: `add-deployment-es-resource-user`
 * Overwrites or creates a user with the given name and definition.
 * @param {string} params.deploymentId Identifier for the Deployment.
 * @param {string} params.refId User-specified RefId for the Resource (or '_main' if there is only one).
 * @param {string} params.userName Name of the user to create, delete or modify.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see Accepts a request payload of type {@link DeploymentUser}
 * @see 200 The user added. See {@link DeploymentUser}
 * @see 400 The user name given in path doesn't match the one in the body. (code: `deployments.user_name_notmatching_request`). See {@link BasicFailedReply}
 * @see 403 One or more of the roles specified are not allowed. (code: `deployments.user_role_forbidden`). See {@link BasicFailedReply}
 * @see 404 * The Deployment specified by {deployment_id} cannot be found. (code: `deployments.deployment_not_found`)
 * The Resource specified by {ref_id} cannot be found. (code: `deployments.deployment_resource_not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function addDeploymentEsResourceUserUrl(
  params: AddDeploymentEsResourceUserUrlParams,
): string {
  const path = _encode`/${params.regionId}/deployments/${params.deploymentId}/elasticsearch/${params.refId}/users/${params.userName}`
  return `${_getBasePath()}${path}`
}

export interface AddDeploymentEsResourceUserUrlParams {
  deploymentId: string
  refId: string
  userName: string
  regionId: string
}

/**
 * DELETE /api/v1/regions/{region_id}/deployments/{deployment_id}/elasticsearch/{ref_id}/users/{user_name}
 * Operation: `delete-deployment-es-resource-user`
 * Deletes the user with the given name.
 * @param {string} params.deploymentId Identifier for the Deployment.
 * @param {string} params.refId User-specified RefId for the Resource (or '_main' if there is only one).
 * @param {string} params.userName Name of the user to create, delete or modify.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 The user was deleted
 * @see 403 The user specified has one more roles this api user is not allowed to modify. (code: `deployments.user_role_forbidden`). See {@link BasicFailedReply}
 * @see 404 * The Deployment specified by {deployment_id} cannot be found. (code: `deployments.deployment_not_found`)
 * The Resource specified by {ref_id} cannot be found. (code: `deployments.deployment_resource_not_found`)
 * The user specifiec by {user_name} cannot be found. (code: `deployments.deployment_user_not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function deleteDeploymentEsResourceUserUrl(
  params: DeleteDeploymentEsResourceUserUrlParams,
): string {
  const path = _encode`/${params.regionId}/deployments/${params.deploymentId}/elasticsearch/${params.refId}/users/${params.userName}`
  return `${_getBasePath()}${path}`
}

export interface DeleteDeploymentEsResourceUserUrlParams {
  deploymentId: string
  refId: string
  userName: string
  regionId: string
}

/**
 * GET /api/v1/regions/{region_id}/deployments/{deployment_id}/enterprise_search/{ref_id}
 * Operation: `get-deployment-enterprise-search-resource-info`
 * Get info about an Enterprise Search Resource belonging to a given Deployment.
 * @param {string} params.deploymentId Identifier for the Deployment.
 * @param {string} params.refId User-specified RefId for the Resource (or '_main' if there is only one).
 * @param {boolean} params.showMetadata Whether to include the full cluster metadata in the response - can be large per cluster and also include credentials.
 * @param {boolean} params.showPlans Whether to include the full current and pending plan information in the response - can be large per cluster. Default: true
 * @param {boolean} params.showPlanLogs Whether to include with the current and pending plan information the attempt log - can be very large per cluster.
 * @param {boolean} params.showPlanHistory Whether to include with the current and pending plan information the plan history- can be very large per cluster.
 * @param {boolean} params.showPlanDefaults If showing plans, whether to show values that are left at their default value (less readable but more informative).
 * @param {boolean} params.showSettings Whether to show cluster settings in the response.
 * @param {boolean} params.clearTransient If set (defaults to false) then removes the transient section from all child resources, making it safe to reapply via an update.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 Standard response. See {@link EnterpriseSearchResourceInfo}
 * @see 404 The Deployment specified by {deployment_id} cannot be found. (code: `deployments.deployment_not_found`). See {@link BasicFailedReply}
 * @see 500 We have failed you. (code: `deployments.deployment_resource_no_longer_exists`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getDeploymentEnterpriseSearchResourceInfoUrl(
  params: GetDeploymentEnterpriseSearchResourceInfoUrlParams,
): string {
  const queryString = _buildQueryString({
    show_metadata: params.showMetadata === undefined ? false : params.showMetadata,
    show_plans: params.showPlans === undefined ? true : params.showPlans,
    show_plan_logs: params.showPlanLogs === undefined ? false : params.showPlanLogs,
    show_plan_history: params.showPlanHistory === undefined ? false : params.showPlanHistory,
    show_plan_defaults: params.showPlanDefaults === undefined ? false : params.showPlanDefaults,
    show_settings: params.showSettings === undefined ? false : params.showSettings,
    clear_transient: params.clearTransient === undefined ? false : params.clearTransient,
  })
  const path = _encode`/${params.regionId}/deployments/${params.deploymentId}/enterprise_search/${params.refId}`
  return `${_getBasePath()}${path}${queryString}`
}

export interface GetDeploymentEnterpriseSearchResourceInfoUrlParams {
  deploymentId: string
  refId: string
  showMetadata?: boolean | null
  showPlans?: boolean | null
  showPlanLogs?: boolean | null
  showPlanHistory?: boolean | null
  showPlanDefaults?: boolean | null
  showSettings?: boolean | null
  clearTransient?: boolean | null
  regionId: string
}

/**
 * GET /api/v1/deployments/{deployment_id}/health
 * Operation: `get-deployment-health`
 * Retrieves health info for an elastic deployment.
 * @param {string} params.deploymentId Identifier for the Deployment.
 * @param {boolean} params.elasticsearchVerbose A boolean flag to specify if Elasticsearch health indicators should include the `details` and `diagnosis` fields.
 These fields are included by default when the parameter is not specified.
 * @see 200 Standard response with the deployment health info. See {@link DeploymentHealth}
 * @see 404 The Deployment specified by {deployment_id} cannot be found. (code: `deployments.deployment_not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getDeploymentHealthUrl(params: GetDeploymentHealthUrlParams): string {
  const queryString = _buildQueryString({
    elasticsearch_verbose: params.elasticsearchVerbose,
  })
  const path = _encode`/deployments/${params.deploymentId}/health`
  return `${_getRawBasePath()}${path}${queryString}`
}

export interface GetDeploymentHealthUrlParams {
  deploymentId: string
  elasticsearchVerbose?: boolean | null
}

/**
 * GET /api/v1/deployments/{deployment_id}/heap_dumps
 * Operation: `get-deployment-heap-dumps`
 * Gets details about existing heap dumps and in progress heap dump captures across all instances belonging to the deployment.
 * @param {string} params.deploymentId Identifier for the Deployment.
 * @see 200 The heap dump details for all instances belonging to the deployment. See {@link DeploymentHeapDumps}
 * @see 404 The deployment specified by {deployment_id} cannot be found. (code: `deployments.deployment_not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getDeploymentHeapDumpsUrl(params: GetDeploymentHeapDumpsUrlParams): string {
  const path = _encode`/deployments/${params.deploymentId}/heap_dumps`
  return `${_getRawBasePath()}${path}`
}

export interface GetDeploymentHeapDumpsUrlParams {
  deploymentId: string
}

/**
 * GET /api/v1/deployments/{deployment_id}/integrations_server/{ref_id}
 * Operation: `get-deployment-integrations-server-resource-info`
 * Get info about an Integrations Server Resource belonging to a given Deployment.
 * @param {string} params.deploymentId Identifier for the Deployment.
 * @param {string} params.refId User-specified RefId for the Resource (or '_main' if there is only one).
 * @param {boolean} params.showMetadata Whether to include the full cluster metadata in the response - can be large per cluster and also include credentials.
 * @param {boolean} params.showPlans Whether to include the full current and pending plan information in the response - can be large per cluster. Default: true
 * @param {boolean} params.showPlanLogs Whether to include with the current and pending plan information the attempt log - can be very large per cluster.
 * @param {boolean} params.showPlanHistory Whether to include with the current and pending plan information the plan history- can be very large per cluster.
 * @param {boolean} params.showPlanDefaults If showing plans, whether to show values that are left at their default value (less readable but more informative).
 * @param {boolean} params.showSettings Whether to show cluster settings in the response.
 * @param {boolean} params.clearTransient If set (defaults to false) then removes the transient section from all child resources, making it safe to reapply via an update.
 * @see 200 Standard response. See {@link IntegrationsServerResourceInfo}
 * @see 404 The Deployment specified by {deployment_id} cannot be found. (code: `deployments.deployment_not_found`). See {@link BasicFailedReply}
 * @see 500 We have failed you. (code: `deployments.deployment_resource_no_longer_exists`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getDeploymentIntegrationsServerResourceInfoUrl(
  params: GetDeploymentIntegrationsServerResourceInfoUrlParams,
): string {
  const queryString = _buildQueryString({
    show_metadata: params.showMetadata === undefined ? false : params.showMetadata,
    show_plans: params.showPlans === undefined ? true : params.showPlans,
    show_plan_logs: params.showPlanLogs === undefined ? false : params.showPlanLogs,
    show_plan_history: params.showPlanHistory === undefined ? false : params.showPlanHistory,
    show_plan_defaults: params.showPlanDefaults === undefined ? false : params.showPlanDefaults,
    show_settings: params.showSettings === undefined ? false : params.showSettings,
    clear_transient: params.clearTransient === undefined ? false : params.clearTransient,
  })
  const path = _encode`/deployments/${params.deploymentId}/integrations_server/${params.refId}`
  return `${_getRawBasePath()}${path}${queryString}`
}

export interface GetDeploymentIntegrationsServerResourceInfoUrlParams {
  deploymentId: string
  refId: string
  showMetadata?: boolean | null
  showPlans?: boolean | null
  showPlanLogs?: boolean | null
  showPlanHistory?: boolean | null
  showPlanDefaults?: boolean | null
  showSettings?: boolean | null
  clearTransient?: boolean | null
}

/**
 * GET /api/v1/regions/{region_id}/deployments/{deployment_id}/kibana/{ref_id}
 * Operation: `get-deployment-kib-resource-info`
 * Get info about a Kibana Resource belonging to a given Deployment.
 * @param {string} params.deploymentId Identifier for the Deployment.
 * @param {string} params.refId User-specified RefId for the Resource (or '_main' if there is only one).
 * @param {boolean} params.showMetadata Whether to include the full cluster metadata in the response - can be large per cluster and also include credentials.
 * @param {boolean} params.showPlans Whether to include the full current and pending plan information in the response - can be large per cluster. Default: true
 * @param {boolean} params.showPlanLogs Whether to include with the current and pending plan information the attempt log - can be very large per cluster.
 * @param {boolean} params.showPlanHistory Whether to include with the current and pending plan information the plan history- can be very large per cluster.
 * @param {boolean} params.showPlanDefaults If showing plans, whether to show values that are left at their default value (less readable but more informative).
 * @param {boolean} params.convertLegacyPlans If showing plans, whether to leave pre-2.0.0 plans in their legacy format (the default), or whether to update them to 2.0.x+ format (if 'true').
 * @param {boolean} params.showSettings Whether to show cluster settings in the response.
 * @param {boolean} params.clearTransient If set (defaults to false) then removes the transient section from all child resources, making it safe to reapply via an update.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 Standard response. See {@link KibanaResourceInfo}
 * @see 404 The Deployment specified by {deployment_id} cannot be found. (code: `deployments.deployment_not_found`). See {@link BasicFailedReply}
 * @see 500 We have failed you. (code: `deployments.deployment_resource_no_longer_exists`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getDeploymentKibResourceInfoUrl(
  params: GetDeploymentKibResourceInfoUrlParams,
): string {
  const queryString = _buildQueryString({
    show_metadata: params.showMetadata === undefined ? false : params.showMetadata,
    show_plans: params.showPlans === undefined ? true : params.showPlans,
    show_plan_logs: params.showPlanLogs === undefined ? false : params.showPlanLogs,
    show_plan_history: params.showPlanHistory === undefined ? false : params.showPlanHistory,
    show_plan_defaults: params.showPlanDefaults === undefined ? false : params.showPlanDefaults,
    convert_legacy_plans:
      params.convertLegacyPlans === undefined ? false : params.convertLegacyPlans,
    show_settings: params.showSettings === undefined ? false : params.showSettings,
    clear_transient: params.clearTransient === undefined ? false : params.clearTransient,
  })
  const path = _encode`/${params.regionId}/deployments/${params.deploymentId}/kibana/${params.refId}`
  return `${_getBasePath()}${path}${queryString}`
}

export interface GetDeploymentKibResourceInfoUrlParams {
  deploymentId: string
  refId: string
  showMetadata?: boolean | null
  showPlans?: boolean | null
  showPlanLogs?: boolean | null
  showPlanHistory?: boolean | null
  showPlanDefaults?: boolean | null
  convertLegacyPlans?: boolean | null
  showSettings?: boolean | null
  clearTransient?: boolean | null
  regionId: string
}

/**
 * GET /api/v1/deployments/{deployment_id}/migrate_template
 * Operation: `migrate-deployment-template`
 * Returns a deployment update request that would migrate this deployment to a different template.
 * @param {string} params.deploymentId Identifier for the Deployment.
 * @param {string} params.templateId The ID of the deployment template to migrate to.
 * @see 200 The request was valid and the deployment can be migrated to the template with the provided ID. See {@link DeploymentUpdateRequest}
 * @see 400 The deployment cannot be successfully migrated to the template with the provided ID. See {@link BasicFailedReply}
 * @see 401 You are not authorized to perform this action. See {@link BasicFailedReply}
 * @see 404 The Deployment specified by {deployment_id} cannot be found. (code: `deployments.deployment_not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function migrateDeploymentTemplateUrl(params: MigrateDeploymentTemplateUrlParams): string {
  const queryString = _buildQueryString({
    template_id: params.templateId,
  })
  const path = _encode`/deployments/${params.deploymentId}/migrate_template`
  return `${_getRawBasePath()}${path}${queryString}`
}

export interface MigrateDeploymentTemplateUrlParams {
  deploymentId: string
  templateId: string
}

/**
 * GET /api/v1/deployments/{deployment_id}/upgrade_assistant/status
 * Operation: `get-deployment-upgrade-assistant-status`
 * Get details about an Elasticsearch resource belonging to a given deployment.
 * @param {string} params.deploymentId Identifier for the Deployment.
 * @param {string} params.targetVersion If present, value is included in resource request to provide additional context (only supported for Kibana).
 * @see 200 The Upgrade Assistant returned the status successfully. See {@link DeploymentUpgradeAssistantStatusResponse}
 * @see 404 The Deployment specified by {deployment_id} cannot be found. (code: `deployments.deployment_not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getDeploymentUpgradeAssistantStatusUrl(
  params: GetDeploymentUpgradeAssistantStatusUrlParams,
): string {
  const queryString = _buildQueryString({
    target_version: params.targetVersion,
  })
  const path = _encode`/deployments/${params.deploymentId}/upgrade_assistant/status`
  return `${_getRawBasePath()}${path}${queryString}`
}

export interface GetDeploymentUpgradeAssistantStatusUrlParams {
  deploymentId: string
  targetVersion?: string | null
}

/**
 * POST /api/v1/regions/{region_id}/deployments/{deployment_id}/{resource_kind}/{ref_id}/_restore
 * Operation: `restore-deployment-resource`
 * Restores a shutdown resource belonging to a given Deployment.
 * @param {string} params.deploymentId Identifier for the Deployment.
 * @param {string} params.resourceKind The kind of resource.
 * @param {string} params.refId User-specified RefId for the Resource (or '_main' if there is only one).
 * @param {boolean} params.restoreSnapshot Whether or not to restore a snapshot for those resources that allow it.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 Standard Deployment Resource Crud Response. See {@link DeploymentResourceCrudResponse}
 * @see 400 * The Resource does not have a pending plan. (code: `deployments.resource_does_not_have_a_pending_plan`)
 * The resource is not shut down. (code: `deployments.resource_not_shutdown`). See {@link BasicFailedReply}
 * @see 404 The Deployment specified by {deployment_id} cannot be found. (code: `deployments.deployment_not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function restoreDeploymentResourceUrl(params: RestoreDeploymentResourceUrlParams): string {
  const queryString = _buildQueryString({
    restore_snapshot: params.restoreSnapshot === undefined ? false : params.restoreSnapshot,
  })
  const path = _encode`/${params.regionId}/deployments/${params.deploymentId}/${params.resourceKind}/${params.refId}/_restore`
  return `${_getBasePath()}${path}${queryString}`
}

export interface RestoreDeploymentResourceUrlParams {
  deploymentId: string
  resourceKind: string
  refId: string
  restoreSnapshot?: boolean | null
  regionId: string
}

/**
 * GET /api/v1/deployments/{deployment_id}/{resource_kind}/{ref_id}/activity/{attempt_id}
 * Operation: `get-deployment-resource-plan-activity`
 * Retrieves details for a single plan activity for the given resource.
 * @param {string} params.deploymentId Identifier for the Deployment.
 * @param {string} params.resourceKind The kind of resource.
 * @param {string} params.refId User-specified RefId for the Resource (or '_main' if there is only one).
 * @param {string} params.attemptId Attempt id for the activity.
 * @see 200 Returning the single plan activity for the specified resource. See {@link CommonClusterPlanInfo}
 * @see 404 * The Deployment specified by {deployment_id} cannot be found. (code: `deployments.deployment_not_found`)
 * The Resource specified by {ref_id} cannot be found. (code: `deployments.deployment_resource_not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getDeploymentResourcePlanActivityUrl(
  params: GetDeploymentResourcePlanActivityUrlParams,
): string {
  const path = _encode`/deployments/${params.deploymentId}/${params.resourceKind}/${params.refId}/activity/${params.attemptId}`
  return `${_getRawBasePath()}${path}`
}

export interface GetDeploymentResourcePlanActivityUrlParams {
  deploymentId: string
  resourceKind: string
  refId: string
  attemptId: string
}

/**
 * POST /api/v1/deployments/{deployment_id}/{resource_kind}/{ref_id}/diagnostics/_capture
 * Operation: `capture-deployment-resource-diagnostics`
 * Retrieves a diagnostic bundle from an active cluster. To successfully retrieve a diagnostic bundle, the cluster must be responsive.
 * @param {string} params.deploymentId Identifier for the Deployment.
 * @param {string} params.resourceKind The kind of resource (one of elasticsearch or kibana).
 * @param {string} params.refId User-specified RefId for the Resource (or '_main' if there is only one).
 * @param {string} params.mode Capture mode - whether to obtain all the diagnostics data or the lightweight, essential-only subset of it. Applicable only to Elasticsearch, ignored for any other components which have just one capture mode implemented. Default: full
 * @see 200 The diagnostic bundle as a zip file. See {@link string}
 * @see 404 * The Deployment specified by {deployment_id} cannot be found. (code: `deployments.deployment_not_found`)
 * The Resource specified by {ref_id} cannot be found. (code: `deployments.deployment_resource_not_found`). See {@link BasicFailedReply}
 * @see 412 The diagnostics cannot be generated because the cluster is either stopped or initializing. (code: `clusters.cluster_plan_state_error`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function captureDeploymentResourceDiagnosticsUrl(
  params: CaptureDeploymentResourceDiagnosticsUrlParams,
): string {
  const queryString = _buildQueryString({
    mode: params.mode === undefined ? `full` : params.mode,
  })
  const path = _encode`/deployments/${params.deploymentId}/${params.resourceKind}/${params.refId}/diagnostics/_capture`
  return `${_getRawBasePath()}${path}${queryString}`
}

export interface CaptureDeploymentResourceDiagnosticsUrlParams {
  deploymentId: string
  resourceKind: string
  refId: string
  mode?: string | null
}

/**
 * POST /api/v1/deployments/{deployment_id}/{resource_kind}/{ref_id}/instances/_start
 * Operation: `start-deployment-resource-instances-all`
 * Starts all instances belonging to a Deployment Resource.
 * @param {string} params.deploymentId Identifier for the Deployment.
 * @param {string} params.resourceKind The kind of resource (one of elasticsearch, kibana, apm, or integrations_server).
 * @param {string} params.refId User-specified RefId for the Resource (or '_main' if there is only one).
 * @see 202 The start command was issued successfully. See {@link DeploymentResourceCommandResponse}
 * @see 403 The start maintenance mode command was prohibited for the given Resource. (code: `deployments.instance_update_prohibited_error`). See {@link BasicFailedReply}
 * @see 404 * The Deployment specified by {deployment_id} cannot be found. (code: `deployments.deployment_not_found`)
 * The Resource specified by {ref_id} cannot be found. (code: `deployments.deployment_resource_not_found`)
 * One or more instances of the given resource type are missing. (code: `deployments.instances_missing_on_update_error`). See {@link BasicFailedReply}
 * @see 500 A Resource that was previously stored no longer exists. (code: `deployments.deployment_resource_no_longer_exists`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function startDeploymentResourceInstancesAllUrl(
  params: StartDeploymentResourceInstancesAllUrlParams,
): string {
  const path = _encode`/deployments/${params.deploymentId}/${params.resourceKind}/${params.refId}/instances/_start`
  return `${_getRawBasePath()}${path}`
}

export interface StartDeploymentResourceInstancesAllUrlParams {
  deploymentId: string
  resourceKind: string
  refId: string
}

/**
 * POST /api/v1/deployments/{deployment_id}/{resource_kind}/{ref_id}/instances/_stop
 * Operation: `stop-deployment-resource-instances-all`
 * Stops all instances belonging to a Deployment Resource.
 * @param {string} params.deploymentId Identifier for the Deployment.
 * @param {string} params.resourceKind The kind of resource (one of elasticsearch, kibana, apm, or integrations_server).
 * @param {string} params.refId User-specified RefId for the Resource (or '_main' if there is only one).
 * @see 202 The stop command was issued successfully. See {@link DeploymentResourceCommandResponse}
 * @see 403 The stop maintenance mode command was prohibited for the given Resource. (code: `deployments.instance_update_prohibited_error`). See {@link BasicFailedReply}
 * @see 404 * The Deployment specified by {deployment_id} cannot be found. (code: `deployments.deployment_not_found`)
 * The Resource specified by {ref_id} cannot be found. (code: `deployments.deployment_resource_not_found`)
 * One or more instances of the given resource type are missing. (code: `deployments.instances_missing_on_update_error`). See {@link BasicFailedReply}
 * @see 500 A Resource that was previously stored no longer exists. (code: `deployments.deployment_resource_no_longer_exists`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function stopDeploymentResourceInstancesAllUrl(
  params: StopDeploymentResourceInstancesAllUrlParams,
): string {
  const path = _encode`/deployments/${params.deploymentId}/${params.resourceKind}/${params.refId}/instances/_stop`
  return `${_getRawBasePath()}${path}`
}

export interface StopDeploymentResourceInstancesAllUrlParams {
  deploymentId: string
  resourceKind: string
  refId: string
}

/**
 * POST /api/v1/deployments/{deployment_id}/{resource_kind}/{ref_id}/instances/maintenance-mode/_start
 * Operation: `start-deployment-resource-instances-all-maintenance-mode`
 * Starts maintenance mode of all instances belonging to a Deployment Resource.
 * @param {string} params.deploymentId Identifier for the Deployment.
 * @param {string} params.resourceKind The kind of resource (one of elasticsearch, kibana, apm, or integrations_server).
 * @param {string} params.refId User-specified RefId for the Resource (or '_main' if there is only one).
 * @see 202 The start maintenance command was issued successfully. See {@link DeploymentResourceCommandResponse}
 * @see 403 The start maintenance mode command was prohibited for the given Resource. (code: `deployments.instance_update_prohibited_error`). See {@link BasicFailedReply}
 * @see 404 * The Deployment specified by {deployment_id} cannot be found. (code: `deployments.deployment_not_found`)
 * The Resource specified by {ref_id} cannot be found. (code: `deployments.deployment_resource_not_found`)
 * One or more instances of the given resource type are missing. (code: `deployments.instances_missing_on_update_error`). See {@link BasicFailedReply}
 * @see 500 A Resource that was previously stored no longer exists. (code: `deployments.deployment_resource_no_longer_exists`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function startDeploymentResourceInstancesAllMaintenanceModeUrl(
  params: StartDeploymentResourceInstancesAllMaintenanceModeUrlParams,
): string {
  const path = _encode`/deployments/${params.deploymentId}/${params.resourceKind}/${params.refId}/instances/maintenance-mode/_start`
  return `${_getRawBasePath()}${path}`
}

export interface StartDeploymentResourceInstancesAllMaintenanceModeUrlParams {
  deploymentId: string
  resourceKind: string
  refId: string
}

/**
 * POST /api/v1/deployments/{deployment_id}/{resource_kind}/{ref_id}/instances/maintenance-mode/_stop
 * Operation: `stop-deployment-resource-instances-all-maintenance-mode`
 * Stops maintenance mode of all instances belonging to a Deployment Resource.
 * @param {string} params.deploymentId Identifier for the Deployment.
 * @param {string} params.resourceKind The kind of resource (one of elasticsearch, kibana, apm, or integrations_server).
 * @param {string} params.refId User-specified RefId for the Resource (or '_main' if there is only one).
 * @see 202 The stop maintenance mode command was issued successfully. See {@link DeploymentResourceCommandResponse}
 * @see 403 The stop maintenance mode command was prohibited for the given Resource. (code: `deployments.instance_update_prohibited_error`). See {@link BasicFailedReply}
 * @see 404 * The Deployment specified by {deployment_id} cannot be found. (code: `deployments.deployment_not_found`)
 * The Resource specified by {ref_id} cannot be found. (code: `deployments.deployment_resource_not_found`)
 * One or more instances of the given resource type are missing. (code: `deployments.instances_missing_on_update_error`). See {@link BasicFailedReply}
 * @see 500 A Resource that was previously stored no longer exists. (code: `deployments.deployment_resource_no_longer_exists`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function stopDeploymentResourceInstancesAllMaintenanceModeUrl(
  params: StopDeploymentResourceInstancesAllMaintenanceModeUrlParams,
): string {
  const path = _encode`/deployments/${params.deploymentId}/${params.resourceKind}/${params.refId}/instances/maintenance-mode/_stop`
  return `${_getRawBasePath()}${path}`
}

export interface StopDeploymentResourceInstancesAllMaintenanceModeUrlParams {
  deploymentId: string
  resourceKind: string
  refId: string
}

/**
 * PUT /api/v1/deployments/{deployment_id}/{resource_kind}/{ref_id}/instances/overrides
 * Operation: `set-all-instances-settings-overrides`
 * Applies size overrides for all of the instances belonging to the given resource. Instance size overrides will be reset with the next plan change.
 * @param {string} params.deploymentId Identifier for the Deployment.
 * @param {string} params.resourceKind The kind of resource. Only Elasticsearch resources are currently supported.
 * @param {string} params.refId User-specified RefId for the Resource (or '_main' if there is only one).
 * @param {boolean} params.restartAfterUpdate After overrides are set, restarts the instance to apply the changes.
 * @see Accepts a request payload of type {@link InstanceOverrides}
 * @see 200 The updated overrides. See {@link InstanceOverrides}
 * @see 404 * The deployment specified by {deployment_id} cannot be found. (code: `deployments.deployment_not_found`)
 * The Resource specified by {ref_id} cannot by found. (code: `deployments.deployment_resource_not_found`)
 * The specified override was out of range. Capacity must be in the range [1024, 65536] and storage multiplier must be in the range [1.0, 1000.0]. (code: `instance_overrides.out_of_range`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function setAllInstancesSettingsOverridesUrl(
  params: SetAllInstancesSettingsOverridesUrlParams,
): string {
  const queryString = _buildQueryString({
    restart_after_update:
      params.restartAfterUpdate === undefined ? false : params.restartAfterUpdate,
  })
  const path = _encode`/deployments/${params.deploymentId}/${params.resourceKind}/${params.refId}/instances/overrides`
  return `${_getRawBasePath()}${path}${queryString}`
}

export interface SetAllInstancesSettingsOverridesUrlParams {
  deploymentId: string
  resourceKind: string
  refId: string
  restartAfterUpdate?: boolean | null
}

/**
 * POST /api/v1/deployments/{deployment_id}/{resource_kind}/{ref_id}/instances/{instance_ids}/_start
 * Operation: `start-deployment-resource-instances`
 * Starts instances belonging to a Deployment Resource.
 * @param {string} params.deploymentId Identifier for the Deployment.
 * @param {string} params.resourceKind The kind of resource (one of elasticsearch, kibana, apm, or integrations_server).
 * @param {string} params.refId User-specified RefId for the Resource (or '_main' if there is only one).
 * @param {array} params.instanceIds A comma-separated list of instance identifiers.
 * @param {boolean} params.ignoreMissing If true and the instance does not exist then quietly proceed to the next instance, otherwise treated as an error.
 * @see 202 The start command was issued successfully. See {@link DeploymentResourceCommandResponse}
 * @see 403 The start maintenance mode command was prohibited for the given Resource. (code: `deployments.instance_update_prohibited_error`). See {@link BasicFailedReply}
 * @see 404 * The Deployment specified by {deployment_id} cannot be found. (code: `deployments.deployment_not_found`)
 * The Resource specified by {ref_id} cannot be found. (code: `deployments.deployment_resource_not_found`)
 * One or more instances of the given resource type are missing. (code: `deployments.instances_missing_on_update_error`). See {@link BasicFailedReply}
 * @see 500 A Resource that was previously stored no longer exists. (code: `deployments.deployment_resource_no_longer_exists`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function startDeploymentResourceInstancesUrl(
  params: StartDeploymentResourceInstancesUrlParams,
): string {
  const queryString = _buildQueryString({
    ignore_missing: params.ignoreMissing === undefined ? false : params.ignoreMissing,
  })
  const path = _encode`/deployments/${params.deploymentId}/${params.resourceKind}/${params.refId}/instances/${params.instanceIds}/_start`
  return `${_getRawBasePath()}${path}${queryString}`
}

export interface StartDeploymentResourceInstancesUrlParams {
  deploymentId: string
  resourceKind: string
  refId: string
  instanceIds: string[]
  ignoreMissing?: boolean | null
}

/**
 * POST /api/v1/deployments/{deployment_id}/{resource_kind}/{ref_id}/instances/{instance_ids}/_stop
 * Operation: `stop-deployment-resource-instances`
 * Stops instances belonging to a Deployment Resource.
 * @param {string} params.deploymentId Identifier for the Deployment.
 * @param {string} params.resourceKind The kind of resource (one of elasticsearch, kibana, apm, or integrations_server).
 * @param {string} params.refId User-specified RefId for the Resource (or '_main' if there is only one).
 * @param {array} params.instanceIds A comma-separated list of instance identifiers.
 * @param {boolean} params.ignoreMissing If true and the instance does not exist then quietly proceed to the next instance, otherwise treated as an error.
 * @see 202 The stop command was issued successfully. See {@link DeploymentResourceCommandResponse}
 * @see 403 The start maintenance mode command was prohibited for the given Resource. (code: `deployments.instance_update_prohibited_error`). See {@link BasicFailedReply}
 * @see 404 * The Deployment specified by {deployment_id} cannot be found. (code: `deployments.deployment_not_found`)
 * The Resource specified by {ref_id} cannot be found. (code: `deployments.deployment_resource_not_found`)
 * One or more instances of the given resource type are missing. (code: `deployments.instances_missing_on_update_error`). See {@link BasicFailedReply}
 * @see 500 A Resource that was previously stored no longer exists. (code: `deployments.deployment_resource_no_longer_exists`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function stopDeploymentResourceInstancesUrl(
  params: StopDeploymentResourceInstancesUrlParams,
): string {
  const queryString = _buildQueryString({
    ignore_missing: params.ignoreMissing === undefined ? false : params.ignoreMissing,
  })
  const path = _encode`/deployments/${params.deploymentId}/${params.resourceKind}/${params.refId}/instances/${params.instanceIds}/_stop`
  return `${_getRawBasePath()}${path}${queryString}`
}

export interface StopDeploymentResourceInstancesUrlParams {
  deploymentId: string
  resourceKind: string
  refId: string
  instanceIds: string[]
  ignoreMissing?: boolean | null
}

/**
 * POST /api/v1/deployments/{deployment_id}/{resource_kind}/{ref_id}/instances/{instance_ids}/maintenance-mode/_start
 * Operation: `start-deployment-resource-maintenance-mode`
 * Starts maintenance mode of instances belonging to a Deployment Resource.
 * @param {string} params.deploymentId Identifier for the Deployment.
 * @param {string} params.resourceKind The kind of resource (one of elasticsearch, kibana, apm, or integrations_server).
 * @param {string} params.refId User-specified RefId for the Resource (or '_main' if there is only one).
 * @param {array} params.instanceIds A comma-separated list of instance identifiers.
 * @param {boolean} params.ignoreMissing If true and the instance does not exist then quietly proceed to the next instance, otherwise treated as an error.
 * @see 202 The start maintenance command was issued successfully. See {@link DeploymentResourceCommandResponse}
 * @see 403 The start maintenance mode command was prohibited for the given Resource. (code: `deployments.instance_update_prohibited_error`). See {@link BasicFailedReply}
 * @see 404 * The Deployment specified by {deployment_id} cannot be found. (code: `deployments.deployment_not_found`)
 * The Resource specified by {ref_id} cannot be found. (code: `deployments.deployment_resource_not_found`)
 * One or more instances of the given resource type are missing. (code: `deployments.instances_missing_on_update_error`). See {@link BasicFailedReply}
 * @see 500 A Resource that was previously stored no longer exists. (code: `deployments.deployment_resource_no_longer_exists`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function startDeploymentResourceMaintenanceModeUrl(
  params: StartDeploymentResourceMaintenanceModeUrlParams,
): string {
  const queryString = _buildQueryString({
    ignore_missing: params.ignoreMissing === undefined ? false : params.ignoreMissing,
  })
  const path = _encode`/deployments/${params.deploymentId}/${params.resourceKind}/${params.refId}/instances/${params.instanceIds}/maintenance-mode/_start`
  return `${_getRawBasePath()}${path}${queryString}`
}

export interface StartDeploymentResourceMaintenanceModeUrlParams {
  deploymentId: string
  resourceKind: string
  refId: string
  instanceIds: string[]
  ignoreMissing?: boolean | null
}

/**
 * POST /api/v1/deployments/{deployment_id}/{resource_kind}/{ref_id}/instances/{instance_ids}/maintenance-mode/_stop
 * Operation: `stop-deployment-resource-maintenance-mode`
 * Stops maintenance mode of instances belonging to a Resource.
 * @param {string} params.deploymentId Identifier for the Deployment.
 * @param {string} params.resourceKind The kind of resource (one of elasticsearch, kibana, apm, or integrations_server).
 * @param {string} params.refId User-specified RefId for the Resource (or '_main' if there is only one).
 * @param {array} params.instanceIds A comma-separated list of instance identifiers.
 * @param {boolean} params.ignoreMissing If true and the instance does not exist then quietly proceed to the next instance, otherwise treated as an error.
 * @see 202 The stop maintenance mode command was issued successfully. See {@link DeploymentResourceCommandResponse}
 * @see 403 The stop maintenance mode command was prohibited for the given Resource. (code: `deployments.instance_update_prohibited_error`). See {@link BasicFailedReply}
 * @see 404 * The Deployment specified by {deployment_id} cannot be found. (code: `deployments.deployment_not_found`)
 * The Resource specified by {ref_id} cannot be found. (code: `deployments.deployment_resource_not_found`)
 * One or more instances of the given resource type are missing. (code: `deployments.instances_missing_on_update_error`). See {@link BasicFailedReply}
 * @see 500 A Resource that was previously stored no longer exists. (code: `deployments.deployment_resource_no_longer_exists`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function stopDeploymentResourceMaintenanceModeUrl(
  params: StopDeploymentResourceMaintenanceModeUrlParams,
): string {
  const queryString = _buildQueryString({
    ignore_missing: params.ignoreMissing === undefined ? false : params.ignoreMissing,
  })
  const path = _encode`/deployments/${params.deploymentId}/${params.resourceKind}/${params.refId}/instances/${params.instanceIds}/maintenance-mode/_stop`
  return `${_getRawBasePath()}${path}${queryString}`
}

export interface StopDeploymentResourceMaintenanceModeUrlParams {
  deploymentId: string
  resourceKind: string
  refId: string
  instanceIds: string[]
  ignoreMissing?: boolean | null
}

/**
 * PUT /api/v1/deployments/{deployment_id}/{resource_kind}/{ref_id}/instances/{instance_ids}/overrides
 * Operation: `set-instance-settings-overrides`
 * Applies size overrides for the given instances belonging to the given resource. Instance size overrides will be reset with the next plan change.
 * @param {string} params.deploymentId Identifier for the Deployment.
 * @param {string} params.resourceKind The kind of resource. Only Elasticsearch resources are currently supported.
 * @param {string} params.refId User-specified RefId for the Resource (or '_main' if there is only one).
 * @param {array} params.instanceIds A comma-separated list of instance identifiers.
 * @param {boolean} params.restartAfterUpdate After overrides are set, restarts the instance to apply the changes.
 * @param {boolean} params.ignoreMissing When `false` and a specified instance is missing, will return an error and not update any instances. When `true` will update only existing instances and not treat a missing instance as an error.
 * @see Accepts a request payload of type {@link InstanceOverrides}
 * @see 200 The updated overrides. See {@link InstanceOverrides}
 * @see 404 * The deployment specified by {deployment_id} cannot be found. (code: `deployments.deployment_not_found`)
 * The Resource specified by {ref_id} cannot by found. (code: `deployments.deployment_resource_not_found`)
 * One or more instances weren't found, and `ignore_missing` is false or unspecified. (code: `deployments.instances_missing_on_update_error`)
 * The specified override was out of range. Capacity must be in the range [1024, 65536] and storage multiplier must be in the range [1.0, 1000.0]. (code: `instance_overrides.out_of_range`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function setInstanceSettingsOverridesUrl(
  params: SetInstanceSettingsOverridesUrlParams,
): string {
  const queryString = _buildQueryString({
    restart_after_update:
      params.restartAfterUpdate === undefined ? false : params.restartAfterUpdate,
    ignore_missing: params.ignoreMissing === undefined ? false : params.ignoreMissing,
  })
  const path = _encode`/deployments/${params.deploymentId}/${params.resourceKind}/${params.refId}/instances/${params.instanceIds}/overrides`
  return `${_getRawBasePath()}${path}${queryString}`
}

export interface SetInstanceSettingsOverridesUrlParams {
  deploymentId: string
  resourceKind: string
  refId: string
  instanceIds: string[]
  restartAfterUpdate?: boolean | null
  ignoreMissing?: boolean | null
}

/**
 * POST /api/v1/deployments/{deployment_id}/{resource_kind}/{ref_id}/instances/{instance_id}/heap_dump/_capture
 * Operation: `capture-deployment-instance-heap-dump`
 * Captures a new on-demand heap dump for the given instance. The capture is completed asynchronously, and you can check its progress by getting heap dumps for the given resource.
 * @param {string} params.deploymentId Identifier for the Deployment.
 * @param {string} params.resourceKind The kind of resource (one of elasticsearch, kibana or apm).
 * @param {string} params.refId User-specified RefId for the Resource (or '_main' if there is only one).
 * @param {string} params.instanceId The instance identifier.
 * @see 202 Indicates that the capture has started
 * @see 404 * The Deployment specified by {deployment_id} cannot be found. (code: `deployments.deployment_not_found`)
 * The Resource specified by {ref_id} cannot be found. (code: `deployments.deployment_resource_not_found`)
 * The specified instance cannot be found. (code: `deployments.instance_not_found_error`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function captureDeploymentInstanceHeapDumpUrl(
  params: CaptureDeploymentInstanceHeapDumpUrlParams,
): string {
  const path = _encode`/deployments/${params.deploymentId}/${params.resourceKind}/${params.refId}/instances/${params.instanceId}/heap_dump/_capture`
  return `${_getRawBasePath()}${path}`
}

export interface CaptureDeploymentInstanceHeapDumpUrlParams {
  deploymentId: string
  resourceKind: string
  refId: string
  instanceId: string
}

/**
 * GET /api/v1/deployments/{deployment_id}/{resource_kind}/{ref_id}/instances/{instance_id}/heap_dump/_download
 * Operation: `download-deployment-instance-heap-dump`
 * Returns the given instance's heap dump for download.
 * @param {string} params.deploymentId Identifier for the Deployment.
 * @param {string} params.resourceKind The kind of resource (one of elasticsearch, kibana or apm).
 * @param {string} params.refId User-specified RefId for the Resource (or '_main' if there is only one).
 * @param {string} params.instanceId The instance identifier.
 * @see 200 The given instance's heap dump. See {@link string}
 * @see 404 * The Deployment specified by {deployment_id} cannot be found. (code: `deployments.deployment_not_found`)
 * The Resource specified by {ref_id} cannot be found. (code: `deployments.deployment_resource_not_found`)
 * The specified instance cannot be found. (code: `deployments.instance_not_found_error`)
 * The specified instance does not have a heap dump available to download. (code: `heap_dumps.heap_dump_not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function downloadDeploymentInstanceHeapDumpUrl(
  params: DownloadDeploymentInstanceHeapDumpUrlParams,
): string {
  const path = _encode`/deployments/${params.deploymentId}/${params.resourceKind}/${params.refId}/instances/${params.instanceId}/heap_dump/_download`
  return `${_getRawBasePath()}${path}`
}

export interface DownloadDeploymentInstanceHeapDumpUrlParams {
  deploymentId: string
  resourceKind: string
  refId: string
  instanceId: string
}

/**
 * POST /api/v1/deployments/{deployment_id}/{resource_kind}/{ref_id}/instances/{instance_id}/thread_dump/_capture
 * Operation: `capture-deployment-instance-thread-dump`
 * Captures a new thread dump for the given instance and returns the thread dump contents.
 * @param {string} params.deploymentId Identifier for the Deployment.
 * @param {string} params.resourceKind The kind of resource (one of elasticsearch, kibana or apm).
 * @param {string} params.refId User-specified RefId for the Resource (or '_main' if there is only one).
 * @param {string} params.instanceId The instance identifier.
 * @see 200 The thread dump contents. See {@link string}
 * @see 404 * The Deployment specified by {deployment_id} cannot be found. (code: `deployments.deployment_not_found`)
 * The Resource specified by {ref_id} cannot be found. (code: `deployments.deployment_resource_not_found`)
 * The specified instance cannot be found. (code: `deployments.instance_not_found_error`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function captureDeploymentInstanceThreadDumpUrl(
  params: CaptureDeploymentInstanceThreadDumpUrlParams,
): string {
  const path = _encode`/deployments/${params.deploymentId}/${params.resourceKind}/${params.refId}/instances/${params.instanceId}/thread_dump/_capture`
  return `${_getRawBasePath()}${path}`
}

export interface CaptureDeploymentInstanceThreadDumpUrlParams {
  deploymentId: string
  resourceKind: string
  refId: string
  instanceId: string
}

/**
 * POST /api/v1/deployments/{deployment_id}/{resource_kind}/{ref_id}/metadata/raw
 * Operation: `set-deployment-resource-raw-metadata`
 * Advanced use only. Sets the internal metadata, in free-form JSON, for the resource. Only use the parameter to set the modified JSON that is returned from the get version of the metadata.
 * @param {string} params.deploymentId Identifier for the Deployment.
 * @param {string} params.resourceKind The kind of resource.
 * @param {string} params.refId User-specified RefId for the Resource (or '_main' if there is only one).
 * @param {string} params.version If specified, checks for conflicts against the metadata version (returned in 'x-cloud-resource-version' of the GET request).
 * @see Accepts a request payload of type {@link string}
 * @see 200 The resource metadata was successfully changed (the updated JSON is returned). See {@link string}
 * @see 400 The metadata is invalid. (code: `deployments.invalid_metadata`). See {@link BasicFailedReply}
 * @see 404 * The Deployment specified by {deployment_id} cannot be found. (code: `deployments.deployment_not_found`)
 * The Resource specified by {ref_id} cannot be found. (code: `deployments.deployment_resource_not_found`). See {@link BasicFailedReply}
 * @see 449 Please retry. (code: `deployments.metadata_internal_error`). See {@link BasicFailedReply}
 * @see 500 We have failed you. (code: `deployments.metadata_internal_error`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function setDeploymentResourceRawMetadataUrl(
  params: SetDeploymentResourceRawMetadataUrlParams,
): string {
  const queryString = _buildQueryString({
    version: params.version,
  })
  const path = _encode`/deployments/${params.deploymentId}/${params.resourceKind}/${params.refId}/metadata/raw`
  return `${_getRawBasePath()}${path}${queryString}`
}

export interface SetDeploymentResourceRawMetadataUrlParams {
  deploymentId: string
  resourceKind: string
  refId: string
  version?: string | null
}

/**
 * PATCH /api/v1/regions/{region_id}/deployments/{deployment_id}/{resource_kind}/{ref_id}/metadata/raw
 * Operation: `patch-deployment-resource-raw-metadata`
 * Advanced use only. Patches the internal metadata with the given data.
 * @param {string} params.deploymentId Identifier for the Deployment.
 * @param {string} params.resourceKind The kind of resource.
 * @param {string} params.refId User-specified RefId for the Resource (or '_main' if there is only one).
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see Accepts a request payload of type {@link string}
 * @see 200 The resource metadata was successfully changed (the updated JSON is returned). See {@link string}
 * @see 400 The metadata is invalid. (code: `deployments.invalid_metadata`). See {@link BasicFailedReply}
 * @see 404 * The Deployment specified by {deployment_id} cannot be found. (code: `deployments.deployment_not_found`)
 * The Resource specified by {ref_id} cannot be found. (code: `deployments.deployment_resource_not_found`). See {@link BasicFailedReply}
 * @see 449 Please retry. (code: `deployments.metadata_internal_error`). See {@link BasicFailedReply}
 * @see 500 We have failed you. (code: `deployments.metadata_internal_error`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function patchDeploymentResourceRawMetadataUrl(
  params: PatchDeploymentResourceRawMetadataUrlParams,
): string {
  const path = _encode`/${params.regionId}/deployments/${params.deploymentId}/${params.resourceKind}/${params.refId}/metadata/raw`
  return `${_getBasePath()}${path}`
}

export interface PatchDeploymentResourceRawMetadataUrlParams {
  deploymentId: string
  resourceKind: string
  refId: string
  regionId: string
}

/**
 * DELETE /api/v1/deployments/{deployment_id}/{resource_kind}/{ref_id}/plan/pending
 * Operation: `cancel-deployment-resource-pending-plan`
 * Cancels the pending plan of a Resource belonging to a given Deployment.
 * @param {string} params.deploymentId Identifier for the Deployment.
 * @param {string} params.resourceKind The kind of resource.
 * @param {string} params.refId User-specified RefId for the Resource (or '_main' if there is only one).
 * @param {boolean} params.forceDelete When `true`, deletes the pending plan instead of attempting a graceful cancellation. The default is `false`.
 * @param {boolean} params.ignoreMissing When `true`, returns successfully, even when plans are missing. The default is `false`.
 * @see 200 Standard Deployment Resource Crud Response. See {@link DeploymentResourceCrudResponse}
 * @see 400 The Resource does not have a pending plan. (code: `deployments.resource_does_not_have_a_pending_plan`). See {@link BasicFailedReply}
 * @see 404 The Deployment specified by {deployment_id} cannot be found. (code: `deployments.deployment_not_found`). See {@link BasicFailedReply}
 * @see 500 We have failed you. (code: `deployments.deployment_resource_no_longer_exists`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function cancelDeploymentResourcePendingPlanUrl(
  params: CancelDeploymentResourcePendingPlanUrlParams,
): string {
  const queryString = _buildQueryString({
    force_delete: params.forceDelete === undefined ? false : params.forceDelete,
    ignore_missing: params.ignoreMissing === undefined ? false : params.ignoreMissing,
  })
  const path = _encode`/deployments/${params.deploymentId}/${params.resourceKind}/${params.refId}/plan/pending`
  return `${_getRawBasePath()}${path}${queryString}`
}

export interface CancelDeploymentResourcePendingPlanUrlParams {
  deploymentId: string
  resourceKind: string
  refId: string
  forceDelete?: boolean | null
  ignoreMissing?: boolean | null
}

/**
 * GET /api/v1/deployments/{deployment_id}/{resource_kind}/{ref_id}/proxy/{proxy_path}
 * Operation: `get-deployment-resource-proxy-requests`
 * Proxies the HTTP GET request to the deployment resource. You must specify the `X-Management-Request` HTTP header. NOTE: Use this endpoint for management purposes. It does not provide high performance.
 * @param {string} params.deploymentId Identifier for the Deployment.
 * @param {string} params.resourceKind The kind of resource.
 * @param {string} params.refId User-specified RefId for the Resource (or '_main' if there is only one).
 * @param {string} params.proxyPath The URL part to proxy to the deployment resource. Example: _cat/indices, /api/spaces/space or /api/ent/v1/internal/health.
 * @see Accepts an optional request payload of type {@link string}
 * @see 200 The request has been processed successfully through the proxy. See {@link GenericResponse}
 * @see 404 * The Deployment specified by {deployment_id} cannot be found. (code: `deployments.deployment_not_found`)
 * The Resource specified by {ref_id} cannot be found. (code: `deployments.deployment_resource_not_found`)
 * The Resource specified by {ref_id} cannot be found. (code: `clusters.cluster_not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getDeploymentResourceProxyRequestsUrl(
  params: GetDeploymentResourceProxyRequestsUrlParams,
): string {
  const path = _encode`/deployments/${params.deploymentId}/${params.resourceKind}/${
    params.refId
  }/proxy/${{ __path: params.proxyPath }}`
  return `${_getRawBasePath()}${path}`
}

export interface GetDeploymentResourceProxyRequestsUrlParams {
  deploymentId: string
  resourceKind: string
  refId: string
  proxyPath: string
}

/**
 * POST /api/v1/deployments/{deployment_id}/{resource_kind}/{ref_id}/proxy/{proxy_path}
 * Operation: `post-deployment-resource-proxy-requests`
 * Proxies the HTTP POST request to the deployment resource. You must specify the `X-Management-Request` HTTP header. NOTE: Use this endpoint for management purposes. It does not provide high performance.
 * @param {string} params.deploymentId Identifier for the Deployment.
 * @param {string} params.resourceKind The kind of resource.
 * @param {string} params.refId User-specified RefId for the Resource (or '_main' if there is only one).
 * @param {string} params.proxyPath The URL part to proxy to the deployment resource. Example: _cat/indices, /api/spaces/space or /api/ent/v1/internal/health.
 * @see Accepts an optional request payload of type {@link string}
 * @see 200 The request has been processed successfully through the proxy. See {@link GenericResponse}
 * @see 404 * The Deployment specified by {deployment_id} cannot be found. (code: `deployments.deployment_not_found`)
 * The Resource specified by {ref_id} cannot be found. (code: `deployments.deployment_resource_not_found`)
 * The Resource specified by {ref_id} cannot be found. (code: `clusters.cluster_not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function postDeploymentResourceProxyRequestsUrl(
  params: PostDeploymentResourceProxyRequestsUrlParams,
): string {
  const path = _encode`/deployments/${params.deploymentId}/${params.resourceKind}/${
    params.refId
  }/proxy/${{ __path: params.proxyPath }}`
  return `${_getRawBasePath()}${path}`
}

export interface PostDeploymentResourceProxyRequestsUrlParams {
  deploymentId: string
  resourceKind: string
  refId: string
  proxyPath: string
}

/**
 * PUT /api/v1/deployments/{deployment_id}/{resource_kind}/{ref_id}/proxy/{proxy_path}
 * Operation: `put-deployment-resource-proxy-requests`
 * Proxies the HTTP PUT request to the deployment resource. You must specify the `X-Management-Request` HTTP header. NOTE: Use this endpoint for management purposes. It does not provide high performance.
 * @param {string} params.deploymentId Identifier for the Deployment.
 * @param {string} params.resourceKind The kind of resource.
 * @param {string} params.refId User-specified RefId for the Resource (or '_main' if there is only one).
 * @param {string} params.proxyPath The URL part to proxy to the deployment resource. Example: _cat/indices, /api/spaces/space or /api/ent/v1/internal/health.
 * @see Accepts an optional request payload of type {@link string}
 * @see 200 The request has been processed successfully through the proxy. See {@link GenericResponse}
 * @see 404 * The Deployment specified by {deployment_id} cannot be found. (code: `deployments.deployment_not_found`)
 * The Resource specified by {ref_id} cannot be found. (code: `deployments.deployment_resource_not_found`)
 * The Resource specified by {ref_id} cannot be found. (code: `clusters.cluster_not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function putDeploymentResourceProxyRequestsUrl(
  params: PutDeploymentResourceProxyRequestsUrlParams,
): string {
  const path = _encode`/deployments/${params.deploymentId}/${params.resourceKind}/${
    params.refId
  }/proxy/${{ __path: params.proxyPath }}`
  return `${_getRawBasePath()}${path}`
}

export interface PutDeploymentResourceProxyRequestsUrlParams {
  deploymentId: string
  resourceKind: string
  refId: string
  proxyPath: string
}

/**
 * DELETE /api/v1/deployments/{deployment_id}/{resource_kind}/{ref_id}/proxy/{proxy_path}
 * Operation: `delete-deployment-resource-proxy-requests`
 * Proxies the HTTP DELETE request to the deployment resource. You must specify the `X-Management-Request` HTTP header. NOTE: Use this endpoint for management purposes. It does not provide high performance.
 * @param {string} params.deploymentId Identifier for the Deployment.
 * @param {string} params.resourceKind The kind of resource.
 * @param {string} params.refId User-specified RefId for the Resource (or '_main' if there is only one).
 * @param {string} params.proxyPath The URL part to proxy to the deployment resource. Example: _cat/indices, /api/spaces/space or /api/ent/v1/internal/health.
 * @see Accepts an optional request payload of type {@link string}
 * @see 200 The request has been processed successfully through the proxy. See {@link GenericResponse}
 * @see 404 * The Deployment specified by {deployment_id} cannot be found. (code: `deployments.deployment_not_found`)
 * The Resource specified by {ref_id} cannot be found. (code: `deployments.deployment_resource_not_found`)
 * The Resource specified by {ref_id} cannot be found. (code: `clusters.cluster_not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function deleteDeploymentResourceProxyRequestsUrl(
  params: DeleteDeploymentResourceProxyRequestsUrlParams,
): string {
  const path = _encode`/deployments/${params.deploymentId}/${params.resourceKind}/${
    params.refId
  }/proxy/${{ __path: params.proxyPath }}`
  return `${_getRawBasePath()}${path}`
}

export interface DeleteDeploymentResourceProxyRequestsUrlParams {
  deploymentId: string
  resourceKind: string
  refId: string
  proxyPath: string
}

/**
 * DELETE /api/v1/deployments/{deployment_id}/{stateless_resource_kind}/{ref_id}
 * Operation: `delete-deployment-stateless-resource`
 * Delete Stateless Resource belonging to a given Deployment. Deployment must be shutdown already.
 * @param {string} params.deploymentId Identifier for the Deployment.
 * @param {string} params.statelessResourceKind The kind of stateless resource.
 * @param {string} params.refId User-specified RefId for the Resource (or '_main' if there is only one).
 * @see 200 Standard Deployment Resource Crud Response. See {@link DeploymentResourceCrudResponse}
 * @see 400 Resource has still instances. (code: `deployments.resource_has_instances`). See {@link BasicFailedReply}
 * @see 404 * The Deployment specified by {deployment_id} cannot be found. (code: `deployments.deployment_not_found`)
 * The Resource specified by {ref_id} cannot be found. (code: `deployments.deployment_resource_not_found`). See {@link BasicFailedReply}
 * @see 500 We have failed you. (code: `deployments.deployment_resource_no_longer_exists`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function deleteDeploymentStatelessResourceUrl(
  params: DeleteDeploymentStatelessResourceUrlParams,
): string {
  const path = _encode`/deployments/${params.deploymentId}/${params.statelessResourceKind}/${params.refId}`
  return `${_getRawBasePath()}${path}`
}

export interface DeleteDeploymentStatelessResourceUrlParams {
  deploymentId: string
  statelessResourceKind: string
  refId: string
}

/**
 * POST /api/v1/deployments/{deployment_id}/{stateless_resource_kind}/{ref_id}/_restart
 * Operation: `restart-deployment-stateless-resource`
 * Restarts a Stateless Resource. If a Resource is active: this command re-applies the existing plan but applies a "cluster_reboot", which issues a restart command and waits for it to complete. If a Resource is inactive: this command starts it up with the most recent successful plan.
 * @param {string} params.deploymentId Identifier for the Deployment.
 * @param {string} params.statelessResourceKind The kind of stateless resource.
 * @param {string} params.refId User-specified RefId for the Resource (or '_main' if there is only one).
 * @param {boolean} params.cancelPending If true, cancels any pending plans before restarting. If false and there are pending plans, returns an error.
 * @see 202 The restart command was issued successfully. See {@link DeploymentResourceCommandResponse}
 * @see 404 * The Deployment specified by {deployment_id} cannot be found. (code: `deployments.deployment_not_found`)
 * The Resource specified by {ref_id} cannot be found. (code: `deployments.deployment_resource_not_found`). See {@link BasicFailedReply}
 * @see 422 The command sent to a Resource found the Resource in an illegal state, the error message gives more details. (code: `deployments.deployment_resource_plan_change_error`). See {@link BasicFailedReply}
 * @see 500 We have failed you. (code: `deployments.deployment_resource_no_longer_exists`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function restartDeploymentStatelessResourceUrl(
  params: RestartDeploymentStatelessResourceUrlParams,
): string {
  const queryString = _buildQueryString({
    cancel_pending: params.cancelPending === undefined ? false : params.cancelPending,
  })
  const path = _encode`/deployments/${params.deploymentId}/${params.statelessResourceKind}/${params.refId}/_restart`
  return `${_getRawBasePath()}${path}${queryString}`
}

export interface RestartDeploymentStatelessResourceUrlParams {
  deploymentId: string
  statelessResourceKind: string
  refId: string
  cancelPending?: boolean | null
}

/**
 * POST /api/v1/deployments/{deployment_id}/{stateless_resource_kind}/{ref_id}/_shutdown
 * Operation: `shutdown-deployment-stateless-resource`
 * Shut down Stateless Resource belonging to a given Deployment. Kibana cannot be shut down on Elasticsearch Service as it is required for Elasticsearch administrative functions, such as Snapshot Lifecycle Management and version upgrades.
 * @param {string} params.deploymentId Identifier for the Deployment.
 * @param {string} params.statelessResourceKind The kind of stateless resource.
 * @param {string} params.refId User-specified RefId for the Resource (or '_main' if there is only one).
 * @param {boolean} params.hide Hide cluster on shutdown. Hidden clusters are not listed by default. Only applicable for Platform administrators.
 * @param {boolean} params.skipSnapshot If true, will skip taking a snapshot of the cluster before shutting the cluster down (if even possible).
 * @see 200 Standard response. See {@link DeploymentResourceCommandResponse}
 * @see 400 Parameter is restricted and can only be set by a Platform administrator. (code: `deployments.restricted_parameter`). See {@link BasicFailedReply}
 * @see 404 * The Deployment specified by {deployment_id} cannot be found. (code: `deployments.deployment_not_found`)
 * The Resource specified by {ref_id} cannot be found. (code: `deployments.deployment_resource_not_found`). See {@link BasicFailedReply}
 * @see 500 We have failed you. (code: `deployments.deployment_resource_no_longer_exists`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function shutdownDeploymentStatelessResourceUrl(
  params: ShutdownDeploymentStatelessResourceUrlParams,
): string {
  const queryString = _buildQueryString({
    hide: params.hide,
    skip_snapshot: params.skipSnapshot === undefined ? false : params.skipSnapshot,
  })
  const path = _encode`/deployments/${params.deploymentId}/${params.statelessResourceKind}/${params.refId}/_shutdown`
  return `${_getRawBasePath()}${path}${queryString}`
}

export interface ShutdownDeploymentStatelessResourceUrlParams {
  deploymentId: string
  statelessResourceKind: string
  refId: string
  hide?: boolean | null
  skipSnapshot?: boolean | null
}
