/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { Fragment } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useHistory } from 'react-router'

import { EuiBadge, EuiSpacer, EuiText, EuiSkeletonText, EuiButtonIcon } from '@elastic/eui'

import type { Statement } from '@modules/cloud-api/v1/types'
import CuiPrice from '@modules/cui/formatters/CuiPrice'
import CuiElasticConsumptionUnits from '@modules/cui/formatters/CuiElasticConsumptionUnits'
import type { Props as CuiTableProps } from '@modules/cui/Table/types'
import type { CuiTableColumn } from '@modules/cui/Table'
import { isPrepaidConsumptionCustomer } from '@modules/billing-lib/billingDetails/utils'
import useBillingDetails from '@modules/billing-lib/billingDetails/useBillingDetails'

import EcuHelpText from '@/components/EcuHelp/EcuHelpText'

import BillingDocuments from '../BillingDocuments'
import BillingHistoryPanel from '../BillingHistoryPanel'
import { toBillingPeriodFormat } from '../lib/date'
import { getBillingPdfs } from '../lib/getBillingPdfs'
import ErrorCallout from '../../ErrorCallout'

import type { ReactElement } from 'react'

export interface Props {
  loading: boolean
  statements: Statement[]
  title?: ReactElement
}

interface GridColumns {
  initialSort: CuiTableColumn<Statement>
  initialSortDirection: CuiTableProps<Statement>['initialSortDirection']
  columns: Array<CuiTableColumn<Statement>>
}

const BillingStatements = ({ loading, statements, title }: Props) => {
  const billingDetails = useBillingDetails()
  const history = useHistory()
  const { formatMessage } = useIntl()

  if (billingDetails.error) {
    return (
      <Fragment>
        <EuiSpacer size='xxl' />
        <ErrorCallout onReload={billingDetails.refetch} />
      </Fragment>
    )
  }

  if (billingDetails.loading) {
    return <EuiSkeletonText />
  }

  const getGridColumns = (): GridColumns => {
    const billingPeriodColumn = {
      label: <FormattedMessage id='billing-documents.period' defaultMessage='Billing period' />,
      render: ({ period_start_date, period_end_date, number }) => (
        <span data-test-id={`billing-period-${number}`}>
          {toBillingPeriodFormat({
            periodStartDate: period_start_date,
            periodEndDate: period_end_date,
          })}
        </span>
      ),
      sortKey: [
        ({ period_start_date }) => new Date(period_start_date),
        ({ period_end_date }) => new Date(period_end_date),
      ],
    }

    const columns: Array<CuiTableColumn<Statement>> = [
      billingPeriodColumn,
      {
        label: (
          <FormattedMessage
            id='billing-documents.statement-amount'
            defaultMessage='Invoiced amount'
          />
        ),
        render: ({ invoiced_amount_in_cents, currency, reseller }) => {
          if (reseller) {
            return <span data-test-id='invoiced-amount'>-</span>
          }

          return (
            <CuiPrice
              value={Math.max(0, invoiced_amount_in_cents!)}
              unit='cents'
              currency={currency}
              data-test-id='invoiced-amount'
            />
          )
        },
        sortKey: `invoiced_amount_in_cents`,
      },
      {
        label: (
          <FormattedMessage
            id='billing-statements.statement-number'
            defaultMessage='Usage statement'
          />
        ),
        // @ts-ignore TODO: remove comment once external_invoice_pdf_download has been added to Statement API
        render: ({ usage_pdf_download, external_invoice_pdf_download, number, issue_date }) =>
          getBillingPdfs({
            usage_pdf_download,
            external_invoice_pdf_download,
            number,
            issue_date,
          }),
        sortKey: `number`,
      },
      {
        label: <FormattedMessage id='billing-documents.status' defaultMessage='Status' />,
        render: ({ status }) => {
          if (status === `paid_from_balance`) {
            return (
              <EuiBadge color='default'>
                {isPrepaidConsumptionCustomer(billingDetails.data!) ? (
                  <FormattedMessage
                    id='billing-statements.paid-from-balance'
                    defaultMessage='Paid from balance'
                  />
                ) : (
                  <FormattedMessage id='billing-documents.paid' defaultMessage='Paid' />
                )}
              </EuiBadge>
            )
          }

          return (
            <EuiBadge color='warning'>
              <FormattedMessage id='billing-documents.invoiced' defaultMessage='Invoiced' />
            </EuiBadge>
          )
        },
        sortKey: `status`,
        width: `10%`,
      },
      {
        label: <FormattedMessage id='billing-documents.actions' defaultMessage='Actions' />,
        render: ({ period_start_date, period_end_date }) => (
          <EuiButtonIcon
            aria-label={formatMessage({
              id: 'billing-documents.view-usage',
              defaultMessage: 'View usage',
            })}
            data-test-subj='billing-history.usage-link'
            onClick={() =>
              history.push({
                pathname: 'usage',
                search: `?startDate=${period_start_date}&endDate=${period_end_date}`,
              })
            }
            iconType='visLine'
          />
        ),
        actions: true,
        align: `center`,
        width: `80px`,
      },
    ]

    if (isPrepaidConsumptionCustomer(billingDetails.data!)) {
      columns.splice(1, 0, {
        key: 'billing-statements.usage-units',
        label: (
          <FormattedMessage id='billing-statements.usage-units' defaultMessage='Usage (ECUs)' />
        ),
        render: ({ ecu_total }) => (
          <CuiElasticConsumptionUnits
            unit='none'
            value={Math.max(0, ecu_total)}
            withSymbol={false}
          />
        ),
        sortKey: `ecu_total`,
      })
    }

    return { columns, initialSort: billingPeriodColumn, initialSortDirection: 'desc' }
  }

  const { columns, initialSort, initialSortDirection } = getGridColumns()

  return (
    <div>
      <BillingHistoryPanel
        title={title}
        showEcuTooltip={isPrepaidConsumptionCustomer(billingDetails.data!)}
        data-test-id='billing-statements-panel'
      >
        <BillingDocuments
          initialLoading={loading}
          documents={statements}
          columns={columns}
          initialSort={initialSort}
          initialSortDirection={initialSortDirection}
        />
      </BillingHistoryPanel>

      {isPrepaidConsumptionCustomer(billingDetails.data!) && (
        <EuiText>
          <EuiSpacer size='m' />
          <EcuHelpText />
        </EuiText>
      )}
    </div>
  )
}

export default BillingStatements
