/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import { sumBy } from 'lodash'

import type { AccessorFn } from '@elastic/charts'

import type { ChartItemType } from '@modules/billing-api/customTypes'

import { ProductType } from '../../types'

import type { Solution, ChartItem } from '../../types'
import type { ReducerState } from '../Filters/filtersReducer'
import type { IntlShape } from 'react-intl'

export const prepareAggregatedProductDataAccessors = (filters: ReducerState) => {
  const aggregatedValues: ChartItemType[] = []

  if (filters.productTypes.includes(ProductType.Deployments)) {
    aggregatedValues.push('deployment')
  }

  if (filters.productTypes.includes(ProductType.Projects)) {
    aggregatedValues.push(...filters.solutions)
  }

  const accessors: AccessorFn[] = []
  aggregatedValues.map((aggregatedValue) => {
    const accessor: AccessorFn = ({ values }) => {
      const items = values.filter(({ type }) => type === aggregatedValue)
      accessor.fieldName = aggregatedValue
      return sumBy(items, 'value') || 0
    }

    accessors.push(accessor)
  })

  return accessors
}

export const getSeriesName = (
  instanceType: ChartItemType,
  formatMessage: IntlShape['formatMessage'],
): string =>
  ({
    deployment: formatMessage({
      id: 'product-name.deployment',
      defaultMessage: 'Stack Hosted',
    }),
    elasticsearch: formatMessage({
      id: 'product-name.elasticsearch',
      defaultMessage: 'Elasticsearch',
    }),
    observability: formatMessage({
      id: 'product-name.observability',
      defaultMessage: 'Observability',
    }),
    security: formatMessage({ id: 'product-name.security', defaultMessage: 'Security' }),
  }[instanceType])

export const hasAnyDataToDisplay = ({
  data,
  filters,
}: {
  data: ChartItem[]
  filters: ReducerState
}): boolean => {
  let result = false

  data.forEach(({ values }) => {
    const filteredData = values
      // Filter data by product type
      .filter((product) => {
        const generalType =
          product.type === 'deployment' ? ProductType.Deployments : ProductType.Projects

        return filters.productTypes.includes(generalType)
      })
      // Filter data by a solution
      .filter((product) => {
        if (product.type === 'deployment') {
          return true
        }

        return filters.solutions.includes(product.type as Solution)
      })

    result = !!filteredData.length
  })

  return result
}
