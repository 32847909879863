/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/*!
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@                                         @@@@
 *!   @@@   THIS FILE WAS AUTOGENERATED BY TOOL   @@@@
 *!   @@@   CHANGES MUST TARGET SCRIPTS/SWAGGER   @@@@
 *!   @@@                                         @@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!
 */

import {
  _buildQueryString,
  _emptyParams,
  _encode,
  _getBasePath,
  _getRawBasePath,
} from './urls-utils'

/**
 * GET /api/v1/user
 * Operation: `get-current-user`
 * Fetch current user information.
 * @see 200 User successfully fetched. See {@link User}
 * @see 404 User not found. (code: `user.not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getCurrentUserUrl(): string {
  const path = `/user`
  return `${_getRawBasePath()}${path}`
}

/**
 * PATCH /api/v1/user
 * Operation: `update-current-user`
 * Updates the current user.
 * @see Accepts a request payload of type {@link string}
 * @see 200 User successfully updated. See {@link User}
 * @see 400 * Some of the provided roles are invalid. (code: `user.roles.invalid`)
 * Some of the provided roles are forbidden. (code: `user.roles.forbidden`)
 * Trying to set a restricted field. (code: `user.restricted_field`)
 * External users cannot be modified. (code: `user.cannot_modify_external`)
 * Built-in users cannot be modified. (code: `user.cannot_modify`). See {@link BasicFailedReply}
 * @see 404 User not found. (code: `user.not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function updateCurrentUserUrl(): string {
  const path = `/user`
  return `${_getRawBasePath()}${path}`
}

/**
 * GET /api/v1/users
 * Operation: `get-users`
 * Fetches all available users.
 * @param {boolean} params.includeDisabled True if disabled users should be included in the response.
 * @see 200 Users successfully fetched. See {@link UserList}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getUsersUrl(params: GetUsersUrlParams = _emptyParams): string {
  const queryString = _buildQueryString({
    include_disabled: params.includeDisabled === undefined ? false : params.includeDisabled,
  })
  const path = `/users`
  return `${_getRawBasePath()}${path}${queryString}`
}

export interface GetUsersUrlParams {
  includeDisabled?: boolean | null
}

/**
 * POST /api/v1/users
 * Operation: `create-user`
 * Creates a new user.
 * @see Accepts a request payload of type {@link User}
 * @see 200 User successfully created. See {@link User}
 * @see 400 * The provided user name is invalid. Check that it is not empty and it does not contain special characters. (code: `user.user_name.invalid`)
 * Some of the provided roles are invalid. (code: `user.roles.invalid`)
 * Some of the provided roles are forbidden. (code: `user.roles.forbidden`)
 * Trying to set a restricted field. (code: `user.restricted_field`). See {@link BasicFailedReply}
 * @see 409 The username is already in use. (code: `user.user_name.conflict`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function createUserUrl(): string {
  const path = `/users`
  return `${_getRawBasePath()}${path}`
}

/**
 * GET /api/v1/regions/{region_id}/users/{user_name}
 * Operation: `get-user`
 * Fetches a single user.
 * @param {string} params.userName Identifier for the user.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 User successfully fetched. See {@link User}
 * @see 404 User not found. (code: `user.not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getUserUrl(params: GetUserUrlParams): string {
  const path = _encode`/${params.regionId}/users/${params.userName}`
  return `${_getBasePath()}${path}`
}

export interface GetUserUrlParams {
  userName: string
  regionId: string
}

/**
 * DELETE /api/v1/users/{user_name}
 * Operation: `delete-user`
 * Deletes an existing user.
 * @param {string} params.userName Identifier for the user.
 * @see 200 User successfully deleted
 * @see 400 * The user cannot be deleted. (code: `user.restricted_deletion`)
 * External users cannot be modified. (code: `user.cannot_modify_external`)
 * Built-in users cannot be modified. (code: `user.cannot_modify`). See {@link BasicFailedReply}
 * @see 404 User not found. (code: `user.not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function deleteUserUrl(params: DeleteUserUrlParams): string {
  const path = _encode`/users/${params.userName}`
  return `${_getRawBasePath()}${path}`
}

export interface DeleteUserUrlParams {
  userName: string
}

/**
 * PATCH /api/v1/users/{user_name}
 * Operation: `update-user`
 * Updates an existing user.
 * @param {string} params.userName Identifier for the user.
 * @see Accepts a request payload of type {@link string}
 * @see 200 User successfully updated. See {@link User}
 * @see 400 * Some of the provided roles are invalid. (code: `user.roles.invalid`)
 * Some of the provided roles are forbidden. (code: `user.roles.forbidden`)
 * Trying to set a restricted field. (code: `user.restricted_field`)
 * External users cannot be modified. (code: `user.cannot_modify_external`)
 * Built-in users cannot be modified. (code: `user.cannot_modify`). See {@link BasicFailedReply}
 * @see 404 User not found. (code: `user.not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function updateUserUrl(params: UpdateUserUrlParams): string {
  const path = _encode`/users/${params.userName}`
  return `${_getRawBasePath()}${path}`
}

export interface UpdateUserUrlParams {
  userName: string
}
