/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import type { EuiSuperSelectProps } from '@elastic/eui'
import {
  EuiBetaBadge,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormLabel,
  EuiIcon,
  EuiSuperSelect,
  EuiText,
} from '@elastic/eui'

import { getProviderInfoById } from '@modules/project-lib/providers'
import type { ProviderInfo } from '@modules/project-lib/providers'
import type { CSP } from '@modules/cluster-user-api/v1/types'
import { useFlagsWhenLoaded } from '@modules/launchdarkly'

import type { FunctionComponent, ReactNode } from 'react'

import './style.scss'

type Props = {
  value: string | undefined
  availableProviders: string[]
  onChange: (value: string) => void
  showPrepend?: boolean
  comingSoon?: boolean
  technicalPreview?: boolean
} & Omit<EuiSuperSelectProps<string>, 'fullWidth' | 'valueOfSelected' | 'options'>

const ProviderSelector: FunctionComponent<Props> = ({
  value,
  availableProviders,
  onChange,
  showPrepend = true,
  comingSoon,
  technicalPreview,
  ...props
}) => {
  const [_, flags] = useFlagsWhenLoaded()

  const { hideAdditionalProvidersText } = flags

  const showTechPreviewBadge: Partial<Record<CSP, boolean>> = {
    azure: flags.isAzureInTechPreviewOnServerless,
    gcp: flags.isGcpInTechPreviewOnServerless,
  }

  const comingSoonPlatforms: CSP[] = ['gcp', 'azure'].reduce<CSP[]>((acc, key) => {
    if (availableProviders.includes(key)) {
      return acc
    }

    return [...acc, key as CSP]
  }, [])

  const providerOptions: Array<{
    inputDisplay: ReactNode
    disabled?: boolean
    value: 'aws' | 'gcp' | 'azure' | 'elastic-cloud-hosted'
    dropdownDisplay?: any
  }> = availableProviders.map((key) => {
    const provider = getProviderInfoById(key as CSP)
    return {
      inputDisplay: (
        <ProviderOption
          provider={provider}
          technicalPreview={technicalPreview && showTechPreviewBadge[provider.id]}
        />
      ),
      value: provider.id,
    }
  })

  if (comingSoon && !hideAdditionalProvidersText && comingSoonPlatforms.length) {
    const comingSoonOptions = comingSoonPlatforms.map((key) => {
      const provider = getProviderInfoById(key as CSP)
      return {
        inputDisplay: <ProviderOption provider={provider} comingSoon={true} />,
        value: provider.id,
        disabled: true,
        'data-test-id': `cloud-platform-${provider.id}`,
      }
    })

    providerOptions.push(...comingSoonOptions, {
      inputDisplay: (
        <FormattedMessage
          id='provider-selector.coming-soon-elastic-cloud-hosted'
          defaultMessage={
            'Additional providers and locations are available on Elastic Cloud Hosted'
          }
        />
      ),
      disabled: true,
      value: 'elastic-cloud-hosted',
      dropdownDisplay: (
        <EuiText
          textAlign='center'
          color='subdued'
          size='xs'
          data-test-id='cloud-platform-elastic-cloud-hosted'
        >
          <FormattedMessage
            id='provider-selector.coming-soon-elastic-cloud-hosted-span'
            defaultMessage={
              'Additional providers and locations are available on <strong>Elastic Cloud Hosted</strong>'
            }
            values={{
              strong: (text: string) => <strong>{text}</strong>,
            }}
          />
        </EuiText>
      ),
    })
  }

  return (
    <EuiSuperSelect
      hasDividers={true}
      fullWidth={true}
      prepend={
        showPrepend ? (
          <EuiFormLabel style={{ width: '150px' }}>
            <FormattedMessage id='provider-selector.label' defaultMessage='Cloud provider' />
          </EuiFormLabel>
        ) : undefined
      }
      options={providerOptions}
      valueOfSelected={value}
      onChange={(provider) => onChange(provider)}
      {...props}
    />
  )
}

export default ProviderSelector

const ProviderOption: FunctionComponent<{
  provider: ProviderInfo
  comingSoon?: boolean
  technicalPreview?: boolean
}> = ({ provider, comingSoon = false, technicalPreview = false }) => {
  const { formatMessage } = useIntl()

  return (
    <EuiFlexGroup gutterSize='s' alignItems='center' responsive={false}>
      <EuiFlexItem grow={false}>
        <EuiIcon size='m' type={provider.iconType} />
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize='s'>
          <EuiFlexItem grow={false}>{provider.title}</EuiFlexItem>
          {technicalPreview && (
            <EuiBetaBadge
              alignment='middle'
              size='s'
              label={formatMessage({
                id: 'provider-selector.technical-preview',
                defaultMessage: 'technical preview',
              })}
              color='hollow'
            />
          )}
        </EuiFlexGroup>
      </EuiFlexItem>
      {comingSoon ? (
        <EuiBetaBadge
          alignment='middle'
          size='s'
          label={formatMessage({
            id: 'provider-selector.coming-soon',
            defaultMessage: 'coming soon',
          })}
          color='hollow'
        />
      ) : null}
    </EuiFlexGroup>
  )
}
