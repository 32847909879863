/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { jsx, css } from '@emotion/react'
import { useIntl } from 'react-intl'
import { type FunctionComponent } from 'react'

import { EuiButtonEmpty, EuiFormRow, EuiSwitch, EuiText, EuiTourStep } from '@elastic/eui'

import { useLocalStorage } from '@modules/utils/hooks/useLocalStorage'
import LocalStorageKey from '@modules/utils/localStorageKeys'

interface Props {
  onChange: ({ cumulativeView }: { cumulativeView: boolean }) => void
  isLoading?: boolean
  value: boolean
}

const CumulativeViewSwitch: FunctionComponent<Props> = ({ onChange, isLoading, value }) => {
  const { formatMessage } = useIntl()
  const [isTourSeen, setIsTourSeen] = useLocalStorage(
    LocalStorageKey.billingCumulativeViewTour,
    false,
  )

  return (
    <EuiFormRow fullWidth={true} display='row' hasEmptyLabelSpace={true}>
      <EuiTourStep
        content={
          <EuiText size='s' color='subdued'>
            {formatMessage({
              defaultMessage: 'Toggle to see your cumulative ECU usage over time.',
              id: 'billing-usage.filters.cumulativeViewTour',
            })}
          </EuiText>
        }
        isStepOpen={!isTourSeen}
        maxWidth={350}
        onFinish={() => setIsTourSeen(true)}
        step={1}
        stepsTotal={1}
        title={formatMessage({
          defaultMessage: 'Cumulative view now available!',
          id: 'billing-usage.filters.cumulativeViewTour.title',
        })}
        subtitle={formatMessage({
          defaultMessage: 'New functionality',
          id: 'billing-usage.filters.cumulativeViewTour.subtitle',
        })}
        anchorPosition='downCenter'
        footerAction={
          <EuiButtonEmpty size='s' color='text' onClick={() => setIsTourSeen(true)}>
            {formatMessage({
              defaultMessage: 'Gotcha',
              id: 'billing-usage.filters.cumulativeViewTour.confirm',
            })}
          </EuiButtonEmpty>
        }
        repositionOnScroll={true}
      >
        <EuiSwitch
          label={formatMessage({
            defaultMessage: 'Cumulative view',
            id: 'billing-usage.filters.cumulativeView',
          })}
          checked={value}
          onChange={(e) => onChange({ cumulativeView: e.target.checked })}
          disabled={isLoading}
          css={css({ alignItems: 'center' })}
        />
      </EuiTourStep>
    </EuiFormRow>
  )
}

export default CumulativeViewSwitch
