/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { find } from 'lodash'

import { EuiCallOut, EuiText } from '@elastic/eui'

import history from '@modules/utils/history'
import { rootUrl } from '@modules/utils/rootUrls'
import { setApmUserContext } from '@modules/utils/apm'

import { shouldCreateOrganization } from '@/lib/organizations'

import AppLoadingRoot from '../../../../../components/AppLoadingRoot'
import LandingPageContainer from '../../../../../components/LandingPageContainer'
import { isMarketplaceSubscription, marketplaceDisplayName } from '../../../../../lib/marketPlace'
import { UserSubscriptionValues } from '../../../../../lib/urlUtils'

import MarketplaceErrorMessage from './MarketplaceErrorMessage'

import type { AllProps as Props } from './types'

export default class MarketplaceConversion extends Component<Props> {
  componentDidMount(): Promise<any> | undefined {
    const {
      activateMarketplaceUser,
      token,
      partner,
      profile,
      hasSubscription,
      agreementId,
      productId,
      createOrganization,
    } = this.props

    if (partner && isMarketplaceSubscription(partner) && token && profile) {
      const encodedToken = token.replace(/ /g, '+')

      setApmUserContext(String(profile.user_id), profile.email)

      const payload = {
        ...(agreementId && productId
          ? { data: { agreement_id: agreementId, product_id: productId } }
          : {}),
        token: encodedToken,
        partner,
      }

      if (shouldCreateOrganization({ profile })) {
        return createOrganization().then(() =>
          activateMarketplaceUser(payload).then(() => {
            this.redirectUrl(hasSubscription, partner)
          }),
        )
      }

      return activateMarketplaceUser(payload).then(() => {
        this.redirectUrl(hasSubscription, partner)
      })
    }

    return
  }

  render(): JSX.Element | null {
    const {
      partner,
      token,
      subscribeMarketplaceUserRequest,
      activateMarketplaceUserRequest,
      createOrganizationRequest,
    } = this.props

    if (!partner || !token) {
      return null
    }

    if (
      subscribeMarketplaceUserRequest.error ||
      activateMarketplaceUserRequest.error ||
      createOrganizationRequest.error
    ) {
      return (
        <LandingPageContainer
          panelProps={{ className: 'fs-unmask' }}
          title={this.renderErrorTitle()}
        >
          <EuiText
            data-test-subj='marketplace-subscribe-error'
            size='m'
            textAlign='center'
            color='subdued'
          >
            <EuiCallOut color='danger'>{this.renderErrorMessage()}</EuiCallOut>
          </EuiText>
        </LandingPageContainer>
      )
    }

    return <AppLoadingRoot />
  }

  renderErrorTitle(): JSX.Element {
    const { partner } = this.props

    const displayName = find(UserSubscriptionValues, (key) => key === partner)
    const partnerDisplayName = displayName ? marketplaceDisplayName(displayName) : partner

    return (
      <FormattedMessage
        id='marketplace-conversion.error-title'
        defaultMessage='Error subscribing to {partner}'
        values={{
          partner: partnerDisplayName,
        }}
      />
    )
  }

  renderErrorMessage(): JSX.Element {
    const {
      subscribeMarketplaceUserRequest,
      activateMarketplaceUserRequest,
      createOrganizationRequest,
    } = this.props

    if (subscribeMarketplaceUserRequest.error) {
      return <MarketplaceErrorMessage error={subscribeMarketplaceUserRequest.error} />
    }

    if (createOrganizationRequest.error) {
      return <MarketplaceErrorMessage error={createOrganizationRequest.error} />
    }

    return <MarketplaceErrorMessage error={activateMarketplaceUserRequest.error!} />
  }

  redirectUrl(hasSubscription, marketplaceType) {
    if (hasSubscription) {
      return history.push(rootUrl())
    }

    return history.push(`/onboarding/redirect`, marketplaceType ? { marketplaceType } : {})
  }
}
