/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { getActiveItems, getExpiredItems, getFutureItems } from '@modules/billing-lib/utils'
import type { LineItem } from '@modules/billing-api/customTypes'

import type { FilterState } from '.'

export type LineItemByFilter = {
  [key in FilterState]: LineItem[]
}

export const getLineItemsByFilter = (lineItems: LineItem[] = []): LineItemByFilter => {
  const dateReference = new Date()

  return {
    active: getActiveItems(lineItems, dateReference),
    expired: getExpiredItems(lineItems, dateReference),
    future: getFutureItems(lineItems, dateReference),
    all: lineItems,
  }
}
