/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { useMutation, useQuery } from 'react-query'

import { queryClient } from '@modules/query'
import {
  deprovisionConsumptionOrg,
  extendTrialAdminV1,
  extendTrialV1,
  getBasePricesV1,
  getBillingDetailsV1,
  getChartsByInstanceV2,
  getChartsV2,
  getCostsByInstancesV2,
  getCostsByItemsV2,
  getEntitlementsV1,
  getItemizedCostsByInstanceV2,
  updateBillingAccountV1,
  recalculateLedgerV1,
  getLineItemsV1,
} from '@modules/billing-api/v1/callers'
import type { operations } from '@modules/billing-api/v1/types'
import type { QueryFunctionReturnType, QueryHook, QueryHookOptions } from '@modules/query/types'
import {
  invalidateGetOrganizationQuery,
  invalidateGetSaasUserQuery,
} from '@modules/profile-lib/hooks'

export const defaultBillingOptions = {
  staleTime: 1000 * 60 * 60, // 1h
  retryOnMount: false,
  retry: false,
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
  refetchIntervalInBackground: false,
}

export const useGetCostsItemsV2: QueryHook<typeof getCostsByItemsV2> = (
  args: {
    pathParameters: operations['getCostsByItemsV2']['parameters']['path']
    queryParameters: operations['getCostsByItemsV2']['parameters']['query']
  },
  options?: QueryHookOptions,
) =>
  useQuery(
    [
      `getCostsByItemsV2`,
      args.pathParameters.organization_id,
      args.queryParameters.from,
      args.queryParameters.to,
      args.queryParameters.serverless_group_by,
    ],
    () => getCostsByItemsV2(args),
    {
      ...defaultBillingOptions,
      ...options,
    },
  )

export const useGetCostsInstancesV2: QueryHook<typeof getCostsByInstancesV2> = (
  args: {
    pathParameters: operations['getCostsByInstancesV2']['parameters']['path']
    queryParameters: operations['getCostsByInstancesV2']['parameters']['query']
  },
  options?: QueryHookOptions,
) =>
  useQuery(
    [
      `getCostsByInstancesV2`,
      args.pathParameters.organization_id,
      args.queryParameters.from,
      args.queryParameters.to,
    ],
    () => getCostsByInstancesV2(args),
    {
      ...defaultBillingOptions,
      ...options,
    },
  )

export const useGetInstanceCostItemsV2: QueryHook<typeof getItemizedCostsByInstanceV2> = (
  args: {
    pathParameters: operations['getItemizedCostsByInstanceV2']['parameters']['path']
    queryParameters: operations['getItemizedCostsByInstanceV2']['parameters']['query']
  },
  options?: QueryHookOptions,
) =>
  useQuery(
    [
      `getItemizedCostsByInstanceV2`,
      args.pathParameters.organization_id,
      args.pathParameters.instance_id,
      args.queryParameters.from,
      args.queryParameters.to,
    ],
    () => getItemizedCostsByInstanceV2(args),
    {
      ...defaultBillingOptions,
      ...options,
    },
  )

export const useGetInstanceChartDataV2: QueryHook<typeof getChartsByInstanceV2> = (
  args: {
    pathParameters: operations['getChartsByInstanceV2']['parameters']['path']
    queryParameters: operations['getChartsByInstanceV2']['parameters']['query']
  },
  options?: QueryHookOptions,
) =>
  useQuery(
    [
      `getChartsByInstanceV2`,
      args.pathParameters.organization_id,
      args.pathParameters.instance_id,
      args.queryParameters.from,
      args.queryParameters.to,
      args.queryParameters.instance_type,
      args.queryParameters.bucketing_strategy,
    ],
    () => getChartsByInstanceV2(args),
    {
      ...defaultBillingOptions,
      ...options,
    },
  )

export const useGetUsageChartsDataV2: QueryHook<typeof getChartsV2> = (
  args: {
    pathParameters: operations['getChartsV2']['parameters']['path']
    queryParameters: operations['getChartsV2']['parameters']['query']
  },
  options?: QueryHookOptions,
) =>
  useQuery(
    [
      'getChartsV2',
      args.pathParameters.organization_id,
      args.queryParameters.from,
      args.queryParameters.to,
      args.queryParameters.bucketing_strategy,
    ],
    () => getChartsV2(args),
    {
      ...defaultBillingOptions,
      ...options,
    },
  )

export const useUpdateBillingAccount = () =>
  useMutation({
    mutationFn: (args: {
      pathParameters: operations['updateBillingAccountV1']['parameters']['path']
      body: operations['updateBillingAccountV1']['requestBody']['content']['application/json']
    }) => updateBillingAccountV1(args),
  })

const getGetBillingDetailsQueryKey = 'getBillingDetailsV1'

export const useGetBillingDetails: QueryHook<typeof getBillingDetailsV1> = (
  args: {
    pathParameters: operations['getBillingDetailsV1']['parameters']['path']
  },
  options?: QueryHookOptions,
) =>
  useQuery(
    [getGetBillingDetailsQueryKey, args.pathParameters.organization_id],
    () => getBillingDetailsV1(args),
    {
      ...defaultBillingOptions,
      ...options,
    },
  )

export const invalidateGetBillingDetails = () =>
  queryClient.invalidateQueries({ queryKey: [getGetBillingDetailsQueryKey] })

export const useGetBasePricesV1: QueryHook<typeof getBasePricesV1> = (
  queryParameters: operations['getBasePricesV1']['parameters']['query'],
  options?: QueryHookOptions,
) =>
  useQuery(
    [`getBasePricesV1`, queryParameters?.product_family, queryParameters?.timestamp],
    () => getBasePricesV1({ queryParameters }),
    {
      ...defaultBillingOptions,
      ...options,
    },
  )

const getEntitlementsV1QueryKey = 'getEntitlementsV1'

export const useGetEntitlementsV1 = (
  organizationId: string | undefined,
  options?: QueryHookOptions,
) =>
  useQuery<QueryFunctionReturnType<typeof getEntitlementsV1>>(
    [getEntitlementsV1QueryKey, organizationId],
    () =>
      organizationId === undefined
        ? Promise.reject(false)
        : getEntitlementsV1({ pathParameters: { organization_id: organizationId } }),
    {
      ...defaultBillingOptions,
      ...options,
    },
  )

export const invalidateGetEntitlementsV1 = () =>
  queryClient.invalidateQueries({ queryKey: [getEntitlementsV1QueryKey] })

export const useExtendTrialV1 = () =>
  useMutation<QueryFunctionReturnType<typeof extendTrialV1>, unknown, { organizationId: string }>({
    mutationFn: ({ organizationId }) =>
      extendTrialV1({
        pathParameters: { organization_id: organizationId },
      }),
  })

export const invalidateQueriesAfterTrialExtension = () => {
  invalidateGetSaasUserQuery()
  invalidateGetOrganizationQuery()
  invalidateGetEntitlementsV1()
  invalidateGetBillingDetails()
}

export const useExtendTrialAdminV1 = () =>
  useMutation<
    QueryFunctionReturnType<typeof extendTrialAdminV1>,
    unknown,
    { organizationId: string; expiration: string }
  >({
    mutationFn: ({ organizationId, expiration }) =>
      extendTrialAdminV1({
        pathParameters: { organization_id: organizationId },
        queryParameters: { expiration },
      }),
  })

export const useDeprovisionConsumptionOrg = () =>
  useMutation({
    mutationFn: (args: {
      pathParameters: operations['deprovisionConsumptionOrg']['parameters']['path']
      body: operations['deprovisionConsumptionOrg']['requestBody']['content']['application/json']
    }) => deprovisionConsumptionOrg(args),
  })

export const useRecalculateLedgerV1 = () =>
  useMutation({
    mutationFn: (args: {
      pathParameters: operations['recalculateLedgerV1']['parameters']['path']
      body: operations['recalculateLedgerV1']['requestBody']['content']['application/json']
    }) => recalculateLedgerV1(args),
  })

export const useGetLineItemsV1: QueryHook<typeof getLineItemsV1> = (
  args: {
    pathParameters: operations['getLineItemsV1']['parameters']['path']
  },
  options?: QueryHookOptions,
) =>
  useQuery([`getLineItemsV1`, args.pathParameters.organization_id], () => getLineItemsV1(args), {
    ...defaultBillingOptions,
    ...options,
  })
