/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/*!
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@                                         @@@@
 *!   @@@   THIS FILE WAS AUTOGENERATED BY TOOL   @@@@
 *!   @@@   CHANGES MUST TARGET SCRIPTS/SWAGGER   @@@@
 *!   @@@                                         @@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!
 */

import {
  _buildQueryString,
  _emptyParams,
  _encode,
  _getBasePath,
  _getRawBasePath,
} from './urls-utils'

/**
 * GET /api/v1/trusted-environments
 * Operation: `get-trusted-envs`
 * Returns the list of trusted environments for the organization. If no organization is passed in, the organization attached to the user is assumed.
 * @param {string} params.organizationId (Optional) Organization Id for which to retrieve the trusted environments.
 * @see 200 Trusted environments for this organization. See {@link ElasticsearchClusterTrustSettings}
 * @see 404 Organization not found. (code: `organization.not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getTrustedEnvsUrl(params: GetTrustedEnvsUrlParams = _emptyParams): string {
  const queryString = _buildQueryString({
    organization_id: params.organizationId,
  })
  const path = `/trusted-environments`
  return `${_getRawBasePath()}${path}${queryString}`
}

export interface GetTrustedEnvsUrlParams {
  organizationId?: string | null
}
