/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/*!
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@                                         @@@@
 *!   @@@   THIS FILE WAS AUTOGENERATED BY TOOL   @@@@
 *!   @@@   CHANGES MUST TARGET SCRIPTS/SWAGGER   @@@@
 *!   @@@                                         @@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!
 */

import {
  _buildQueryString,
  _emptyParams,
  _encode,
  _getBasePath,
  _getRawBasePath,
} from './urls-utils'

/**
 * GET /api/v1/billing/costs/{organization_id}
 * Operation: `get-costs-overview`
 * EXPERIMENTAL (it may change in future versions): Retrieves an overview of the costs by organization ID.
 * @param {string} params.organizationId Identifier for the organization.
 * @param {string} params.from A datetime for the beginning of the desired range for which to fetch costs. Defaults to start of current month.
 * @param {string} params.to A datetime for the end of the desired range for which to fetch costs. Defaults to the current date.
 * @see 200 Top-level cost overview for the organization. See {@link CostsOverview}
 * @see 400 The specified date range is invalid. (code: `costs.invalid_date_range`). See {@link BasicFailedReply}
 * @see 403 The current user does not have access to the requested organization. (code: `organization.invalid_access`). See {@link BasicFailedReply}
 * @see 404 Organization not found. (code: `organization.not_found`). See {@link BasicFailedReply}
 * @see 500 An error occurred when fetching an overview of the costs for the organization. (code: `billing_service.failed_request`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getCostsOverviewUrl(params: GetCostsOverviewUrlParams): string {
  const queryString = _buildQueryString({
    from: params.from,
    to: params.to,
  })
  const path = _encode`/billing/costs/${params.organizationId}`
  return `${_getRawBasePath()}${path}${queryString}`
}

export interface GetCostsOverviewUrlParams {
  organizationId: string
  from?: string | null
  to?: string | null
}

/**
 * GET /api/v1/billing/costs/{organization_id}/charts
 * Operation: `get-costs-charts`
 * EXPERIMENTAL (it may change in future versions): Retrieves the usage charts for the organization.
 * @param {string} params.organizationId Identifier for the organization.
 * @param {string} params.from A datetime for the beginning of the desired range for which to fetch costs. Defaults to start of current month.
 * @param {string} params.to A datetime for the end of the desired range for which to fetch costs. Defaults to the current date.
 * @param {string} params.bucketingStrategy The desired bucketing strategy for the charts. Defaults to `daily`. Default: daily
 * @see 200 The usage charts of an organization. See {@link ChartItems}
 * @see 400 The specified date range is invalid. (code: `costs.invalid_date_range`). See {@link BasicFailedReply}
 * @see 403 The current user does not have access to the requested organization. (code: `organization.invalid_access`). See {@link BasicFailedReply}
 * @see 404 Organization not found. (code: `organization.not_found`). See {@link BasicFailedReply}
 * @see 429 Too many requests. (code: `billing_service.rate_limited`). See {@link BasicFailedReply}
 * @see 500 Error fetching the itemized costs for the organization. (code: `billing_service.failed_request`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getCostsChartsUrl(params: GetCostsChartsUrlParams): string {
  const queryString = _buildQueryString({
    from: params.from,
    to: params.to,
    bucketing_strategy: params.bucketingStrategy === undefined ? `daily` : params.bucketingStrategy,
  })
  const path = _encode`/billing/costs/${params.organizationId}/charts`
  return `${_getRawBasePath()}${path}${queryString}`
}

export interface GetCostsChartsUrlParams {
  organizationId: string
  from?: string | null
  to?: string | null
  bucketingStrategy?: string | null
}

/**
 * GET /api/v1/billing/costs/{organization_id}/deployments
 * Operation: `get-costs-deployments`
 * EXPERIMENTAL (it may change in future versions): Retrieves the costs associated with all deployments for the organization.
 * @param {string} params.organizationId Identifier for the organization.
 * @param {string} params.from A datetime for the beginning of the desired range for which to fetch activity. Defaults to start of current month.
 * @param {string} params.to A datetime for the end of the desired range for which to fetch activity. Defaults to the current date.
 * @see 200 The costs associated to a set of products. See {@link DeploymentsCosts}
 * @see 400 Invalid date range specified. (code: `costs.invalid_date_range`). See {@link BasicFailedReply}
 * @see 403 The current user does not have access to the requested organization. (code: `organization.invalid_access`). See {@link BasicFailedReply}
 * @see 404 Organization not found. (code: `organization.not_found`). See {@link BasicFailedReply}
 * @see 500 An error occurred when fetching the costs associated with all deployments for the organization. (code: `billing_service.failed_request`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getCostsDeploymentsUrl(params: GetCostsDeploymentsUrlParams): string {
  const queryString = _buildQueryString({
    from: params.from,
    to: params.to,
  })
  const path = _encode`/billing/costs/${params.organizationId}/deployments`
  return `${_getRawBasePath()}${path}${queryString}`
}

export interface GetCostsDeploymentsUrlParams {
  organizationId: string
  from?: string | null
  to?: string | null
}

/**
 * GET /api/v1/billing/costs/{organization_id}/deployments/{deployment_id}/charts
 * Operation: `get-costs-charts-by-deployment`
 * EXPERIMENTAL (it may change in future versions): Retrieves the usage charts for the given  deployment.
 * @param {string} params.organizationId Identifier for the organization.
 * @param {string} params.deploymentId Id of a Deployment.
 * @param {string} params.from A datetime for the beginning of the desired range for which to fetch costs. Defaults to start of current month.
 * @param {string} params.to A datetime for the end of the desired range for which to fetch costs. Defaults to the current date.
 * @param {string} params.bucketingStrategy The desired bucketing strategy for the charts. Defaults to `daily`. Default: daily
 * @see 200 The usage charts of a deployment. See {@link ChartItems}
 * @see 400 The specified date range is invalid. (code: `costs.invalid_date_range`). See {@link BasicFailedReply}
 * @see 403 The current user does not have access to the requested organization. (code: `organization.invalid_access`). See {@link BasicFailedReply}
 * @see 404 Organization not found. (code: `organization.not_found`). See {@link BasicFailedReply}
 * @see 429 Too many requests. (code: `billing_service.rate_limited`). See {@link BasicFailedReply}
 * @see 500 Error fetching the itemized costs for the organization. (code: `billing_service.failed_request`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getCostsChartsByDeploymentUrl(params: GetCostsChartsByDeploymentUrlParams): string {
  const queryString = _buildQueryString({
    from: params.from,
    to: params.to,
    bucketing_strategy: params.bucketingStrategy === undefined ? `daily` : params.bucketingStrategy,
  })
  const path = _encode`/billing/costs/${params.organizationId}/deployments/${params.deploymentId}/charts`
  return `${_getRawBasePath()}${path}${queryString}`
}

export interface GetCostsChartsByDeploymentUrlParams {
  organizationId: string
  deploymentId: string
  from?: string | null
  to?: string | null
  bucketingStrategy?: string | null
}

/**
 * GET /api/v1/billing/costs/{organization_id}/deployments/{deployment_id}/items
 * Operation: `get-costs-items-by-deployment`
 * EXPERIMENTAL (it may change in future versions): Retrieves the itemized costs for the given deployment.
 * @param {string} params.organizationId Identifier for the organization.
 * @param {string} params.deploymentId Id of a Deployment.
 * @param {string} params.from A datetime for the beginning of the desired range for which to fetch costs. Defaults to start of current month.
 * @param {string} params.to A datetime for the end of the desired range for which to fetch costs. Defaults to the current date.
 * @see 200 The costs associated to a set items billed for a single deployment. See {@link ItemsCosts}
 * @see 400 The specified date range is invalid. (code: `costs.invalid_date_range`). See {@link BasicFailedReply}
 * @see 403 The current user does not have access to the requested organization. (code: `organization.invalid_access`). See {@link BasicFailedReply}
 * @see 404 * The organization was not found. (code: `organization.not_found`)
 * The Elasticsearch cluster was not found. (code: `billing_service.es_cluster_id_not_found`). See {@link BasicFailedReply}
 * @see 500 An error occurred when fetching the itemized costs for the given deployment of the organization. (code: `billing_service.failed_request`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getCostsItemsByDeploymentUrl(params: GetCostsItemsByDeploymentUrlParams): string {
  const queryString = _buildQueryString({
    from: params.from,
    to: params.to,
  })
  const path = _encode`/billing/costs/${params.organizationId}/deployments/${params.deploymentId}/items`
  return `${_getRawBasePath()}${path}${queryString}`
}

export interface GetCostsItemsByDeploymentUrlParams {
  organizationId: string
  deploymentId: string
  from?: string | null
  to?: string | null
}

/**
 * GET /api/v1/billing/costs/{organization_id}/items
 * Operation: `get-costs-items`
 * EXPERIMENTAL (it may change in future versions): Retrieves the itemized costs for the organization.
 * @param {string} params.organizationId Identifier for the organization.
 * @param {string} params.from A datetime for the beginning of the desired range for which to fetch costs. Defaults to start of current month.
 * @param {string} params.to A datetime for the end of the desired range for which to fetch costs. Defaults to the current date.
 * @see 200 The costs associated to a set of items. See {@link ItemsCosts}
 * @see 400 The specified date range is invalid. (code: `costs.invalid_date_range`). See {@link BasicFailedReply}
 * @see 403 The current user does not have access to the requested organization. (code: `organization.invalid_access`). See {@link BasicFailedReply}
 * @see 404 Organization not found. (code: `organization.not_found`). See {@link BasicFailedReply}
 * @see 500 Error fetching the itemized costs for the organization. (code: `billing_service.failed_request`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getCostsItemsUrl(params: GetCostsItemsUrlParams): string {
  const queryString = _buildQueryString({
    from: params.from,
    to: params.to,
  })
  const path = _encode`/billing/costs/${params.organizationId}/items`
  return `${_getRawBasePath()}${path}${queryString}`
}

export interface GetCostsItemsUrlParams {
  organizationId: string
  from?: string | null
  to?: string | null
}
