/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'

import { EuiFlexGroup, EuiFlexItem } from '@elastic/eui'

import { useSolutionOptions, useSolutionPickerVersion } from '@modules/solution-selector/hooks'
import SelectSolutionCardV4 from '@modules/solution-selector/SelectSolutionCard'
import type { SolutionType } from '@modules/solution-selector/types'
import SelectSolutionCardV5 from '@modules/solution-selector/SelectSolutionCardV5'
import { useSolutionOptionsV5 } from '@modules/solution-selector/hooksV5'

import type { FC } from 'react'

type DefaultSolutionViewSelectorProps = {
  onChooseSolution: (label: SolutionType) => void
}

const DefaultSolutionViewSelector: FC<DefaultSolutionViewSelectorProps> = ({
  onChooseSolution,
}) => {
  const solutionOptionsV4 = useSolutionOptions()

  const solutionOptionsV5 = useSolutionOptionsV5()

  const solutionPickerVersion = useSolutionPickerVersion()

  const [solutionOptions, SelectSolutionCardComponent] =
    solutionPickerVersion === 'v5'
      ? [solutionOptionsV5, SelectSolutionCardV5]
      : [solutionOptionsV4, SelectSolutionCardV4]

  return (
    <EuiFlexGroup justifyContent='center'>
      {solutionOptions.map((solutionOption) => (
        <EuiFlexItem key={solutionOption.label}>
          <SelectSolutionCardComponent
            {...solutionOption}
            onNext={onChooseSolution}
            dataAttributePrefix='deployment-select-solution-'
          />
        </EuiFlexItem>
      ))}
    </EuiFlexGroup>
  )
}

export default DefaultSolutionViewSelector
