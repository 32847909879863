/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/*!
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@                                         @@@@
 *!   @@@   THIS FILE WAS AUTOGENERATED BY TOOL   @@@@
 *!   @@@   CHANGES MUST TARGET SCRIPTS/SWAGGER   @@@@
 *!   @@@                                         @@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!
 */

import {
  _buildQueryString,
  _emptyParams,
  _encode,
  _getBasePath,
  _getRawBasePath,
} from './urls-utils'

/**
 * GET /api/v1/phone-home/config
 * Operation: `get-telemetry-config`
 * Returns whether ECE telemetry is enabled.
 * @see 200 The current ECE telemetry configuration. See {@link TelemetryConfig}
 * @see 403 User must have Platform level permissions. (code: `root.unauthorized.rbac`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getTelemetryConfigUrl(): string {
  const path = `/phone-home/config`
  return `${_getRawBasePath()}${path}`
}

/**
 * PUT /api/v1/phone-home/config
 * Operation: `set-telemetry-config`
 * Sets whether to enable ECE telemetry.
 * @see Accepts a request payload of type {@link TelemetryConfigRequest}
 * @see 200 Telemetry configuration updated successfully. See {@link TelemetryConfig}
 * @see 403 User must have Platform level permissions. (code: `root.unauthorized.rbac`). See {@link BasicFailedReply}
 * @see 409 The telemetry configuration did not exist so there was an attempt to create one. Another request resulted in the creation of a telemetry configuration before this request completed, resulting in the failure of this request to create a configuration. Please retry. (code: `telemetry.already_exists`). See {@link BasicFailedReply}
 * @see 500 Failed to set the configuration due to an internal server error. (code: `telemetry.request_execution_failed`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function setTelemetryConfigUrl(): string {
  const path = `/phone-home/config`
  return `${_getRawBasePath()}${path}`
}

/**
 * GET /api/v1/phone-home/data
 * Operation: `get-phone-home-data`
 * Returns ECE usage statistics for Elastic's internal use.
 * @see 200 Telemetry for the ECE installation. See {@link PhoneHomeTelemetry}
 * @see 403 User must have Platform level permissions. (code: `root.unauthorized.rbac`). See {@link BasicFailedReply}
 * @see 404 Data is unavailable because telemetry is disabled for this ECE installation. (code: `telemetry.not_enabled`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getPhoneHomeDataUrl(): string {
  const path = `/phone-home/data`
  return `${_getRawBasePath()}${path}`
}
