/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/*!
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@                                         @@@@
 *!   @@@   THIS FILE WAS AUTOGENERATED BY TOOL   @@@@
 *!   @@@   CHANGES MUST TARGET SCRIPTS/SWAGGER   @@@@
 *!   @@@                                         @@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!
 */

import {
  _buildQueryString,
  _emptyParams,
  _encode,
  _getBasePath,
  _getRawBasePath,
} from './urls-utils'

/**
 * GET /api/v1(/regions/{region_id})?/platform
 * Operation: `get-platform`
 * Retrieves information about the active platform.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 The platform information is retrieved. See {@link PlatformInfo}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getPlatformUrl(params: GetPlatformUrlParams = _emptyParams): string {
  const path =
    (params.regionId == null ? '' : '/' + encodeURIComponent(params.regionId)) + `/platform`
  return `${params.regionId == null ? _getRawBasePath() : _getBasePath()}${path}`
}

export interface GetPlatformUrlParams {
  regionId?: string | null
}

/**
 * GET /api/v1/regions/{region_id}/platform/configuration/security/extra_certs
 * Operation: `get-extra-certificates`
 * List all of the extra certificates.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 The collection of all available extra certificates public chains. See {@link PublicCertChainCollection}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getExtraCertificatesUrl(params: GetExtraCertificatesUrlParams): string {
  const path = _encode`/${params.regionId}/platform/configuration/security/extra_certs`
  return `${_getBasePath()}${path}`
}

export interface GetExtraCertificatesUrlParams {
  regionId: string
}

/**
 * GET /api/v1/regions/{region_id}/platform/configuration/security/extra_certs/{cert_id}
 * Operation: `get-extra-certificate`
 * Read an extra certificate.
 * @param {string} params.certId The extra certificate Id. For example, `star_my_domain_com`.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 Extra certificate public chain if exists. See {@link PublicCertChain}
 * @see 404 The extra certificate cannot be found. (code: `cert.not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getExtraCertificateUrl(params: GetExtraCertificateUrlParams): string {
  const path = _encode`/${params.regionId}/platform/configuration/security/extra_certs/${params.certId}`
  return `${_getBasePath()}${path}`
}

export interface GetExtraCertificateUrlParams {
  certId: string
  regionId: string
}

/**
 * PUT /api/v1/regions/{region_id}/platform/configuration/security/extra_certs/{cert_id}
 * Operation: `set-extra-certificate`
 * Add or update an extra certificate.
 * @param {string} params.certId The extra certificate Id. For example, `star_my_domain_com`.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see Accepts a request payload of type {@link string}
 * @see 200 Updates extra certificate under given id
 * @see 400 Certificate uses unknown cryptographic algorithm. (code: `cert.unknown_cert_algorithm`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function setExtraCertificateUrl(params: SetExtraCertificateUrlParams): string {
  const path = _encode`/${params.regionId}/platform/configuration/security/extra_certs/${params.certId}`
  return `${_getBasePath()}${path}`
}

export interface SetExtraCertificateUrlParams {
  certId: string
  regionId: string
}

/**
 * DELETE /api/v1/regions/{region_id}/platform/configuration/security/extra_certs/{cert_id}
 * Operation: `delete-extra-certificate`
 * Delete an extra certificate.
 * @param {string} params.certId The extra certificate Id. For example, `star_my_domain_com`.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 Extra certificate under the given id is deleted if exists
 * @see 404 The extra certificate cannot be found. (code: `cert.not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function deleteExtraCertificateUrl(params: DeleteExtraCertificateUrlParams): string {
  const path = _encode`/${params.regionId}/platform/configuration/security/extra_certs/${params.certId}`
  return `${_getBasePath()}${path}`
}

export interface DeleteExtraCertificateUrlParams {
  certId: string
  regionId: string
}

/**
 * GET /api/v1/regions/{region_id}/platform/health
 * Operation: `list-platform-health`
 * Retrieves the platform health.
 * @param {string} params.expectStatus If specified, returns a 417 status code if the health status is not what was sent or better. Should be one of [red, yellow, or green].
 * @param {boolean} params.verbose When true, returns the list of all health check results.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 The platform health. See {@link PlatformHealth}
 * @see 417 NOTE: When expect_status is sent, but the health status is _not_ better or equal to what was sent, the status code is 417. See {@link PlatformHealth}
 * @return {string} A fully-interpolated and escaped URL
 */
export function listPlatformHealthUrl(params: ListPlatformHealthUrlParams): string {
  const queryString = _buildQueryString({
    expect_status: params.expectStatus,
    verbose: params.verbose,
  })
  const path = _encode`/${params.regionId}/platform/health`
  return `${_getBasePath()}${path}${queryString}`
}

export interface ListPlatformHealthUrlParams {
  expectStatus?: string | null
  verbose?: boolean | null
  regionId: string
}

/**
 * GET /api/v1/regions/{region_id}/platform/health/{health_check_name}
 * Operation: `get-platform-health`
 * Gets an individual platform health check by name.
 * @param {string} params.healthCheckName The name of the health check.
 * @param {string} params.expectStatus If specified, returns a 417 status code if the health status is not what was sent or better. Should be one of [red, yellow, or green].
 * @param {boolean} params.verbose When true, returns the list of all health check results.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 The platform health check. See {@link PlatformHealth}
 * @see 404 The health check specified at {health_check_name} could not be found. (code: `health.health_check_not_found`). See {@link BasicFailedReply}
 * @see 417 NOTE: When expect_status is sent, but the health status is _not_ better or equal to what was sent, the status code is 417. See {@link PlatformHealth}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getPlatformHealthUrl(params: GetPlatformHealthUrlParams): string {
  const queryString = _buildQueryString({
    expect_status: params.expectStatus,
    verbose: params.verbose,
  })
  const path = _encode`/${params.regionId}/platform/health/${params.healthCheckName}`
  return `${_getBasePath()}${path}${queryString}`
}

export interface GetPlatformHealthUrlParams {
  healthCheckName: string
  expectStatus?: string | null
  verbose?: boolean | null
  regionId: string
}
