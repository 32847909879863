/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { useState } from 'react'
import { useIntl } from 'react-intl'

import { EuiFlexGroup, EuiFlexItem, EuiLink } from '@elastic/eui'

import type { AnyProject } from '@modules/project-api/types'
import genericMessages from '@modules/project-lib/genericMessages'

import ProjectAliasEditorFlyout from './ProjectAliasEditorFlyout'

import type { FC } from 'react'

type Props = {
  project: AnyProject
}

const ProjectAliasEditor: FC<Props> = ({ project }) => {
  const { formatMessage } = useIntl()
  const linkLabel = formatMessage(genericMessages.edit)

  const [isFlyoutOpen, setIsFlyoutOpen] = useState(false)

  const openFlyout = () => setIsFlyoutOpen(true)
  const closeFlyout = () => setIsFlyoutOpen(false)

  return (
    <EuiFlexGroup>
      <EuiFlexItem grow={false}>{project.alias}</EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiLink
          onClick={openFlyout}
          aria-label={formatMessage(genericMessages.linkActionAriaLabel, {
            action: linkLabel,
            name: formatMessage(genericMessages.connectionAlias),
          })}
        >
          {linkLabel}
        </EuiLink>

        {isFlyoutOpen && <ProjectAliasEditorFlyout project={project} closeFlyout={closeFlyout} />}
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}

export default ProjectAliasEditor
