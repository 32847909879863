/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { ProfileStateImpl } from '@modules/ui-types'
import type { BillingDetails } from '@modules/billing-api/customTypes'

import { cloudTrialStartedWithin30Days } from '@/lib/cookies'

export function getContextAndAttributes(
  profile: ProfileStateImpl,
  billingModel: BillingDetails['billing_model'],
) {
  const context = {
    window: {
      location: {
        hash: window.location.hash,
        host: window.location.host,
        hostname: window.location.hostname,
        href: window.location.href,
        origin: window.location.origin,
        pathname: window.location.pathname,
        port: window.location.port,
        protocol: window.location.protocol,
        search: window.location.search,
      },
      navigator: {
        language: window.navigator.language,
        userAgent: window.navigator.userAgent,
      },
      innerHeight: window.innerHeight,
      innerWidth: window.innerWidth,
    },
    document: {
      title: document.title,
      referrer: document.referrer,
    },
  }
  const { created, trials } = profile
  const firstTrial = trials[0]
  const latestTrial = trials[trials.length - 1]
  const userAttributes = {
    email: profile.email,
    cloud_user_domain: profile.domain,
    cloud_user_trial: profile.inTrial && profile.trials.length > 0,
    cloud_user_created_at: profile.created,
    cloud_user_conversion_date: profile.conversion_timestamp,
    cloud_user_paying: profile.is_paying,
    cloud_user_billing_model: billingModel || null,
    cloud_user_trial_start: latestTrial ? latestTrial.start : null,
    cloud_user_trial_start_within_30_days: firstTrial
      ? cloudTrialStartedWithin30Days(created, firstTrial.start)
      : false,
  }

  return { context, userAttributes }
}

export function startTrackingUserAttributes(
  chatIframe: HTMLIFrameElement,
  profile: ProfileStateImpl,
  billingModel: BillingDetails['billing_model'],
): void {
  // in case if props have been changed
  // between initial rendering and driftWidgetReady event
  if (!chatIframe.contentWindow) {
    return
  }

  postMessage(chatIframe, 'driftSetUserAttributes', getContextAndAttributes(profile, billingModel))
}

export function postMessage(chatIframe: HTMLIFrameElement | null, type: string, data?: any) {
  if (!chatIframe?.contentWindow) {
    return
  }

  chatIframe.contentWindow.postMessage(
    {
      type,
      data,
    },
    '*',
  )
}
