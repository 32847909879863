/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { Fragment, useEffect, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import cx from 'classnames'

import { EuiSpacer, EuiFlexGroup, EuiFlexItem } from '@elastic/eui'

import type { AsyncRequestError, RoutableBreadcrumb } from '@modules/ui-types'
import history from '@modules/utils/history'
import { isNonEmptyArray } from '@modules/ts-utils'
import { useOrganizationIdp } from '@modules/security-idp-lib/hooks'
import Feature from '@modules/utils/feature'
import { usePermissionsBatch } from '@modules/permissions-lib/hooks'
import { useConfig } from '@modules/cui/ConfigContext'

import LeaveOrganizationButton from '@/components/Organization/OrganizationMembers/LeaveOrganizationButton'
import InviteOrganizationMembersButton from '@/components/Organization/OrganizationMembers/InviteOrganizationMembersFlyout/InviteOrganizationMembersButton'
import OrganizationEmptyPrompt from '@/components/Organization/OrganizationEmptyPrompt/'
import OrganizationNoRolesEmptyPrompt from '@/components/Organization/OrganizationNoRolesEmptyPrompt'
import OrganizationMembers from '@/components/Organization/OrganizationMembers'
import AccountDetails from '@/apps/userconsole/components/AccountDetails'
import OrganizationName from '@/components/Organization/OrganizationName'
import ApiKeys from '@/components/ApiKeys'
import {
  accountContactsCrumbs,
  accountMembersCrumbs,
  accountCrumbs,
  accountApiKeysCrumbs,
  orgSecurityCrumbs,
} from '@/lib/crumbBuilder'
import { accountContactsUrl } from '@/apps/userconsole/urls'
import Security from '@/components/Security'
import UsageNotice from '@/apps/userconsole/components/NotificationBanner/UsageNotice'

import PortalPage from '../PortalPage'

import type { TabbedContent } from '../PortalPage'
import type { ReactElement } from 'react'
import type { AllProps as Props, Tabs } from './types'

const Account = (props: Props) => {
  const {
    isRouteFSTraced,
    organizationId,
    accountDetails,
    showOrganization,
    location,
    organization,
    isLoadingOrganization,
    organizationMemberRows,
    fetchOrganizationIfNeeded,
    fetchOrganizationMembers,
    fetchOrganizationInvites,
    fetchOrganizationRequest,
    fetchOrganizationMembersRequest,
    fetchOrganizationInvitationsRequest,
  } = props
  const {
    hasViewMembersTabPermission,
    hasViewAccountTabPermission,
    hasViewApiKeysTabPermission,
    hasAccessToSecurityTab,
    hasGetOrganizationPermission,
    hasListOrganizationMembersPermission,
    hasListOrganizationInvitationsPermission,
    isLoading: isLoadingPermissions,
  } = usePermissionsBatch({
    hasViewMembersTabPermission: {
      permissionsToCheck: [
        {
          type: 'organization-members',
          action: 'list',
        },
        {
          type: 'organization-invitation',
          action: 'list',
        },
      ],
      behavior: 'some',
    },
    hasViewAccountTabPermission: {
      permissionsToCheck: [
        {
          type: 'organization',
          action: 'update',
        },
      ],
    },
    hasViewApiKeysTabPermission: {
      permissionsToCheck: [
        {
          type: 'api-key',
          action: 'list',
        },
      ],
    },
    hasGetOrganizationPermission: {
      permissionsToCheck: [
        {
          type: 'organization',
          action: 'get',
        },
      ],
    },
    hasListOrganizationMembersPermission: {
      permissionsToCheck: [
        {
          type: 'organization-members',
          action: 'list',
        },
      ],
    },
    hasListOrganizationInvitationsPermission: {
      permissionsToCheck: [
        {
          type: 'organization-invitation',
          action: 'list',
        },
      ],
    },
    hasAccessToSecurityTab: {
      permissionsToCheck: [
        {
          type: 'organization-idp',
          action: 'get',
        },
        {
          type: 'organization-domain-claim',
          action: 'get',
        },
        {
          type: 'organization-role-mapping',
          action: 'get',
        },
      ],
      behavior: 'some',
    },
  })
  const enableUsageNoticeElasticCheck = useConfig(Feature.enableUsageNoticeElasticCheck)
  const { isLoading: isLoadingIdp, data: idpInfo } = useOrganizationIdp(organizationId)
  const tabIdFromUrl = useMemo(() => location.pathname.split('/').pop(), [location.pathname])

  useEffect(() => {
    if (!showOrganization) {
      history.replace(accountContactsUrl())
    }

    if (organizationId && showOrganization) {
      fetchOrganizationIfNeeded(organizationId)
      fetchOrganizationMembers(organizationId)
      fetchOrganizationInvites(organizationId)
    }
  }, [])

  if (showOrganization && !organizationId) {
    return (
      <PortalPage
        breadcrumbs={accountCrumbs(true)}
        className={cx({ 'fs-unmask': isRouteFSTraced }, 'cloud-portal-account')}
      >
        <OrganizationEmptyPrompt />
      </PortalPage>
    )
  }

  const isLoading = isLoadingPermissions || isLoadingOrganization || isLoadingIdp

  const hasNoPermissionsToSeeTabs =
    showOrganization &&
    organizationId &&
    !hasViewAccountTabPermission &&
    !hasViewMembersTabPermission &&
    !hasViewApiKeysTabPermission &&
    !hasAccessToSecurityTab

  const renderContentHeader = (): ReactElement => {
    if (showOrganization && organizationId) {
      return (
        <EuiFlexGroup direction='column'>
          <EuiFlexGroup>
            <EuiFlexItem>
              <OrganizationName organizationId={organizationId} />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>{renderActionButtons()}</EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexGroup>
      )
    }

    return <FormattedMessage id='cloud.account.title' defaultMessage='Account' />
  }

  if (hasNoPermissionsToSeeTabs && !isLoading) {
    return (
      <PortalPage
        className={cx({ 'fs-unmask': isRouteFSTraced }, 'cloud-portal-account')}
        contentHeader={renderContentHeader()}
      >
        <EuiSpacer size='l' />
        <OrganizationNoRolesEmptyPrompt />
      </PortalPage>
    )
  }

  const renderActionButtons = (): ReactElement | null => {
    if (organization === undefined || isLoadingOrganization) {
      return null
    }

    return (
      <EuiFlexGroup gutterSize='m'>
        <EuiFlexItem grow={false}>
          <InviteOrganizationMembersButton
            organizationId={organization.id}
            organizationMemberRows={organizationMemberRows}
            error={getError(organization.id)}
          />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <LeaveOrganizationButton organization={organization} error={getError(organization.id)} />
        </EuiFlexItem>
      </EuiFlexGroup>
    )
  }

  const renderTabContent = (content: ReactElement): ReactElement => (
    <Fragment>
      <EuiSpacer size='l' />
      {content}
    </Fragment>
  )

  const getTabbedContent = (): TabbedContent | undefined => {
    const tabs: Tabs[] = []

    if (showOrganization && organizationId && hasViewMembersTabPermission) {
      tabs.unshift({
        id: 'members',
        name: <FormattedMessage id='tabs.members' defaultMessage='Members' />,
        content: renderTabContent(
          <OrganizationMembers
            organizationId={organizationId}
            organization={organization}
            organizationMemberRows={organizationMemberRows}
            isLoading={isLoadingOrganization}
            error={getError(organizationId)}
          />,
        ),
      })
    }

    if (!showOrganization || hasViewAccountTabPermission) {
      tabs.push({
        id: 'contacts',
        name: <FormattedMessage id='tabs.contacts' defaultMessage='Contacts' />,
        content: renderTabContent(<AccountDetails data-test-id='account-index-contacts-link' />),
      })
    }

    if (hasViewApiKeysTabPermission) {
      tabs.push({
        id: 'keys',
        name: <FormattedMessage id='tabs.apiKeys' defaultMessage='API keys' />,
        content: renderTabContent(<ApiKeys />),
      })
    }

    if (hasAccessToSecurityTab && organizationId) {
      tabs.push({
        id: 'idp',
        name: <FormattedMessage id='tabs.security' defaultMessage='Security' />,
        content: renderTabContent(<Security organizationId={organizationId} />),
      })
    }

    if (isNonEmptyArray(tabs)) {
      const initialSelectedTab = tabs.find(({ id }) => id === tabIdFromUrl)
      return { tabs, initialSelectedTab: initialSelectedTab ?? tabs[0] }
    }

    return
  }

  const getBreadcrumbs = (): RoutableBreadcrumb[] => {
    const crumbs = {
      contacts: accountContactsCrumbs,
      members: accountMembersCrumbs,
      keys: accountApiKeysCrumbs,
      idp: orgSecurityCrumbs,
    }

    if (!tabbedContent) {
      return accountCrumbs(showOrganization)
    }

    return [...accountCrumbs(showOrganization), ...crumbs[tabbedContent.initialSelectedTab.id]()]
  }

  function getError(organizationId: string): AsyncRequestError | undefined {
    const fetchOrganizationRequestError = hasGetOrganizationPermission
      ? fetchOrganizationRequest(organizationId).error
      : undefined

    const fetchOrganizationMembershipsRequestError = hasListOrganizationMembersPermission
      ? fetchOrganizationMembersRequest(organizationId).error
      : undefined

    const fetchOrganizationInvitationsRequestError = hasListOrganizationInvitationsPermission
      ? fetchOrganizationInvitationsRequest(organizationId).error
      : undefined

    return (
      fetchOrganizationRequestError ||
      fetchOrganizationMembershipsRequestError ||
      fetchOrganizationInvitationsRequestError
    )
  }

  const tabbedContent = getTabbedContent()
  const breadcrumbs = getBreadcrumbs()

  return (
    <PortalPage
      breadcrumbs={breadcrumbs}
      noticeBanner={
        accountDetails &&
        idpInfo?.configuration.enabled &&
        tabIdFromUrl === 'idp' && (
          <UsageNotice
            featureName={
              <FormattedMessage id='feature.idp' defaultMessage='the SAML SSO feature' />
            }
            accountDetails={accountDetails}
            overrideUsageLevel='enterprise'
            enableUsageNoticeElasticCheck={enableUsageNoticeElasticCheck}
          />
        )
      }
      className={cx({ 'fs-unmask': isRouteFSTraced }, 'cloud-portal-account')}
      contentHeader={renderContentHeader()}
      tabbedContent={tabbedContent}
    />
  )
}

export default Account
