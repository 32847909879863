/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiFlexGroup, EuiFlexItem } from '@elastic/eui'

import { useSolutionOptionsV5 } from '@modules/solution-selector/hooksV5'
import { StepLayout } from '@modules/cui/Step/StepLayout'
import type { ProjectType } from '@modules/ui-types/projects'
import SelectProjectTypeCardV5 from '@modules/project-creation-components/SelectSolution/SelectProjectTypeCardV5'

interface SolutionsOnboardingProps {
  onNext: (label: ProjectType) => void
}

const FLEX_STEP_LAYOUT_PROPS = {
  alignItems: 'stretch',
  justifyContent: 'center',
  direction: 'row',
}

const SolutionsOnboardingV5 = ({ onNext }: SolutionsOnboardingProps) => {
  const solutionOptions = useSolutionOptionsV5()

  return (
    <StepLayout
      title={
        <FormattedMessage
          id='discovery-questions-use-case.title'
          defaultMessage='Which use case are you looking to try first?'
        />
      }
      footer={null}
      flexGroupChildrenProps={FLEX_STEP_LAYOUT_PROPS}
    >
      <EuiFlexGroup alignItems='stretch' justifyContent='center'>
        {solutionOptions.map(({ label, description, features, logo, title }, index) => (
          <EuiFlexItem grow={1} style={{ maxWidth: '500px', minWidth: '355px' }}>
            <SelectProjectTypeCardV5
              label={label}
              description={description}
              features={features}
              logo={logo}
              title={title}
              key={`${index}-key`}
              onNext={onNext}
            />
          </EuiFlexItem>
        ))}
      </EuiFlexGroup>
    </StepLayout>
  )
}

export default SolutionsOnboardingV5
