/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/*!
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@                                         @@@@
 *!   @@@   THIS FILE WAS AUTOGENERATED BY TOOL   @@@@
 *!   @@@   CHANGES MUST TARGET SCRIPTS/SWAGGER   @@@@
 *!   @@@                                         @@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!
 */

import { stringify } from 'query-string'
import { isEmpty, pickBy } from 'lodash'

export const _emptyParams = Object.freeze({})

export const _getRawBasePath = () => `/api/v1`
export const _getBasePath = () => `/api/v1/regions`

export function _buildQueryString(parameters) {
  const nonNullParams = pickBy(parameters, (parameter) => parameter != null)
  return isEmpty(nonNullParams) ? `` : `?${stringify(nonNullParams)}`
}

export function _encode(strings, ...params) {
  let result = ''

  for (let i = 0; i < strings.length; i++) {
    result += strings[i]

    if (params[i]) {
      const value = params[i]

      if (Array.isArray(value)) {
        result += value.map(encodeURIComponent).join(`,`)
      } else if (
        value != null &&
        typeof value === 'object' &&
        value.hasOwnProperty('__path') &&
        typeof value.__path === 'string'
      ) {
        result += value.__path
      } else {
        result += encodeURIComponent(value)
      }
    }
  }

  return result
}
