/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { withRouter } from 'react-router'
import { connect } from 'react-redux'

import { getProfile } from '@/apps/userconsole/reducers/profile'
import {
  fetchOrganizationMembershipsRequest,
  fetchOrganizationInvitationsRequest,
  fetchOrganizationRequest,
} from '@/reducers/asyncRequests/registry'
import { getOrganization } from '@/reducers/organizations'
import {
  fetchOrganizationIfNeeded,
  fetchOrganizationInvitations,
  fetchOrganizationMemberships,
} from '@/actions/organizations'
import { shouldShowOrganization } from '@/components/Organization/lib'
import { getOrganizationMemberRows } from '@/components/Organization/OrganizationMembers/lib'
import { getAccountDetails } from '@/apps/userconsole/reducers/account'

import Account from './Account'

import type { ReduxState } from '@/types/redux'
import type { ConsumerProps, StateProps, DispatchProps } from './types'

const isLoading = (state: ReduxState, organizationId: string | undefined): boolean => {
  if (organizationId === undefined) {
    return false
  }

  return (
    fetchOrganizationRequest(state, organizationId).inProgress ||
    fetchOrganizationInvitationsRequest(state, organizationId).inProgress ||
    fetchOrganizationMembershipsRequest(state, organizationId).inProgress
  )
}

const mapStateToProps = (state: ReduxState, { location }: ConsumerProps): StateProps => {
  const profile = getProfile(state)
  const organizationId = profile?.organization_id
  const organizationMemberRows = getOrganizationMemberRows(state, organizationId)

  return {
    isLoadingOrganization: isLoading(state, organizationId),
    location,
    organizationId,
    organizationMemberRows,
    showOrganization: shouldShowOrganization(profile),
    accountDetails: getAccountDetails(state),
    organization: organizationId ? getOrganization(state, organizationId) : undefined,
    fetchOrganizationRequest: (id: string) => fetchOrganizationRequest(state, id),
    fetchOrganizationInvitationsRequest: (id: string) =>
      fetchOrganizationInvitationsRequest(state, id),
    fetchOrganizationMembersRequest: (id: string) => fetchOrganizationMembershipsRequest(state, id),
  }
}

const mapDispatchToProps = (dispatch): DispatchProps => ({
  fetchOrganizationIfNeeded: (organizationId) =>
    dispatch(fetchOrganizationIfNeeded({ organizationId })),
  fetchOrganizationMembers: (organizationId) =>
    dispatch(fetchOrganizationMemberships({ organizationId })),
  fetchOrganizationInvites: (organizationId) =>
    dispatch(fetchOrganizationInvitations({ organizationId })),
})

export default withRouter(
  connect<StateProps, DispatchProps, ConsumerProps>(mapStateToProps, mapDispatchToProps)(Account),
)
