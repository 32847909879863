/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiText, EuiFlexItem, EuiFlexGrid, EuiHorizontalRule, EuiSkeletonText } from '@elastic/eui'

import CuiElasticConsumptionUnits from '@modules/cui/formatters/CuiElasticConsumptionUnits'
import type { Items } from '@modules/billing-api/v1/types'

import UsageStat from '../UsageStat'
import messages from '../../messages'

import { getDeploymentsTotalUsage, getProjectsTotalUsage } from './utils'

interface Props {
  isLoading: boolean
  data?: Items
}

const SimplifyUsageSummary = ({ isLoading, data }: Props) => (
  <Fragment>
    <EuiFlexGrid columns={2} gutterSize='m' responsive={false}>
      <EuiFlexItem>
        <EuiText size='s'>
          <FormattedMessage
            id='billing.usage-card.simplify-view.serverless-projects'
            defaultMessage='Serverless projects'
          />
        </EuiText>
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiSkeletonText lines={1} isLoading={isLoading}>
          <EuiText textAlign='right' size='s'>
            <h4>
              <UsageStat
                title={
                  <CuiElasticConsumptionUnits
                    value={getProjectsTotalUsage(data?.products)}
                    unit='none'
                    withSymbol={false}
                  />
                }
                subText={<FormattedMessage {...messages.ecuUnit} />}
              />
            </h4>
          </EuiText>
        </EuiSkeletonText>
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiText size='s'>
          <FormattedMessage
            id='billing.usage-card.simplify-view.cloud-hosted'
            defaultMessage='Cloud hosted deployments'
          />
        </EuiText>
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiSkeletonText lines={1} isLoading={isLoading}>
          <EuiText textAlign='right' size='s'>
            <h4>
              <UsageStat
                title={
                  <CuiElasticConsumptionUnits
                    value={getDeploymentsTotalUsage(data?.products)}
                    unit='none'
                    withSymbol={false}
                  />
                }
                subText={<FormattedMessage {...messages.ecuUnit} />}
              />
            </h4>
          </EuiText>
        </EuiSkeletonText>
      </EuiFlexItem>
    </EuiFlexGrid>

    <EuiHorizontalRule size='full' margin='m' />

    <EuiFlexGrid columns={2} gutterSize='m' responsive={false}>
      <EuiFlexItem>
        <EuiText size='s'>
          <b>
            <FormattedMessage {...messages.currentUsage} />
          </b>
        </EuiText>
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiSkeletonText lines={1} isLoading={isLoading}>
          <EuiText textAlign='right' size='s'>
            <h3>
              <UsageStat
                title={
                  <CuiElasticConsumptionUnits
                    value={data?.total_ecu || 0}
                    unit='none'
                    withSymbol={false}
                  />
                }
                subText={<FormattedMessage {...messages.ecuUnit} />}
              />
            </h3>
          </EuiText>
        </EuiSkeletonText>
      </EuiFlexItem>
    </EuiFlexGrid>
  </Fragment>
)

export default SimplifyUsageSummary
