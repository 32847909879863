/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import { useEffect } from 'react'
import { useHistory } from 'react-router'

import { useProfile } from '../../profile-lib/hooks'

export const useRedirectToHome = () => {
  const profile = useProfile({ staleTime: 2000 })
  const history = useHistory<{ marketplaceType: string | null }>()

  useEffect(() => {
    const shouldRedirectHome =
      profile &&
      (profile.organization_id || profile.data.discovery) &&
      !history.location.state?.marketplaceType

    if (shouldRedirectHome) {
      history.push('/home')
    }
  }, [Boolean(profile)])
}
