/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/*!
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@                                         @@@@
 *!   @@@   THIS FILE WAS AUTOGENERATED BY TOOL   @@@@
 *!   @@@   CHANGES MUST TARGET SCRIPTS/SWAGGER   @@@@
 *!   @@@                                         @@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!
 */

import {
  _buildQueryString,
  _emptyParams,
  _encode,
  _getBasePath,
  _getRawBasePath,
} from './urls-utils'

/**
 * GET /api/v1/saas/billing/{organization_id}/features
 * Operation: `get-features-usage`
 * Retrieves the features in use by an organization above its subscription level in the last hour.
 * @param {string} params.organizationId Identifier for the organization.
 * @see 200 The Features Usage has been returned. See {@link FeaturesUsage}
 * @see 404 Organization not found. (code: `organization.not_found`). See {@link BasicFailedReply}
 * @see 500 Failed to retrieve valid features usage due to an internal server error. (code: `features_usage.invalid_stored_data`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getFeaturesUsageUrl(params: GetFeaturesUsageUrlParams): string {
  const path = _encode`/saas/billing/${params.organizationId}/features`
  return `${_getRawBasePath()}${path}`
}

export interface GetFeaturesUsageUrlParams {
  organizationId: string
}
