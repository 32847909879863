/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiCallOut } from '@elastic/eui'

import type { SliderInstanceType, StackDeployment } from '@modules/ui-types'

import { getFirstAvailableSliderClusterFromGet } from '@/lib/stackDeployments/selectors/available'
import { gte } from '@/lib/semver'

import DocLink from '../../components/DocLink'
import { getVersion, isSizedSliderResource } from '../stackDeployments/selectors/fundamentals'

import { getSliderPrettyName } from './messages'

const slidersRequiringReadOnlyUpgrades: SliderInstanceType[] = [`appsearch`, `enterprise_search`]

export const getReadOnlyCallout = ({
  deployment,
  pendingVersion = '',
}: {
  deployment: StackDeployment
  pendingVersion?: string | null
}): JSX.Element | null => {
  for (const sliderInstanceType of slidersRequiringReadOnlyUpgrades) {
    if (
      pendingVersion &&
      sliderInstanceType === 'enterprise_search' &&
      gte(pendingVersion, '9.0.0')
    ) {
      continue
    }

    const resource = getFirstAvailableSliderClusterFromGet({ deployment, sliderInstanceType })

    if (!resource) {
      continue
    }

    const hasSlider = isSizedSliderResource({ resource })

    if (!hasSlider) {
      continue
    }

    const version = getVersion({ deployment })
    const sliderPrettyName = (
      <FormattedMessage {...getSliderPrettyName({ sliderInstanceType, version })} />
    )

    return (
      <EuiCallOut
        title={
          <FormattedMessage
            id='upgradable-deployment-version-readonly-mode-title'
            defaultMessage='{sliderPrettyName} in read-only'
            values={{ sliderPrettyName }}
          />
        }
        color='warning'
        data-test-id='upgradable-deployment-version-readonly-mode'
      >
        <FormattedMessage
          id='upgradable-deployment-version.readonly-mode'
          defaultMessage='During the upgrade, {sliderPrettyName} will be in read-only mode. Requests to modify data on the system will fail until the upgrade has completed. {docLink}'
          values={{
            sliderPrettyName,
            docLink: (
              <DocLink link='readonlyAppSearchEnterpriseSearch'>
                <FormattedMessage
                  id='upgradable-deployment-version.readonly.learnmore'
                  defaultMessage='Learn more'
                />
              </DocLink>
            ),
          }}
        />
      </EuiCallOut>
    )
  }

  return null
}
