/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { useEffect, useRef, useState } from 'react'
import { camelCase } from 'lodash'

import { useEuiTheme } from '@elastic/eui'

import { useSessionStorageBackedState } from '@modules/utils/hooks/useLocalStorage'
import useBillingDetails from '@modules/billing-lib/billingDetails/useBillingDetails'

import ChatIframe from '@/apps/userconsole/components/DriftChat/ChatIframe'

import { getContextAndAttributes, postMessage, startTrackingUserAttributes } from './lib/functions'
import { useDriftChat } from './lib/hooks'
import { DRIFT_PLAYBOOK_FIRED } from './constants'

import type { CSSProperties, FC } from 'react'
import type { AllProps } from './types'

export type Props = Exclude<AllProps, 'profile'> & {
  profile: Exclude<AllProps['profile'], null> // require by the time this is rendered

  /** the chat widget is ready */
  onReady?: (chatApi: ChatApi) => void

  /** invoked when the playbook is fired */
  onPlaybookFired?: () => void

  /** styles set from outside, takes precedence over what an iframe sets */
  styles?: CSSProperties
}

export interface ChatApi {
  show: () => void
  hide: () => void
  toggle: () => void
}

const DriftChatIframe: FC<Props> = ({
  chatUrl,
  fetchDriftJwtRequestState,
  profile,
  styles,
  fetchDriftJwt,
  onReady,
  onPlaybookFired,
  onOpen,
}) => {
  const {
    euiTheme: {
      levels: { maskBelowHeader },
    },
  } = useEuiTheme()
  const { data, loading: billingDataLoading } = useBillingDetails()
  const { isChatOpen, hideChat, showChat, toggleChat } = useDriftChat()

  const [chatIframe, setChatIframe] = useState<HTMLIFrameElement | null>(null)

  const [innerStyles, setInnerStyles] = useState<CSSProperties>({})

  const [isWidgetOpen, setIsWidgetOpen] = useState<boolean>(false)
  const allowWidgetOpenSetter = useRef(true)

  const [hasPlaybookFiredOnce, setPlaybookFiredOnce] = useSessionStorageBackedState<boolean>(
    DRIFT_PLAYBOOK_FIRED,
    false,
  )

  useEffect(() => {
    fetchDriftJwt()
  }, [fetchDriftJwt])

  useEffect(() => {
    if (isChatOpen) {
      postMessage(chatIframe, `driftShow`)

      postMessage(chatIframe, `driftOpenChat`)
    } else {
      postMessage(chatIframe, `driftHide`)
    }
  }, [chatIframe, isChatOpen])

  const chatApi: ChatApi = {
    show: () => {
      showChat()
    },
    hide: () => {
      hideChat()
    },
    toggle: () => {
      toggleChat()
    },
  }

  if (fetchDriftJwtRequestState.inProgress || billingDataLoading) {
    return null
  }

  const mergedStyles = {
    ...innerStyles,
    // override set z-index
    zIndex: maskBelowHeader,
    // override with styles from outside
    ...styles,
  }

  return (
    <ChatIframe
      chatUrl={chatUrl}
      handleWindowMessage={handleWindowMessage}
      hideWidget={handleHideWidget}
      isWidgetOpen={isWidgetOpen}
      styles={mergedStyles}
    />
  )

  function handleHideWidget() {
    postMessage(chatIframe, `driftHide`)
    setIsWidgetOpen(false)
    allowWidgetOpenSetter.current = false
  }

  function handleWindowMessage(event: MessageEvent, sourceChatIframe: HTMLIFrameElement): void {
    if (!sourceChatIframe.contentWindow || event.source !== sourceChatIframe.contentWindow) {
      return
    }

    setChatIframe(sourceChatIframe)

    const message = event.data

    switch (message.type) {
      case 'driftWidgetReady': {
        chatApi.hide()

        startTrackingUserAttributes(sourceChatIframe, profile, data?.billing_model)

        onReady?.(chatApi)

        if (hasPlaybookFiredOnce) {
          onPlaybookFired?.()
        }

        break
      }

      case 'driftPlaybookFired': {
        onPlaybookFired?.()
        setPlaybookFiredOnce(true)
        break
      }

      case 'driftChatClosed': {
        chatApi.hide()
        setIsWidgetOpen(true)
        allowWidgetOpenSetter.current = true
        break
      }

      case 'driftIframeReady': {
        const { context, userAttributes } = getContextAndAttributes(profile, data?.billing_model)
        const userData = { ...userAttributes, jwt: profile.driftJwt }
        postMessage(sourceChatIframe, `driftSetContext`, { context, user: userData })
        break
      }

      case 'driftIframeResize': {
        const incomingStyles = message.data.styles || ({} as CSSProperties)
        // camelize to avoid style warnings from react
        const camelStyles = Object.keys(incomingStyles).reduce((acc, key) => {
          acc[camelCase(key)] = incomingStyles[key]
          return acc
        }, {} as Record<string, string>) as CSSProperties

        const newStyles = {
          ...innerStyles,
          ...camelStyles,
        }

        setInnerStyles(newStyles)

        if (allowWidgetOpenSetter.current) {
          setIsWidgetOpen(isElementVisibleWithCssAttributes(newStyles))
        }

        break
      }

      case 'driftChatOpened': {
        onOpen()
        setIsWidgetOpen(false)
        allowWidgetOpenSetter.current = false
        break
      }

      default:
        break
    }
  }
}

function isElementVisibleWithCssAttributes(style: CSSProperties) {
  if (
    (style.display !== undefined && style.display === 'none') ||
    (style.visibility !== undefined && style.visibility === 'hidden') ||
    (style.opacity !== undefined && parseFloat(style.opacity.toString()) === 0) ||
    (style.maxHeight !== undefined && parseFloat(style.maxHeight.toString()) === 0) ||
    (style.maxWidth !== undefined && parseFloat(style.maxWidth.toString()) === 0) ||
    (style.height !== undefined && parseFloat(style.height.toString()) === 0) ||
    (style.width !== undefined && parseFloat(style.width.toString()) === 0)
  ) {
    return false
  }

  // Check for clipping (clip-path)
  if (style.clipPath && ['none', 'inset(0%)'].includes(style.clipPath)) {
    return false
  }

  // Check for transformations that eliminate visibility
  if (style.transform && style.transform.includes('scale(0)')) {
    return false
  }

  return true
}

export default DriftChatIframe
