/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/** @jsx jsx */
import { parse } from 'querystring'

import { Component, Fragment, createRef, useEffect } from 'react'
import cx from 'classnames'
import { css, jsx } from '@emotion/react'
import { useLocation } from 'react-router'

import { EuiErrorBoundary, EuiPage, EuiPageBody, EuiPageSection, useEuiTheme } from '@elastic/eui'

import { themedBoxShadow } from '@modules/cui/styles/boxShadow'
import history from '@modules/utils/history'
import { BreadcrumbsContext } from '@modules/cui/Breadcrumbs'
import ProfileProvider from '@modules/profile-lib/ProfileProvider'
import LocalStorageKey from '@modules/utils/localStorageKeys'
import BillingDetailsMiddleware from '@modules/billing-lib/billingDetails/BillingDetailsMiddleware'
import PermissionsGate from '@modules/permissions-components/PermissionsGate'
import TrialSummary from '@modules/trial-components/TrialSummary'
import applyMiddleware from '@modules/app/applyMiddleware'
import MfaEnforcementMiddleware from '@modules/mfa-enforcement/MfaEnforcement'
import EbtProvider from '@modules/ebt/EbtProvider'
import type { ProfileState } from '@modules/ui-types'
import { isTrialUser } from '@modules/billing-lib/utils'

import { setCloudTrialCookie } from '@/lib/cookies'
import { AppRouterContext } from '@/components/AppRouter'
import RefreshApiToken from '@/components/RefreshApiToken'
import SchedulerMiddleware from '@/lib/schedule/SchedulerMiddleware'
import { useSetBillingModelCookie } from '@/lib/billing_model_cookie'
import { ErrorNotification } from '@/components/ErrorNotification'
import { marketplaceConversionUrl } from '@/lib/urlBuilder'
import { isMarketPlaceUser } from '@/lib/marketPlace'

import UserconsoleChromeNavigation from '../UserconsoleChromeNavigation'
import NotificationBanner from '../NotificationBanner'
import AppLoadingRoot from '../../../../components/AppLoadingRoot'
import ChromeHeader from '../../../../components/ChromeHeader'
import PhoneHomeData from '../../../../components/PhoneHome/Data'
import BottomDriftChat from '../DriftChat/BottomDriftChat'
import { useEbtDriftChat } from '../DriftChat/lib/hooks'
import { setUpMfaUrl } from '../../urls'

import ProfileSchedule from './ProfileSchedule'
import LaunchDarklyMiddleware from './middleware/LaunchDarklyMiddleware'
import FullStoryMiddleware from './middleware/FullStoryMiddleware'

import type { FC } from 'react'
import type { Props } from './types'

interface State {
  isTrialModalDismissed: boolean
}

type PropsWithTheme = Props

class App extends Component<PropsWithTheme, State> {
  state: State = {
    isTrialModalDismissed:
      localStorage.getItem(LocalStorageKey.trialExperienceDismissed) === 'true',
  }

  breadcrumbsRef = createRef<HTMLDivElement>()

  announcementsBreadcrumbsRef = createRef<HTMLDivElement>()

  componentDidMount() {
    const { fetchProfileIfNeeded, fetchEolStatusIfNeeded, fetchAuthzRoles } = this.props

    fetchEolStatusIfNeeded()
    fetchAuthzRoles()
    fetchProfileIfNeeded()
  }

  componentDidUpdate() {
    const { profile } = this.props

    if (profile && profile.inTrial) {
      setCloudTrialCookie(profile)
    }
  }

  render() {
    const {
      profile,
      fetchProfileIfNeeded,
      fetchProfileRequest,
      hideNavigation,
      isRouteFSTraced,
      isPortalRoute,
      children,
    } = this.props

    const { isTrialModalDismissed } = this.state
    const pending_level = profile?.pending_level

    /**
     * The provided component will be wrapped in the middleware components, in order
     * from outside in. Be mindful of any dependencies between them!
     */
    return applyMiddleware(
      [
        SchedulerMiddleware,
        ProfileProvider,
        BillingDetailsMiddleware,
        LaunchDarklyMiddleware,
        FullStoryMiddleware,
        MfaEnforcementMiddleware,
        EbtProvider,
      ],
      <Fragment>
        {profile && profile.hasExpiredTrial && !isTrialModalDismissed && (
          <TrialSummary onClose={this.onDismissTrialModal} />
        )}

        {this.shouldDisplayHeader() && (
          <HeaderAndNotifications
            hideNavigation={hideNavigation}
            profile={profile}
            breadcrumbsRef={this.breadcrumbsRef}
            announcementsRef={this.announcementsBreadcrumbsRef}
          />
        )}

        <Content
          profile={profile}
          isPortalRoute={isPortalRoute}
          hideNavigation={hideNavigation}
          isRouteFSTraced={isRouteFSTraced}
          breadcrumbsRef={this.breadcrumbsRef}
          announcementsBreadcrumbsRef={this.announcementsBreadcrumbsRef}
        >
          {children}
        </Content>

        {pending_level && (
          <ProfileSchedule
            fetchProfile={fetchProfileIfNeeded}
            busy={fetchProfileRequest.inProgress}
          />
        )}

        <RefreshApiToken />
      </Fragment>,
    )
  }

  shouldDisplayHeader() {
    const { shouldDisplayGlobalHeader, profile } = this.props

    return shouldDisplayGlobalHeader && profile && !this.isServerlessDiscoveryQuestions()
  }

  onDismissTrialModal = () => {
    localStorage.setItem(LocalStorageKey.trialExperienceDismissed, 'true')
    this.setState({ isTrialModalDismissed: true })
  }

  isServerlessDiscoveryQuestions(): boolean {
    const { search } = history.location
    const query = parse(search.slice(1))

    return history.location.pathname.startsWith('/onboarding') || Boolean(query.onboarding_token)
  }
}

const Content = ({
  profile,
  isPortalRoute,
  children,
  hideNavigation,
  isRouteFSTraced,
  breadcrumbsRef,
  announcementsBreadcrumbsRef,
}) => {
  const { pathname, search } = useLocation()

  const query = parse(search.slice(1))

  useEffect(() => {
    redirectOnboarding()
  }, [profile])

  if (profile == null) {
    return <AppLoadingRoot />
  }

  const pageContents = (
    <Fragment>
      <PhoneHomeData />
      <BillingModelCookieComponent />
      <EuiErrorBoundary>
        <BreadcrumbsContext.Provider
          value={{
            breadcrumbsRef,
            announcementsRef: announcementsBreadcrumbsRef,
          }}
        >
          {children}
        </BreadcrumbsContext.Provider>
      </EuiErrorBoundary>
    </Fragment>
  )

  // portal renders its own `<EuiPage>` frame
  if (isPortalRoute) {
    return pageContents
  }

  return (
    <CloudAppPage
      isHideNavigation={hideNavigation}
      isRouteFSTraced={isRouteFSTraced}
      pageContents={pageContents}
    />
  )

  function redirectOnboarding() {
    const isMarketplaceConversion = pathname === marketplaceConversionUrl()
    const registrationSource = profile?.data.registration_source

    if (profile && !profile.organization_id && isTrialUser(profile)) {
      const queryOnboardingToken = query.onboarding_token

      if (
        pathname.startsWith('/onboarding') ||
        profile.hasExpiredTrial ||
        isMarketplaceConversion ||
        registrationSource === 'community' ||
        registrationSource === 'training' ||
        registrationSource === 'support' ||
        registrationSource === 'organization-invite' ||
        registrationSource === 'organization-sso' ||
        history.location.pathname === setUpMfaUrl() ||
        query.invitationToken ||
        isMarketPlaceUser(profile) ||
        profile.data.discovery
      ) {
        return null
      }

      history.push(`/onboarding/redirect?onboarding_token=${queryOnboardingToken}`)
      return null
    }

    return null
  }
}

const HeaderAndNotifications: FC<{
  hideNavigation: boolean
  profile: ProfileState
  breadcrumbsRef: React.RefObject<HTMLDivElement>
  announcementsRef: React.RefObject<HTMLDivElement>
}> = ({ profile, breadcrumbsRef, announcementsRef }) => {
  const { reportOpenDriftChatEbt } = useEbtDriftChat()

  if (profile === null) {
    return null
  }

  return (
    <Fragment>
      <ChromeHeader
        showBreadcrumbs={true}
        showDrift={false}
        showHelp={true}
        breadcrumbsRef={breadcrumbsRef}
        announcementsRef={announcementsRef}
      />
      <ErrorNotification />
      <PermissionsGate
        permissions={[
          {
            type: 'feature-usage',
            action: 'list',
          },
        ]}
      >
        {({ hasPermissions }) => <NotificationBanner hasPermissions={hasPermissions} />}
      </PermissionsGate>
      <BottomDriftChat
        onOpen={() =>
          reportOpenDriftChatEbt({
            from: 'drift',
          })
        }
      />
    </Fragment>
  )
}

const CloudAppPage: FC<{
  isHideNavigation: boolean
  isRouteFSTraced: boolean | undefined
  pageContents: JSX.Element
}> = ({ isHideNavigation, isRouteFSTraced, pageContents }) => {
  const theme = useEuiTheme()

  const cloudContentStyle = css`
    background: ${!isHideNavigation
      ? theme.euiTheme.colors.emptyShade
      : theme.euiTheme.colors.body};
    box-shadow: ${themedBoxShadow({ theme })};
  `

  return (
    <EuiPage className={cx({ 'fs-unmask': isRouteFSTraced })}>
      {!isHideNavigation && (
        <aside className='cloudSidebar'>
          <AppRouterContext.Consumer>
            {({ routes }) => <UserconsoleChromeNavigation routes={routes} />}
          </AppRouterContext.Consumer>
        </aside>
      )}

      <div
        className={cx('cloudContent', { createPage: isHideNavigation })}
        id='cloudPortalPage'
        css={cloudContentStyle}
      >
        <EuiPageBody>
          <EuiPageSection className='cloudContentBody' paddingSize='m' color='transparent'>
            <div data-app='appContentBody'>{pageContents}</div>
          </EuiPageSection>
        </EuiPageBody>
      </div>
    </EuiPage>
  )
}

const BillingModelCookieComponent = () => {
  useSetBillingModelCookie()

  return null
}

export default App
