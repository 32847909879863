/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { useRef } from 'react'
import { useIntl } from 'react-intl'

import { EuiFlexGroup, EuiFlexItem, EuiSpacer, EuiSplitPanel } from '@elastic/eui'
import {
  AreaSeries,
  BarSeries,
  Axis,
  Chart,
  Position,
  ScaleType,
  Settings,
  type PointerEvent,
} from '@elastic/charts'

import { MetricTitle } from '@modules/auto-ops-components/MetricTitle/MetricTitle'
import { ChartCallout } from '@modules/auto-ops-components/ChartCallout/ChartCallout'
import { bytesToGigabytes, generateCallout } from '@modules/autoops-lib/util'
import { timeFormatterFn } from '@modules/autoops-lib/constants'
import type { MetricItem } from '@modules/autoops-api/types'
import { useElasticChartsTheme } from '@modules/cui/charts/hooks'
import { TruncatedTooltip } from '@modules/auto-ops-components/TruncatedTooltip/TruncatedTooltip'

type DefaultChartsProps = { data: Record<string, MetricItem | undefined> }

const DefaultCharts = ({ data }: DefaultChartsProps) => {
  const { formatMessage } = useIntl()
  const { ingest_rate, storage_retained } = data
  const hasIngest = ingest_rate !== undefined && ingest_rate.error === null
  const hasStorage = storage_retained !== undefined && storage_retained.error === null
  const refIngest = useRef<Chart>(null)
  const refStorage = useRef<Chart>(null)
  const ingestCallout = generateCallout('ingest_rate', [ingest_rate])
  const storageCallout = generateCallout('storage_retained', [storage_retained])

  const pointerUpdate = (event: PointerEvent) => {
    refIngest.current?.dispatchExternalPointerEvent(event)
    refStorage.current?.dispatchExternalPointerEvent(event)
  }

  const baseTheme = useElasticChartsTheme()

  if (!hasIngest && !hasStorage) {
    return null
  }

  return (
    <EuiFlexGroup gutterSize='l'>
      <EuiFlexItem>
        <EuiSplitPanel.Outer grow={true} hasBorder={true}>
          <EuiSplitPanel.Inner>
            <MetricTitle
              title='Ingest rate'
              infoContent='Amount of data ingested per unit of time.'
            />
            <EuiSpacer size='xs' />
            <Chart size={{ height: '200px' }} ref={refIngest}>
              <Settings
                baseTheme={baseTheme}
                noResults={
                  <ChartCallout
                    type={ingestCallout.type}
                    title={formatMessage(ingestCallout.title)}
                    message={ingestCallout.message}
                  />
                }
                pointerUpdateDebounce={0}
                onPointerUpdate={pointerUpdate}
              />

              <TruncatedTooltip units='GB' />

              <Axis id='bottom' position={Position.Bottom} tickFormat={timeFormatterFn} />

              <Axis id='left' position={Position.Left} tickFormat={(d) => `${d} GB`} />

              {hasIngest && (
                <BarSeries
                  key='Ingest rate'
                  id='Ingest rate'
                  xScaleType={ScaleType.Time}
                  yScaleType={ScaleType.Linear}
                  xAccessor={0}
                  yAccessors={[1]}
                  data={(ingest_rate.data ?? []).map(([x, y]) => [x, bytesToGigabytes(y)])}
                />
              )}
            </Chart>
          </EuiSplitPanel.Inner>
        </EuiSplitPanel.Outer>
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiSplitPanel.Outer grow={true} hasBorder={true}>
          <EuiSplitPanel.Inner>
            <MetricTitle
              title='Storage retained'
              infoContent='Amount of data that is being retained in long-term object storage (subject to data retention settings).'
            />
            <EuiSpacer size='xs' />
            <Chart size={{ height: '200px' }} ref={refStorage}>
              <Settings
                baseTheme={baseTheme}
                noResults={
                  <ChartCallout
                    type={storageCallout.type}
                    title={formatMessage(storageCallout.title)}
                    message={storageCallout.message}
                  />
                }
                pointerUpdateDebounce={0}
                onPointerUpdate={pointerUpdate}
              />

              <TruncatedTooltip units='GB' />

              <Axis id='bottom' position={Position.Bottom} tickFormat={timeFormatterFn} />

              <Axis id='left' position={Position.Left} tickFormat={(d) => `${d} GB`} />

              {hasStorage && (
                <AreaSeries
                  key='Storage retained'
                  id='Storage retained'
                  xScaleType={ScaleType.Time}
                  yScaleType={ScaleType.Linear}
                  xAccessor={0}
                  yAccessors={[1]}
                  data={(storage_retained.data ?? []).map(([x, y]) => [x, bytesToGigabytes(y)])}
                />
              )}
            </Chart>
          </EuiSplitPanel.Inner>
        </EuiSplitPanel.Outer>
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}

export default DefaultCharts
