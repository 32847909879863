/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/*!
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@                                         @@@@
 *!   @@@   THIS FILE WAS AUTOGENERATED BY TOOL   @@@@
 *!   @@@   CHANGES MUST TARGET SCRIPTS/SWAGGER   @@@@
 *!   @@@                                         @@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!
 */

import {
  _buildQueryString,
  _emptyParams,
  _encode,
  _getBasePath,
  _getRawBasePath,
} from './urls-utils'

/**
 * GET /api/v1/organizations
 * Operation: `list-organizations`
 * Fetch organizations available to the current user. Currently unavailable in self-hosted ECE.
 * @param {string} params.q Optional query string for filtering. Only `name:$nameToMatch` is supported for this parameter.
 * @param {string} params.nextPage Pagination cursor to get the next page of records.
 * @param {integer} params.limit The max records to return (max: 100). Default: 100
 * @see 200 Organizations fetched successfully. See {@link OrganizationList}
 * @see 401 User not found. (code: `user.not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function listOrganizationsUrl(params: ListOrganizationsUrlParams = _emptyParams): string {
  const queryString = _buildQueryString({
    q: params.q,
    next_page: params.nextPage,
    limit: params.limit === undefined ? 100 : params.limit,
  })
  const path = `/organizations`
  return `${_getRawBasePath()}${path}${queryString}`
}

export interface ListOrganizationsUrlParams {
  q?: string | null
  nextPage?: string | null
  limit?: number | null
}

/**
 * POST /api/v1/organizations
 * Operation: `create-organization`
 * Creates a new organization. Currently unavailable in self-hosted ECE.
 * @see Accepts a request payload of type {@link OrganizationRequest}
 * @see 201 Organization created successfully. See {@link Organization}
 * @see 400 * Name must be between 2 and 30 characters. (code: `organization.invalid_name`)
 * An organization with this id already exists. (code: `organization.already_exists`). See {@link BasicFailedReply}
 * @see 401 User not found. (code: `user.not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function createOrganizationUrl(): string {
  const path = `/organizations`
  return `${_getRawBasePath()}${path}`
}

/**
 * GET /api/v1/organizations/invitations/{invitation_token}
 * Operation: `get-organization-invitation`
 * Gets a single invitation to an organization by token. Currently unavailable in self-hosted ECE.
 * @param {string} params.invitationToken Organization invitation token.
 * @see 200 Organization invitation fetched successfully. See {@link OrganizationInvitation}
 * @see 404 Invitation not found. (code: `organization.invitation_not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getOrganizationInvitationUrl(params: GetOrganizationInvitationUrlParams): string {
  const path = _encode`/organizations/invitations/${params.invitationToken}`
  return `${_getRawBasePath()}${path}`
}

export interface GetOrganizationInvitationUrlParams {
  invitationToken: string
}

/**
 * POST /api/v1/organizations/invitations/{invitation_token}/_accept
 * Operation: `accept-organization-invitation`
 * Accepts an organization invitation. Currently unavailable in self-hosted ECE.
 * @param {string} params.invitationToken Organization invitation token.
 * @see 200 Organization invitation accepted successfully
 * @see 400 User already belongs to organization. (code: `organization.user_organization_already_belongs`). See {@link BasicFailedReply}
 * @see 403 The current user authentication is not valid. (code: `root.invalid_authentication`). See {@link BasicFailedReply}
 * @see 404 * Organization not found. (code: `organization.not_found`)
 * User not found. (code: `user.not_found`)
 * Invitation not found. (code: `organization.invitation_not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function acceptOrganizationInvitationUrl(
  params: AcceptOrganizationInvitationUrlParams,
): string {
  const path = _encode`/organizations/invitations/${params.invitationToken}/_accept`
  return `${_getRawBasePath()}${path}`
}

export interface AcceptOrganizationInvitationUrlParams {
  invitationToken: string
}

/**
 * GET /api/v1/organizations/{organization_id}
 * Operation: `get-organization`
 * Fetch a single organization by id. Currently unavailable in self-hosted ECE.
 * @param {string} params.organizationId Identifier for the Organization.
 * @see 200 Organization fetched successfully. See {@link Organization}
 * @see 401 User not found. (code: `user.not_found`). See {@link BasicFailedReply}
 * @see 403 The current user does not have access to the requested organization. (code: `organization.invalid_access`). See {@link BasicFailedReply}
 * @see 404 Organization not found. (code: `organization.not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getOrganizationUrl(params: GetOrganizationUrlParams): string {
  const path = _encode`/organizations/${params.organizationId}`
  return `${_getRawBasePath()}${path}`
}

export interface GetOrganizationUrlParams {
  organizationId: string
}

/**
 * PUT /api/v1/organizations/{organization_id}
 * Operation: `update-organization`
 * > WARNING > This endpoint is deprecated and scheduled to be removed in the next major version.  Updates an existing organization. Currently unavailable in self-hosted ECE.
 * @param {string} params.organizationId Identifier for the Organization.
 * @see Accepts a request payload of type {@link OrganizationRequest}
 * @see 200 Organization updated successfully. See {@link Organization}
 * @see 400 * Name must be between 2 and 30 characters. (code: `organization.invalid_name`)
 * User already has an organization. (code: `organization.user_organization_already_exists`). See {@link BasicFailedReply}
 * @see 401 You are not authorized to perform this action. See {@link BasicFailedReply}
 * @see 403 The current user does not have access to the requested organization. (code: `organization.invalid_access`). See {@link BasicFailedReply}
 * @see 404 Organization not found. (code: `organization.not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function updateOrganizationUrl(params: UpdateOrganizationUrlParams): string {
  const path = _encode`/organizations/${params.organizationId}`
  return `${_getRawBasePath()}${path}`
}

export interface UpdateOrganizationUrlParams {
  organizationId: string
}

/**
 * DELETE /api/v1/organizations/{organization_id}
 * Operation: `delete-organization`
 * Deletes an organization. Currently unavailable in self-hosted ECE.
 * @param {string} params.organizationId Identifier for the Organization.
 * @see 200 Organization deleted successfully
 * @see 400 * Organization has more than one member and cannot be deleted. (code: `organization.has_members`)
 * Organization has active deployments and cannot be deleted. (code: `organization.has_deployments`). See {@link BasicFailedReply}
 * @see 401 You are not authorized to perform this action. See {@link BasicFailedReply}
 * @see 403 The current user does not have access to the requested organization. (code: `organization.invalid_access`). See {@link BasicFailedReply}
 * @see 404 Organization not found. (code: `organization.not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function deleteOrganizationUrl(params: DeleteOrganizationUrlParams): string {
  const path = _encode`/organizations/${params.organizationId}`
  return `${_getRawBasePath()}${path}`
}

export interface DeleteOrganizationUrlParams {
  organizationId: string
}

/**
 * PATCH /api/v1/organizations/{organization_id}
 * Operation: `patch-organization`
 * Patches an existing organization. Currently unavailable in self-hosted ECE.
 * @param {string} params.organizationId Identifier for the Organization.
 * @see Accepts a request payload of type {@link string}
 * @see 200 Organization updated successfully. See {@link Organization}
 * @see 400 * Name must be between 2 and 30 characters. (code: `organization.invalid_name`)
 * User already has an organization. (code: `organization.user_organization_already_exists`). See {@link BasicFailedReply}
 * @see 401 You are not authorized to perform this action. See {@link BasicFailedReply}
 * @see 403 The current user does not have access to the requested organization. (code: `organization.invalid_access`). See {@link BasicFailedReply}
 * @see 404 Organization not found. (code: `organization.not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function patchOrganizationUrl(params: PatchOrganizationUrlParams): string {
  const path = _encode`/organizations/${params.organizationId}`
  return `${_getRawBasePath()}${path}`
}

export interface PatchOrganizationUrlParams {
  organizationId: string
}

/**
 * GET /api/v1/regions/{region_id}/organizations/{organization_id}/audit_logs
 * Operation: `get-audit-logs-status`
 * Get status of audit logs for the organization.
 * @param {string} params.organizationId Identifier for the Organization.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 Status of audit logs for the organization. See {@link AuditLogsStatus}
 * @see 404 * Organization not found. (code: `organization.not_found`)
 * Audit Logs configuration not found. (code: `organization.audit_service_not_configured`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getAuditLogsStatusUrl(params: GetAuditLogsStatusUrlParams): string {
  const path = _encode`/${params.regionId}/organizations/${params.organizationId}/audit_logs`
  return `${_getBasePath()}${path}`
}

export interface GetAuditLogsStatusUrlParams {
  organizationId: string
  regionId: string
}

/**
 * POST /api/v1/regions/{region_id}/organizations/{organization_id}/audit_logs
 * Operation: `enable-audit-logs`
 * Enable Audit Logging for an organization.
 * @param {string} params.organizationId Identifier for the Organization.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see Accepts a request payload of type {@link AuditLogsDestinationSettings}
 * @see 201 Audit Logs enabled successfully. See {@link AuditLogsStatus}
 * @see 404 Organization not found. (code: `organization.not_found`). See {@link BasicFailedReply}
 * @see 500 Audit Logs Service capacity is beyond configured threshold. (code: `organization.audit_service_at_capacity`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function enableAuditLogsUrl(params: EnableAuditLogsUrlParams): string {
  const path = _encode`/${params.regionId}/organizations/${params.organizationId}/audit_logs`
  return `${_getBasePath()}${path}`
}

export interface EnableAuditLogsUrlParams {
  organizationId: string
  regionId: string
}

/**
 * DELETE /api/v1/regions/{region_id}/organizations/{organization_id}/audit_logs
 * Operation: `disable-audit-logs`
 * Disable audit logs for this organization.
 * @param {string} params.organizationId Identifier for the Organization.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 Disable audit logs for the organization.
 * @see 404 Organization not found. (code: `organization.not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function disableAuditLogsUrl(params: DisableAuditLogsUrlParams): string {
  const path = _encode`/${params.regionId}/organizations/${params.organizationId}/audit_logs`
  return `${_getBasePath()}${path}`
}

export interface DisableAuditLogsUrlParams {
  organizationId: string
  regionId: string
}

/**
 * GET /api/v1/organizations/{organization_id}/domains
 * Operation: `domain-claim-get-domain-claims`
 * Get domain claims for the organization.
 * @param {string} params.organizationId Identifier for the Organization.
 * @see 200 The claimed domains for the organization. See {@link GetOrganizationClaimedDomainsResponse}
 * @see 404 Organization not found. (code: `organization.not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function domainClaimGetDomainClaimsUrl(params: DomainClaimGetDomainClaimsUrlParams): string {
  const path = _encode`/organizations/${params.organizationId}/domains`
  return `${_getRawBasePath()}${path}`
}

export interface DomainClaimGetDomainClaimsUrlParams {
  organizationId: string
}

/**
 * DELETE /api/v1/organizations/{organization_id}/domains
 * Operation: `domain-claim-delete`
 * Delete domain claim.
 * @param {string} params.organizationId Identifier for the Organization.
 * @see Accepts a request payload of type {@link DeleteDomainClaimRequest}
 * @see 200 With status 200 ok to signal the domain has been unclaimed
 * @see 400 An IdP is configured for the organization. (code: `org.idp.idp_configured`). See {@link BasicFailedReply}
 * @see 404 Organization not found. (code: `organization.not_found`). See {@link BasicFailedReply}
 * @see 409 Concurrent organization modification. (code: `org.domain_claim.concurrent_organization_modification`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function domainClaimDeleteUrl(params: DomainClaimDeleteUrlParams): string {
  const path = _encode`/organizations/${params.organizationId}/domains`
  return `${_getRawBasePath()}${path}`
}

export interface DomainClaimDeleteUrlParams {
  organizationId: string
}

/**
 * POST /api/v1/organizations/{organization_id}/domains/_generate_verification_code
 * Operation: `domain-claim-generate-verification-code`
 * Generate verification code for the domain claim challenge.
 * @param {string} params.organizationId Identifier for the Organization.
 * @see Accepts a request payload of type {@link VerificationCodeRequest}
 * @see 200 The verification code response. See {@link VerificationCodeResponse}
 * @see 404 Organization not found. (code: `organization.not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function domainClaimGenerateVerificationCodeUrl(
  params: DomainClaimGenerateVerificationCodeUrlParams,
): string {
  const path = _encode`/organizations/${params.organizationId}/domains/_generate_verification_code`
  return `${_getRawBasePath()}${path}`
}

export interface DomainClaimGenerateVerificationCodeUrlParams {
  organizationId: string
}

/**
 * POST /api/v1/organizations/{organization_id}/domains/_verify
 * Operation: `domain-claim-verify-domain`
 * Verify domain claim challenge.
 * @param {string} params.organizationId Identifier for the Organization.
 * @see Accepts a request payload of type {@link DomainVerificationRequest}
 * @see 200 With status 200 ok to signal the domain has been claimed
 * @see 400 Domain claim already exists. (code: `org.domain_claim.already_exists`). See {@link BasicFailedReply}
 * @see 404 Organization not found. (code: `organization.not_found`). See {@link BasicFailedReply}
 * @see 409 Concurrent organization modification. (code: `org.domain_claim.concurrent_organization_modification`). See {@link BasicFailedReply}
 * @see 449 The challenge can not be verified at the moment, please retry later
 * @return {string} A fully-interpolated and escaped URL
 */
export function domainClaimVerifyDomainUrl(params: DomainClaimVerifyDomainUrlParams): string {
  const path = _encode`/organizations/${params.organizationId}/domains/_verify`
  return `${_getRawBasePath()}${path}`
}

export interface DomainClaimVerifyDomainUrlParams {
  organizationId: string
}

/**
 * GET /api/v1/organizations/{organization_id}/idp
 * Operation: `get-organization-idp`
 * Gets the oranization's IdP. Currently unavailable in self-hosted ECE.
 * @param {string} params.organizationId Identifier for the Organization.
 * @see 200 The IdP info. See {@link OrganizationIdp}
 * @see 404 IdP was not configured yet. (code: `org.idp.idp_not_configured`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getOrganizationIdpUrl(params: GetOrganizationIdpUrlParams): string {
  const path = _encode`/organizations/${params.organizationId}/idp`
  return `${_getRawBasePath()}${path}`
}

export interface GetOrganizationIdpUrlParams {
  organizationId: string
}

/**
 * PUT /api/v1/organizations/{organization_id}/idp
 * Operation: `setup-organization-idp`
 * Sets up an organization's IdP. Currently unavailable in self-hosted ECE.
 * @param {string} params.organizationId Identifier for the Organization.
 * @see Accepts a request payload of type {@link IdpConfigurationRequest}
 * @see 200 The IdP info. See {@link OrganizationIdp}
 * @see 404 Organization not found. (code: `organization.not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function setupOrganizationIdpUrl(params: SetupOrganizationIdpUrlParams): string {
  const path = _encode`/organizations/${params.organizationId}/idp`
  return `${_getRawBasePath()}${path}`
}

export interface SetupOrganizationIdpUrlParams {
  organizationId: string
}

/**
 * DELETE /api/v1/regions/{region_id}/organizations/{organization_id}/idp
 * Operation: `teardown-organization-idp`
 * Tear down up an organization's IdP. Currently unavailable in self-hosted ECE.
 * @param {string} params.organizationId Identifier for the Organization.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 The IdP was successfully removed
 * @see 404 Organization IdP not configured. (code: `org.idp.idp_not_configured`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function teardownOrganizationIdpUrl(params: TeardownOrganizationIdpUrlParams): string {
  const path = _encode`/${params.regionId}/organizations/${params.organizationId}/idp`
  return `${_getBasePath()}${path}`
}

export interface TeardownOrganizationIdpUrlParams {
  organizationId: string
  regionId: string
}

/**
 * GET /api/v1/organizations/{organization_id}/idp/metadata.xml
 * Operation: `get-organization-idp-metadata`
 * Gets the oranization's IdP metadata.xml. Currently unavailable in self-hosted ECE.
 * @param {string} params.organizationId Identifier for the Organization.
 * @see 200 The IdP info. See {@link string}
 * @see 404 IdP was not configured yet. (code: `org.idp.idp_not_configured`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getOrganizationIdpMetadataUrl(params: GetOrganizationIdpMetadataUrlParams): string {
  const path = _encode`/organizations/${params.organizationId}/idp/metadata.xml`
  return `${_getRawBasePath()}${path}`
}

export interface GetOrganizationIdpMetadataUrlParams {
  organizationId: string
}

/**
 * GET /api/v1/organizations/{organization_id}/invitations
 * Operation: `list-organization-invitations`
 * Fetch open invitations to the selected organization. Currently unavailable in self-hosted ECE.
 * @param {string} params.organizationId Identifier for the Organization.
 * @see 200 Organization invitations fetched successfully. See {@link OrganizationInvitations}
 * @see 404 Organization not found. (code: `organization.not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function listOrganizationInvitationsUrl(
  params: ListOrganizationInvitationsUrlParams,
): string {
  const path = _encode`/organizations/${params.organizationId}/invitations`
  return `${_getRawBasePath()}${path}`
}

export interface ListOrganizationInvitationsUrlParams {
  organizationId: string
}

/**
 * POST /api/v1/organizations/{organization_id}/invitations
 * Operation: `create-organization-invitations`
 * Creates or refreshes organization invitations. Currently unavailable in self-hosted ECE.
 * @param {string} params.organizationId Identifier for the Organization.
 * @see Accepts a request payload of type {@link OrganizationInvitationRequest}
 * @see 201 Organization invitations created successfully. See {@link OrganizationInvitations}
 * @see 400 * Invitation email was not valid. (code: `organization.invitation_invalid_email`)
 * Invitation already sent. (code: `organization.invitation_already_exists`)
 * User already belongs to organization. (code: `organization.user_organization_already_belongs`). See {@link BasicFailedReply}
 * @see 403 The current user authentication is not valid. (code: `root.invalid_authentication`). See {@link BasicFailedReply}
 * @see 404 * Organization not found. (code: `organization.not_found`)
 * User not found. (code: `user.not_found`)
 * Invitation sender does not belong to organization. (code: `organization.user_organization_does_not_belong`). See {@link BasicFailedReply}
 * @see 429 Request exceeds organization invitation creation rate limits. (code: `organization.invitations_rate_limit_exceeded`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function createOrganizationInvitationsUrl(
  params: CreateOrganizationInvitationsUrlParams,
): string {
  const path = _encode`/organizations/${params.organizationId}/invitations`
  return `${_getRawBasePath()}${path}`
}

export interface CreateOrganizationInvitationsUrlParams {
  organizationId: string
}

/**
 * DELETE /api/v1/organizations/{organization_id}/invitations/{invitation_tokens}
 * Operation: `delete-organization-invitations`
 * Deletes one or more organization invitations. Currently unavailable in self-hosted ECE.
 * @param {string} params.organizationId Identifier for the Organization.
 * @param {string} params.invitationTokens CSV list of Invitation tokens.
 * @see 200 Organization invitations deleted successfully
 * @see 400 No valid invitation token was supplied. (code: `root.invalid_data`). See {@link BasicFailedReply}
 * @see 404 * Organization not found. (code: `organization.not_found`)
 * Invitation not found. (code: `organization.invitation_not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function deleteOrganizationInvitationsUrl(
  params: DeleteOrganizationInvitationsUrlParams,
): string {
  const path = _encode`/organizations/${params.organizationId}/invitations/${params.invitationTokens}`
  return `${_getRawBasePath()}${path}`
}

export interface DeleteOrganizationInvitationsUrlParams {
  organizationId: string
  invitationTokens: string
}

/**
 * GET /api/v1/organizations/{organization_id}/members
 * Operation: `list-organization-members`
 * Fetch users belonging to the selected organization. Currently unavailable in self-hosted ECE.
 * @param {string} params.organizationId Identifier for the Organization.
 * @param {boolean} params.showHiddenRoleAssignments If true, hidden Role Assignments are returned.
 * @see 200 Organization members fetched successfully. See {@link OrganizationMemberships}
 * @see 404 Organization not found. (code: `organization.not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function listOrganizationMembersUrl(params: ListOrganizationMembersUrlParams): string {
  const queryString = _buildQueryString({
    show_hidden_role_assignments:
      params.showHiddenRoleAssignments === undefined ? false : params.showHiddenRoleAssignments,
  })
  const path = _encode`/organizations/${params.organizationId}/members`
  return `${_getRawBasePath()}${path}${queryString}`
}

export interface ListOrganizationMembersUrlParams {
  organizationId: string
  showHiddenRoleAssignments?: boolean | null
}

/**
 * POST /api/v1/organizations/{organization_id}/members
 * Operation: `create-organization-membership`
 * Creates an organization membership. Currently unavailable in self-hosted ECE.
 * @param {string} params.organizationId Identifier for the Organization.
 * @see Accepts a request payload of type {@link OrganizationMembershipRequest}
 * @see 201 Organization membership created successfully. See {@link OrganizationMembership}
 * @see 400 User already has an organization. (code: `organization.user_organization_already_exists`). See {@link BasicFailedReply}
 * @see 404 * User not found. (code: `user.not_found`)
 * Organization not found. (code: `organization.not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function createOrganizationMembershipUrl(
  params: CreateOrganizationMembershipUrlParams,
): string {
  const path = _encode`/organizations/${params.organizationId}/members`
  return `${_getRawBasePath()}${path}`
}

export interface CreateOrganizationMembershipUrlParams {
  organizationId: string
}

/**
 * DELETE /api/v1/organizations/{organization_id}/members/{user_ids}
 * Operation: `delete-organization-memberships`
 * Deletes one or more organization memberships. Currently unavailable in self-hosted ECE.
 * @param {string} params.organizationId Identifier for the Organization.
 * @param {string} params.userIds CSV list of User identifiers.
 * @param {boolean} params.force Whether or not to force the removal of Org memberships (effective only for Platform Admins).
 * @see 200 Organization membership deleted successfully
 * @see 404 * User not found. (code: `user.not_found`)
 * Organization not found. (code: `organization.not_found`)
 * Organization membership not found. (code: `organization.membership_not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function deleteOrganizationMembershipsUrl(
  params: DeleteOrganizationMembershipsUrlParams,
): string {
  const queryString = _buildQueryString({
    force: params.force === undefined ? false : params.force,
  })
  const path = _encode`/organizations/${params.organizationId}/members/${params.userIds}`
  return `${_getRawBasePath()}${path}${queryString}`
}

export interface DeleteOrganizationMembershipsUrlParams {
  organizationId: string
  userIds: string
  force?: boolean | null
}

/**
 * GET /api/v1/organizations/{organization_id}/role_mappings
 * Operation: `get-role-mappings`
 * Gets the organization role  mappings. Currently unavailable in self-hosted ECE.
 * @param {string} params.organizationId Identifier for the Organization.
 * @see 200 The IdP info. See {@link GetRoleMappingsResponse}
 * @see 404 Organization not found. (code: `organization.not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getRoleMappingsUrl(params: GetRoleMappingsUrlParams): string {
  const path = _encode`/organizations/${params.organizationId}/role_mappings`
  return `${_getRawBasePath()}${path}`
}

export interface GetRoleMappingsUrlParams {
  organizationId: string
}

/**
 * PUT /api/v1/organizations/{organization_id}/role_mappings
 * Operation: `update-role-mappings`
 * Updates the role assignments applicable when logging via SSO. Currently unavailable in self-hosted ECE.
 * @param {string} params.organizationId Identifier for the Organization.
 * @see Accepts a request payload of type {@link UpdateOrganizationRoleMappingRequest}
 * @see 200 An empty response
 * @see 400 Validation error. (code: `org.role_mapping_rule.syntax_error`). See {@link BasicFailedReply}
 * @see 404 Organization not found. (code: `organization.not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function updateRoleMappingsUrl(params: UpdateRoleMappingsUrlParams): string {
  const path = _encode`/organizations/${params.organizationId}/role_mappings`
  return `${_getRawBasePath()}${path}`
}

export interface UpdateRoleMappingsUrlParams {
  organizationId: string
}

/**
 * DELETE /api/v1/organizations/{organization_id}/role_mappings
 * Operation: `delete-role-mappings`
 * Deletes the organization role  mappings. Currently unavailable in self-hosted ECE.
 * @param {string} params.organizationId Identifier for the Organization.
 * @see 200 The mappings were successfully deleted
 * @see 404 Organization not found. (code: `organization.not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function deleteRoleMappingsUrl(params: DeleteRoleMappingsUrlParams): string {
  const path = _encode`/organizations/${params.organizationId}/role_mappings`
  return `${_getRawBasePath()}${path}`
}

export interface DeleteRoleMappingsUrlParams {
  organizationId: string
}
