/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { find } from 'lodash'

import { EuiSkeletonText, EuiSpacer } from '@elastic/eui'

import { getPlatform } from '@modules/utils/platform'
import type {
  GlobalDeploymentTemplateInfo,
  DeploymentTemplateInfoV2,
  Provider,
} from '@modules/cloud-api/v1/types'
import type {
  PlatformId,
  RegionId,
  VersionNumber,
  Region,
  StackDeploymentCreateRequest,
} from '@modules/ui-types'
import { useToggle } from '@modules/utils/hooks/useToggle'

// eslint-disable-next-line import/no-restricted-paths
import { getUpsertVersion } from '@/lib/stackDeployments/selectors/creates'
// eslint-disable-next-line import/no-restricted-paths
import { filterGlobalTemplates } from '@/lib/globalDeploymentTemplates'
// eslint-disable-next-line import/no-restricted-paths
import { getVisibleTemplates } from '@/lib/stackDeployments/selectors/deploymentTemplates'

import AdvancedSettings from './AdvancedSettings'
import CreateDeploymentButton from './CreateDeploymentButton'

import type { FunctionComponent } from 'react'

type Props = {
  availableVersions: VersionNumber[]
  deploymentTemplates?: DeploymentTemplateInfoV2[] | null
  editorState: StackDeploymentCreateRequest
  globalDeploymentTemplates?: GlobalDeploymentTemplateInfo[] | null
  onDeploymentCreation: () => Promise<void>
  providerIds: PlatformId[]
  providers: Provider[]
  region?: Region
  setGlobalTemplate: (
    globalTemplate: DeploymentTemplateInfoV2 | GlobalDeploymentTemplateInfo,
  ) => void
  setRegion: (args: { regionId: RegionId; stackVersion: VersionNumber }) => void
  setVersion: (version: VersionNumber) => void
  trialMaxedOut: boolean
  whitelistedVersions: VersionNumber[]
  onDeploymentCreationLoading?: (isLoading: boolean) => void
}

const SelectOptions: FunctionComponent<Props> = (props) => {
  const {
    availableVersions,
    deploymentTemplates,
    editorState,
    globalDeploymentTemplates,
    onDeploymentCreation,
    providerIds,
    providers,
    region,
    setGlobalTemplate,
    setRegion,
    setVersion,
    trialMaxedOut,
    whitelistedVersions,
    onDeploymentCreationLoading: onResourceCreationLoading,
  } = props
  const [isAdvancedSettingsOpen, _, setAdvancedSettingsOpen] = useToggle()

  const { deploymentTemplate, regionId: userSelectedRegionId } = editorState

  const getRegionsByProvider = (providers: Provider[], providerId: PlatformId) => {
    const currentProvider = find(providers, (stateProvider) => stateProvider.name === providerId)

    if (currentProvider) {
      return currentProvider.regions
    }

    return []
  }

  const getRegionIdsByProvider = (providers: Provider[], providerId: PlatformId): string[] => {
    const regions = getRegionsByProvider(providers, providerId)

    return regions.map(({ identifier }) => identifier)
  }

  const version = getUpsertVersion(editorState)

  const platform = getPlatform(userSelectedRegionId)
  const availableRegions = getRegionsByProvider(providers, platform)

  const filteredGlobalTemplates = filterGlobalTemplates({
    globalTemplates: globalDeploymentTemplates,
    deploymentTemplates,
    version,
    regionId: userSelectedRegionId,
  })

  const onChangeRegion = ({
    regionId,
    stackVersion,
  }: {
    regionId: string
    stackVersion: string
  }): void => {
    setRegion({ regionId, stackVersion })
  }

  const onChangePlatform = (platform: PlatformId): void => {
    const version = getUpsertVersion(editorState)

    const availableRegions = getRegionIdsByProvider(providers, platform)

    return onChangeRegion({ regionId: availableRegions[0]!, stackVersion: version! })
  }

  const filterSolutionTemplates = (
    templates?: GlobalDeploymentTemplateInfo[] | null,
  ): GlobalDeploymentTemplateInfo[] => {
    if (!templates) {
      return []
    }

    return templates.filter((template) =>
      template.metadata?.find((item) => item.key === `parent_solution`),
    )
  }

  // Once the API returns the correct templates, this won't be necessary #82357
  const visibleTemplates = getVisibleTemplates(filterSolutionTemplates(filteredGlobalTemplates))

  const isCreateDeploymentButtonDisabled = trialMaxedOut

  return deploymentTemplate && deploymentTemplates ? (
    <Fragment>
      <AdvancedSettings
        version={version}
        regionId={region?.id}
        region={region}
        availablePlatforms={providerIds}
        trialMaxedOut={trialMaxedOut}
        onChangePlatform={onChangePlatform}
        onChangeRegion={(regionId: string) => onChangeRegion({ regionId, stackVersion: version! })}
        availableRegions={availableRegions}
        availableVersions={availableVersions}
        whitelistedVersions={whitelistedVersions}
        setVersion={setVersion}
        editorState={editorState}
        globalDeploymentTemplates={visibleTemplates}
        onChangeTemplate={setGlobalTemplate}
        deploymentTemplates={deploymentTemplates}
        isOpen={isAdvancedSettingsOpen}
        setIsOpen={setAdvancedSettingsOpen}
      />
      <EuiSpacer size='xxl' />
      <CreateDeploymentButton
        onDeploymentCreation={onDeploymentCreation}
        editorState={editorState}
        region={region}
        disabled={isCreateDeploymentButtonDisabled}
        onDeploymentCreationLoading={onResourceCreationLoading}
      />
    </Fragment>
  ) : (
    <EuiSkeletonText />
  )
}

export default SelectOptions
