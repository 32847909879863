/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/*!
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@                                         @@@@
 *!   @@@   THIS FILE WAS AUTOGENERATED BY TOOL   @@@@
 *!   @@@   CHANGES MUST TARGET SCRIPTS/SWAGGER   @@@@
 *!   @@@                                         @@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!
 */

import {
  _buildQueryString,
  _emptyParams,
  _encode,
  _getBasePath,
  _getRawBasePath,
} from './urls-utils'

/**
 * GET /api/v1/saas/billing/prices
 * Operation: `get-prices`
 * Retrieves the list of Elastic Cloud products' prices by regions.
 * @param {string} params.region The region for which to fetch prices.
 * @param {string} params.from A datetime for the beginning of the desired range for which to fetch prices.
 * @param {string} params.to A datetime for the end of the desired range for which to fetch prices.
 * @param {string} params.timestamp A timestamp to retrieve prices effective at a specific point in time. Default: the current instant using the system UTC clock.
 * @param {boolean} params.isMarketplace Whether or not to include marketplace uplifts in the prices.
 * @param {boolean} params.includeDeprecatedSkus Whether or not to include deprecated SKUs.
 * @see 200 Prices for all the products in a region. See {@link Prices}
 * @see 404 Region not found. (code: `billing_service.region_not_found`). See {@link BasicFailedReply}
 * @see 500 Error fetching the list of Elastic Cloud products' prices. (code: `billing_service.failed_request`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getPricesUrl(params: GetPricesUrlParams): string {
  const queryString = _buildQueryString({
    region: params.region,
    from: params.from,
    to: params.to,
    timestamp: params.timestamp,
    is_marketplace: params.isMarketplace === undefined ? false : params.isMarketplace,
    include_deprecated_skus:
      params.includeDeprecatedSkus === undefined ? false : params.includeDeprecatedSkus,
  })
  const path = `/saas/billing/prices`
  return `${_getRawBasePath()}${path}${queryString}`
}

export interface GetPricesUrlParams {
  region: string
  from?: string | null
  to?: string | null
  timestamp?: string | null
  isMarketplace?: boolean | null
  includeDeprecatedSkus?: boolean | null
}
