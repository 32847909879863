/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/*!
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@                                         @@@@
 *!   @@@   THIS FILE WAS AUTOGENERATED BY TOOL   @@@@
 *!   @@@   CHANGES MUST TARGET SCRIPTS/SWAGGER   @@@@
 *!   @@@                                         @@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!
 */

import {
  _buildQueryString,
  _emptyParams,
  _encode,
  _getBasePath,
  _getRawBasePath,
} from './urls-utils'

/**
 * GET /api/v1/regions/{region_id}/authentication/_authenticate
 * Operation: `authenticate`
 * Authenticate a user and retrieve information about the authenticated user.
 * @param {boolean} params.showRoleAssignments If true, role assignments are returned.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 Authenticated user. See {@link AuthenticationResponse}
 * @see 401 The supplied authentication is invalid. (code: `root.unauthenticated`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function authenticateUrl(params: AuthenticateUrlParams): string {
  const queryString = _buildQueryString({
    show_role_assignments:
      params.showRoleAssignments === undefined ? false : params.showRoleAssignments,
  })
  const path = _encode`/${params.regionId}/authentication/_authenticate`
  return `${_getBasePath()}${path}${queryString}`
}

export interface AuthenticateUrlParams {
  showRoleAssignments?: boolean | null
  regionId: string
}

/**
 * POST /api/v1/saas/auth/_login
 * Operation: `login-saas-current-user`
 * Primary authentication for an Elastic Cloud user via email address and password.
 * @see Accepts a request payload of type {@link SaasAuthRequest}
 * @see 200 Login was successful. See {@link SaasAuthResponse}
 * @see 401 Invalid user credentials. (code: `root.unauthorized`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function loginSaasCurrentUserUrl(): string {
  const path = `/saas/auth/_login`
  return `${_getRawBasePath()}${path}`
}

/**
 * POST /api/v1/saas/auth/_logout
 * Operation: `logout-saas-current-user`
 * Ends an authenticated session for an Elastic Cloud user.
 * @see 200 Logout was successful
 * @return {string} A fully-interpolated and escaped URL
 */
export function logoutSaasCurrentUserUrl(): string {
  const path = `/saas/auth/_logout`
  return `${_getRawBasePath()}${path}`
}

/**
 * POST /api/v1/saas/auth/_refresh
 * Operation: `refresh-saas-current-user`
 * Refreshes an authenticated session for an Elastic Cloud user with updated authorization.
 * @see 200 Refresh was successful
 * @return {string} A fully-interpolated and escaped URL
 */
export function refreshSaasCurrentUserUrl(): string {
  const path = `/saas/auth/_refresh`
  return `${_getRawBasePath()}${path}`
}

/**
 * POST /api/v1/regions/{region_id}/saas/auth/heroku/_callback
 * Operation: `saas-heroku-callback`
 * Finishes a Heroku authentication by validating and authorizing a token.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see Accepts a request payload of type {@link SaasHerokuCallbackRequest}
 * @see 200 The token validation was successful. See {@link SaasAuthResponse}
 * @see 400 The token validation request was invalid. (code: `auth.expired_credentials`). See {@link BasicFailedReply}
 * @see 401 The token validation failed. (code: `root.unauthorized`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function saasHerokuCallbackUrl(params: SaasHerokuCallbackUrlParams): string {
  const path = _encode`/${params.regionId}/saas/auth/heroku/_callback`
  return `${_getBasePath()}${path}`
}

export interface SaasHerokuCallbackUrlParams {
  regionId: string
}

/**
 * GET /api/v1/saas/auth/mfa
 * Operation: `get-saas-current-user-mfa-enabled`
 * Returns the MFA enabled status for the current user.
 * @see 200 The MFA enabled/disabled state response. See {@link SaasAuthMfaEnabledResponse}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getSaasCurrentUserMfaEnabledUrl(): string {
  const path = `/saas/auth/mfa`
  return `${_getRawBasePath()}${path}`
}

/**
 * PUT /api/v1/saas/auth/mfa
 * Operation: `set-saas-current-user-mfa-enabled`
 * Enables or disables MFA for the current user.
 * @see Accepts a request payload of type {@link SaasAuthMfaEnabledRequest}
 * @see 200 The MFA device is enabled
 * @see 400 MFA cannot be enabled unless there are available devices. (code: `auth.no_available_mfa_devices`). See {@link BasicFailedReply}
 * @see 429 Authenticated session is too old for this critical operation, please retry after re-authenticating. (code: `auth.must_reauthenticate_for_critical_operation`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function setSaasCurrentUserMfaEnabledUrl(): string {
  const path = `/saas/auth/mfa`
  return `${_getRawBasePath()}${path}`
}

/**
 * GET /api/v1/saas/auth/mfa/devices
 * Operation: `get-saas-current-user-mfa-devices`
 * Returns all available MFA devices for the current user.
 * @see 200 The MFA devices response. See {@link SaasAuthMfaDevicesResponse}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getSaasCurrentUserMfaDevicesUrl(): string {
  const path = `/saas/auth/mfa/devices`
  return `${_getRawBasePath()}${path}`
}

/**
 * POST /api/v1/saas/auth/mfa/devices
 * Operation: `enroll-saas-current-user-mfa-device`
 * Enrolls a new MFA device for the current user.
 * @see Accepts a request payload of type {@link SaasAuthMfaEnrollDeviceRequest}
 * @see 200 The MFA device enrollment response. See {@link SaasAuthMfaDeviceResponse}
 * @return {string} A fully-interpolated and escaped URL
 */
export function enrollSaasCurrentUserMfaDeviceUrl(): string {
  const path = `/saas/auth/mfa/devices`
  return `${_getRawBasePath()}${path}`
}

/**
 * PUT /api/v1/saas/auth/mfa/devices/{device_id}
 * Operation: `activate-saas-current-user-mfa-device`
 * Activates an enrolled MFA device for the current user.
 * @param {string} params.deviceId Identifier for the MFA device.
 * @see Accepts a request payload of type {@link SaasAuthMfaActivateDeviceRequest}
 * @see 200 The MFA device activation response. See {@link SaasAuthMfaDeviceResponse}
 * @return {string} A fully-interpolated and escaped URL
 */
export function activateSaasCurrentUserMfaDeviceUrl(
  params: ActivateSaasCurrentUserMfaDeviceUrlParams,
): string {
  const path = _encode`/saas/auth/mfa/devices/${params.deviceId}`
  return `${_getRawBasePath()}${path}`
}

export interface ActivateSaasCurrentUserMfaDeviceUrlParams {
  deviceId: string
}

/**
 * DELETE /api/v1/saas/auth/mfa/devices/{device_id}
 * Operation: `delete-saas-current-user-mfa-device`
 * Deletes an enrolled MFA device for the current user.
 * @param {string} params.deviceId Identifier for the MFA device.
 * @see 200 The MFA device was successfully deleted
 * @see 400 Cannot delete the last active MFA device while MFA is enabled. (code: `auth.no_available_mfa_devices`). See {@link BasicFailedReply}
 * @see 429 Authenticated session is too old for this critical operation, please retry after re-authenticating. (code: `auth.must_reauthenticate_for_critical_operation`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function deleteSaasCurrentUserMfaDeviceUrl(
  params: DeleteSaasCurrentUserMfaDeviceUrlParams,
): string {
  const path = _encode`/saas/auth/mfa/devices/${params.deviceId}`
  return `${_getRawBasePath()}${path}`
}

export interface DeleteSaasCurrentUserMfaDeviceUrlParams {
  deviceId: string
}

/**
 * POST /api/v1/saas/auth/mfa/devices/{device_id}/_challenge
 * Operation: `challenge-saas-current-user-mfa-device`
 * For some MFA device types (such as SMS), an SMS message or push notification is sent to the device. This endpoint requires the state_id input field to be set.
 * @param {string} params.deviceId Identifier for the MFA device.
 * @see Accepts a request payload of type {@link SaasAuthMfaChallengeRequest}
 * @see 200 The challenge was successfully delivered to the MFA device. See {@link SaasAuthMfaChallengeResponse}
 * @return {string} A fully-interpolated and escaped URL
 */
export function challengeSaasCurrentUserMfaDeviceUrl(
  params: ChallengeSaasCurrentUserMfaDeviceUrlParams,
): string {
  const path = _encode`/saas/auth/mfa/devices/${params.deviceId}/_challenge`
  return `${_getRawBasePath()}${path}`
}

export interface ChallengeSaasCurrentUserMfaDeviceUrlParams {
  deviceId: string
}

/**
 * POST /api/v1/saas/auth/mfa/devices/{device_id}/_challenge_factor
 * Operation: `challenge-saas-current-user-mfa-factor`
 * For some MFA device types (such as SMS), an SMS message or push notification is sent to the device. This endpoint requires authentication.
 * @param {string} params.deviceId Identifier for the MFA device.
 * @see Accepts a request payload of type {@link SaasAuthMfaFactorChallengeRequest}
 * @see 200 The challenge was successfully delivered to the MFA device. See {@link SaasAuthMfaChallengeResponse}
 * @return {string} A fully-interpolated and escaped URL
 */
export function challengeSaasCurrentUserMfaFactorUrl(
  params: ChallengeSaasCurrentUserMfaFactorUrlParams,
): string {
  const path = _encode`/saas/auth/mfa/devices/${params.deviceId}/_challenge_factor`
  return `${_getRawBasePath()}${path}`
}

export interface ChallengeSaasCurrentUserMfaFactorUrlParams {
  deviceId: string
}

/**
 * POST /api/v1/saas/auth/mfa/devices/{device_id}/_verify_challenge
 * Operation: `verify-saas-current-user-mfa-challenge`
 * Called in response to a primary (password) authentication request demanding an additional factor. This endpoint requires the state_id input field to be set.
 * @param {string} params.deviceId Identifier for the MFA device.
 * @see Accepts a request payload of type {@link SaasAuthMfaVerifyChallengeRequest}
 * @see 200 The MFA device activation response. See {@link SaasAuthResponse}
 * @see 401 The MFA challenge verification failed. (code: `root.unauthorized`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function verifySaasCurrentUserMfaChallengeUrl(
  params: VerifySaasCurrentUserMfaChallengeUrlParams,
): string {
  const path = _encode`/saas/auth/mfa/devices/${params.deviceId}/_verify_challenge`
  return `${_getRawBasePath()}${path}`
}

export interface VerifySaasCurrentUserMfaChallengeUrlParams {
  deviceId: string
}

/**
 * POST /api/v1/saas/auth/mfa/devices/{device_id}/_verify_factor
 * Operation: `verify-saas-current-user-mfa-factor`
 * Called to return a fresh authenticated session using an MFA factor. This endpoint requires authentication.
 * @param {string} params.deviceId Identifier for the MFA device.
 * @see Accepts a request payload of type {@link SaasAuthMfaFactorVerifyChallengeRequest}
 * @see 200 The MFA factor verification response. See {@link SaasAuthResponse}
 * @see 400 The MFA challenge verification failed. (code: `auth.mfa_factor_failed`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function verifySaasCurrentUserMfaFactorUrl(
  params: VerifySaasCurrentUserMfaFactorUrlParams,
): string {
  const path = _encode`/saas/auth/mfa/devices/${params.deviceId}/_verify_factor`
  return `${_getRawBasePath()}${path}`
}

export interface VerifySaasCurrentUserMfaFactorUrlParams {
  deviceId: string
}

/**
 * POST /api/v1/saas/auth/oauth/_callback
 * Operation: `saas-openid-callback`
 * Finishes an OAuth2 authentication by validating and authorizing an ID token.
 * @see Accepts a request payload of type {@link SaasOpenIdCodeCallbackRequest}
 * @see 200 The token validation was successful. See {@link SaasAuthResponse}
 * @see 400 The token validation request was invalid. (code: `auth.expired_credentials`). See {@link BasicFailedReply}
 * @see 401 The token validation failed. (code: `root.unauthorized`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function saasOpenidCallbackUrl(): string {
  const path = `/saas/auth/oauth/_callback`
  return `${_getRawBasePath()}${path}`
}

/**
 * POST /api/v1/saas/auth/sso/_callback
 * Operation: `organization-sso-callback`
 * Finishes SSO authentication.
 * @see Accepts a request payload of type {@link SsoCallbackRequest}
 * @see 200 Login was successful. See {@link SaasAuthResponse}
 * @return {string} A fully-interpolated and escaped URL
 */
export function organizationSsoCallbackUrl(): string {
  const path = `/saas/auth/sso/_callback`
  return `${_getRawBasePath()}${path}`
}

/**
 * POST /api/v1/saas/auth/sso/_find_idp
 * Operation: `organization-sso-find-idp`
 * Finds organization identity providers that can authenticate a given user's email address.
 * @see Accepts a request payload of type {@link FindIdpRequest}
 * @see 200 IdP results that match the request. See {@link FindIdpResults}
 * @return {string} A fully-interpolated and escaped URL
 */
export function organizationSsoFindIdpUrl(): string {
  const path = `/saas/auth/sso/_find_idp`
  return `${_getRawBasePath()}${path}`
}

/**
 * GET /api/v1/saas/auth/sso/{login_identifier}
 * Operation: `organization-sso-init`
 * Starts SSO authentication with the configured organization IdP with the given login identifier.
 * @param {string} params.loginIdentifier The ID that identifies which organization to SSO with.
 * @param {string} params.redirectUri The URI to redirect to after authentication.
 * @param {string} params.state A value sent by the client that will be returned in the token.
 * @see 302 Redirection to the organization IdP for authentication
 * @see 404 Invalid login identifier. (code: `auth.no_org_for_login_id`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function organizationSsoInitUrl(params: OrganizationSsoInitUrlParams): string {
  const queryString = _buildQueryString({
    redirect_uri: params.redirectUri,
    state: params.state,
  })
  const path = _encode`/saas/auth/sso/${params.loginIdentifier}`
  return `${_getRawBasePath()}${path}${queryString}`
}

export interface OrganizationSsoInitUrlParams {
  loginIdentifier: string
  redirectUri: string
  state: string
}

/**
 * GET /api/v1/users/auth
 * Operation: `get-authentication-info`
 * Provides authentication information about a user, including elevated permission status and TOTP device availability.
 * @see 200 User authentication information response. See {@link AuthenticationInfo}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getAuthenticationInfoUrl(): string {
  const path = `/users/auth`
  return `${_getRawBasePath()}${path}`
}

/**
 * POST /api/v1/regions/{region_id}/users/auth/_login
 * Operation: `login`
 * Authenticates against available users.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see Accepts a request payload of type {@link LoginRequest}
 * @see 200 Login successful, returns the token in the body (if 'login_state.path' not specified). See {@link TokenResponse}
 * @see 302 Redirects to '/sso/token#BEARER_TOKEN?state=LOGIN_STATE' with the fragment containing a bearer token  (if 'login_state.path' is specified)
 * @see 401 The supplied authentication is invalid. (code: `root.unauthenticated`). See {@link BasicFailedReply}
 * @see 501 The administrator needs to configure the authentication cluster. (code: `authc.no_authentication_cluster`). See {@link BasicFailedReply}
 * @see 502 The authentication cluster failed to process the request. The response body contains details about the error. (code: `authc.authentication_cluster_error`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function loginUrl(params: LoginUrlParams): string {
  const path = _encode`/${params.regionId}/users/auth/_login`
  return `${_getBasePath()}${path}`
}

export interface LoginUrlParams {
  regionId: string
}

/**
 * POST /api/v1/users/auth/_logout
 * Operation: `logout`
 * Destroys the current session.
 * @see 200 The current session was successfully destroyed.
 * @see 501 The administrator needs to configure the authentication cluster. (code: `authc.no_authentication_cluster`). See {@link BasicFailedReply}
 * @see 502 The authentication cluster failed to process the request. The response body contains details about the error. (code: `authc.authentication_cluster_error`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function logoutUrl(): string {
  const path = `/users/auth/_logout`
  return `${_getRawBasePath()}${path}`
}

/**
 * POST /api/v1/users/auth/_refresh
 * Operation: `refresh-token`
 * Issues a new authentication token.
 * @see 200 The token refreshed successfully and was returned in the body of the response. See {@link TokenResponse}
 * @see 401 The authentication token is invalid or expired. (code: `root.unauthorized`). See {@link BasicFailedReply}
 * @see 501 The administrator needs to configure the authentication cluster. (code: `authc.no_authentication_cluster`). See {@link BasicFailedReply}
 * @see 502 The authentication cluster failed to process the request. The response body contains details about the error. (code: `authc.authentication_cluster_error`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function refreshTokenUrl(): string {
  const path = `/users/auth/_refresh`
  return `${_getRawBasePath()}${path}`
}

/**
 * GET /api/v1/users/auth/keys
 * Operation: `get-api-keys`
 * Retrieves the metadata for all of the API keys that the user generated.
 * @see 200 The metadata for the API keys is retrieved. See {@link ApiKeysResponse}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getApiKeysUrl(): string {
  const path = `/users/auth/keys`
  return `${_getRawBasePath()}${path}`
}

/**
 * POST /api/v1/users/auth/keys
 * Operation: `create-api-key`
 * Creates a new API key.
 * @see Accepts a request payload of type {@link CreateApiKeyRequest}
 * @see 201 The API key is created and returned in the body of the response. See {@link ApiKeyResponse}
 * @see 400 The request is invalid. Specify a different request, then try again. (code: `api_keys.invalid_input`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function createApiKeyUrl(): string {
  const path = `/users/auth/keys`
  return `${_getRawBasePath()}${path}`
}

/**
 * DELETE /api/v1/users/auth/keys
 * Operation: `delete-api-keys`
 * Delete or invalidate API keys.
 * @see Accepts a request payload of type {@link DeleteApiKeysRequest}
 * @see 200 The API keys are deleted.
 * @return {string} A fully-interpolated and escaped URL
 */
export function deleteApiKeysUrl(): string {
  const path = `/users/auth/keys`
  return `${_getRawBasePath()}${path}`
}

/**
 * GET /api/v1/users/auth/keys/_all
 * Operation: `get-users-api-keys`
 * Retrieves the metadata for all of the API keys for all users.
 * @see 200 The metadata for the API keys is retrieved. See {@link ApiKeysResponse}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getUsersApiKeysUrl(): string {
  const path = `/users/auth/keys/_all`
  return `${_getRawBasePath()}${path}`
}

/**
 * DELETE /api/v1/users/auth/keys/_all
 * Operation: `delete-users-api-keys`
 * Delete or invalidate the API keys for multiple users.
 * @see Accepts a request payload of type {@link DeleteUsersApiKeysRequest}
 * @see 200 The API keys are deleted.
 * @return {string} A fully-interpolated and escaped URL
 */
export function deleteUsersApiKeysUrl(): string {
  const path = `/users/auth/keys/_all`
  return `${_getRawBasePath()}${path}`
}

/**
 * GET /api/v1/regions/{region_id}/users/auth/keys/{api_key_id}
 * Operation: `get-api-key`
 * Retrieves the metadata for an API key.
 * @param {string} params.apiKeyId The API Key ID.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 The API key metadata is retrieved. See {@link ApiKeyResponse}
 * @see 404 The {api_key_id} can't be found. (code: `api_keys.key_not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getApiKeyUrl(params: GetApiKeyUrlParams): string {
  const path = _encode`/${params.regionId}/users/auth/keys/${params.apiKeyId}`
  return `${_getBasePath()}${path}`
}

export interface GetApiKeyUrlParams {
  apiKeyId: string
  regionId: string
}

/**
 * DELETE /api/v1/users/auth/keys/{api_key_id}
 * Operation: `delete-api-key`
 * Delete or invalidate the API key.
 * @param {string} params.apiKeyId The API Key ID.
 * @see 200 The API key is deleted.
 * @see 404 The {api_key_id} can't be found. (code: `api_keys.key_not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function deleteApiKeyUrl(params: DeleteApiKeyUrlParams): string {
  const path = _encode`/users/auth/keys/${params.apiKeyId}`
  return `${_getRawBasePath()}${path}`
}

export interface DeleteApiKeyUrlParams {
  apiKeyId: string
}

/**
 * GET /api/v1/users/auth/methods
 * Operation: `methods`
 * Provides information about available authentication methods.
 * @see 200 Available authentication methods response. See {@link AvailableAuthenticationMethods}
 * @return {string} A fully-interpolated and escaped URL
 */
export function methodsUrl(): string {
  const path = `/users/auth/methods`
  return `${_getRawBasePath()}${path}`
}

/**
 * POST /api/v1/regions/{region_id}/users/auth/saml/_callback
 * Operation: `saml-callback`
 * Accepts a callback request from an identity provider and authenticates the user.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see Accepts a request payload of type {@link string}
 * @see Accepts an optional request payload of type {@link string}
 * @see 302 Redirects to the UI endpoint with an authorization token in the fragment and the relay state, if it was specified during the initialization.
 * @see 401 The supplied SAML response is invalid. (code: `root.unauthenticated`). See {@link BasicFailedReply}
 * @see 501 The administrator needs to configure the authentication cluster. (code: `authc.no_authentication_cluster`). See {@link BasicFailedReply}
 * @see 502 The authentication cluster failed to process the request. The response body contains details about the error. (code: `authc.authentication_cluster_error`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function samlCallbackUrl(params: SamlCallbackUrlParams): string {
  const path = _encode`/${params.regionId}/users/auth/saml/_callback`
  return `${_getBasePath()}${path}`
}

export interface SamlCallbackUrlParams {
  regionId: string
}

/**
 * GET /api/v1/regions/{region_id}/users/auth/saml/_init
 * Operation: `saml-init`
 * Calls the authentication cluster to initiate SAML Single Sign-on (Web Browser SSO profile) protocol and redirects the user to the identity provider for authentication. The authentication cluster must be configured prior to initiation.
 * @param {string} params.state An optional relay state that is sent back to the client after the user is authenticated.
 * @param {string} params.realm An optional SAML realm to use for authentication.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 302 Redirects the client to the identity provider with a SAML authentication request
 * @see 501 The administrator needs to configure the authentication cluster. (code: `authc.no_authentication_cluster`). See {@link BasicFailedReply}
 * @see 502 The authentication cluster failed to process the request. The response body contains details about the error. (code: `authc.authentication_cluster_error`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function samlInitUrl(params: SamlInitUrlParams): string {
  const queryString = _buildQueryString({
    state: params.state,
    realm: params.realm,
  })
  const path = _encode`/${params.regionId}/users/auth/saml/_init`
  return `${_getBasePath()}${path}${queryString}`
}

export interface SamlInitUrlParams {
  state?: string | null
  realm?: string | null
  regionId: string
}

/**
 * GET /api/v1/regions/{region_id}/users/{user_id}/auth/keys
 * Operation: `get-user-api-keys`
 * Retrieves metadata for all API keys created by the given user.
 * @param {string} params.userId The user ID.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 The API key metadata is retrieved. See {@link ApiKeysResponse}
 * @see 404 The {user_id} can't be found. (code: `api_keys.user_not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getUserApiKeysUrl(params: GetUserApiKeysUrlParams): string {
  const path = _encode`/${params.regionId}/users/${params.userId}/auth/keys`
  return `${_getBasePath()}${path}`
}

export interface GetUserApiKeysUrlParams {
  userId: string
  regionId: string
}

/**
 * DELETE /api/v1/regions/{region_id}/users/{user_id}/auth/keys
 * Operation: `delete-user-api-keys`
 * Delete or invalidate all of the API keys for a user.
 * @param {string} params.userId The user ID.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 The API key is deleted.
 * @see 404 The {user_id} can't be found. (code: `api_keys.user_not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function deleteUserApiKeysUrl(params: DeleteUserApiKeysUrlParams): string {
  const path = _encode`/${params.regionId}/users/${params.userId}/auth/keys`
  return `${_getBasePath()}${path}`
}

export interface DeleteUserApiKeysUrlParams {
  userId: string
  regionId: string
}

/**
 * GET /api/v1/regions/{region_id}/users/{user_id}/auth/keys/{api_key_id}
 * Operation: `get-user-api-key`
 * Retrieves the API key metadata for a user.
 * @param {string} params.userId The user ID.
 * @param {string} params.apiKeyId The API Key ID.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 The API key metadata is retrieved. See {@link ApiKeyResponse}
 * @see 404 The {api_key_id} can't be found. (code: `api_keys.key_not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getUserApiKeyUrl(params: GetUserApiKeyUrlParams): string {
  const path = _encode`/${params.regionId}/users/${params.userId}/auth/keys/${params.apiKeyId}`
  return `${_getBasePath()}${path}`
}

export interface GetUserApiKeyUrlParams {
  userId: string
  apiKeyId: string
  regionId: string
}

/**
 * DELETE /api/v1/regions/{region_id}/users/{user_id}/auth/keys/{api_key_id}
 * Operation: `delete-user-api-key`
 * Delete or invalidate an API key for a user.
 * @param {string} params.userId The user ID.
 * @param {string} params.apiKeyId The API Key ID.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 The API key is deleted.
 * @see 404 The {api_key_id} can't be found. (code: `api_keys.key_not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function deleteUserApiKeyUrl(params: DeleteUserApiKeyUrlParams): string {
  const path = _encode`/${params.regionId}/users/${params.userId}/auth/keys/${params.apiKeyId}`
  return `${_getBasePath()}${path}`
}

export interface DeleteUserApiKeyUrlParams {
  userId: string
  apiKeyId: string
  regionId: string
}
