/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/*!
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@                                         @@@@
 *!   @@@   THIS FILE WAS AUTOGENERATED BY TOOL   @@@@
 *!   @@@   CHANGES MUST TARGET SCRIPTS/SWAGGER   @@@@
 *!   @@@                                         @@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!
 */

import {
  _buildQueryString,
  _emptyParams,
  _encode,
  _getBasePath,
  _getRawBasePath,
} from './urls-utils'

/**
 * GET /api/v1/saas/user
 * Operation: `get-saas-current-user`
 * Returns the current user.
 * @param {boolean} params.showHiddenRoleAssignments If true, hidden role assignments are returned.
 * @param {boolean} params.showRoleAssignments If true, role assignments are returned.
 * @see 200 User successfully fetched. See {@link SaasUserResponse}
 * @see 401 Invalid user authentication. See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getSaasCurrentUserUrl(params: GetSaasCurrentUserUrlParams = _emptyParams): string {
  const queryString = _buildQueryString({
    show_hidden_role_assignments:
      params.showHiddenRoleAssignments === undefined ? false : params.showHiddenRoleAssignments,
    show_role_assignments:
      params.showRoleAssignments === undefined ? false : params.showRoleAssignments,
  })
  const path = `/saas/user`
  return `${_getRawBasePath()}${path}${queryString}`
}

export interface GetSaasCurrentUserUrlParams {
  showHiddenRoleAssignments?: boolean | null
  showRoleAssignments?: boolean | null
}

/**
 * POST /api/v1/saas/user
 * Operation: `create-saas-user`
 * Create SaaS user.
 * @param {boolean} params.activate Activate the new user if true*, only Stage if false.
 * @see Accepts a request payload of type {@link CreateSaasUserRequest}
 * @see 201 User successfully created. See {@link SaasUserResponse}
 * @see 401 Invalid user authentication. See {@link BasicFailedReply}
 * @see 409 User already exists. See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function createSaasUserUrl(params: CreateSaasUserUrlParams = _emptyParams): string {
  const queryString = _buildQueryString({
    activate: params.activate,
  })
  const path = `/saas/user`
  return `${_getRawBasePath()}${path}${queryString}`
}

export interface CreateSaasUserUrlParams {
  activate?: boolean | null
}

/**
 * POST /api/v1/saas/user/_email
 * Operation: `set-saas-current-user-email`
 * Updates the user's email address.
 * @see Accepts a request payload of type {@link SaasUpdateEmailRequestData}
 * @see 200 User successfully updated. See {@link SaasUserResponse}
 * @see 401 Invalid user authentication. See {@link BasicFailedReply}
 * @see 403 Access is denied. See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function setSaasCurrentUserEmailUrl(): string {
  const path = `/saas/user/_email`
  return `${_getRawBasePath()}${path}`
}

/**
 * POST /api/v1/saas/user/_password
 * Operation: `set-saas-current-user-password`
 * Updates the user's password.
 * @see Accepts a request payload of type {@link SaasUpdatePasswordRequestData}
 * @see 200 User successfully updated. See {@link SaasUserResponse}
 * @see 401 Invalid user authentication. See {@link BasicFailedReply}
 * @see 403 Access is denied. See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function setSaasCurrentUserPasswordUrl(): string {
  const path = `/saas/user/_password`
  return `${_getRawBasePath()}${path}`
}

/**
 * POST /api/v1/saas/user/_password_hash
 * Operation: `set-saas-current-user-password-hash`
 * Updates the user's password via password reset hash.
 * @see Accepts a request payload of type {@link SaasUpdatePasswordWithHashRequestData}
 * @see 200 User successfully updated. See {@link SaasUserResponse}
 * @see 401 Invalid user authentication. See {@link BasicFailedReply}
 * @see 403 Access is denied. See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function setSaasCurrentUserPasswordHashUrl(): string {
  const path = `/saas/user/_password_hash`
  return `${_getRawBasePath()}${path}`
}

/**
 * POST /api/v1/saas/user/_resend_verification
 * Operation: `resend-saas-verification-email`
 * Resends the email for the user to verify their email address.
 * @see Accepts a request payload of type {@link SaasResendVerificationRequest}
 * @see 200 Verification email resent
 * @see 400 Cannot fulfil request. See {@link BasicFailedReply}
 * @see 429 Rate limit exceeded. See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function resendSaasVerificationEmailUrl(): string {
  const path = `/saas/user/_resend_verification`
  return `${_getRawBasePath()}${path}`
}

/**
 * POST /api/v1/saas/user/_reset-password
 * Operation: `reset-saas-user-password`
 * Requests a password reset. A confirmation email is sent to existing users.
 * @see Accepts a request payload of type {@link SaasResetPasswordRequest}
 * @see 200 Password reset procedure initiated
 * @return {string} A fully-interpolated and escaped URL
 */
export function resetSaasUserPasswordUrl(): string {
  const path = `/saas/user/_reset-password`
  return `${_getRawBasePath()}${path}`
}

/**
 * POST /api/v1/saas/user/_subscription_level
 * Operation: `subscription-level`
 * Update subscription level request.
 * @see Accepts a request payload of type {@link SaasUpdateSubscriptionLevelRequestData}
 * @see 200 Update subscription level request was submitted successfully
 * @see 401 Invalid user authentication. See {@link BasicFailedReply}
 * @see 402 The request execution failed (code: 'user.update_user_level_denied'). See {@link BasicFailedReply}
 * @see 403 Access is denied. See {@link BasicFailedReply}
 * @see 500 The request execution failed (code: 'event.event_not_found'). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function subscriptionLevelUrl(): string {
  const path = `/saas/user/_subscription_level`
  return `${_getRawBasePath()}${path}`
}

/**
 * POST /api/v1/saas/user/_verify
 * Operation: `verify-saas-user-email`
 * Verifies the user's email address and activates those users with pending activation.
 * @see Accepts a request payload of type {@link SaasVerifyUserEmailRequest}
 * @see 200 User's email was verified. See {@link SaasUserResponse}
 * @see 401 Invalid credentials hash. See {@link BasicFailedReply}
 * @see 403 Expired or previously used credentials token. See {@link BasicFailedReply}
 * @see 404 User not found. See {@link BasicFailedReply}
 * @see 409 User has already verified their email. See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function verifySaasUserEmailUrl(): string {
  const path = `/saas/user/_verify`
  return `${_getRawBasePath()}${path}`
}

/**
 * GET /api/v1/saas/user/applications
 * Operation: `get-saas-current-user-applications`
 * Retrieves the list of Applications the user can access.
 * @see 200 The user applications have been returned. See {@link SaasUserApplicationsResponse}
 * @see 401 Invalid user authentication. See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getSaasCurrentUserApplicationsUrl(): string {
  const path = `/saas/user/applications`
  return `${_getRawBasePath()}${path}`
}

/**
 * PUT /api/v1/saas/user/discovery_questions
 * Operation: `put-saas-current-user-discovery-questions`
 * Updates discovery questions answers for the current user.
 * @see Accepts a request payload of type {@link SaasUserUpdateDiscoveryQuestions}
 * @see 200 User successfully updated. See {@link SaasUserResponse}
 * @see 401 Invalid user authentication. See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function putSaasCurrentUserDiscoveryQuestionsUrl(): string {
  const path = `/saas/user/discovery_questions`
  return `${_getRawBasePath()}${path}`
}

/**
 * POST /api/v1/saas/user/feedback
 * Operation: `submit-feedback`
 * Submit feedback.
 * @see Accepts a request payload of type {@link SaasUserFeedback}
 * @see 200 Feedback was submitted successfully
 * @see 401 Invalid user authentication. See {@link BasicFailedReply}
 * @see 403 Access is denied. See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function submitFeedbackUrl(): string {
  const path = `/saas/user/feedback`
  return `${_getRawBasePath()}${path}`
}

/**
 * POST /api/v1/saas/user/mfa/_reset
 * Operation: `reset-saas-current-user-mfa`
 * Resets MFA for the current user.
 * @see 200 The MFA was successfully reseted
 * @see 401 Invalid user authentication. See {@link BasicFailedReply}
 * @see 403 Access is denied. See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function resetSaasCurrentUserMfaUrl(): string {
  const path = `/saas/user/mfa/_reset`
  return `${_getRawBasePath()}${path}`
}

/**
 * PUT /api/v1/saas/user/profile
 * Operation: `put-saas-current-user`
 * Updates profile information for the current user.
 * @see Accepts a request payload of type {@link SaasUpdateProfileRequestData}
 * @see 200 User successfully updated. See {@link SaasUserResponse}
 * @see 400 Invalid Request. See {@link BasicFailedReply}
 * @see 401 Invalid user authentication. See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function putSaasCurrentUserUrl(): string {
  const path = `/saas/user/profile`
  return `${_getRawBasePath()}${path}`
}

/**
 * POST /api/v1/saas/user/trials/_extend
 * Operation: `extend-trial`
 * Request a one-time trial extension.
 * @see Accepts a request payload of type {@link SaasExtendTrialRequestData}
 * @see 200 Trial was extended successfully
 * @see 400 Trial extension request was not valid. See {@link BasicFailedReply}
 * @see 401 Invalid user authentication. See {@link BasicFailedReply}
 * @see 403 Access is denied. See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function extendTrialUrl(): string {
  const path = `/saas/user/trials/_extend`
  return `${_getRawBasePath()}${path}`
}
