/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/*!
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@                                         @@@@
 *!   @@@   THIS FILE WAS AUTOGENERATED BY TOOL   @@@@
 *!   @@@   CHANGES MUST TARGET SCRIPTS/SWAGGER   @@@@
 *!   @@@                                         @@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!
 */

import {
  _buildQueryString,
  _emptyParams,
  _encode,
  _getBasePath,
  _getRawBasePath,
} from './urls-utils'

/**
 * POST /api/v1/users/{user_id}/role_assignments
 * Operation: `add-role-assignments`
 * Adds a set of RoleAssignments to the specified User. Currently unavailable in self-hosted ECE.
 * @param {string} params.userId Identifier for the user; include realm name and id if required.
 * @see Accepts a request payload of type {@link RoleAssignments}
 * @see 200 Role Assignments were successfully added to the target User
 * @see 400 The target user Id is invalid. (code: `role_assignments.invalid_target_user_id`). See {@link BasicFailedReply}
 * @see 401 Credentials were invalid. (code: `root.unauthorized`). See {@link BasicFailedReply}
 * @see 403 You are not authorised to add the specified RoleAssignments. (code: `role_assignments.unauthorized_role_assignments`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function addRoleAssignmentsUrl(params: AddRoleAssignmentsUrlParams): string {
  const path = _encode`/users/${params.userId}/role_assignments`
  return `${_getRawBasePath()}${path}`
}

export interface AddRoleAssignmentsUrlParams {
  userId: string
}

/**
 * DELETE /api/v1/users/{user_id}/role_assignments
 * Operation: `remove-role-assignments`
 * Removes a set of RoleAssignments from the specified User. Currently unavailable in self-hosted ECE.
 * @param {string} params.userId Identifier for the user; include realm name and id if required.
 * @param {boolean} params.force Whether or not to force the removal of role assignments (effective only for Platform Admins).
 * @see Accepts a request payload of type {@link RoleAssignments}
 * @see 200 Role Assignments were successfully removed from the target User
 * @see 400 The target user Id is invalid. (code: `role_assignments.invalid_target_user_id`). See {@link BasicFailedReply}
 * @see 401 Credentials were invalid. (code: `root.unauthorized`). See {@link BasicFailedReply}
 * @see 403 You are not authorised to remove the specified RoleAssignments. (code: `role_assignments.unauthorized_role_assignments`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function removeRoleAssignmentsUrl(params: RemoveRoleAssignmentsUrlParams): string {
  const queryString = _buildQueryString({
    force: params.force === undefined ? false : params.force,
  })
  const path = _encode`/users/${params.userId}/role_assignments`
  return `${_getRawBasePath()}${path}${queryString}`
}

export interface RemoveRoleAssignmentsUrlParams {
  userId: string
  force?: boolean | null
}
