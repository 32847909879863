/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiCallOut, EuiSpacer } from '@elastic/eui'

import type { AsyncRequestState, StackDeployment } from '@modules/ui-types'
import type { DeploymentTemplateInfoV2, DeploymentUpdateRequest } from '@modules/cloud-api/v1/types'
import { CuiAlert } from '@modules/cui/Alert'
import { useLocalStorage } from '@modules/utils/hooks/useLocalStorage'
import LocalStorageKey from '@modules/utils/localStorageKeys'

import DocLink from '@/components/DocLink'
import { isSSOPortOverridePresent } from '@/lib/stackDeployments/selectors/metadata'
import {
  getFirstEsClusterFromGet,
  getFirstSliderClusterFromGet,
  getPlanInfo,
} from '@/lib/stackDeployments/selectors/fundamentals'
import {
  sanitizeUpdateRequestBeforeSend,
  createUpdateRequestFromGetResponse,
} from '@/lib/stackDeployments/updates'
import DangerButton from '@/components/DangerButton'
import { getUserSettingsFromConfiguration } from '@/lib/stackDeployments/selectors/configuration'

import type { FunctionComponent } from 'react'

export type StateProps = {
  deploymentTemplate?: DeploymentTemplateInfoV2
  updateDeploymentRequest: AsyncRequestState
}

export type DispatchProps = {
  updateDeployment: (args: {
    deploymentId: string
    deployment: DeploymentUpdateRequest
    redirect?: boolean
    dryRun?: boolean
  }) => Promise<unknown>
}

export type ConsumerProps = {
  deployment: StackDeployment
  isDismissable: boolean
}

type AllProps = StateProps & DispatchProps & ConsumerProps

const SSOPortOverrideNotification: FunctionComponent<AllProps> = ({
  deployment,
  isDismissable,
  deploymentTemplate,
  updateDeploymentRequest,
  updateDeployment,
}) => {
  const showSSOPortOverride = isSSOPortOverridePresent({ deployment })

  const [dismissed, setDismissed] = useLocalStorage(
    `${LocalStorageKey.ssoPortOverrideNotification}_${deployment.id}`,
    false,
  )

  if (!showSSOPortOverride) {
    return null
  }

  const onDismissMigrateSSOPort = () => {
    setDismissed(true)
  }

  if (isDismissable && dismissed) {
    return null
  }

  if (hasPendingPlan()) {
    return null
  }

  const hasPort9243 = isPort9243PresentInYaml(deployment)

  if (isDismissable) {
    // on the overview page we want a smaller callout
    return (
      <EuiCallOut
        onDismiss={() => onDismissMigrateSSOPort()}
        size='s'
        title={
          <Fragment>
            <FormattedMessage
              id='sso-port-override.title-small'
              defaultMessage='Kibana is using port 9243, if you want to move to port 443, you can do so. We will be moving all instances to port 443 in the future. {learnMore}'
              values={{
                learnMore: (
                  <DocLink link='accessKibana' favorSaasContext={true} showExternalLinkIcon={true}>
                    <FormattedMessage id='slider-description.link' defaultMessage='Learn more' />
                  </DocLink>
                ),
              }}
            />
            <EuiSpacer size='s' />
            <DangerButton
              color='primary'
              fill={false}
              size='s'
              onConfirm={() => migrateToPort443()}
              isBusy={updateDeploymentRequest.inProgress}
              modal={{
                title: (
                  <FormattedMessage
                    id='sso-port-override.confirm-title'
                    defaultMessage='Migrate to port 443'
                  />
                ),
                body: (
                  <Fragment>
                    {!hasPort9243 && (
                      <CuiAlert type='warning'>
                        <FormattedMessage
                          id='sso-port-override.confirm-alert'
                          defaultMessage="You should review any custom settings you have added to ensure they don't depend on the existing configuration."
                        />
                      </CuiAlert>
                    )}
                    {hasPort9243 && (
                      <CuiAlert type='danger'>
                        <FormattedMessage
                          id='sso-port-override.confirm-alert-detected'
                          defaultMessage="We've detected that your deployment may have some settings associated with port 9243. Review your settings, make any necessary changes and then run this migration."
                        />
                      </CuiAlert>
                    )}
                    <EuiSpacer />
                    <FormattedMessage
                      id='sso-port-override.confirm-explanation'
                      defaultMessage='This action requires a configuration change that might take a few minutes to apply.'
                    />
                  </Fragment>
                ),
              }}
            >
              <FormattedMessage
                id='sso-port-override.migrate'
                defaultMessage='Migrate to port 443'
              />
            </DangerButton>
          </Fragment>
        }
      >
        {updateDeploymentRequest.error && (
          <Fragment>
            <EuiSpacer size='s' />
            <CuiAlert type='error'>{updateDeploymentRequest.error}</CuiAlert>
          </Fragment>
        )}
      </EuiCallOut>
    )
  }

  return (
    <Fragment>
      <EuiCallOut
        title={
          <FormattedMessage
            id='sso-port-override.title'
            defaultMessage='Kibana is using port 9243'
          />
        }
      >
        <p>
          <FormattedMessage
            id='sso-port-override.message'
            defaultMessage='If you want to move to port 443, you can do so. We will be moving all instances to port 443 in the future. {learnMore}'
            values={{
              learnMore: (
                <DocLink link='accessKibana' favorSaasContext={true}>
                  <FormattedMessage id='slider-description.link' defaultMessage='Learn more' />.
                </DocLink>
              ),
            }}
          />
        </p>
        <DangerButton
          color='primary'
          fill={true}
          onConfirm={() => migrateToPort443()}
          isBusy={updateDeploymentRequest.inProgress}
          modal={{
            title: (
              <FormattedMessage
                id='sso-port-override.confirm-title'
                defaultMessage='Migrate to port 443'
              />
            ),
            body: (
              <FormattedMessage
                id='sso-port-override.confirm-explanation'
                defaultMessage='This action requires a configuration change that might take a few minutes to apply.'
              />
            ),
          }}
        >
          <FormattedMessage id='sso-port-override.migrate' defaultMessage='Migrate to port 443' />
        </DangerButton>
        {updateDeploymentRequest.error && (
          <Fragment>
            <EuiSpacer size='s' />
            <CuiAlert type='error'>{updateDeploymentRequest.error}</CuiAlert>
          </Fragment>
        )}
      </EuiCallOut>
      <EuiSpacer />
    </Fragment>
  )

  function hasPendingPlan(): boolean {
    const resource = getFirstEsClusterFromGet({ deployment })
    return Boolean(!resource || getPlanInfo({ resource, state: 'pending' }))
  }

  function migrateToPort443() {
    const updateDeploymentObj: DeploymentUpdateRequest = createUpdateRequestFromGetResponse({
      deployment,
      deploymentTemplate,
    })

    if (updateDeploymentObj.resources?.elasticsearch?.[0]) {
      if (updateDeploymentObj.resources.elasticsearch[0].plan.identity) {
        updateDeploymentObj.resources.elasticsearch[0].plan.identity.sso_port_override = 443
      } else {
        updateDeploymentObj.resources.elasticsearch[0].plan.identity = {
          sso_port_override: 443,
        }
      }

      updateDeployment({
        deploymentId: deployment.id,
        deployment: sanitizeUpdateRequestBeforeSend({ deployment: updateDeploymentObj }),
        redirect: true,
        dryRun: false,
      })
    }
  }
}

export function isPort9243PresentInYaml(deployment: StackDeployment): boolean {
  const elasticsearch = getFirstEsClusterFromGet({ deployment })
  const kibana = getFirstSliderClusterFromGet({
    deployment,
    sliderInstanceType: `kibana`,
  })

  if (
    elasticsearch &&
    elasticsearch.info.plan_info.current &&
    elasticsearch.info.plan_info.current.plan
  ) {
    const esUserSettings = getUserSettingsFromConfiguration(
      elasticsearch.info.plan_info.current.plan.elasticsearch,
    )

    if (esUserSettings && esUserSettings.includes(`9243`)) {
      return true
    }
  }

  if (kibana && kibana.info.plan_info.current && kibana.info.plan_info.current.plan) {
    const kibanaUserSettings = getUserSettingsFromConfiguration(
      kibana.info.plan_info.current.plan.kibana,
    )

    if (kibanaUserSettings && kibanaUserSettings.includes(`9243`)) {
      return true
    }
  }

  return false
}

export default SSOPortOverrideNotification
