/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/*!
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@                                         @@@@
 *!   @@@   THIS FILE WAS AUTOGENERATED BY TOOL   @@@@
 *!   @@@   CHANGES MUST TARGET SCRIPTS/SWAGGER   @@@@
 *!   @@@                                         @@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!
 */

import {
  _buildQueryString,
  _emptyParams,
  _encode,
  _getBasePath,
  _getRawBasePath,
} from './urls-utils'

/**
 * GET /api/v1/saas/tiles/event
 * Operation: `get-saas-tiles-event`
 * Retrieves Elastic events.
 * @param {integer} params.count How many events should be returned. Default: 2
 * @see 200 The Elastic events have been returned. See {@link Array<Event>}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getSaasTilesEventUrl(params: GetSaasTilesEventUrlParams = _emptyParams): string {
  const queryString = _buildQueryString({
    count: params.count === undefined ? 2 : params.count,
  })
  const path = `/saas/tiles/event`
  return `${_getRawBasePath()}${path}${queryString}`
}

export interface GetSaasTilesEventUrlParams {
  count?: number | null
}
