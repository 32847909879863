/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiLoadingSpinner } from '@elastic/eui'

import { useLogout } from '@/hooks'

import LandingPage from '../LandingPage'
import LandingPageContainer from '../LandingPageContainer'
import { getConfigForKey } from '../../store'
import lock from '../../files/cloud-lock-white.svg'
import lockDark from '../../files/cloud-lock-dark.svg'

import type { ReactElement } from 'react'
import type { AllProps as Props } from './types'

const Logout = (props: Props): ReactElement => {
  const { isUnauthorised } = props
  const { logout } = useLogout()

  useEffect(() => {
    // When a 401 happens and user is redirected to the /logout page,
    // we MAY or NOT want to sign out from Okta, it depends if they used to have
    // a valid session before and it's the first time it's being invalidated.
    // On the other hand, when /logout is explicitly called by the user,
    // we DO want to sign out from Okta.
    logout()
  }, [])

  const isEssUserconsole =
    getConfigForKey('APP_PLATFORM') === 'saas' && getConfigForKey('APP_NAME') === 'userconsole'

  const renderLogoutMessage = (): ReactElement => {
    if (isUnauthorised) {
      return (
        <FormattedMessage
          id='logout.processing-for-unauthorised.redirecting'
          defaultMessage='Redirecting to login page...'
        />
      )
    }

    return (
      <div>
        <FormattedMessage id='logout.processing' defaultMessage='Logging out' />
        <EuiLoadingSpinner size='m' style={{ marginLeft: '1rem' }} />
      </div>
    )
  }

  const renderIsUnauthorisedMessage = (): ReactElement | null => {
    if (!isUnauthorised) {
      return null
    }

    return (
      <FormattedMessage
        id='logout.processing-for-unauthorised'
        defaultMessage='You are not logged in or your session has expired.'
      />
    )
  }

  if (isEssUserconsole) {
    return (
      <LandingPageContainer
        image={lock}
        darkImage={lockDark}
        title={renderLogoutMessage()}
        subtitle={renderIsUnauthorisedMessage()}
        panelProps={{
          className: 'logout-content-panel',
          hasShadow: false,
          hasBorder: false,
          style: { background: 'transparent' },
        }}
      />
    )
  }

  return (
    <LandingPage loading={true}>
      <div data-test-id='logout-page'>{renderLogoutMessage()}</div>
    </LandingPage>
  )
}

export default Logout
