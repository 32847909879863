/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { jsx } from '@emotion/react'

import type { WithEuiThemeProps } from '@elastic/eui'
import { EuiLink, withEuiTheme, EuiCallOut, EuiText, EuiSpacer } from '@elastic/eui'

import { isElasticStaff } from '@modules/billing-lib/utils'

import SelectSubscriptionModal from '../../Billing/SelectSubscription'
import ReviewSubscriptionChangesModal from '../../Billing/ReviewSubscriptionChangesModal'
import { isUsageOutOfCompliance } from '../../../../../lib/usage'

import ReviewChangesLink from './ReviewChangesLink'

import type { Props } from './types'
import type { ReactElement } from 'react'

type State = {
  showChangeSubscriptionModal: boolean
  showReviewChangesModal: boolean
  upgradeLevel: string | undefined
}

class UsageNotice extends Component<Props & WithEuiThemeProps, State> {
  state = {
    showChangeSubscriptionModal: false,
    showReviewChangesModal: false,
    upgradeLevel: undefined,
  }

  componentDidMount(): void {
    const {
      usageDetails,
      fetchUsageDetails,
      accountDetails: { organization_id: organizationId },
    } = this.props

    if (!usageDetails && organizationId) {
      fetchUsageDetails({ organizationId })
    }
  }

  render(): ReactElement | null {
    const { usageDetails, overrideUsageLevel } = this.props
    const { upgradeLevel } = this.state

    if (!usageDetails) {
      return null
    }

    const { usage_level } = usageDetails
    const showUsageBanner = this.getShowUsageBanner()

    if (showUsageBanner) {
      return (
        <Fragment>
          <EuiCallOut data-test-id='usage-notice'>
            <EuiText textAlign='center' size='s'>
              {this.renderMessage()}
            </EuiText>
          </EuiCallOut>
          <EuiSpacer size='xs' />
          {this.state.showChangeSubscriptionModal && (
            <SelectSubscriptionModal
              overrideUsageLevel={overrideUsageLevel}
              upgradeLevel={upgradeLevel}
              closeModal={() =>
                this.setState({ showChangeSubscriptionModal: false, upgradeLevel: undefined })
              }
              onSeeReviewChanges={() =>
                this.setState({ showChangeSubscriptionModal: false, showReviewChangesModal: true })
              }
            />
          )}
          {this.state.showReviewChangesModal && (
            <ReviewSubscriptionChangesModal
              closeModal={() => this.setState({ showReviewChangesModal: false })}
              onUpgrade={() => this.onUpgrade(usage_level)}
            />
          )}
        </Fragment>
      )
    }

    return null
  }

  renderMessage(): ReactElement {
    const {
      accountDetails: { contract_type },
      featureName,
      overrideUsageLevel,
    } = this.props

    const getChangeSubscriptionActionMessage = () => {
      if (contract_type === `annual`) {
        return overrideUsageLevel ? (
          <FormattedMessage
            data-test-id='annual-usage-notice-override-usage-level'
            id='annual-usage-notice.override-usage-level.message'
            defaultMessage={`Contact your sales representative to upgrade your subscription.`}
          />
        ) : (
          <FormattedMessage
            data-test-id='annual-usage-notice'
            id='annual-usage-notice.message'
            defaultMessage={`Contact your sales representative to upgrade your subscription or {removeFeatures}.`}
            values={{
              removeFeatures: (
                <ReviewChangesLink onReviewChanges={() => this.onReviewChanges()} isAnnual={true} />
              ),
            }}
          />
        )
      }

      const ChangeSubscriptionLink = () => (
        <EuiLink onClick={() => this.onChangeSubscription()}>
          <FormattedMessage
            id='monthly-usage-notice.message.change-subscription'
            defaultMessage='Change your subscription'
          />
        </EuiLink>
      )

      return overrideUsageLevel ? (
        <FormattedMessage
          data-test-id='monthly-usage-notice-override-usage-level'
          id='monthly-usage-notice.override-usage-level.message'
          defaultMessage={`{changeSubscription}.`}
          values={{
            changeSubscription: <ChangeSubscriptionLink />,
          }}
        />
      ) : (
        <FormattedMessage
          data-test-id='monthly-usage-notice'
          id='usage-notices.message'
          defaultMessage={`{changeSubscription} or {reviewChanges}.`}
          values={{
            changeSubscription: <ChangeSubscriptionLink />,
            reviewChanges: <ReviewChangesLink onReviewChanges={() => this.onReviewChanges()} />,
          }}
        />
      )
    }

    return (
      <FormattedMessage
        data-test-id='usage-notice.prefix'
        id='usage-notice.message-prefix'
        defaultMessage={`Your subscription level doesn’t match {features} you’re using. {changeSubscriptionAction}`}
        values={{
          features: overrideUsageLevel ? (
            <span>{featureName}</span>
          ) : (
            <FormattedMessage id='usage-notices.message.features' defaultMessage='the features' />
          ),
          changeSubscriptionAction: getChangeSubscriptionActionMessage(),
        }}
      />
    )
  }

  onChangeSubscription(): void {
    this.setState({ showChangeSubscriptionModal: true })
  }

  onReviewChanges(): void {
    this.setState({ showReviewChangesModal: true })
  }

  onUpgrade(upgradeLevel: string | undefined): void {
    this.setState({
      showReviewChangesModal: false,
      showChangeSubscriptionModal: true,
      upgradeLevel,
    })
  }

  getShowUsageBanner(): boolean {
    const {
      usageDetails,
      accountDetails: { email, is_trial },
      enableUsageNoticeElasticCheck,
      overrideUsageLevel,
    } = this.props

    if (
      // We don't want to show the banner to elastic employees in production environments.
      (enableUsageNoticeElasticCheck && isElasticStaff({ email })) ||
      // If overrideUsageLevel is same with usage_level, we don't want to display duplicated banner.
      (overrideUsageLevel && overrideUsageLevel === usageDetails?.usage_level)
    ) {
      return false
    }

    const outOfCompliance = isUsageOutOfCompliance({
      subscriptionLevel: usageDetails?.subscription_level,
      usageLevel: overrideUsageLevel || usageDetails?.usage_level,
    })

    return outOfCompliance && !is_trial
  }
}

export default withEuiTheme(UsageNotice)
