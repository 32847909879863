/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiText } from '@elastic/eui'

import { CuiAlert } from '@modules/cui/Alert'
import { useGetLineItemsV1 } from '@modules/billing-lib/hooks'

import ExternalLink from '@/components/ExternalLink'
import { contactUs } from '@/apps/userconsole/urls'

import PrepaidBalancePanel from './PrepaidBalancePanel'
import PrepaidsTable from './PrepaidsTable'
import AddEcuCredits from './AddEcuCredits'
import { getLineItemsByFilter } from './utils'

interface Props {
  organizationId: string
  hasBorder?: boolean
}

export type FilterState = 'all' | 'expired' | 'active' | 'future'

const PrepaidAccountDetailsPanel = ({ organizationId, hasBorder }: Props) => {
  const [filter, setFilter] = useState<FilterState>('active')

  const {
    data: prepaidBalanceLineItems,
    isFetching,
    isError,
    isFetched,
    refetch,
  } = useGetLineItemsV1({
    pathParameters: { organization_id: organizationId },
  })

  const showEmptyState = isFetched && !prepaidBalanceLineItems?.length

  // @ts-ignore TODO - API returns incorrect API schema (callers and response have a different schema).
  // ts-ignore can be removed when: https://elasticco.atlassian.net/browse/PB-3814 is done
  const lineItemsByFilter = getLineItemsByFilter(prepaidBalanceLineItems)

  if (isError) {
    return (
      <PrepaidBalancePanel hasBorder={hasBorder}>
        <CuiAlert type='error'>
          <FormattedMessage
            id='generic-error.billing-message'
            defaultMessage={`Something isn't right. Please wait a moment and try refreshing the page. If the error persists, contact Support.`}
          />
        </CuiAlert>

        <AddEcuCredits organizationId={organizationId} refetchLineItems={refetch} />
      </PrepaidBalancePanel>
    )
  }

  if (showEmptyState) {
    return (
      <PrepaidBalancePanel hasBorder={hasBorder}>
        <EuiText size='s' data-test-id='empty-state-no-results'>
          <FormattedMessage
            id='billing-details-summary.empty-grid-body'
            defaultMessage='Add Elastic Consumption Unit credits to your organization with an activation code. If you need help, {contactUs}.'
            values={{
              contactUs: (
                <ExternalLink href={contactUs}>
                  <FormattedMessage
                    id='billing-details-summary.subscription-level.contact-us-link'
                    defaultMessage='contact us'
                  />
                </ExternalLink>
              ),
            }}
          />
        </EuiText>

        <AddEcuCredits organizationId={organizationId} refetchLineItems={refetch} />
      </PrepaidBalancePanel>
    )
  }

  return (
    <PrepaidBalancePanel
      filter={filter}
      onFilterChange={(value: FilterState) => {
        setFilter(value)
      }}
      hasBorder={hasBorder}
      lineItemsByFilter={lineItemsByFilter}
    >
      <PrepaidsTable filter={filter} rows={lineItemsByFilter[filter]} initialLoading={isFetching} />
      <AddEcuCredits organizationId={organizationId} refetchLineItems={refetch} />
    </PrepaidBalancePanel>
  )
}

export default PrepaidAccountDetailsPanel
