/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/*!
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@                                         @@@@
 *!   @@@   THIS FILE WAS AUTOGENERATED BY TOOL   @@@@
 *!   @@@   CHANGES MUST TARGET SCRIPTS/SWAGGER   @@@@
 *!   @@@                                         @@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!
 */

import {
  _buildQueryString,
  _emptyParams,
  _encode,
  _getBasePath,
  _getRawBasePath,
} from './urls-utils'

/**
 * GET /api/v1/all/_search
 * Operation: `search-all`
 * Searches for entities matching the given query.
 * @param {string} params.q The query string to search with.
 * @param {boolean} params.showPlanLogs Includes the active, pending, and historical plan information in the attempt log. NOTE: This option can result in large responses.
 * @see 200 The results of the search. See {@link SearchResults}
 * @return {string} A fully-interpolated and escaped URL
 */
export function searchAllUrl(params: SearchAllUrlParams): string {
  const queryString = _buildQueryString({
    q: params.q,
    show_plan_logs: params.showPlanLogs === undefined ? false : params.showPlanLogs,
  })
  const path = `/all/_search`
  return `${_getRawBasePath()}${path}${queryString}`
}

export interface SearchAllUrlParams {
  q: string
  showPlanLogs?: boolean | null
}
