/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/*!
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@                                         @@@@
 *!   @@@   THIS FILE WAS AUTOGENERATED BY TOOL   @@@@
 *!   @@@   CHANGES MUST TARGET SCRIPTS/SWAGGER   @@@@
 *!   @@@                                         @@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!
 */

import {
  _buildQueryString,
  _emptyParams,
  _encode,
  _getBasePath,
  _getRawBasePath,
} from './urls-utils'

/**
 * GET /api/v1/platform/configuration/api_base_url
 * Operation: `get-api-base-url`
 * Gets the API base Url configuration value.
 * @see 200 The API base Url was successfully retrieved. See {@link ApiBaseUrlData}
 * @see 404 There is no configured API base value but optimistic locking was sent. (code: `adminconsole.base_url.not_found`). See {@link BasicFailedReply}
 * @see 500 The persisted Url is malformed. (code: `adminconsole.base_url.invalid_persisted_data`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getApiBaseUrlUrl(): string {
  const path = `/platform/configuration/api_base_url`
  return `${_getRawBasePath()}${path}`
}

/**
 * PUT /api/v1/platform/configuration/api_base_url
 * Operation: `set-api-base-url`
 * Saves the API base Url configuration value.
 * @param {string} params.version If specified, then checks for conflicts against the version stored in the persistent store (returned in 'x-cloud-resource-version' of the GET request). If not specified, will unconditionally upsert.
 * @param {boolean} params.skipCascadingOperations Whether or not to skip cascading operations, such as re-provisioning the Security Deployment.
 * @see Accepts a request payload of type {@link ApiBaseUrlData}
 * @see 200 The API base Url was successfully saved. See {@link ApiBaseUrlData}
 * @see 400 The optimistic locking version format was wrong. (code: `adminconsole.base_url.bad_version_format`). See {@link BasicFailedReply}
 * @see 404 There is no configured API base value but optimistic locking was sent. (code: `adminconsole.base_url.not_found`). See {@link BasicFailedReply}
 * @see 409 There was an optimistic locking version conflict. (code: `adminconsole.base_url.version_conflict`). See {@link BasicFailedReply}
 * @see 412 skip_cascading_operations was false but the Security Deployment already had a pending plan. (code: `security_deployment.cluster_pending_plan_exists`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function setApiBaseUrlUrl(params: SetApiBaseUrlUrlParams = _emptyParams): string {
  const queryString = _buildQueryString({
    version: params.version,
    skip_cascading_operations:
      params.skipCascadingOperations === undefined ? false : params.skipCascadingOperations,
  })
  const path = `/platform/configuration/api_base_url`
  return `${_getRawBasePath()}${path}${queryString}`
}

export interface SetApiBaseUrlUrlParams {
  version?: string | null
  skipCascadingOperations?: boolean | null
}

/**
 * GET /api/v1/regions/{region_id}/platform/configuration/store
 * Operation: `list-config-store-option`
 * List all existing Config Store Option.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 List of existing Config Store Option. See {@link ConfigStoreOptionList}
 * @return {string} A fully-interpolated and escaped URL
 */
export function listConfigStoreOptionUrl(params: ListConfigStoreOptionUrlParams): string {
  const path = _encode`/${params.regionId}/platform/configuration/store`
  return `${_getBasePath()}${path}`
}

export interface ListConfigStoreOptionUrlParams {
  regionId: string
}

/**
 * GET /api/v1/regions/{region_id}/platform/configuration/store/{config_option_id}
 * Operation: `get-config-store-option`
 * Find Config Store Option by name.
 * @param {string} params.configOptionId Name of the Config Store Option that you would like to find.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 Config Store Option retrieved successfully. See {@link ConfigStoreOption}
 * @see 404 Config Store Option do not exist by that name. (code: `platform.config.store.not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getConfigStoreOptionUrl(params: GetConfigStoreOptionUrlParams): string {
  const path = _encode`/${params.regionId}/platform/configuration/store/${params.configOptionId}`
  return `${_getBasePath()}${path}`
}

export interface GetConfigStoreOptionUrlParams {
  configOptionId: string
  regionId: string
}

/**
 * POST /api/v1/regions/{region_id}/platform/configuration/store/{config_option_id}
 * Operation: `create-config-store-option`
 * Inserts new Config Store Option.
 * @param {string} params.configOptionId Name of the Config Store Option that you would like to create.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see Accepts a request payload of type {@link ConfigStoreOptionData}
 * @see 201 The Config Store Option was inserted successfully. See {@link ConfigStoreOption}
 * @see 400 Config Store Option data already exists for the given name. (code: `platform.config.store.already_exists`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function createConfigStoreOptionUrl(params: CreateConfigStoreOptionUrlParams): string {
  const path = _encode`/${params.regionId}/platform/configuration/store/${params.configOptionId}`
  return `${_getBasePath()}${path}`
}

export interface CreateConfigStoreOptionUrlParams {
  configOptionId: string
  regionId: string
}

/**
 * PUT /api/v1/regions/{region_id}/platform/configuration/store/{config_option_id}
 * Operation: `put-config-store-option`
 * Update existing Config Store Option.
 * @param {string} params.configOptionId Name of the Config Store Option that you would like to modify.
 * @param {string} params.version If specified then checks for conflicts against the version stored in the persistent store (returned in 'x-cloud-resource-version' of the GET request).
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see Accepts a request payload of type {@link ConfigStoreOptionData}
 * @see 200 Config Store Option retrieved successfully. See {@link ConfigStoreOption}
 * @see 404 There was no existing data for the given Config Store Id. (code: `platform.config.store.not_found`). See {@link BasicFailedReply}
 * @see 409 There is a version conflict. (code: `platform.config.store.version_conflict`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function putConfigStoreOptionUrl(params: PutConfigStoreOptionUrlParams): string {
  const queryString = _buildQueryString({
    version: params.version,
  })
  const path = _encode`/${params.regionId}/platform/configuration/store/${params.configOptionId}`
  return `${_getBasePath()}${path}${queryString}`
}

export interface PutConfigStoreOptionUrlParams {
  configOptionId: string
  version?: string | null
  regionId: string
}

/**
 * DELETE /api/v1/regions/{region_id}/platform/configuration/store/{config_option_id}
 * Operation: `delete-config-store-option`
 * Delete Config Store Option by name.
 * @param {string} params.configOptionId Name of the Config Store Option that you would like to delete.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 Config Store Option deleted successfully
 * @see 404 Config Store Option do not exist by that name. (code: `platform.config.store.not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function deleteConfigStoreOptionUrl(params: DeleteConfigStoreOptionUrlParams): string {
  const path = _encode`/${params.regionId}/platform/configuration/store/${params.configOptionId}`
  return `${_getBasePath()}${path}`
}

export interface DeleteConfigStoreOptionUrlParams {
  configOptionId: string
  regionId: string
}

/**
 * GET /api/v1/regions/{region_id}/platform/infrastructure/adminconsoles
 * Operation: `get-adminconsoles`
 * Retrieves an overview of all adminconsoles.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 An overview of all adminconsoles. See {@link AdminconsolesOverview}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getAdminconsolesUrl(params: GetAdminconsolesUrlParams): string {
  const path = _encode`/${params.regionId}/platform/infrastructure/adminconsoles`
  return `${_getBasePath()}${path}`
}

export interface GetAdminconsolesUrlParams {
  regionId: string
}

/**
 * POST /api/v1/regions/{region_id}/platform/infrastructure/adminconsoles/_reindex
 * Operation: `reindex-adminconsoles`
 * Start reindex of all regional indices.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 202 Started reindexing of regional indices.
 * @return {string} A fully-interpolated and escaped URL
 */
export function reindexAdminconsolesUrl(params: ReindexAdminconsolesUrlParams): string {
  const path = _encode`/${params.regionId}/platform/infrastructure/adminconsoles/_reindex`
  return `${_getBasePath()}${path}`
}

export interface ReindexAdminconsolesUrlParams {
  regionId: string
}

/**
 * GET /api/v1/regions/{region_id}/platform/infrastructure/adminconsoles/{adminconsole_id}/logging_settings
 * Operation: `get-adminconsole-logging-settings`
 * Get the logging settings for this adminconsole instance.
 * @param {string} params.adminconsoleId The identifier for the adminconsole instance.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 The logging settings for the adminconsole instance. See {@link LoggingSettings}
 * @see 404 The logging settings for this adminconsole were not found. (code: `adminconsoles.logging_settings.not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getAdminconsoleLoggingSettingsUrl(
  params: GetAdminconsoleLoggingSettingsUrlParams,
): string {
  const path = _encode`/${params.regionId}/platform/infrastructure/adminconsoles/${params.adminconsoleId}/logging_settings`
  return `${_getBasePath()}${path}`
}

export interface GetAdminconsoleLoggingSettingsUrlParams {
  adminconsoleId: string
  regionId: string
}

/**
 * PUT /api/v1/regions/{region_id}/platform/infrastructure/adminconsoles/{adminconsole_id}/logging_settings
 * Operation: `set-adminconsole-logging-settings`
 * Set the logging settings for this adminconsole instance.
 * @param {string} params.adminconsoleId The identifier for the adminconsole instance.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see Accepts a request payload of type {@link LoggingSettings}
 * @see 200 The updated logging settings for the adminconsole instance. See {@link LoggingSettings}
 * @see 404 The logging settings for this adminconsole were not found. (code: `adminconsoles.logging_settings.not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function setAdminconsoleLoggingSettingsUrl(
  params: SetAdminconsoleLoggingSettingsUrlParams,
): string {
  const path = _encode`/${params.regionId}/platform/infrastructure/adminconsoles/${params.adminconsoleId}/logging_settings`
  return `${_getBasePath()}${path}`
}

export interface SetAdminconsoleLoggingSettingsUrlParams {
  adminconsoleId: string
  regionId: string
}

/**
 * DELETE /api/v1/regions/{region_id}/platform/infrastructure/adminconsoles/{adminconsole_id}/logging_settings
 * Operation: `delete-adminconsole-logging-settings`
 * Reset the default logging settings for this adminconsole instance.
 * @param {string} params.adminconsoleId The identifier for the adminconsole instance.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 The updated logging settings for the adminconsole instance. See {@link LoggingSettings}
 * @see 404 The logging settings for this adminconsole were not found. (code: `adminconsoles.logging_settings.not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function deleteAdminconsoleLoggingSettingsUrl(
  params: DeleteAdminconsoleLoggingSettingsUrlParams,
): string {
  const path = _encode`/${params.regionId}/platform/infrastructure/adminconsoles/${params.adminconsoleId}/logging_settings`
  return `${_getBasePath()}${path}`
}

export interface DeleteAdminconsoleLoggingSettingsUrlParams {
  adminconsoleId: string
  regionId: string
}

/**
 * PATCH /api/v1/regions/{region_id}/platform/infrastructure/adminconsoles/{adminconsole_id}/logging_settings
 * Operation: `update-adminconsole-logging-settings`
 * All changes in the specified object are applied to the logging settings for this adminconsole instance according to the JSON Merge Patch processing rules. Omitting existing fields causes the same values to be reapplied. Specifying a `null` value reverts the field to the default value, or removes the field when no default value exists.
 * @param {string} params.adminconsoleId The identifier for the adminconsole instance.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see Accepts a request payload of type {@link string}
 * @see 200 The updated logging settings for the adminconsole instance. See {@link LoggingSettings}
 * @see 400 The update request is invalid. (code: `patch.request_malformed`). See {@link BasicFailedReply}
 * @see 404 The logging settings for this adminconsole were not found. (code: `adminconsoles.logging_settings.not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function updateAdminconsoleLoggingSettingsUrl(
  params: UpdateAdminconsoleLoggingSettingsUrlParams,
): string {
  const path = _encode`/${params.regionId}/platform/infrastructure/adminconsoles/${params.adminconsoleId}/logging_settings`
  return `${_getBasePath()}${path}`
}

export interface UpdateAdminconsoleLoggingSettingsUrlParams {
  adminconsoleId: string
  regionId: string
}

/**
 * GET /api/v1/regions/{region_id}/platform/infrastructure/allocators
 * Operation: `get-allocators`
 * Retrieves the information for all of the allocators in the platform.
 * @param {string} params.q (Optional) The query that filters the allocators. Maps to an Elasticsearch `query_string` query.
 * @param {integer} params.size (Optional) The maximum number of search results to return. Defaults to 100. Default: 100
 * @param {integer} params.from (Optional) The offset from the first result you want to fetch. Defaults to 0.
 * @param {string} params.sort (Optional) A comma-separated array of fields to sort the search results by. Defaults to `allocator_id`.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 An overview of all allocators. See {@link AllocatorOverview}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getAllocatorsUrl(params: GetAllocatorsUrlParams): string {
  const queryString = _buildQueryString({
    q: params.q,
    size: params.size === undefined ? 100 : params.size,
    from: params.from === undefined ? 0 : params.from,
    sort: params.sort,
  })
  const path = _encode`/${params.regionId}/platform/infrastructure/allocators`
  return `${_getBasePath()}${path}${queryString}`
}

export interface GetAllocatorsUrlParams {
  q?: string | null
  size?: number | null
  from?: number | null
  sort?: string | null
  regionId: string
}

/**
 * POST /api/v1/regions/{region_id}/platform/infrastructure/allocators/_resync
 * Operation: `resync-allocators`
 * Asynchronously synchronizes all allocator information in the back-end search index.
 * @param {boolean} params.skipMatchingVersion When true, skips the document indexing when the version matches the in-memory copy. Default: true
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 202 The ids of documents, organized by model version, that will be synchronized. See {@link ModelVersionIndexSynchronizationResults}
 * @return {string} A fully-interpolated and escaped URL
 */
export function resyncAllocatorsUrl(params: ResyncAllocatorsUrlParams): string {
  const queryString = _buildQueryString({
    skip_matching_version:
      params.skipMatchingVersion === undefined ? true : params.skipMatchingVersion,
  })
  const path = _encode`/${params.regionId}/platform/infrastructure/allocators/_resync`
  return `${_getBasePath()}${path}${queryString}`
}

export interface ResyncAllocatorsUrlParams {
  skipMatchingVersion?: boolean | null
  regionId: string
}

/**
 * POST /api/v1(/regions/{region_id})?/platform/infrastructure/allocators/_search
 * Operation: `search-allocators`
 * Retrieves the information for all the allocators in the platform that match a specified query.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see Accepts an optional request payload of type {@link SearchRequest}
 * @see 200 An overview of allocators that matched the given search query. See {@link AllocatorOverview}
 * @return {string} A fully-interpolated and escaped URL
 */
export function searchAllocatorsUrl(params: SearchAllocatorsUrlParams = _emptyParams): string {
  const path =
    (params.regionId == null ? '' : '/' + encodeURIComponent(params.regionId)) +
    `/platform/infrastructure/allocators/_search`
  return `${params.regionId == null ? _getRawBasePath() : _getBasePath()}${path}`
}

export interface SearchAllocatorsUrlParams {
  regionId?: string | null
}

/**
 * GET /api/v1/regions/{region_id}/platform/infrastructure/allocators/{allocator_id}
 * Operation: `get-allocator`
 * Retrieves the allocator by the ID.
 * @param {string} params.allocatorId The allocator identifier.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 The information for the allocator specified by {allocator_id}. See {@link AllocatorInfo}
 * @see 404 The allocator specified by {allocator_id} cannot be found. (code: `allocators.allocator_not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getAllocatorUrl(params: GetAllocatorUrlParams): string {
  const path = _encode`/${params.regionId}/platform/infrastructure/allocators/${params.allocatorId}`
  return `${_getBasePath()}${path}`
}

export interface GetAllocatorUrlParams {
  allocatorId: string
  regionId: string
}

/**
 * DELETE /api/v1/regions/{region_id}/platform/infrastructure/allocators/{allocator_id}
 * Operation: `delete-allocator`
 * Deletes the allocator.
 * @param {string} params.allocatorId The allocator identifier.
 * @param {boolean} params.removeInstances Removes the instances from the deleted allocator.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 The allocator specified by {allocator_id} was successfully deleted
 * @see 400 * The allocator specified by {allocator_id} could not be deleted. (code: `allocators.delete_connected_allocator_attempt`)
 * The allocator specified by {allocator_id} could not be deleted. (code: `allocators.delete_allocator_with_instances_attempt`). See {@link BasicFailedReply}
 * @see 404 The allocator specified by {allocator_id} cannot be found. (code: `allocators.allocator_not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function deleteAllocatorUrl(params: DeleteAllocatorUrlParams): string {
  const queryString = _buildQueryString({
    remove_instances: params.removeInstances === undefined ? false : params.removeInstances,
  })
  const path = _encode`/${params.regionId}/platform/infrastructure/allocators/${params.allocatorId}`
  return `${_getBasePath()}${path}${queryString}`
}

export interface DeleteAllocatorUrlParams {
  allocatorId: string
  removeInstances?: boolean | null
  regionId: string
}

/**
 * POST /api/v1/regions/{region_id}/platform/infrastructure/allocators/{allocator_id}/_resync
 * Operation: `resync-allocator`
 * Immediately synchronizes the allocator information in the back-end search index and caches.
 * @param {string} params.allocatorId The allocator identifier.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 The allocator resync operation executed successfully
 * @see 500 The allocator resync operation failed for allocator {allocator_id}. (code: `allocators.resync_failed`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function resyncAllocatorUrl(params: ResyncAllocatorUrlParams): string {
  const path = _encode`/${params.regionId}/platform/infrastructure/allocators/${params.allocatorId}/_resync`
  return `${_getBasePath()}${path}`
}

export interface ResyncAllocatorUrlParams {
  allocatorId: string
  regionId: string
}

/**
 * POST /api/v1/regions/{region_id}/platform/infrastructure/allocators/{allocator_id}/clusters/_move
 * Operation: `move-clusters`
 * Moves the clusters to a different allocator.
 * @param {string} params.allocatorId The allocator identifier.
 * @param {boolean} params.forceUpdate When `true`, cancels and overwrites the pending plans, or treats the instance as an error.
 * @param {boolean} params.moveOnly When `true`, moves the specified instances and ignores the changes for the cluster state. Default: true
 * @param {boolean} params.forceMove When `true`, execute a primitive vacate by moving data at file-system level, and recreating instances on the target allocator(s).
 * @param {boolean} params.allocatorDown When `true`, considers all instances on the allocator as permanently shut down when deciding how to migrate data to new nodes.When left blank, the system automatically decides. NOTE: The default treats the allocator as up.
 * @param {boolean} params.validateOnly When `true`, validates the plan overrides, then returns the plan without performing the move.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see Accepts an optional request payload of type {@link MoveClustersRequest}
 * @see 202 The move command was issued successfully, use the "GET" command on each /{cluster_id} resource to monitor progress. See {@link MoveClustersCommandResponse}
 * @see 400 * The cluster definition contained errors. (code: `clusters.cluster_invalid_plan`)
 * The cluster definition contained errors. (code: `clusters.plan_feature_not_implemented`). See {@link BasicFailedReply}
 * @see 403 The move command was prohibited for the given cluster. (code: `clusters.command_prohibited`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function moveClustersUrl(params: MoveClustersUrlParams): string {
  const queryString = _buildQueryString({
    force_update: params.forceUpdate === undefined ? false : params.forceUpdate,
    move_only: params.moveOnly === undefined ? true : params.moveOnly,
    force_move: params.forceMove === undefined ? false : params.forceMove,
    allocator_down: params.allocatorDown,
    validate_only: params.validateOnly === undefined ? false : params.validateOnly,
  })
  const path = _encode`/${params.regionId}/platform/infrastructure/allocators/${params.allocatorId}/clusters/_move`
  return `${_getBasePath()}${path}${queryString}`
}

export interface MoveClustersUrlParams {
  allocatorId: string
  forceUpdate?: boolean | null
  moveOnly?: boolean | null
  forceMove?: boolean | null
  allocatorDown?: boolean | null
  validateOnly?: boolean | null
  regionId: string
}

/**
 * POST /api/v1/regions/{region_id}/platform/infrastructure/allocators/{allocator_id}/clusters/{cluster_type}/_move
 * Operation: `move-clusters-by-type`
 * Moves the clusters, by type, to a different allocator.
 * @param {string} params.allocatorId The allocator identifier.
 * @param {string} params.clusterType The cluster types to move off of the allocator. NOTE: When unspecified, all clusters are moved.
 * @param {boolean} params.forceUpdate When true, cancels and overwrites pending plans, or treats instance as an error.
 * @param {boolean} params.moveOnly When true, bypasses the cluster state changes, but continues to move the specified instances. Default: true
 * @param {boolean} params.forceMove When `true`, execute a primitive vacate by moving data at file-system level, and recreating instances on the target allocator(s).
 * @param {boolean} params.allocatorDown When `true`, considers all instances on the allocator as permanently shut down when deciding how to migrate data to new nodes.When left blank, the system automatically decides. NOTE: The default treats the allocator as up.
 * @param {boolean} params.validateOnly When `true`, validates the plan overrides, then returns the plan without performing the move.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see Accepts an optional request payload of type {@link MoveClustersRequest}
 * @see 202 The move command was issued successfully, use the "GET" command on each /{cluster_id} resource to monitor progress. See {@link MoveClustersCommandResponse}
 * @see 400 * The cluster definition contained errors. (code: `clusters.cluster_invalid_plan`)
 * The cluster definition contained errors. (code: `clusters.plan_feature_not_implemented`). See {@link BasicFailedReply}
 * @see 403 The move command was prohibited for the given cluster. (code: `clusters.command_prohibited`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function moveClustersByTypeUrl(params: MoveClustersByTypeUrlParams): string {
  const queryString = _buildQueryString({
    force_update: params.forceUpdate === undefined ? false : params.forceUpdate,
    move_only: params.moveOnly === undefined ? true : params.moveOnly,
    force_move: params.forceMove === undefined ? false : params.forceMove,
    allocator_down: params.allocatorDown,
    validate_only: params.validateOnly === undefined ? false : params.validateOnly,
  })
  const path = _encode`/${params.regionId}/platform/infrastructure/allocators/${params.allocatorId}/clusters/${params.clusterType}/_move`
  return `${_getBasePath()}${path}${queryString}`
}

export interface MoveClustersByTypeUrlParams {
  allocatorId: string
  clusterType: string
  forceUpdate?: boolean | null
  moveOnly?: boolean | null
  forceMove?: boolean | null
  allocatorDown?: boolean | null
  validateOnly?: boolean | null
  regionId: string
}

/**
 * GET /api/v1/regions/{region_id}/platform/infrastructure/allocators/{allocator_id}/logging_settings
 * Operation: `get-allocator-logging-settings`
 * Get the logging settings for this allocator instance.
 * @param {string} params.allocatorId The allocator identifier.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 The logging settings for the allocator instance. See {@link LoggingSettings}
 * @see 404 The logging settings for this allocator were not found. (code: `allocators.logging_settings.not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getAllocatorLoggingSettingsUrl(
  params: GetAllocatorLoggingSettingsUrlParams,
): string {
  const path = _encode`/${params.regionId}/platform/infrastructure/allocators/${params.allocatorId}/logging_settings`
  return `${_getBasePath()}${path}`
}

export interface GetAllocatorLoggingSettingsUrlParams {
  allocatorId: string
  regionId: string
}

/**
 * PUT /api/v1/regions/{region_id}/platform/infrastructure/allocators/{allocator_id}/logging_settings
 * Operation: `set-allocator-logging-settings`
 * Set the logging settings for this allocator instance.
 * @param {string} params.allocatorId The allocator identifier.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see Accepts a request payload of type {@link LoggingSettings}
 * @see 200 The updated logging settings for the allocator instance. See {@link LoggingSettings}
 * @see 404 The logging settings for this allocator were not found. (code: `allocators.logging_settings.not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function setAllocatorLoggingSettingsUrl(
  params: SetAllocatorLoggingSettingsUrlParams,
): string {
  const path = _encode`/${params.regionId}/platform/infrastructure/allocators/${params.allocatorId}/logging_settings`
  return `${_getBasePath()}${path}`
}

export interface SetAllocatorLoggingSettingsUrlParams {
  allocatorId: string
  regionId: string
}

/**
 * DELETE /api/v1/regions/{region_id}/platform/infrastructure/allocators/{allocator_id}/logging_settings
 * Operation: `delete-allocator-logging-settings`
 * Reset the default logging settings for this allocator instance.
 * @param {string} params.allocatorId The allocator identifier.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 The updated logging settings for the allocator instance. See {@link LoggingSettings}
 * @see 404 The logging settings for this allocator were not found. (code: `allocators.logging_settings.not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function deleteAllocatorLoggingSettingsUrl(
  params: DeleteAllocatorLoggingSettingsUrlParams,
): string {
  const path = _encode`/${params.regionId}/platform/infrastructure/allocators/${params.allocatorId}/logging_settings`
  return `${_getBasePath()}${path}`
}

export interface DeleteAllocatorLoggingSettingsUrlParams {
  allocatorId: string
  regionId: string
}

/**
 * PATCH /api/v1/regions/{region_id}/platform/infrastructure/allocators/{allocator_id}/logging_settings
 * Operation: `update-allocator-logging-settings`
 * All changes in the specified object are applied to the logging settings for this allocator instance according to the JSON Merge Patch processing rules. Omitting existing fields causes the same values to be reapplied. Specifying a `null` value reverts the field to the default value, or removes the field when no default value exists.
 * @param {string} params.allocatorId The allocator identifier.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see Accepts a request payload of type {@link string}
 * @see 200 The updated logging settings for the allocator instance. See {@link LoggingSettings}
 * @see 400 The update request is invalid. (code: `patch.request_malformed`). See {@link BasicFailedReply}
 * @see 404 The logging settings for this allocator were not found. (code: `allocators.logging_settings.not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function updateAllocatorLoggingSettingsUrl(
  params: UpdateAllocatorLoggingSettingsUrlParams,
): string {
  const path = _encode`/${params.regionId}/platform/infrastructure/allocators/${params.allocatorId}/logging_settings`
  return `${_getBasePath()}${path}`
}

export interface UpdateAllocatorLoggingSettingsUrlParams {
  allocatorId: string
  regionId: string
}

/**
 * POST /api/v1/regions/{region_id}/platform/infrastructure/allocators/{allocator_id}/maintenance-mode/_start
 * Operation: `start-allocator-maintenance-mode`
 * Starts maintenance mode on the allocator.
 * @param {string} params.allocatorId The allocator identifier.
 * @param {string} params.reason Provides a reason for changing the maintenance mode which will appear as a message on the allocator.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 202 The start maintenance mode command was issued successfully
 * @see 403 The start maintenance mode command was prohibited for the given allocator. (code: `root.unauthorized.rbac`). See {@link BasicFailedReply}
 * @see 404 The allocator specified by {allocator_id} cannot be found. (code: `allocators.allocator_not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function startAllocatorMaintenanceModeUrl(
  params: StartAllocatorMaintenanceModeUrlParams,
): string {
  const queryString = _buildQueryString({
    reason: params.reason,
  })
  const path = _encode`/${params.regionId}/platform/infrastructure/allocators/${params.allocatorId}/maintenance-mode/_start`
  return `${_getBasePath()}${path}${queryString}`
}

export interface StartAllocatorMaintenanceModeUrlParams {
  allocatorId: string
  reason?: string | null
  regionId: string
}

/**
 * POST /api/v1/regions/{region_id}/platform/infrastructure/allocators/{allocator_id}/maintenance-mode/_stop
 * Operation: `stop-allocator-maintenance-mode`
 * Stops maintenance mode on the allocator.
 * @param {string} params.allocatorId The allocator identifier.
 * @param {string} params.reason Provides a reason for changing the maintenance mode which will appear as a message on the allocator.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 202 The stop maintenance mode command was issued successfully
 * @see 403 The stop maintenance mode command was prohibited for the given allocator. (code: `root.unauthorized.rbac`). See {@link BasicFailedReply}
 * @see 404 The allocator specified by {allocator_id} cannot be found. (code: `allocators.allocator_not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function stopAllocatorMaintenanceModeUrl(
  params: StopAllocatorMaintenanceModeUrlParams,
): string {
  const queryString = _buildQueryString({
    reason: params.reason,
  })
  const path = _encode`/${params.regionId}/platform/infrastructure/allocators/${params.allocatorId}/maintenance-mode/_stop`
  return `${_getBasePath()}${path}${queryString}`
}

export interface StopAllocatorMaintenanceModeUrlParams {
  allocatorId: string
  reason?: string | null
  regionId: string
}

/**
 * GET /api/v1/regions/{region_id}/platform/infrastructure/allocators/{allocator_id}/metadata
 * Operation: `get-allocator-metadata`
 * Retrieves the allocator metadata.
 * @param {string} params.allocatorId The allocator identifier.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 The allocator metadata was successfully returned. See {@link Array<MetadataItem>}
 * @see 404 The allocator specified by {allocator_id} cannot be found. (code: `allocators.allocator_not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getAllocatorMetadataUrl(params: GetAllocatorMetadataUrlParams): string {
  const path = _encode`/${params.regionId}/platform/infrastructure/allocators/${params.allocatorId}/metadata`
  return `${_getBasePath()}${path}`
}

export interface GetAllocatorMetadataUrlParams {
  allocatorId: string
  regionId: string
}

/**
 * PUT /api/v1/regions/{region_id}/platform/infrastructure/allocators/{allocator_id}/metadata
 * Operation: `set-allocator-metadata`
 * Sets the allocator metadata.
 * @param {string} params.allocatorId The allocator identifier.
 * @param {string} params.version Checks for conflicts against the metadata version, then returns the value in the `x-cloud-resource-version` header.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see Accepts a request payload of type {@link MetadataItems}
 * @see 200 The allocator metadata was successfully changed (the updated JSON is returned). See {@link Array<MetadataItem>}
 * @see 400 The value specified is empty for at least one of the metadata tags. (code: `allocators.invalid_empty_metadata_items`). See {@link BasicFailedReply}
 * @see 404 The allocator specified by {allocator_id} cannot be found. (code: `allocators.allocator_not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function setAllocatorMetadataUrl(params: SetAllocatorMetadataUrlParams): string {
  const queryString = _buildQueryString({
    version: params.version,
  })
  const path = _encode`/${params.regionId}/platform/infrastructure/allocators/${params.allocatorId}/metadata`
  return `${_getBasePath()}${path}${queryString}`
}

export interface SetAllocatorMetadataUrlParams {
  allocatorId: string
  version?: string | null
  regionId: string
}

/**
 * PUT /api/v1/regions/{region_id}/platform/infrastructure/allocators/{allocator_id}/metadata/{key}
 * Operation: `set-allocator-metadata-item`
 * Adds or updates a single item in the allocator metadata.
 * @param {string} params.allocatorId The allocator identifier.
 * @param {string} params.key The metadata item key.
 * @param {string} params.version Checks for conflicts against the metadata version, then returns the value in the `x-cloud-resource-version` header.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see Accepts a request payload of type {@link MetadataItemValue}
 * @see 200 The allocator metadata was successfully changed (the updated JSON is returned). See {@link Array<MetadataItem>}
 * @see 400 The value specified for the metadata tag is empty. (code: `allocators.invalid_empty_metadata_item`). See {@link BasicFailedReply}
 * @see 404 * The allocator specified by {allocator_id} cannot be found. (code: `allocators.allocator_not_found`)
 * The metadata item specified by {key} cannot be found. (code: `allocators.metadata_item_not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function setAllocatorMetadataItemUrl(params: SetAllocatorMetadataItemUrlParams): string {
  const queryString = _buildQueryString({
    version: params.version,
  })
  const path = _encode`/${params.regionId}/platform/infrastructure/allocators/${params.allocatorId}/metadata/${params.key}`
  return `${_getBasePath()}${path}${queryString}`
}

export interface SetAllocatorMetadataItemUrlParams {
  allocatorId: string
  key: string
  version?: string | null
  regionId: string
}

/**
 * DELETE /api/v1/regions/{region_id}/platform/infrastructure/allocators/{allocator_id}/metadata/{key}
 * Operation: `delete-allocator-metadata-item`
 * Removes a single item from the allocator metadata.
 * @param {string} params.allocatorId The allocator identifier.
 * @param {string} params.key The metadata item key.
 * @param {string} params.version Checks for conflicts against the metadata version, then returns the value in the `x-cloud-resource-version` header.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 The allocator metadata was successfully changed (the updated JSON is returned). See {@link Array<MetadataItem>}
 * @see 404 The allocator specified by {allocator_id} cannot be found. (code: `allocators.allocator_not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function deleteAllocatorMetadataItemUrl(
  params: DeleteAllocatorMetadataItemUrlParams,
): string {
  const queryString = _buildQueryString({
    version: params.version,
  })
  const path = _encode`/${params.regionId}/platform/infrastructure/allocators/${params.allocatorId}/metadata/${params.key}`
  return `${_getBasePath()}${path}${queryString}`
}

export interface DeleteAllocatorMetadataItemUrlParams {
  allocatorId: string
  key: string
  version?: string | null
  regionId: string
}

/**
 * GET /api/v1/regions/{region_id}/platform/infrastructure/allocators/{allocator_id}/settings
 * Operation: `get-allocator-settings`
 * Retrieves the allocator override settings.
 * @param {string} params.allocatorId The allocator identifier.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 Returns the settings for the specified Allocator. See {@link AllocatorSettings}
 * @see 404 The allocator specified by {allocator_id} cannot be found. (code: `allocators.allocator_not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getAllocatorSettingsUrl(params: GetAllocatorSettingsUrlParams): string {
  const path = _encode`/${params.regionId}/platform/infrastructure/allocators/${params.allocatorId}/settings`
  return `${_getBasePath()}${path}`
}

export interface GetAllocatorSettingsUrlParams {
  allocatorId: string
  regionId: string
}

/**
 * PUT /api/v1/regions/{region_id}/platform/infrastructure/allocators/{allocator_id}/settings
 * Operation: `set-allocator-settings`
 * Overwrites the allocator settings with the specified settings. WARNING: Any unspecified fields are deleted.
 * @param {string} params.allocatorId The allocator identifier.
 * @param {string} params.version Checks for conflicts against the metadata version, then returns the value in the `x-cloud-resource-version` header.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see Accepts a request payload of type {@link AllocatorSettings}
 * @see 200 Returns the updated settings for the specified allocator. See {@link AllocatorSettings}
 * @see 404 The allocator specified by {allocator_id} cannot be found. (code: `allocators.allocator_not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function setAllocatorSettingsUrl(params: SetAllocatorSettingsUrlParams): string {
  const queryString = _buildQueryString({
    version: params.version,
  })
  const path = _encode`/${params.regionId}/platform/infrastructure/allocators/${params.allocatorId}/settings`
  return `${_getBasePath()}${path}${queryString}`
}

export interface SetAllocatorSettingsUrlParams {
  allocatorId: string
  version?: string | null
  regionId: string
}

/**
 * PATCH /api/v1/regions/{region_id}/platform/infrastructure/allocators/{allocator_id}/settings
 * Operation: `update-allocator-settings`
 * Applies the settings as a patch. Only the fields that are referenced in the update are altered.
 * @param {string} params.allocatorId The allocator identifier.
 * @param {string} params.version Checks for conflicts against the metadata version, then returns the value in the `x-cloud-resource-version` header.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see Accepts a request payload of type {@link string}
 * @see 200 Returns the updated settings for the specified allocator. See {@link AllocatorSettings}
 * @see 404 The allocator specified by {allocator_id} cannot be found. (code: `allocators.allocator_not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function updateAllocatorSettingsUrl(params: UpdateAllocatorSettingsUrlParams): string {
  const queryString = _buildQueryString({
    version: params.version,
  })
  const path = _encode`/${params.regionId}/platform/infrastructure/allocators/${params.allocatorId}/settings`
  return `${_getBasePath()}${path}${queryString}`
}

export interface UpdateAllocatorSettingsUrlParams {
  allocatorId: string
  version?: string | null
  regionId: string
}

/**
 * GET /api/v1/regions/{region_id}/platform/infrastructure/blueprinter/roles
 * Operation: `list-blueprinter-roles`
 * Retrieve all persisted roles.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 The role aggregates. See {@link RoleAggregates}
 * @return {string} A fully-interpolated and escaped URL
 */
export function listBlueprinterRolesUrl(params: ListBlueprinterRolesUrlParams): string {
  const path = _encode`/${params.regionId}/platform/infrastructure/blueprinter/roles`
  return `${_getBasePath()}${path}`
}

export interface ListBlueprinterRolesUrlParams {
  regionId: string
}

/**
 * POST /api/v1/regions/{region_id}/platform/infrastructure/blueprinter/roles
 * Operation: `create-blueprinter-role`
 * Create a Blueprinter role.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see Accepts a request payload of type {@link RoleAggregateCreateData}
 * @see 201 The role aggregate that was just created. See {@link RoleAggregate}
 * @see 409 The role ID you specified is already in use. (code: `roles.already_exists`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function createBlueprinterRoleUrl(params: CreateBlueprinterRoleUrlParams): string {
  const path = _encode`/${params.regionId}/platform/infrastructure/blueprinter/roles`
  return `${_getBasePath()}${path}`
}

export interface CreateBlueprinterRoleUrlParams {
  regionId: string
}

/**
 * GET /api/v1/regions/{region_id}/platform/infrastructure/blueprinter/roles/{blueprinter_role_id}
 * Operation: `get-blueprinter-role`
 * Retrieve a role.
 * @param {string} params.blueprinterRoleId User-specified Blueprinter role ID.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 The role aggregate definition. See {@link RoleAggregate}
 * @see 404 The role can't be found. (code: `roles.not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getBlueprinterRoleUrl(params: GetBlueprinterRoleUrlParams): string {
  const path = _encode`/${params.regionId}/platform/infrastructure/blueprinter/roles/${params.blueprinterRoleId}`
  return `${_getBasePath()}${path}`
}

export interface GetBlueprinterRoleUrlParams {
  blueprinterRoleId: string
  regionId: string
}

/**
 * PUT /api/v1/regions/{region_id}/platform/infrastructure/blueprinter/roles/{blueprinter_role_id}
 * Operation: `update-blueprinter-role`
 * Update a role.
 * @param {string} params.blueprinterRoleId User-specified Blueprinter role ID.
 * @param {integer} params.version When specified, checks for conflicts against the version stored in the persistent store (returned in 'x-cloud-resource-version' of the GET request).
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see Accepts a request payload of type {@link Role}
 * @see 200 The role was successfully updated. See {@link RoleAggregate}
 * @see 400 The role is currently running container sets. (code: `roles.in_use`). See {@link BasicFailedReply}
 * @see 404 The role can't be found. (code: `roles.not_found`). See {@link BasicFailedReply}
 * @see 409 Your request failed because the specified version does not match the persisted version. (code: `roles.version_conflict`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function updateBlueprinterRoleUrl(params: UpdateBlueprinterRoleUrlParams): string {
  const queryString = _buildQueryString({
    version: params.version,
  })
  const path = _encode`/${params.regionId}/platform/infrastructure/blueprinter/roles/${params.blueprinterRoleId}`
  return `${_getBasePath()}${path}${queryString}`
}

export interface UpdateBlueprinterRoleUrlParams {
  blueprinterRoleId: string
  version?: number | null
  regionId: string
}

/**
 * DELETE /api/v1/regions/{region_id}/platform/infrastructure/blueprinter/roles/{blueprinter_role_id}
 * Operation: `delete-blueprinter-role`
 * Delete a role.
 * @param {string} params.blueprinterRoleId User-specified Blueprinter role ID.
 * @param {boolean} params.skipValidations When sent as true, ignores validation errors.
 * @param {integer} params.version When specified, checks for conflicts against the version stored in the persistent store (returned in 'x-cloud-resource-version' of the GET request).
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 The role was successfully deleted.
 * @see 400 The role is currently running container sets. (code: `roles.in_use`). See {@link BasicFailedReply}
 * @see 404 The role can't be found. (code: `roles.not_found`). See {@link BasicFailedReply}
 * @see 409 Your request failed because the specified version does not match the persisted version. (code: `roles.version_conflict`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function deleteBlueprinterRoleUrl(params: DeleteBlueprinterRoleUrlParams): string {
  const queryString = _buildQueryString({
    skip_validations: params.skipValidations === undefined ? false : params.skipValidations,
    version: params.version,
  })
  const path = _encode`/${params.regionId}/platform/infrastructure/blueprinter/roles/${params.blueprinterRoleId}`
  return `${_getBasePath()}${path}${queryString}`
}

export interface DeleteBlueprinterRoleUrlParams {
  blueprinterRoleId: string
  skipValidations?: boolean | null
  version?: number | null
  regionId: string
}

/**
 * PUT /api/v1/regions/{region_id}/platform/infrastructure/blueprinter/roles/{blueprinter_role_id}/blessings
 * Operation: `set-blueprinter-blessings`
 * Set blessings for a role.
 * @param {string} params.blueprinterRoleId User-specified Blueprinter role ID.
 * @param {integer} params.version When specified, checks for conflicts against the version stored in the persistent store (returned in 'x-cloud-resource-version' of the GET request).
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see Accepts a request payload of type {@link Blessings}
 * @see 200 The blessings were successfully set. See {@link RoleAggregate}
 * @see 404 The role can't be found. (code: `roles.not_found`). See {@link BasicFailedReply}
 * @see 409 Your request failed because the specified version does not match the persisted version. (code: `roles.version_conflict`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function setBlueprinterBlessingsUrl(params: SetBlueprinterBlessingsUrlParams): string {
  const queryString = _buildQueryString({
    version: params.version,
  })
  const path = _encode`/${params.regionId}/platform/infrastructure/blueprinter/roles/${params.blueprinterRoleId}/blessings`
  return `${_getBasePath()}${path}${queryString}`
}

export interface SetBlueprinterBlessingsUrlParams {
  blueprinterRoleId: string
  version?: number | null
  regionId: string
}

/**
 * PUT /api/v1/regions/{region_id}/platform/infrastructure/blueprinter/roles/{blueprinter_role_id}/blessings/{runner_id}
 * Operation: `add-blueprinter-blessing`
 * Add a Blessing for a runner to a role.
 * @param {string} params.blueprinterRoleId User-specified Blueprinter role ID.
 * @param {string} params.runnerId Runner ID for a blessing associated with a role.
 * @param {integer} params.version When specified, checks for conflicts against the version stored in the persistent store (returned in 'x-cloud-resource-version' of the GET request).
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see Accepts a request payload of type {@link Blessing}
 * @see 200 Blessing added successfully. See {@link RoleAggregate}
 * @see 404 The role can't be found. (code: `roles.not_found`). See {@link BasicFailedReply}
 * @see 409 Your request failed because the specified version does not match the persisted version. (code: `roles.version_conflict`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function addBlueprinterBlessingUrl(params: AddBlueprinterBlessingUrlParams): string {
  const queryString = _buildQueryString({
    version: params.version,
  })
  const path = _encode`/${params.regionId}/platform/infrastructure/blueprinter/roles/${params.blueprinterRoleId}/blessings/${params.runnerId}`
  return `${_getBasePath()}${path}${queryString}`
}

export interface AddBlueprinterBlessingUrlParams {
  blueprinterRoleId: string
  runnerId: string
  version?: number | null
  regionId: string
}

/**
 * GET /api/v1/regions/{region_id}/platform/infrastructure/constructors
 * Operation: `get-constructors`
 * Retrieves an overview of all constructors in an ECE installation.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 An overview of all constructors. See {@link ConstructorOverview}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getConstructorsUrl(params: GetConstructorsUrlParams): string {
  const path = _encode`/${params.regionId}/platform/infrastructure/constructors`
  return `${_getBasePath()}${path}`
}

export interface GetConstructorsUrlParams {
  regionId: string
}

/**
 * POST /api/v1/regions/{region_id}/platform/infrastructure/constructors/_resync
 * Operation: `resync-constructors`
 * Asynchronously synchronizes all constructor information in the back-end search index.
 * @param {boolean} params.skipMatchingVersion When true, skips the document indexing when the version matches the in-memory copy. Default: true
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 202 The ids of documents, organized by model version, that will be synchronized. See {@link ModelVersionIndexSynchronizationResults}
 * @return {string} A fully-interpolated and escaped URL
 */
export function resyncConstructorsUrl(params: ResyncConstructorsUrlParams): string {
  const queryString = _buildQueryString({
    skip_matching_version:
      params.skipMatchingVersion === undefined ? true : params.skipMatchingVersion,
  })
  const path = _encode`/${params.regionId}/platform/infrastructure/constructors/_resync`
  return `${_getBasePath()}${path}${queryString}`
}

export interface ResyncConstructorsUrlParams {
  skipMatchingVersion?: boolean | null
  regionId: string
}

/**
 * GET /api/v1/regions/{region_id}/platform/infrastructure/constructors/{constructor_id}
 * Operation: `get-constructor`
 * Retrieves a constructor by id.
 * @param {string} params.constructorId Identifier for the constructor.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 The information for the constructor specified by {constructor_id}. See {@link ConstructorInfo}
 * @see 404 The constructor specified by {constructor_id} cannot be found. (code: `constructors.constructor_not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getConstructorUrl(params: GetConstructorUrlParams): string {
  const path = _encode`/${params.regionId}/platform/infrastructure/constructors/${params.constructorId}`
  return `${_getBasePath()}${path}`
}

export interface GetConstructorUrlParams {
  constructorId: string
  regionId: string
}

/**
 * POST /api/v1/regions/{region_id}/platform/infrastructure/constructors/{constructor_id}/_resync
 * Operation: `resync-constructor`
 * Immediately resynchronizes the search index and cache for the selected constructor.
 * @param {string} params.constructorId Identifier for the constructor.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 The constructor resync operation executed successfully
 * @see 500 The constructor resync operation failed for allocator {constructor_id}. (code: `constructors.resync_failed`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function resyncConstructorUrl(params: ResyncConstructorUrlParams): string {
  const path = _encode`/${params.regionId}/platform/infrastructure/constructors/${params.constructorId}/_resync`
  return `${_getBasePath()}${path}`
}

export interface ResyncConstructorUrlParams {
  constructorId: string
  regionId: string
}

/**
 * GET /api/v1/regions/{region_id}/platform/infrastructure/constructors/{constructor_id}/logging_settings
 * Operation: `get-constructor-logging-settings`
 * Get the logging settings for this constructor instance.
 * @param {string} params.constructorId Identifier for the constructor.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 The logging settings for the constructor instance. See {@link LoggingSettings}
 * @see 404 The logging settings for this constructor were not found. (code: `constructors.logging_settings.not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getConstructorLoggingSettingsUrl(
  params: GetConstructorLoggingSettingsUrlParams,
): string {
  const path = _encode`/${params.regionId}/platform/infrastructure/constructors/${params.constructorId}/logging_settings`
  return `${_getBasePath()}${path}`
}

export interface GetConstructorLoggingSettingsUrlParams {
  constructorId: string
  regionId: string
}

/**
 * PUT /api/v1/regions/{region_id}/platform/infrastructure/constructors/{constructor_id}/logging_settings
 * Operation: `set-constructor-logging-settings`
 * Set the logging settings for this constructor instance.
 * @param {string} params.constructorId Identifier for the constructor.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see Accepts a request payload of type {@link LoggingSettings}
 * @see 200 The updated logging settings for the constructor instance. See {@link LoggingSettings}
 * @see 404 The logging settings for this constructor were not found. (code: `constructors.logging_settings.not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function setConstructorLoggingSettingsUrl(
  params: SetConstructorLoggingSettingsUrlParams,
): string {
  const path = _encode`/${params.regionId}/platform/infrastructure/constructors/${params.constructorId}/logging_settings`
  return `${_getBasePath()}${path}`
}

export interface SetConstructorLoggingSettingsUrlParams {
  constructorId: string
  regionId: string
}

/**
 * DELETE /api/v1/regions/{region_id}/platform/infrastructure/constructors/{constructor_id}/logging_settings
 * Operation: `delete-constructor-logging-settings`
 * Reset the default logging settings for this constructor instance.
 * @param {string} params.constructorId Identifier for the constructor.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 The updated logging settings for the constructor instance. See {@link LoggingSettings}
 * @see 404 The logging settings for this constructor were not found. (code: `constructors.logging_settings.not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function deleteConstructorLoggingSettingsUrl(
  params: DeleteConstructorLoggingSettingsUrlParams,
): string {
  const path = _encode`/${params.regionId}/platform/infrastructure/constructors/${params.constructorId}/logging_settings`
  return `${_getBasePath()}${path}`
}

export interface DeleteConstructorLoggingSettingsUrlParams {
  constructorId: string
  regionId: string
}

/**
 * PATCH /api/v1/regions/{region_id}/platform/infrastructure/constructors/{constructor_id}/logging_settings
 * Operation: `update-constructor-logging-settings`
 * All changes in the specified object are applied to the logging settings for this constructor instance according to the JSON Merge Patch processing rules. Omitting existing fields causes the same values to be reapplied. Specifying a `null` value reverts the field to the default value, or removes the field when no default value exists.
 * @param {string} params.constructorId Identifier for the constructor.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see Accepts a request payload of type {@link string}
 * @see 200 The updated logging settings for the constructor instance. See {@link LoggingSettings}
 * @see 400 The update request is invalid. (code: `patch.request_malformed`). See {@link BasicFailedReply}
 * @see 404 The logging settings for this constructor were not found. (code: `constructors.logging_settings.not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function updateConstructorLoggingSettingsUrl(
  params: UpdateConstructorLoggingSettingsUrlParams,
): string {
  const path = _encode`/${params.regionId}/platform/infrastructure/constructors/${params.constructorId}/logging_settings`
  return `${_getBasePath()}${path}`
}

export interface UpdateConstructorLoggingSettingsUrlParams {
  constructorId: string
  regionId: string
}

/**
 * POST /api/v1/regions/{region_id}/platform/infrastructure/constructors/{constructor_id}/maintenance-mode/_start
 * Operation: `start-constructor-maintenance-mode`
 * Start maintenance mode of a constructor. It will stop constructing new plans while continuing with on-going ones.
 * @param {string} params.constructorId Identifier for the constructor.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 202 The start maintenance mode command was issued successfully
 * @see 403 The start maintenance mode command was prohibited for the given constructor. (code: `constructors.command_prohibited`). See {@link BasicFailedReply}
 * @see 404 The constructor specified by {constructor_id} cannot be found. (code: `constructors.constructor_not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function startConstructorMaintenanceModeUrl(
  params: StartConstructorMaintenanceModeUrlParams,
): string {
  const path = _encode`/${params.regionId}/platform/infrastructure/constructors/${params.constructorId}/maintenance-mode/_start`
  return `${_getBasePath()}${path}`
}

export interface StartConstructorMaintenanceModeUrlParams {
  constructorId: string
  regionId: string
}

/**
 * POST /api/v1/regions/{region_id}/platform/infrastructure/constructors/{constructor_id}/maintenance-mode/_stop
 * Operation: `stop-constructor-maintenance-mode`
 * Stop maintenance mode of a constructor. It will resume the construction of submitted plans.
 * @param {string} params.constructorId Identifier for the constructor.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 202 The stop maintenance mode command was issued successfully
 * @see 403 The stop maintenance mode command was prohibited for the given constructor. (code: `constructors.command_prohibited`). See {@link BasicFailedReply}
 * @see 404 The constructor specified by {constructor_id} cannot be found. (code: `constructors.constructor_not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function stopConstructorMaintenanceModeUrl(
  params: StopConstructorMaintenanceModeUrlParams,
): string {
  const path = _encode`/${params.regionId}/platform/infrastructure/constructors/${params.constructorId}/maintenance-mode/_stop`
  return `${_getBasePath()}${path}`
}

export interface StopConstructorMaintenanceModeUrlParams {
  constructorId: string
  regionId: string
}

/**
 * GET /api/v1/regions/{region_id}/platform/infrastructure/container-sets
 * Operation: `get-container-sets`
 * Retrieves an overview of all container sets.
 * @param {boolean} params.hidden Include hidden container sets in output.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 An overview of all container sets. See {@link ContainerSetsOverview}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getContainerSetsUrl(params: GetContainerSetsUrlParams): string {
  const queryString = _buildQueryString({
    hidden: params.hidden === undefined ? false : params.hidden,
  })
  const path = _encode`/${params.regionId}/platform/infrastructure/container-sets`
  return `${_getBasePath()}${path}${queryString}`
}

export interface GetContainerSetsUrlParams {
  hidden?: boolean | null
  regionId: string
}

/**
 * POST /api/v1/regions/{region_id}/platform/infrastructure/container-sets
 * Operation: `create-container-set`
 * Creates a new container set, with optional id.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see Accepts an optional request payload of type {@link ContainerSet}
 * @see 200 The container set was created. See {@link ContainerSet}
 * @see 400 The container set create failed. (code: `container_sets.create_failed`). See {@link BasicFailedReply}
 * @see 409 Container Set name already in use. (code: `container_sets.name_conflict`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function createContainerSetUrl(params: CreateContainerSetUrlParams): string {
  const path = _encode`/${params.regionId}/platform/infrastructure/container-sets`
  return `${_getBasePath()}${path}`
}

export interface CreateContainerSetUrlParams {
  regionId: string
}

/**
 * GET /api/v1/regions/{region_id}/platform/infrastructure/container-sets/{containerset_id}
 * Operation: `get-container-set`
 * Retrieves a container set by id.
 * @param {string} params.containersetId Identifier for the container set.
 * @param {string} params.include Comma delimited list of properties to include in the output.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 The information for the container set specified by {containerset_id}. See {@link ContainerSetDetails}
 * @see 404 The container set specified by {containerset_id} cannot be found. (code: `container_sets.container_set_not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getContainerSetUrl(params: GetContainerSetUrlParams): string {
  const queryString = _buildQueryString({
    include: params.include,
  })
  const path = _encode`/${params.regionId}/platform/infrastructure/container-sets/${params.containersetId}`
  return `${_getBasePath()}${path}${queryString}`
}

export interface GetContainerSetUrlParams {
  containersetId: string
  include?: string | null
  regionId: string
}

/**
 * DELETE /api/v1/regions/{region_id}/platform/infrastructure/container-sets/{containerset_id}
 * Operation: `delete-container-set`
 * Deletes a container set.
 * @param {string} params.containersetId Identifier for the container set.
 * @param {string} params.version The container set version.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 The container set was deleted
 * @see 400 The container set delete failed. (code: `container_sets.delete_failed`). See {@link BasicFailedReply}
 * @see 404 The container set specified by {containerset_id} cannot be found. (code: `container_sets.container_set_not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function deleteContainerSetUrl(params: DeleteContainerSetUrlParams): string {
  const queryString = _buildQueryString({
    version: params.version,
  })
  const path = _encode`/${params.regionId}/platform/infrastructure/container-sets/${params.containersetId}`
  return `${_getBasePath()}${path}${queryString}`
}

export interface DeleteContainerSetUrlParams {
  containersetId: string
  version?: string | null
  regionId: string
}

/**
 * GET /api/v1/regions/{region_id}/platform/infrastructure/container-sets/{containerset_id}/containers
 * Operation: `get-containers`
 * Lists containers by container-set id.
 * @param {string} params.containersetId Identifier for the container set.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 The overview of containers belonging to the container-set specified by {container_set_id}. See {@link ContainersOverview}
 * @see 404 The container set cannot be found. (code: `container_sets.container_set_not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getContainersUrl(params: GetContainersUrlParams): string {
  const path = _encode`/${params.regionId}/platform/infrastructure/container-sets/${params.containersetId}/containers`
  return `${_getBasePath()}${path}`
}

export interface GetContainersUrlParams {
  containersetId: string
  regionId: string
}

/**
 * POST /api/v1/regions/{region_id}/platform/infrastructure/container-sets/{containerset_id}/containers
 * Operation: `create-container`
 * Creates a new container.
 * @param {string} params.containersetId Identifier for the container set.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see Accepts a request payload of type {@link Container}
 * @see 200 The container was created. See {@link Container}
 * @see 400 The container create failed. (code: `container_sets.container_create_failed`). See {@link BasicFailedReply}
 * @see 404 The container set cannot be found. (code: `container_sets.container_set_not_found`). See {@link BasicFailedReply}
 * @see 409 Container name already in use. (code: `container_sets.container_name_conflict`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function createContainerUrl(params: CreateContainerUrlParams): string {
  const path = _encode`/${params.regionId}/platform/infrastructure/container-sets/${params.containersetId}/containers`
  return `${_getBasePath()}${path}`
}

export interface CreateContainerUrlParams {
  containersetId: string
  regionId: string
}

/**
 * GET /api/v1/regions/{region_id}/platform/infrastructure/container-sets/{containerset_id}/containers/{container_id}
 * Operation: `get-container`
 * Retrieves a container by id.
 * @param {string} params.containersetId Identifier for the container set.
 * @param {string} params.containerId Identifier for the container.
 * @param {string} params.include Comma delimited list of properties to include in the output. Currently, only 'inspect' is supported for containers.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 The information for the container specified by {container_id}. See {@link Container}
 * @see 404 * The container set cannot be found. (code: `container_sets.container_set_not_found`)
 * The container cannot be found. (code: `container_sets.container_not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getContainerUrl(params: GetContainerUrlParams): string {
  const queryString = _buildQueryString({
    include: params.include,
  })
  const path = _encode`/${params.regionId}/platform/infrastructure/container-sets/${params.containersetId}/containers/${params.containerId}`
  return `${_getBasePath()}${path}${queryString}`
}

export interface GetContainerUrlParams {
  containersetId: string
  containerId: string
  include?: string | null
  regionId: string
}

/**
 * DELETE /api/v1/regions/{region_id}/platform/infrastructure/container-sets/{containerset_id}/containers/{container_id}
 * Operation: `delete-container`
 * Deletes a container.
 * @param {string} params.containersetId Identifier for the container set.
 * @param {string} params.containerId Identifier for the container.
 * @param {string} params.version The container version.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 The container was deleted
 * @see 400 The container delete failed. (code: `container_sets.container_delete_failed`). See {@link BasicFailedReply}
 * @see 404 * The container set cannot be found. (code: `container_sets.container_set_not_found`)
 * The container cannot be found. (code: `container_sets.container_not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function deleteContainerUrl(params: DeleteContainerUrlParams): string {
  const queryString = _buildQueryString({
    version: params.version,
  })
  const path = _encode`/${params.regionId}/platform/infrastructure/container-sets/${params.containersetId}/containers/${params.containerId}`
  return `${_getBasePath()}${path}${queryString}`
}

export interface DeleteContainerUrlParams {
  containersetId: string
  containerId: string
  version?: string | null
  regionId: string
}

/**
 * PATCH /api/v1/regions/{region_id}/platform/infrastructure/container-sets/{containerset_id}/containers/{container_id}
 * Operation: `patch-container`
 * All changes in the specified object are applied to an existing container according to the JSON Merge Patch processing rules. Omitting existing fields causes the same values to be reapplied. Specifying a `null` value reverts the field to the default value, or removes the field when no default value exists.
 * @param {string} params.containersetId Identifier for the container set.
 * @param {string} params.containerId Identifier for the container.
 * @param {string} params.version The container version.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see Accepts a request payload of type {@link string}
 * @see 200 The container was updated. See {@link Container}
 * @see 400 The container update failed. (code: `container_sets.container_update_failed`). See {@link BasicFailedReply}
 * @see 404 * The container set cannot be found. (code: `container_sets.container_set_not_found`)
 * The container cannot be found. (code: `container_sets.container_not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function patchContainerUrl(params: PatchContainerUrlParams): string {
  const queryString = _buildQueryString({
    version: params.version,
  })
  const path = _encode`/${params.regionId}/platform/infrastructure/container-sets/${params.containersetId}/containers/${params.containerId}`
  return `${_getBasePath()}${path}${queryString}`
}

export interface PatchContainerUrlParams {
  containersetId: string
  containerId: string
  version?: string | null
  regionId: string
}

/**
 * GET /api/v1/regions/{region_id}/platform/infrastructure/coordinators
 * Operation: `get-coordinators`
 * Retrieves an overview of all coordinators.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 An overview of all coordinators. See {@link CoordinatorsSummary}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getCoordinatorsUrl(params: GetCoordinatorsUrlParams): string {
  const path = _encode`/${params.regionId}/platform/infrastructure/coordinators`
  return `${_getBasePath()}${path}`
}

export interface GetCoordinatorsUrlParams {
  regionId: string
}

/**
 * GET /api/v1/regions/{region_id}/platform/infrastructure/coordinators/candidates
 * Operation: `get-coordinator-candidates`
 * Retrieve a list of all coordinator candidates.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 A list of all coordinator candidates. See {@link CoordinatorCandidatesSummary}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getCoordinatorCandidatesUrl(params: GetCoordinatorCandidatesUrlParams): string {
  const path = _encode`/${params.regionId}/platform/infrastructure/coordinators/candidates`
  return `${_getBasePath()}${path}`
}

export interface GetCoordinatorCandidatesUrlParams {
  regionId: string
}

/**
 * GET /api/v1/regions/{region_id}/platform/infrastructure/coordinators/candidates/{coordinator_candidate_id}
 * Operation: `get-coordinator-candidate`
 * Retrieve a coordinator candidate.
 * @param {string} params.coordinatorCandidateId The identifier for the coordinator candidate.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 Info about a coordinator candidate. See {@link CoordinatorCandidateInfo}
 * @see 404 Unable to find coordinator candidate {coordinator_candidate_id}. Edit your request, then try again. (code: `coordinators.candidate_not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getCoordinatorCandidateUrl(params: GetCoordinatorCandidateUrlParams): string {
  const path = _encode`/${params.regionId}/platform/infrastructure/coordinators/candidates/${params.coordinatorCandidateId}`
  return `${_getBasePath()}${path}`
}

export interface GetCoordinatorCandidateUrlParams {
  coordinatorCandidateId: string
  regionId: string
}

/**
 * DELETE /api/v1/regions/{region_id}/platform/infrastructure/coordinators/candidates/{coordinator_candidate_id}
 * Operation: `delete-coordinator-candidate`
 * Delete a coordinator candidate by id.
 * @param {string} params.coordinatorCandidateId The identifier for the coordinator candidate.
 * @param {string} params.version cloud resource version.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 The coordinator candidate specified by {coordinator_candidate_id} was deleted.
 * @see 400 The coordinator specified by {coordinator_candidate_id} could not be deleted. (code: `coordinators.candidate_deletion_failed`). See {@link BasicFailedReply}
 * @see 404 The coordinator candidate specified by {coordinator_candidate_id} cannot be found. (code: `coordinators.candidate_not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function deleteCoordinatorCandidateUrl(params: DeleteCoordinatorCandidateUrlParams): string {
  const queryString = _buildQueryString({
    version: params.version,
  })
  const path = _encode`/${params.regionId}/platform/infrastructure/coordinators/candidates/${params.coordinatorCandidateId}`
  return `${_getBasePath()}${path}${queryString}`
}

export interface DeleteCoordinatorCandidateUrlParams {
  coordinatorCandidateId: string
  version?: string | null
  regionId: string
}

/**
 * POST /api/v1/regions/{region_id}/platform/infrastructure/coordinators/candidates/{coordinator_candidate_id}/_promote
 * Operation: `promote-coordinator-candidate`
 * Promotes a coordinator candidate.
 * @param {string} params.coordinatorCandidateId The identifier for the coordinator candidate.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 202 Accepted promote of coordinator candidate. See {@link CoordinatorCandidateInfo}
 * @see 404 Unable to find coordinator candidate {coordinator_id}. Edit your request, then try again. (code: `coordinators.candidate_not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function promoteCoordinatorCandidateUrl(
  params: PromoteCoordinatorCandidateUrlParams,
): string {
  const path = _encode`/${params.regionId}/platform/infrastructure/coordinators/candidates/${params.coordinatorCandidateId}/_promote`
  return `${_getBasePath()}${path}`
}

export interface PromoteCoordinatorCandidateUrlParams {
  coordinatorCandidateId: string
  regionId: string
}

/**
 * GET /api/v1/regions/{region_id}/platform/infrastructure/coordinators/{coordinator_id}
 * Operation: `get-coordinator`
 * Retrieve overview of a single coordinator.
 * @param {string} params.coordinatorId The identifier for the coordinator.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 An overview of a coordinator instance. See {@link CoordinatorSummary}
 * @see 404 Unable to find coordinator {coordinator_id}. Edit your request, then try again. (code: `coordinators.coordinator_not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getCoordinatorUrl(params: GetCoordinatorUrlParams): string {
  const path = _encode`/${params.regionId}/platform/infrastructure/coordinators/${params.coordinatorId}`
  return `${_getBasePath()}${path}`
}

export interface GetCoordinatorUrlParams {
  coordinatorId: string
  regionId: string
}

/**
 * POST /api/v1/regions/{region_id}/platform/infrastructure/coordinators/{coordinator_id}/_demote
 * Operation: `demote-coordinator`
 * Demotes a coordinator.
 * @param {string} params.coordinatorId The identifier for the coordinator.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 Accepted demote of coordinator.
 * @see 400 The coordinator specified by {coordinator_id} could not be demoted. (code: `coordinators.coordinator_demote_failed`). See {@link BasicFailedReply}
 * @see 404 Unable to find the coordinator {coordinator_id}. Edit your request, then try again. (code: `coordinators.coordinator_not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function demoteCoordinatorUrl(params: DemoteCoordinatorUrlParams): string {
  const path = _encode`/${params.regionId}/platform/infrastructure/coordinators/${params.coordinatorId}/_demote`
  return `${_getBasePath()}${path}`
}

export interface DemoteCoordinatorUrlParams {
  coordinatorId: string
  regionId: string
}

/**
 * GET /api/v1/regions/{region_id}/platform/infrastructure/proxies
 * Operation: `get-proxies`
 * Retrieves an overview of all proxies.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 An overview of all proxies. See {@link ProxyOverview}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getProxiesUrl(params: GetProxiesUrlParams): string {
  const path = _encode`/${params.regionId}/platform/infrastructure/proxies`
  return `${_getBasePath()}${path}`
}

export interface GetProxiesUrlParams {
  regionId: string
}

/**
 * POST /api/v1/regions/{region_id}/platform/infrastructure/proxies/filtered-groups
 * Operation: `create-proxies-filtered-group`
 * Create the settings for a filtered group of proxies.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see Accepts a request payload of type {@link ProxiesFilteredGroup}
 * @see 200 Returns the created or updated filtered group of proxies. See {@link ProxiesFilteredGroup}
 * @see 400 * The filtered group of proxies has empty id. (code: `proxies.proxies_filtered_group_empty_id`)
 * A filtered group of proxies with the same identifier already exists. (code: `proxies.proxies_filtered_group_already_exists`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function createProxiesFilteredGroupUrl(params: CreateProxiesFilteredGroupUrlParams): string {
  const path = _encode`/${params.regionId}/platform/infrastructure/proxies/filtered-groups`
  return `${_getBasePath()}${path}`
}

export interface CreateProxiesFilteredGroupUrlParams {
  regionId: string
}

/**
 * GET /api/v1/regions/{region_id}/platform/infrastructure/proxies/filtered-groups/{proxies_filtered_group_id}
 * Operation: `get-proxies-filtered-group`
 * Get data for a filtered group of proxies.
 * @param {string} params.proxiesFilteredGroupId "The identifier for the filtered group of proxies.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 Data for the filtered group of proxies identified by {proxies_filtered_group_id}. See {@link ProxiesFilteredGroup}
 * @see 404 Unable to find the {proxies_filtered_group_id} specified filtered group of proxies. Edit your request, then try again. (code: `proxies.proxies_filtered_group_not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getProxiesFilteredGroupUrl(params: GetProxiesFilteredGroupUrlParams): string {
  const path = _encode`/${params.regionId}/platform/infrastructure/proxies/filtered-groups/${params.proxiesFilteredGroupId}`
  return `${_getBasePath()}${path}`
}

export interface GetProxiesFilteredGroupUrlParams {
  proxiesFilteredGroupId: string
  regionId: string
}

/**
 * PUT /api/v1/regions/{region_id}/platform/infrastructure/proxies/filtered-groups/{proxies_filtered_group_id}
 * Operation: `update-proxies-filtered-group`
 * Update the settings for a filtered group of proxies.
 * @param {string} params.proxiesFilteredGroupId "The identifier for the filtered group of proxies.
 * @param {string} params.version Checks for conflicts against the metadata version, then returns the value in the `x-cloud-resource-version` header.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see Accepts a request payload of type {@link ProxiesFilteredGroup}
 * @see 200 Returns the created or updated filtered group of proxies. See {@link ProxiesFilteredGroup}
 * @see 400 The provided identifier doesn't match the identifier in the object. (code: `proxies.proxies_filtered_group_id_conflict`). See {@link BasicFailedReply}
 * @see 409 There is a version conflict. (code: `proxies.proxies_filtered_group_version_conflict`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function updateProxiesFilteredGroupUrl(params: UpdateProxiesFilteredGroupUrlParams): string {
  const queryString = _buildQueryString({
    version: params.version,
  })
  const path = _encode`/${params.regionId}/platform/infrastructure/proxies/filtered-groups/${params.proxiesFilteredGroupId}`
  return `${_getBasePath()}${path}${queryString}`
}

export interface UpdateProxiesFilteredGroupUrlParams {
  proxiesFilteredGroupId: string
  version?: string | null
  regionId: string
}

/**
 * DELETE /api/v1/regions/{region_id}/platform/infrastructure/proxies/filtered-groups/{proxies_filtered_group_id}
 * Operation: `delete-proxies-filtered-group`
 * Deletes a filtered group of proxies by ID.
 * @param {string} params.proxiesFilteredGroupId "The identifier for the filtered group of proxies.
 * @param {string} params.version Checks for conflicts against the metadata version, then returns the value in the `x-cloud-resource-version` header.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 The filtered group of proxies was successfully deleted
 * @see 404 Unable to find the {proxies_filtered_group_id} specified filtered group of proxies. Edit your request, then try again. (code: `proxies.proxies_filtered_group_not_found`). See {@link BasicFailedReply}
 * @see 409 There is a version conflict. (code: `proxies.proxies_filtered_group_version_conflict`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function deleteProxiesFilteredGroupUrl(params: DeleteProxiesFilteredGroupUrlParams): string {
  const queryString = _buildQueryString({
    version: params.version,
  })
  const path = _encode`/${params.regionId}/platform/infrastructure/proxies/filtered-groups/${params.proxiesFilteredGroupId}`
  return `${_getBasePath()}${path}${queryString}`
}

export interface DeleteProxiesFilteredGroupUrlParams {
  proxiesFilteredGroupId: string
  version?: string | null
  regionId: string
}

/**
 * GET /api/v1/regions/{region_id}/platform/infrastructure/proxies/filtered-groups/{proxies_filtered_group_id}/health
 * Operation: `get-proxies-filtered-group-health`
 * Get the health status of a filtered group of proxies.
 * @param {string} params.proxiesFilteredGroupId "The identifier for the filtered group of proxies.
 * @param {string} params.expectStatus The expected status.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 Returns health information on a filtered group of proxies. See {@link ProxiesFilteredGroupHealth}
 * @see 417 The health status is worse than the expected one. See {@link ProxiesFilteredGroupHealth}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getProxiesFilteredGroupHealthUrl(
  params: GetProxiesFilteredGroupHealthUrlParams,
): string {
  const queryString = _buildQueryString({
    expect_status: params.expectStatus,
  })
  const path = _encode`/${params.regionId}/platform/infrastructure/proxies/filtered-groups/${params.proxiesFilteredGroupId}/health`
  return `${_getBasePath()}${path}${queryString}`
}

export interface GetProxiesFilteredGroupHealthUrlParams {
  proxiesFilteredGroupId: string
  expectStatus?: string | null
  regionId: string
}

/**
 * GET /api/v1/regions/{region_id}/platform/infrastructure/proxies/health
 * Operation: `get-proxies-health`
 * Get the health status of all proxies.
 * @param {string} params.expectStatus The expected status.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 Returns health information on all the proxies. See {@link ProxiesHealth}
 * @see 417 The health status is worse than the expected one. See {@link ProxiesHealth}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getProxiesHealthUrl(params: GetProxiesHealthUrlParams): string {
  const queryString = _buildQueryString({
    expect_status: params.expectStatus,
  })
  const path = _encode`/${params.regionId}/platform/infrastructure/proxies/health`
  return `${_getBasePath()}${path}${queryString}`
}

export interface GetProxiesHealthUrlParams {
  expectStatus?: string | null
  regionId: string
}

/**
 * GET /api/v1/regions/{region_id}/platform/infrastructure/proxies/settings
 * Operation: `get-proxies-settings`
 * > WARNING > This endpoint is deprecated and scheduled to be removed in the next major version.  Retrieves the settings for all proxies.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 Settings for all proxies. See {@link ProxiesSettings}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getProxiesSettingsUrl(params: GetProxiesSettingsUrlParams): string {
  const path = _encode`/${params.regionId}/platform/infrastructure/proxies/settings`
  return `${_getBasePath()}${path}`
}

export interface GetProxiesSettingsUrlParams {
  regionId: string
}

/**
 * PUT /api/v1/regions/{region_id}/platform/infrastructure/proxies/settings
 * Operation: `set-proxies-settings`
 * > WARNING > This endpoint is deprecated and scheduled to be removed in the next major version.  Overwrites the proxy settings. All unspecified fields are deleted.
 * @param {string} params.version If specified, checks for conflicts against the version of the settings (returned in 'x-cloud-resource-version' of the GET request).
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see Accepts a request payload of type {@link ProxiesSettings}
 * @see 200 Returns the updated settings. See {@link ProxiesSettings}
 * @see 409 There is a version conflict. (code: `proxies.version_conflict`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function setProxiesSettingsUrl(params: SetProxiesSettingsUrlParams): string {
  const queryString = _buildQueryString({
    version: params.version,
  })
  const path = _encode`/${params.regionId}/platform/infrastructure/proxies/settings`
  return `${_getBasePath()}${path}${queryString}`
}

export interface SetProxiesSettingsUrlParams {
  version?: string | null
  regionId: string
}

/**
 * PATCH /api/v1/regions/{region_id}/platform/infrastructure/proxies/settings
 * Operation: `update-proxies-settings`
 * > WARNING > This endpoint is deprecated and scheduled to be removed in the next major version.  Applies the settings as a patch. Only the fields that are referenced in the update are changed.
 * @param {string} params.version If specified, checks for conflicts against the version of the repository configuration (returned in 'x-cloud-resource-version' of the GET request).
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see Accepts a request payload of type {@link string}
 * @see 200 Returns the updated settings. See {@link ProxiesSettings}
 * @see 409 There is a version conflict. (code: `proxies.version_conflict`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function updateProxiesSettingsUrl(params: UpdateProxiesSettingsUrlParams): string {
  const queryString = _buildQueryString({
    version: params.version,
  })
  const path = _encode`/${params.regionId}/platform/infrastructure/proxies/settings`
  return `${_getBasePath()}${path}${queryString}`
}

export interface UpdateProxiesSettingsUrlParams {
  version?: string | null
  regionId: string
}

/**
 * GET /api/v1/regions/{region_id}/platform/infrastructure/proxies/{proxy_id}
 * Operation: `get-proxy`
 * Retrieves a single proxy by ID.
 * @param {string} params.proxyId The identifier for the proxy.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 The information for the proxy specified by {proxy_id}. See {@link ProxyInfo}
 * @see 404 Unable to find the {proxy_id} specified proxy. Edit your request, then try again. (code: `proxies.proxy_not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getProxyUrl(params: GetProxyUrlParams): string {
  const path = _encode`/${params.regionId}/platform/infrastructure/proxies/${params.proxyId}`
  return `${_getBasePath()}${path}`
}

export interface GetProxyUrlParams {
  proxyId: string
  regionId: string
}

/**
 * GET /api/v1/regions/{region_id}/platform/infrastructure/runners
 * Operation: `get-runners`
 * Retrieves an overview of all runners.
 * @param {string} params.q An optional query to filter runners by. Maps to an Elasticsearch query_string query.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 An overview of all runners. See {@link RunnerOverview}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getRunnersUrl(params: GetRunnersUrlParams): string {
  const queryString = _buildQueryString({
    q: params.q,
  })
  const path = _encode`/${params.regionId}/platform/infrastructure/runners`
  return `${_getBasePath()}${path}${queryString}`
}

export interface GetRunnersUrlParams {
  q?: string | null
  regionId: string
}

/**
 * POST /api/v1/regions/{region_id}/platform/infrastructure/runners/_resync
 * Operation: `resync-runners`
 * Asynchronously synchronizes all runner information in the search index.
 * @param {boolean} params.skipMatchingVersion When true, skips the document indexing when the version matches the in-memory copy. Default: true
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 202 The ids of documents, organized by model version, that will be synchronized. See {@link ModelVersionIndexSynchronizationResults}
 * @return {string} A fully-interpolated and escaped URL
 */
export function resyncRunnersUrl(params: ResyncRunnersUrlParams): string {
  const queryString = _buildQueryString({
    skip_matching_version:
      params.skipMatchingVersion === undefined ? true : params.skipMatchingVersion,
  })
  const path = _encode`/${params.regionId}/platform/infrastructure/runners/_resync`
  return `${_getBasePath()}${path}${queryString}`
}

export interface ResyncRunnersUrlParams {
  skipMatchingVersion?: boolean | null
  regionId: string
}

/**
 * POST /api/v1/regions/{region_id}/platform/infrastructure/runners/_search
 * Operation: `search-runners`
 * Retrieves runners that match a given query.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see Accepts an optional request payload of type {@link SearchRequest}
 * @see 200 An overview of runners that matched the given search query. See {@link RunnerOverview}
 * @see 400 The search request failed. (code: `runners.search_failed`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function searchRunnersUrl(params: SearchRunnersUrlParams): string {
  const path = _encode`/${params.regionId}/platform/infrastructure/runners/_search`
  return `${_getBasePath()}${path}`
}

export interface SearchRunnersUrlParams {
  regionId: string
}

/**
 * GET /api/v1/regions/{region_id}/platform/infrastructure/runners/{runner_id}
 * Operation: `get-runner`
 * Retrieves a runner by id.
 * @param {string} params.runnerId The identifier for the runner.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 The information for the runner specified by {runner_id}. See {@link RunnerInfo}
 * @see 404 Unable to find the {runner_id} specified runner. Edit your request, then try again. (code: `runners.runner_not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getRunnerUrl(params: GetRunnerUrlParams): string {
  const path = _encode`/${params.regionId}/platform/infrastructure/runners/${params.runnerId}`
  return `${_getBasePath()}${path}`
}

export interface GetRunnerUrlParams {
  runnerId: string
  regionId: string
}

/**
 * DELETE /api/v1/regions/{region_id}/platform/infrastructure/runners/{runner_id}
 * Operation: `delete-runner`
 * Delete a runner by id.
 * @param {string} params.runnerId The identifier for the runner.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 The runner specified by {runner_id} was deleted.
 * @see 400 The runner specified by {runner_id} could not be deleted. (code: `runners.runner_deletion_failed`). See {@link BasicFailedReply}
 * @see 404 The runner specified by {runner_id} cannot be found. (code: `runners.runner_not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function deleteRunnerUrl(params: DeleteRunnerUrlParams): string {
  const path = _encode`/${params.regionId}/platform/infrastructure/runners/${params.runnerId}`
  return `${_getBasePath()}${path}`
}

export interface DeleteRunnerUrlParams {
  runnerId: string
  regionId: string
}

/**
 * POST /api/v1/regions/{region_id}/platform/infrastructure/runners/{runner_id}/_resync
 * Operation: `resync-runner`
 * Immediately resynchronizes the search index and cache for the selected runner.
 * @param {string} params.runnerId The identifier for the runner.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 The runner resync operation executed successfully
 * @see 500 The runner resync operation failed for runner {runner_id}. (code: `runners.resync_failed`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function resyncRunnerUrl(params: ResyncRunnerUrlParams): string {
  const path = _encode`/${params.regionId}/platform/infrastructure/runners/${params.runnerId}/_resync`
  return `${_getBasePath()}${path}`
}

export interface ResyncRunnerUrlParams {
  runnerId: string
  regionId: string
}

/**
 * GET /api/v1/regions/{region_id}/platform/infrastructure/runners/{runner_id}/logging_settings
 * Operation: `get-runner-logging-settings`
 * Get the logging settings for this runner instance.
 * @param {string} params.runnerId The identifier for the runner.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 The logging settings for the runner instance. See {@link LoggingSettings}
 * @see 404 The logging settings for this runner were not found. (code: `runners.logging_settings.not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getRunnerLoggingSettingsUrl(params: GetRunnerLoggingSettingsUrlParams): string {
  const path = _encode`/${params.regionId}/platform/infrastructure/runners/${params.runnerId}/logging_settings`
  return `${_getBasePath()}${path}`
}

export interface GetRunnerLoggingSettingsUrlParams {
  runnerId: string
  regionId: string
}

/**
 * PUT /api/v1/regions/{region_id}/platform/infrastructure/runners/{runner_id}/logging_settings
 * Operation: `set-runner-logging-settings`
 * Set the logging settings for this runner instance.
 * @param {string} params.runnerId The identifier for the runner.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see Accepts a request payload of type {@link LoggingSettings}
 * @see 200 The updated logging settings for the runner instance. See {@link LoggingSettings}
 * @see 404 The logging settings for this runner were not found. (code: `runners.logging_settings.not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function setRunnerLoggingSettingsUrl(params: SetRunnerLoggingSettingsUrlParams): string {
  const path = _encode`/${params.regionId}/platform/infrastructure/runners/${params.runnerId}/logging_settings`
  return `${_getBasePath()}${path}`
}

export interface SetRunnerLoggingSettingsUrlParams {
  runnerId: string
  regionId: string
}

/**
 * DELETE /api/v1/regions/{region_id}/platform/infrastructure/runners/{runner_id}/logging_settings
 * Operation: `delete-runner-logging-settings`
 * Reset the default logging settings for this runner instance.
 * @param {string} params.runnerId The identifier for the runner.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 The updated logging settings for the runner instance. See {@link LoggingSettings}
 * @see 404 The logging settings for this runner were not found. (code: `runners.logging_settings.not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function deleteRunnerLoggingSettingsUrl(
  params: DeleteRunnerLoggingSettingsUrlParams,
): string {
  const path = _encode`/${params.regionId}/platform/infrastructure/runners/${params.runnerId}/logging_settings`
  return `${_getBasePath()}${path}`
}

export interface DeleteRunnerLoggingSettingsUrlParams {
  runnerId: string
  regionId: string
}

/**
 * PATCH /api/v1/regions/{region_id}/platform/infrastructure/runners/{runner_id}/logging_settings
 * Operation: `update-runner-logging-settings`
 * All changes in the specified object are applied to the logging settings for this runner instance according to the JSON Merge Patch processing rules. Omitting existing fields causes the same values to be reapplied. Specifying a `null` value reverts the field to the default value, or removes the field when no default value exists.
 * @param {string} params.runnerId The identifier for the runner.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see Accepts a request payload of type {@link string}
 * @see 200 The updated logging settings for the runner instance. See {@link LoggingSettings}
 * @see 400 The update request is invalid. (code: `patch.request_malformed`). See {@link BasicFailedReply}
 * @see 404 The logging settings for this runner were not found. (code: `runners.logging_settings.not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function updateRunnerLoggingSettingsUrl(
  params: UpdateRunnerLoggingSettingsUrlParams,
): string {
  const path = _encode`/${params.regionId}/platform/infrastructure/runners/${params.runnerId}/logging_settings`
  return `${_getBasePath()}${path}`
}

export interface UpdateRunnerLoggingSettingsUrlParams {
  runnerId: string
  regionId: string
}

/**
 * PUT /api/v1/regions/{region_id}/platform/infrastructure/runners/{runner_id}/roles
 * Operation: `set-runner-roles`
 * Set runner roles.
 * @param {string} params.runnerId The identifier for the runner.
 * @param {boolean} params.bless Assigns the runner to the roles.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see Accepts a request payload of type {@link RunnerRolesInfo}
 * @see 200 The roles for the {runner_id} specified runner are assigned. See {@link RunnerRolesInfo}
 * @see 400 There was an error while assigning the roles for the {runner_id} specified runner. Try your request again. (code: `runners.runner_set_roles_failed`). See {@link BasicFailedReply}
 * @see 404 Unable to find the {runner_id} specified runner. Edit your request, then try again. (code: `runners.runner_not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function setRunnerRolesUrl(params: SetRunnerRolesUrlParams): string {
  const queryString = _buildQueryString({
    bless: params.bless,
  })
  const path = _encode`/${params.regionId}/platform/infrastructure/runners/${params.runnerId}/roles`
  return `${_getBasePath()}${path}${queryString}`
}

export interface SetRunnerRolesUrlParams {
  runnerId: string
  bless?: boolean | null
  regionId: string
}

/**
 * GET /api/v1/regions/{region_id}/platform/infrastructure/services/accounter/health
 * Operation: `get-accounter-health`
 * Checks the health of the Accounter.
 * @param {string} params.expectStatus If specified, returns a 417 status code if the status is not what was sent or better. Should be one of [Red, Yellow, or Green].
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 The Accounter's health status. See {@link AccounterHealth}
 * @see 412 The Accounter's health status; 417 is sent when expect_status is sent but the status is _not_ better or equal to what was sent. See {@link AccounterHealth}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getAccounterHealthUrl(params: GetAccounterHealthUrlParams): string {
  const queryString = _buildQueryString({
    expect_status: params.expectStatus,
  })
  const path = _encode`/${params.regionId}/platform/infrastructure/services/accounter/health`
  return `${_getBasePath()}${path}${queryString}`
}

export interface GetAccounterHealthUrlParams {
  expectStatus?: string | null
  regionId: string
}

/**
 * GET /api/v1/regions/{region_id}/platform/infrastructure/services/mailer/health
 * Operation: `get-mailer-health`
 * Checks the health of the Mailer.
 * @param {string} params.expectStatus If specified, returns 417 if status is worse. Should be one of [Red, Yellow, or Green].
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 Returns Mailer health. See {@link EventProcessorHealth}
 * @see 417 When expect_status is sent and status is worse than that, the response code is 417. See {@link EventProcessorHealth}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getMailerHealthUrl(params: GetMailerHealthUrlParams): string {
  const queryString = _buildQueryString({
    expect_status: params.expectStatus,
  })
  const path = _encode`/${params.regionId}/platform/infrastructure/services/mailer/health`
  return `${_getBasePath()}${path}${queryString}`
}

export interface GetMailerHealthUrlParams {
  expectStatus?: string | null
  regionId: string
}

/**
 * GET /api/v1/regions/{region_id}/platform/infrastructure/services/scaler/health
 * Operation: `get-scaler-health`
 * Checks the health of the Scaler.
 * @param {string} params.expectStatus If specified, returns 417 if status is worse. Should be one of [Red, Yellow, or Green].
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 Returns Scaler health. See {@link EventProcessorHealth}
 * @see 417 When expect_status is sent and status is worse than that, the response code is 417. See {@link EventProcessorHealth}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getScalerHealthUrl(params: GetScalerHealthUrlParams): string {
  const queryString = _buildQueryString({
    expect_status: params.expectStatus,
  })
  const path = _encode`/${params.regionId}/platform/infrastructure/services/scaler/health`
  return `${_getBasePath()}${path}${queryString}`
}

export interface GetScalerHealthUrlParams {
  expectStatus?: string | null
  regionId: string
}

/**
 * GET /api/v1/regions/{region_id}/platform/license
 * Operation: `get-license`
 * Retrieves the license.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 The information for the license. See {@link LicenseObject}
 * @see 404 The license cannot be found. (code: `license.license_not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getLicenseUrl(params: GetLicenseUrlParams): string {
  const path = _encode`/${params.regionId}/platform/license`
  return `${_getBasePath()}${path}`
}

export interface GetLicenseUrlParams {
  regionId: string
}

/**
 * PUT /api/v1/regions/{region_id}/platform/license
 * Operation: `set-license`
 * Adds a new or updates an existing license.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see Accepts a request payload of type {@link LicenseObject}
 * @see 200 The license was updated.
 * @see 400 The license could not be updated. (code: `license.invalid_license`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function setLicenseUrl(params: SetLicenseUrlParams): string {
  const path = _encode`/${params.regionId}/platform/license`
  return `${_getBasePath()}${path}`
}

export interface SetLicenseUrlParams {
  regionId: string
}

/**
 * DELETE /api/v1/regions/{region_id}/platform/license
 * Operation: `delete-license`
 * Deletes the license.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 The license was deleted.
 * @see 404 The license cannot be found. (code: `license.license_not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function deleteLicenseUrl(params: DeleteLicenseUrlParams): string {
  const path = _encode`/${params.regionId}/platform/license`
  return `${_getBasePath()}${path}`
}

export interface DeleteLicenseUrlParams {
  regionId: string
}

/**
 * GET /api/v1/platform/license/eula
 * Operation: `eula-status`
 * Get status of EULA.
 * @see 200 The status of the EULA. See {@link EulaState}
 * @return {string} A fully-interpolated and escaped URL
 */
export function eulaStatusUrl(): string {
  const path = `/platform/license/eula`
  return `${_getRawBasePath()}${path}`
}

/**
 * POST /api/v1/platform/license/eula/_accept
 * Operation: `accept-eula`
 * Accept end-user license agreement.
 * @see 200 EULA was accepted.
 * @see 500 Something went wrong during EULA accept. (code: `license.eula_not_accepted`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function acceptEulaUrl(): string {
  const path = `/platform/license/eula/_accept`
  return `${_getRawBasePath()}${path}`
}

/**
 * GET /api/v1/regions/{region_id}/platform/resources/aws/s3/accounts
 * Operation: `list-aws-s3-account-credentials`
 * List all existing Account Credentials.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 List of existing Account Credentials. See {@link AccountCredentialsList}
 * @return {string} A fully-interpolated and escaped URL
 */
export function listAwsS3AccountCredentialsUrl(
  params: ListAwsS3AccountCredentialsUrlParams,
): string {
  const path = _encode`/${params.regionId}/platform/resources/aws/s3/accounts`
  return `${_getBasePath()}${path}`
}

export interface ListAwsS3AccountCredentialsUrlParams {
  regionId: string
}

/**
 * GET /api/v1/regions/{region_id}/platform/resources/aws/s3/accounts/{account_name}
 * Operation: `get-aws-s3-account-credentials`
 * Find Account Credentials by name.
 * @param {string} params.accountName Name of the Account Credentials that you would like to find.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 Account Credentials retrieved successfully. See {@link AccountCredentials}
 * @see 404 Account Credentials do not exist by that name. (code: `saas.resources.aws.s3.not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getAwsS3AccountCredentialsUrl(params: GetAwsS3AccountCredentialsUrlParams): string {
  const path = _encode`/${params.regionId}/platform/resources/aws/s3/accounts/${params.accountName}`
  return `${_getBasePath()}${path}`
}

export interface GetAwsS3AccountCredentialsUrlParams {
  accountName: string
  regionId: string
}

/**
 * POST /api/v1/regions/{region_id}/platform/resources/aws/s3/accounts/{account_name}
 * Operation: `create-aws-s3-account-credentials`
 * Inserts new AWS S3 Account Credentials.
 * @param {string} params.accountName Name of the Account Credentials that you would like to create.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see Accepts a request payload of type {@link AccountCredentialsData}
 * @see 201 The Account Credentials were inserted successfully. See {@link AccountCredentials}
 * @see 400 Account Credentials data already exists for the given name. (code: `saas.resources.aws.s3.already_exists`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function createAwsS3AccountCredentialsUrl(
  params: CreateAwsS3AccountCredentialsUrlParams,
): string {
  const path = _encode`/${params.regionId}/platform/resources/aws/s3/accounts/${params.accountName}`
  return `${_getBasePath()}${path}`
}

export interface CreateAwsS3AccountCredentialsUrlParams {
  accountName: string
  regionId: string
}

/**
 * PUT /api/v1/regions/{region_id}/platform/resources/aws/s3/accounts/{account_name}
 * Operation: `put-aws-s3-account-credentials`
 * Update existing Account Credentials.
 * @param {string} params.accountName Name of the Account Credentials that you would like to modify.
 * @param {string} params.version If specified then checks for conflicts against the version stored in the persistent store (returned in 'x-cloud-resource-version' of the GET request).
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see Accepts a request payload of type {@link AccountCredentialsData}
 * @see 200 Account Credentials retrieved successfully. See {@link AccountCredentials}
 * @see 404 There was no existing data for the given account name. (code: `saas.resources.aws.s3.not_found`). See {@link BasicFailedReply}
 * @see 409 There is a version conflict. (code: `saas.resources.aws.s3.bad_version`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function putAwsS3AccountCredentialsUrl(params: PutAwsS3AccountCredentialsUrlParams): string {
  const queryString = _buildQueryString({
    version: params.version,
  })
  const path = _encode`/${params.regionId}/platform/resources/aws/s3/accounts/${params.accountName}`
  return `${_getBasePath()}${path}${queryString}`
}

export interface PutAwsS3AccountCredentialsUrlParams {
  accountName: string
  version?: string | null
  regionId: string
}

/**
 * DELETE /api/v1/regions/{region_id}/platform/resources/aws/s3/accounts/{account_name}
 * Operation: `delete-aws-s3-account-credentials`
 * Delete Account Credentials by name.
 * @param {string} params.accountName Name of the Account Credentials that you would like to delete.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 Account Credentials deleted successfully
 * @see 404 Account Credentials do not exist by that name. (code: `saas.resources.aws.s3.not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function deleteAwsS3AccountCredentialsUrl(
  params: DeleteAwsS3AccountCredentialsUrlParams,
): string {
  const path = _encode`/${params.regionId}/platform/resources/aws/s3/accounts/${params.accountName}`
  return `${_getBasePath()}${path}`
}

export interface DeleteAwsS3AccountCredentialsUrlParams {
  accountName: string
  regionId: string
}

/**
 * GET /api/v1/regions/{region_id}/platform/resources/{cloud_service}/{storage_service}/health
 * Operation: `get-resources-health`
 * Get health for the resources of the given storage provider.
 * @param {string} params.cloudService The cloud service.
 * @param {string} params.storageService The storage service.
 * @param {string} params.expectStatus If specified, returns a 417 status code if the health status is not what was sent or better. Should be one of [red, yellow, or green].
 * @param {boolean} params.verbose When true, returns the list of health check results.
 * @param {integer} params.threshold When provided will base the healthiness on the given threshold. Expressed in .
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 The resources health check. See {@link ResourcesHealth}
 * @see 417 NOTE: When expect_status is sent, but the health status is _not_ better or equal to what was sent, the status code is 417. See {@link ResourcesHealth}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getResourcesHealthUrl(params: GetResourcesHealthUrlParams): string {
  const queryString = _buildQueryString({
    expect_status: params.expectStatus,
    verbose: params.verbose,
    threshold: params.threshold,
  })
  const path = _encode`/${params.regionId}/platform/resources/${params.cloudService}/${params.storageService}/health`
  return `${_getBasePath()}${path}${queryString}`
}

export interface GetResourcesHealthUrlParams {
  cloudService: string
  storageService: string
  expectStatus?: string | null
  verbose?: boolean | null
  threshold?: number | null
  regionId: string
}
