/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { useState, Fragment, type FC } from 'react'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'
import { useLocation } from 'react-router'

import {
  EuiAvatar,
  EuiButtonEmpty,
  EuiHeaderSectionItemButton,
  EuiPopover,
  EuiHorizontalRule,
  useEuiTheme,
  EuiIcon,
} from '@elastic/eui'

import { loginUrl, logoutUrl } from '@modules/auth/urls'
import history from '@modules/utils/history'
import type { Theme } from '@modules/ui-types'
import type { WithPermissionsProps } from '@modules/permissions-components/PermissionsGate/withPermissions'

import { userSettingsUrl } from '@/lib/urlBuilder'
import { accountUrl, billingUrl } from '@/apps/userconsole/urls'

import CustomerProfile from '../CustomerProfile'

interface UserMenuProps
  extends WithPermissionsProps<
    'hasSearchAllocatorPermission' | 'hasCreateBillingOrganizationPermission'
  > {
  username: string | null
  cloudPortalEnabled: boolean
  hasUserSettings: boolean
  loggedIn: boolean
  setTheme: (theme: Theme) => void
  showOrganization: boolean
  theme: Theme
}

const UserMenu: FC<UserMenuProps> = ({
  username,
  cloudPortalEnabled,
  hasUserSettings,
  loggedIn,
  setTheme,
  theme,
  showOrganization,
  permissions: { hasCreateBillingOrganizationPermission },
}) => {
  const { euiTheme } = useEuiTheme()
  const { pathname } = useLocation()
  const { formatMessage } = useIntl()
  const [isUserMenuOpen, setUserMenuOpen] = useState<boolean>(false)
  const chromeHeaderLinkStyle = css`
    width: 100%;
    display: flex;
    span {
      justify-content: left;
      svg {
        fill: ${euiTheme.colors.mediumShade};
      }
    }
    &:hover,
    &:focus {
      background: ${euiTheme.colors.lightestShade};
    }
  `
  const ariaMessages = defineMessages({
    ariaUserMenu: {
      id: 'chrome-header.aria-user-menu',
      defaultMessage: 'User menu',
    },
  })
  return (
    <EuiPopover
      id='chromeHeader-userMenuPopover'
      anchorPosition='downCenter'
      panelPaddingSize='none'
      panelProps={{
        css: css`
          .euiPopover__panelArrow.euiPopover__panelArrow--bottom::after {
            border-bottom-color: ${euiTheme.colors.lightestShade};
          }

          .euiPopover__panelArrow.euiPopover__panelArrow--left::after {
            border-left-color: ${euiTheme.colors.lightestShade};
          }
        `,
      }}
      ownFocus={true}
      isOpen={isUserMenuOpen}
      closePopover={() => setUserMenuOpen(false)}
      button={
        <EuiHeaderSectionItemButton
          onClick={() => setUserMenuOpen(!isUserMenuOpen)}
          data-test-id='userMenuButton'
          aria-label={formatMessage(ariaMessages.ariaUserMenu)}
        >
          {loggedIn ? (
            <EuiAvatar
              size='s'
              name={getUsername({ username, formatMessage })}
              aria-hidden={true}
            />
          ) : (
            <EuiIcon type='glasses' size='m' />
          )}
        </EuiHeaderSectionItemButton>
      }
    >
      <div
        css={css`
          padding: ${euiTheme.size.s} ${euiTheme.size.m};
          background-color: ${euiTheme.colors.lightestShade};
          border-radius: 6px 6px 0 0;
        `}
      >
        <CustomerProfile />
      </div>

      <EuiHorizontalRule margin='none' />

      {loggedIn && (hasUserSettings || cloudPortalEnabled) && (
        <EuiButtonEmpty
          onClick={() => {
            history.push(userSettingsUrl())
            setUserMenuOpen(false)
          }}
          color='text'
          css={chromeHeaderLinkStyle}
          data-test-id='SettingsButton'
          iconType='user'
          role='link'
        >
          <FormattedMessage id='chrome-header.settings' defaultMessage='Profile' />
        </EuiButtonEmpty>
      )}

      {loggedIn && cloudPortalEnabled && (
        <Fragment>
          {hasCreateBillingOrganizationPermission && (
            <EuiButtonEmpty
              onClick={() => {
                history.push(billingUrl())
                setUserMenuOpen(false)
              }}
              color='text'
              css={chromeHeaderLinkStyle}
              data-test-id='billingButton'
              iconType='payment'
              role='link'
            >
              <FormattedMessage id='chrome-header.billing' defaultMessage='Billing' />
            </EuiButtonEmpty>
          )}

          <EuiButtonEmpty
            onClick={() => {
              history.push(accountUrl())
              setUserMenuOpen(false)
            }}
            color='text'
            css={chromeHeaderLinkStyle}
            data-test-id='organizationButton'
            iconType='users'
            role='link'
          >
            {showOrganization ? (
              <FormattedMessage id='chrome-header.organization' defaultMessage='Organization' />
            ) : (
              <FormattedMessage id='chrome-header.account' defaultMessage='Account' />
            )}
          </EuiButtonEmpty>
        </Fragment>
      )}

      {theme === `light` ? (
        <div>
          <EuiButtonEmpty
            onClick={() => {
              setTheme(`dark`)
              setUserMenuOpen(false)
            }}
            color='text'
            css={chromeHeaderLinkStyle}
            iconType='moon'
          >
            <FormattedMessage id='chrome-header.night-mode' defaultMessage='Dark mode' />
          </EuiButtonEmpty>
        </div>
      ) : (
        <div>
          <EuiButtonEmpty
            onClick={() => {
              setTheme(`light`)
              setUserMenuOpen(false)
            }}
            color='text'
            css={chromeHeaderLinkStyle}
            iconType='sun'
          >
            <FormattedMessage id='chrome-header.light-mode' defaultMessage='Light mode' />
          </EuiButtonEmpty>
        </div>
      )}

      {loggedIn ? (
        <div>
          <EuiButtonEmpty
            onClick={() => {
              history.push({ pathname: logoutUrl(), state: { from: pathname } })
              setUserMenuOpen(false)
            }}
            color='text'
            css={chromeHeaderLinkStyle}
            data-test-id='LogoutButton'
            iconType='exit'
          >
            <FormattedMessage id='chrome-header.log-out' defaultMessage='Log out' />
          </EuiButtonEmpty>
        </div>
      ) : (
        <div>
          <EuiButtonEmpty
            onClick={() => {
              history.push(loginUrl())
              setUserMenuOpen(false)
            }}
            color='text'
            css={chromeHeaderLinkStyle}
            data-test-id='LoginButton'
            iconType='user'
          >
            <FormattedMessage id='chrome-header.log-in' defaultMessage='Log in' />
          </EuiButtonEmpty>
        </div>
      )}
    </EuiPopover>
  )
}

export default UserMenu

const getUsername = ({ username, formatMessage }) => {
  if (!username) {
    return formatMessage({
      id: 'customer-profile.unauthenticated',
      defaultMessage: 'Unauthenticated',
    })
  }

  return username.toUpperCase()
}
