/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { PureComponent, Fragment } from 'react'
import { isEmpty } from 'lodash'

import {
  EuiSkeletonText,
  EuiPanel,
  EuiSpacer,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHorizontalRule,
} from '@elastic/eui'

import type { DeploymentSearchResponse } from '@modules/cloud-api/v1/types'
import type { UserProfile } from '@modules/ui-types'
import { withErrorBoundary } from '@modules/cui/Boundary'

import { isMarketPlaceUser, isUnSubscribedMarketPlaceUser } from '@/lib/marketPlace'
import HostedDeploymentsTileTitle from '@/components/Portal/components/HostedDeploymentsTile/HostedDeploymentsTileTitle'

import PortalDeployments from '../PortalDeployments'
import HasNoDeployment from '../PortalDeployments/HasNoDeployments'
import UnSubscribedMarketPlaceUserCallout from '../../../MarketPlace/UnSubscribedMarketPlaceUserCallout'
import DeploymentsSchedule from '../../../StackDeploymentSearch/DeploymentsSchedule'

import CreateDeploymentButtonPopover from './CreateDeploymentButtonPopover'

export interface Props {
  deployments: DeploymentSearchResponse[] | null
  profile?: UserProfile | null
  search: () => void
  isPollingEnabled: boolean
  isSearching: boolean
}

class HostedDeploymentsTile extends PureComponent<Props> {
  isScheduledDeploymentSearch: boolean = false

  render() {
    const { deployments, profile } = this.props

    // isSearching cannot be used bellow because deployment search
    // requests are polled, which means this would always be true,
    // we need to use `deployments === null` instead to indicate the first
    // search is still in progress.
    if (deployments === null) {
      return (
        <EuiPanel hasShadow={true} grow={false}>
          <EuiSkeletonText />
        </EuiPanel>
      )
    }

    const hasNoDeployments = Boolean(!deployments.length)

    return (
      <EuiPanel hasShadow={true} grow={false}>
        <EuiFlexGroup gutterSize='s' justifyContent='flexStart' alignItems='center'>
          <EuiFlexItem grow={true}>
            <EuiFlexGroup
              gutterSize='s'
              justifyContent='spaceBetween'
              alignItems='center'
              responsive={false}
            >
              <EuiFlexItem grow={true}>
                <HostedDeploymentsTileTitle />
              </EuiFlexItem>

              <EuiFlexItem grow={false}>
                <CreateDeploymentButtonPopover
                  hasNoDeployments={hasNoDeployments}
                  inTrial={Boolean(profile?.inTrial)}
                  hasExpiredTrial={Boolean(profile?.hasExpiredTrial)}
                  unSubscribed={Boolean(
                    profile && isMarketPlaceUser(profile) && isUnSubscribedMarketPlaceUser(profile),
                  )}
                />
              </EuiFlexItem>
            </EuiFlexGroup>

            {isEmpty(deployments) && <EuiHorizontalRule />}
          </EuiFlexItem>
        </EuiFlexGroup>

        {this.props.isPollingEnabled && this.renderDeploymentsSchedule()}

        <div data-test-id='cloud-portal-elastic-search-service-tile-content'>
          {this.renderUnSubscribedMarketPlaceUserCallout()}
          {this.renderDeployments()}
        </div>
      </EuiPanel>
    )
  }

  renderUnSubscribedMarketPlaceUserCallout() {
    const { profile } = this.props

    if (!profile || !isMarketPlaceUser(profile) || !isUnSubscribedMarketPlaceUser(profile)) {
      return null
    }

    return (
      <Fragment>
        <UnSubscribedMarketPlaceUserCallout profile={profile} />

        <EuiSpacer size='m' />
      </Fragment>
    )
  }

  renderDeployments() {
    const { deployments } = this.props

    if (deployments === null) {
      return <EuiSkeletonText />
    }

    const hasNoDeployments = isEmpty(deployments)

    if (hasNoDeployments) {
      return <HasNoDeployment />
    }

    return (
      <Fragment>
        <EuiSpacer size='m' />
        {this.renderDeploymentsTable()}
      </Fragment>
    )
  }

  renderDeploymentsTable() {
    const { deployments, profile } = this.props
    const hasNoDeployments = (deployments && isEmpty(deployments)) || false

    if (hasNoDeployments || !profile) {
      return null
    }

    return <PortalDeployments deployments={deployments} />
  }

  renderDeploymentsSchedule() {
    const { isSearching } = this.props

    return <DeploymentsSchedule search={this.search} busy={isSearching} />
  }

  search = () => {
    const { search } = this.props

    if (!this.isScheduledDeploymentSearch) {
      this.isScheduledDeploymentSearch = true
    }

    search()
  }
}

export default withErrorBoundary(HostedDeploymentsTile)
