/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/*!
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@                                         @@@@
 *!   @@@   THIS FILE WAS AUTOGENERATED BY TOOL   @@@@
 *!   @@@   CHANGES MUST TARGET SCRIPTS/SWAGGER   @@@@
 *!   @@@                                         @@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!
 */

import {
  _buildQueryString,
  _emptyParams,
  _encode,
  _getBasePath,
  _getRawBasePath,
} from './urls-utils'

/**
 * POST /api/v1/saas/marketplace/_activate
 * Operation: `post-activate-marketplace`
 * Activate a pending subscription for an authenticated user. When available, firmographics will update the organization's info.
 * @see Accepts a request payload of type {@link MarketplaceActivateRequest}
 * @see 202 The organization successfully activated the Marketplace subscription. See {@link MarketplaceActivationResponse}
 * @see 400 * The partner token expired. (code: `user.marketplace.expired_token`)
 * The partner token is invalid. (code: `user.marketplace.invalid_token`)
 * Unable to resolve partner token. (code: `user.marketplace.token_resolve_error`)
 * The user is already subscribed. (code: `user.marketplace.already_subscribed`)
 * The user already has a pending subscription. (code: `user.marketplace.pending_subscription`)
 * The user is running a trial on a different provider. (code: `user.marketplace.incompatible_deployments`). See {@link BasicFailedReply}
 * @see 404 The partner marketplace doesn't exist. (code: `user.marketplace.invalid_marketplace`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function postActivateMarketplaceUrl(): string {
  const path = `/saas/marketplace/_activate`
  return `${_getRawBasePath()}${path}`
}

/**
 * POST /api/v1/regions/{region_id}/saas/marketplace/_deactivate
 * Operation: `post-deactivate-marketplace`
 * Deactivate an organization from a Marketplace after unsubscribed.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see Accepts a request payload of type {@link MarketplaceDeactivateRequest}
 * @see 200 The organization was successfully deactivated from the Marketplace.
 * @see 400 * Unable to put the deployment parameter. (code: `user.marketplace.put_deployment_parameter.error`)
 * Failed to update the organization's metadata. (code: `user.update_organization_metadata.error`). See {@link BasicFailedReply}
 * @see 404 * The partner marketplace doesn't exist. (code: `user.marketplace.invalid_marketplace`)
 * Unable to find the organization. (code: `user.marketplace.organization_not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function postDeactivateMarketplaceUrl(params: PostDeactivateMarketplaceUrlParams): string {
  const path = _encode`/${params.regionId}/saas/marketplace/_deactivate`
  return `${_getBasePath()}${path}`
}

export interface PostDeactivateMarketplaceUrlParams {
  regionId: string
}

/**
 * POST /api/v1/saas/marketplace/_introspect
 * Operation: `get-marketplace-subscription-availability`
 * Introspect the availability of user ids to be converted to marketplace subscription.
 * @see Accepts a request payload of type {@link MarketplaceIntrospectionRequest}
 * @see 200 The introspection request completed successfully. See {@link MarketplaceIntrospectionResponse}
 * @see 400 * The partner token expired. (code: `user.marketplace.expired_token`)
 * The partner token is invalid. (code: `user.marketplace.invalid_token`)
 * Unable to resolve partner token. (code: `user.marketplace.token_resolve_error`)
 * The introspection request is not allowed for the client. (code: `user.marketplace.introspection_denied`). See {@link BasicFailedReply}
 * @see 404 The partner marketplace doesn't exist. (code: `user.marketplace.invalid_marketplace`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getMarketplaceSubscriptionAvailabilityUrl(): string {
  const path = `/saas/marketplace/_introspect`
  return `${_getRawBasePath()}${path}`
}

/**
 * POST /api/v1/saas/marketplace/_subscribe
 * Operation: `post-subscribe-marketplace`
 * Subscribe an authenticated user to an Elastic Marketplace partner, putting the user in a waiting state until an activation is performed.
 * @see Accepts a request payload of type {@link MarketplaceSubscribeRequest}
 * @see 201 The user was successfully subscribed to the Marketplace.
 * @see 400 * The partner token expired. (code: `user.marketplace.expired_token`)
 * The partner token is invalid. (code: `user.marketplace.invalid_token`)
 * The user is already subscribed. (code: `user.marketplace.already_subscribed`)
 * The user already has a pending subscription. (code: `user.marketplace.pending_subscription`)
 * The user is running a trial on a different provider. (code: `user.marketplace.incompatible_deployments`). See {@link BasicFailedReply}
 * @see 404 The partner marketplace doesn't exist. (code: `user.marketplace.invalid_marketplace`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function postSubscribeMarketplaceUrl(): string {
  const path = `/saas/marketplace/_subscribe`
  return `${_getRawBasePath()}${path}`
}
