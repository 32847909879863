/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

import { EuiHorizontalRule, EuiSkeletonText, EuiSpacer, EuiTitle } from '@elastic/eui'

import type {
  GlobalDeploymentTemplateInfo,
  DeploymentSearchResponse,
} from '@modules/cloud-api/v1/types'
import { withErrorBoundary } from '@modules/cui/Boundary'
import type { PlatformId } from '@modules/ui-types'
import { getPlatform } from '@modules/utils/platform'
import { isDefaultSolutionView } from '@modules/deployment-creation-wizard/types'

import { filterGlobalTemplates } from '@/lib/globalDeploymentTemplates'
import { getVisibleTemplates } from '@/lib/stackDeployments/selectors/deploymentTemplates'
import { getRegionId } from '@/lib/stackDeployments/selectors/fundamentals'
import { getUpsertVersion } from '@/lib/stackDeployments/selectors/creates'

import CcsSetupRemoteDeployments from '../CcsSetupRemoteDeployments'
import { isCrossClusterSearch } from '../../../../lib/deployments/ccs'
import { inTrial } from '../../../../lib/trial'
import { getPrettySolutionType } from '../../CreateStackDeploymentRoute/lib'

import AdvancedSettings from './AdvancedSettings'
import TrialLimitMessage from './TrialLimitMessage'
import NameDeployment from './NameDeployment'

import type { WrappedComponentProps } from 'react-intl'
import type { AllProps as Props } from './types'

import '../../../Topology/DeploymentTemplates/DeploymentTemplateWizard/deploymentTemplateWizard.scss'
import '../../../Topology/DeploymentTemplates/components/ConfigureInstance/configureInstanceTemplate.scss'

interface State {
  restoredSnapshotName: string
  selectedSnapshotDeployment: any
}

class SelectTemplate extends Component<Props & WrappedComponentProps, State> {
  state = {
    restoredSnapshotName: '',
    selectedSnapshotDeployment: null,
  }

  render(): JSX.Element {
    const {
      availableVersions,
      editorState,
      getRegionsByProvider,
      onChange,
      onChangeSnapshot,
      providerIds,
      region,
      restoreFromSnapshot,
      setDeploymentName,
      setEsSettings,
      setVersion,
      showRegion,
      trialMaxedOut,
      whitelistedVersions,
      profile,
      globalDeploymentTemplates,
      setGlobalTemplate,
      isUserconsole,
      hasDefaultSnapshotRepository,
      disabled,
      deploymentTemplates,
      showSettings,
      setSnapshotSettingsDisabled,
      intl: { formatMessage },
    } = this.props

    const { deployment, deploymentTemplate, regionId: userSelectedRegionId } = editorState
    const { name } = deployment

    const version = getUpsertVersion(editorState)

    const platform = getPlatform(userSelectedRegionId)
    const availableRegions = getRegionsByProvider(platform)

    const showPayingCustomerSections = !inTrial({ profile })

    const isTemplateCrossClusterSearch = isCrossClusterSearch({
      deploymentTemplate,
      deploymentTemplateId: deploymentTemplate?.id,
      systemOwned: false,
    })
    const filteredGlobalTemplates = filterGlobalTemplates({
      globalTemplates: globalDeploymentTemplates,
      deploymentTemplates,
      version,
      regionId: userSelectedRegionId,
    })
    // Once the API returns the correct templates, this won't be necessary #82357
    const visibleTemplates = isUserconsole
      ? getVisibleTemplates(this.filterSolutionTemplates(filteredGlobalTemplates))
      : getVisibleTemplates(globalDeploymentTemplates)

    let nameDeploymentPlaceholder: string | undefined

    if (this.state.restoredSnapshotName) {
      nameDeploymentPlaceholder = `Restored ${this.state.restoredSnapshotName}`
    }

    if (nameDeploymentPlaceholder === undefined) {
      const solutionView = deployment.settings?.solution_type

      if (isDefaultSolutionView(solutionView)) {
        const prettySolutionType = formatMessage(getPrettySolutionType(solutionView))

        nameDeploymentPlaceholder = `My ${prettySolutionType} deployment`
      }
    }

    return (
      <div>
        {trialMaxedOut ? <TrialLimitMessage days={profile?.trial_length} /> : null}

        {isUserconsole && (
          <Fragment>
            <NameDeployment
              name={name}
              onChange={setDeploymentName}
              placeholder={nameDeploymentPlaceholder}
              disabled={trialMaxedOut || disabled}
            />
            <EuiSpacer size='l' />
          </Fragment>
        )}

        {deploymentTemplate ? (
          <Fragment>
            <AdvancedSettings
              onUpdateSnapshotSource={this.handleChangeSnapshotSource}
              disabled={disabled}
              restoreFromSnapshot={restoreFromSnapshot}
              isUserconsole={isUserconsole}
              name={deployment.name}
              setDeploymentName={setDeploymentName}
              version={version}
              region={region!}
              getRegionsByProvider={getRegionsByProvider}
              availablePlatforms={providerIds}
              trialMaxedOut={trialMaxedOut}
              onChangePlatform={this.onChangePlatform}
              showRegion={showRegion}
              onChangeRegion={(regionId: string) =>
                this.onChangeRegion({ regionId, stackVersion: version! })
              }
              selectedSnapshotDeployment={this.state.selectedSnapshotDeployment}
              availableRegions={availableRegions}
              availableVersions={availableVersions}
              whitelistedVersions={whitelistedVersions}
              setVersion={setVersion}
              editorState={editorState}
              onChangeSnapshot={onChangeSnapshot!}
              setEsSettings={setEsSettings!}
              showPayingCustomerSections={showPayingCustomerSections}
              globalDeploymentTemplates={visibleTemplates}
              deploymentTemplate={deploymentTemplate}
              onChangeTemplate={setGlobalTemplate}
              hasDefaultSnapshotRepository={hasDefaultSnapshotRepository}
              deploymentTemplates={deploymentTemplates}
              showSettings={showSettings}
              setSnapshotSettingsDisabled={setSnapshotSettingsDisabled}
            />
            {isTemplateCrossClusterSearch && (
              <Fragment>
                <EuiHorizontalRule />
                <EuiTitle size='s'>
                  <h2>
                    <FormattedMessage
                      id='create-ccs-deployment.remote-deployments-title'
                      defaultMessage='Remote deployments'
                    />
                  </h2>
                </EuiTitle>

                <CcsSetupRemoteDeployments editorState={editorState} onChange={onChange} />

                <EuiSpacer />
              </Fragment>
            )}
          </Fragment>
        ) : (
          <EuiSkeletonText />
        )}
      </div>
    )
  }

  onChangePlatform = (platform: PlatformId): void => {
    const { getRegionIdsByProvider, editorState } = this.props
    const version = getUpsertVersion(editorState)

    const availableRegions = getRegionIdsByProvider(platform)

    return this.onChangeRegion({ regionId: availableRegions[0]!, stackVersion: version! })
  }

  onChangeRegion = ({
    regionId,
    stackVersion,
  }: {
    regionId: string
    stackVersion: string
  }): void => {
    const { fetchBasePrices, setRegion, subscription, profile } = this.props

    setRegion({ regionId, stackVersion })

    fetchBasePrices({ regionId, level: subscription || profile?.level })
  }

  // This function won't be necessary once the API returns the correct templates
  filterSolutionTemplates = (
    templates?: GlobalDeploymentTemplateInfo[] | null,
  ): GlobalDeploymentTemplateInfo[] => {
    if (!templates) {
      return []
    }

    return templates.filter((template) =>
      template.metadata?.find((item) => item.key === `parent_solution`),
    )
  }

  handleChangeSnapshotSource = (snapshotSource?: DeploymentSearchResponse | null): void => {
    const { onChangeSnapshotSource, isUserconsole } = this.props
    const regionId = snapshotSource ? getRegionId({ deployment: snapshotSource }) : null

    if (snapshotSource && regionId) {
      const { editorState } = this.props
      const version = getUpsertVersion(editorState)
      this.setState({
        restoredSnapshotName: snapshotSource.name,
        selectedSnapshotDeployment: snapshotSource,
      })

      if (isUserconsole) {
        this.onChangeRegion({ regionId, stackVersion: version! })
        onChangeSnapshotSource && onChangeSnapshotSource(snapshotSource, regionId)
        return
      }
    }

    this.setState({ selectedSnapshotDeployment: snapshotSource })
    onChangeSnapshotSource && onChangeSnapshotSource(snapshotSource)
  }
}

export default withErrorBoundary(injectIntl(SelectTemplate))
