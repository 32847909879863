/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import { defineMessage, defineMessages } from 'react-intl'

import { timeFormatter } from '@elastic/charts'

import type { ProjectType } from '@modules/ui-types/projects'
import type { ChartType, MetricType } from '@modules/autoops-api/types'

export const METRIC_TYPES_BY_PROJECT_TYPE: Record<ProjectType, MetricType[]> = {
  elasticsearch: [
    'search_latency',
    'search_rate',
    'index_latency',
    'index_rate',
    'search_vcu',
    'ingest_vcu',
    'ml_vcu',
    'storage_retained',
  ],
  observability: ['ingest_rate', 'storage_retained'],
  security: ['ingest_rate', 'storage_retained'],
}

export const ERROR_CHART_MESSAGE = defineMessage({
  id: 'project.chart.error',
  defaultMessage: 'Sorry, there was an error',
})

export const NO_DATA_TITLE_BY_METRIC_TYPE = defineMessages<ChartType>({
  vcu: {
    id: 'project.chart.no_data.vcu',
    defaultMessage: 'Sorry, there was no search, ingest or ML load during the selected period',
  },
  ingest_rate: {
    id: 'project.chart.no_data.ingest_rate',
    defaultMessage: 'Sorry, there was no ingest activity during the selected period',
  },
  storage_retained: {
    id: 'project.chart.no_data.storage_retained',
    defaultMessage: 'Sorry, there was no data retained during the selected period',
  },
  index: {
    id: 'project.chart.no_data.index',
    defaultMessage: 'Sorry, there was no indexing activity during the selected period',
  },
  search: {
    id: 'project.chart.no_data.search',
    defaultMessage: 'Sorry, there was no search activity during the selected period',
  },
})

const DATE_RANGE_OPTIONS = [
  'now-3h/h',
  'now-6h/h',
  'now-12h/h',
  'now-24h/h',
  'now-2d/d',
  'now-7d/d',
  'now-10d/d',
] as const

export type DateRangeOption = (typeof DATE_RANGE_OPTIONS)[number]

export const DEFAULT_RANGE_OPTION: DateRangeOption = 'now-24h/h'

export const timeFullFormatterFn = timeFormatter('MMM DD YYYY hh:mm A')
export const timeFormatterFn = timeFormatter('MMM DD hh:mm a')
