/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component } from 'react'
import { defineMessages, injectIntl, FormattedMessage } from 'react-intl'
import { isEmpty } from 'lodash'

import {
  EuiSkeletonText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiButtonGroup,
  EuiButtonEmpty,
} from '@elastic/eui'

import { getHourlyRate } from '@/lib/deployments/architecture'

import TotalPrice from './TotalPrice'

import type { PriceButtonProps as Props } from './types'

class PriceButton extends Component<Props> {
  componentDidMount() {
    const { regionId, fetchBasePrices, basePrices } = this.props

    if (!basePrices) {
      fetchBasePrices({
        regionId,
      })
    }
  }

  render() {
    const {
      nodeConfigurations,
      priceViewSelected,
      deploymentTemplate,
      regionId,
      showPrice,
      onChangePriceView,
      onClickPriceButton,
      intl: { formatMessage },
      basePrices,
      profile,
      fetchBasePricesRequest,
    } = this.props
    const { inProgress, error } = fetchBasePricesRequest

    if (!showPrice) {
      return null
    }

    const messages = defineMessages({
      monthly: {
        id: 'price-button.monthly-price',
        defaultMessage: 'Monthly',
      },
      hourly: {
        id: 'price-button.hourly-price',
        defaultMessage: 'Hourly',
      },
      buttonLabel: {
        id: `price-button.label`,
        defaultMessage: `view summary`,
      },
    })

    const instanceConfigurations = deploymentTemplate!.instance_configurations
    const priceViewButtons = [
      {
        id: 'hourly',
        label: formatMessage(messages.hourly),
      },
      {
        id: 'monthly',
        label: formatMessage(messages.monthly),
      },
    ]

    if (!basePrices || !profile) {
      return <EuiSkeletonText lines={1} />
    }

    if (isEmpty(basePrices) && inProgress && !error) {
      return <EuiSkeletonText lines={1} />
    }

    const { hourlyRate } = getHourlyRate({
      basePrices,
      regionId,
      instanceConfigurations,
      nodeConfigurations,
      level: profile.level,
    })

    if (hourlyRate === 0) {
      return (
        <EuiButtonEmpty
          onClick={onClickPriceButton}
          color='warning'
          className='price-unavailable-button'
          iconSide='right'
          size='s'
          iconType={this.getIconType()}
        >
          <strong>
            <FormattedMessage
              id='price-button.price-unavailable'
              defaultMessage='Price unavailable'
            />
          </strong>
        </EuiButtonEmpty>
      )
    }

    return (
      <EuiFlexGroup responsive={false} alignItems='flexStart' justifyContent='center'>
        <EuiFlexItem grow={false}>
          <EuiButtonGroup
            buttonSize='compressed'
            legend='price button toggle'
            options={priceViewButtons}
            idSelected={priceViewSelected}
            onChange={(id) => onChangePriceView(id)}
          />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiButtonEmpty
            color='text'
            iconSide='right'
            size='s'
            iconType={this.getIconType()}
            data-test-id='architecture-price-button'
            onClick={onClickPriceButton}
          >
            <TotalPrice hourlyRate={hourlyRate} priceViewSelected={priceViewSelected} />
          </EuiButtonEmpty>
        </EuiFlexItem>
      </EuiFlexGroup>
    )
  }

  getIconType() {
    const { showArchitectureSummary } = this.props

    if (showArchitectureSummary) {
      return `arrowUp`
    }

    return `arrowDown`
  }
}

export default injectIntl(PriceButton)
