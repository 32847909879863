/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React from 'react'
import { FormattedMessage } from 'react-intl'
import moment from 'moment'

import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiShowFor, EuiSpacer } from '@elastic/eui'

import TrialSummary from '@modules/trial-components/TrialSummary'
import useBillingDetails from '@modules/billing-lib/billingDetails/useBillingDetails'
import { getMarketplaceDisplayName } from '@modules/billing-lib/billingDetails/utils'
import { useGetOrganizationQuery } from '@modules/profile-lib/hooks'
import {
  DEFAULT_TRIAL_MP_DAYS,
  getSaasEntityTrialDaysRemaining,
  getSaasLatestTrial,
} from '@modules/profile-lib/trial'
import { useConfig } from '@modules/cui/ConfigContext'

import SuggestedContentPanel from '@/apps/userconsole/components/Billing/BillingOverview/SuggestedContentPanel'

import FeaturesOverview from '../components/FeaturesOverview'
import PrepaidAccountDetailsPanel from '../../BillingOverview/PrepaidAccountDetailsPanel'
import SubscriptionOverviewPanel from '../components/SubscriptionOverviewPanel'
import CustomerOnlyAction from '../../CustomerOnlyAction'

import type { MouseEventHandler } from 'react'

interface Props {
  organizationId: string
}

const TrialOverview = ({ organizationId }: Props) => {
  const organizationQuery = useGetOrganizationQuery(organizationId)
  const billingDetails = useBillingDetails()
  const channel = billingDetails.data?.channel
  const trialDaysRemaining =
    organizationQuery.data && getSaasEntityTrialDaysRemaining(organizationQuery.data)
  const trialEnd = organizationQuery.data && getSaasLatestTrial(organizationQuery.data)?.end
  const isAdminConsole = useConfig('APP_NAME') === 'adminconsole'

  const trialDescription = () => {
    if (trialDaysRemaining && trialEnd) {
      return (
        <FormattedMessage
          id='trial-subscription-details-card.description.remaining'
          defaultMessage='You have <strong>{trialDaysRemaining, plural, one {{trialDaysRemaining} day} other {{trialDaysRemaining} days}}</strong> remaining on your trial, which will end on {endDate}.{br} After the trial is over, you will get charged for any usage through {channel}. To end the trial or charges thereafter, please delete your resources.'
          values={{
            trialDaysRemaining,
            strong: (content) => <strong>{content}</strong>,
            channel: getMarketplaceDisplayName(channel),
            endDate: moment(trialEnd).format('YYYY-MM-DD [at] HH:mm [UTC]'),
            br: <br />,
          }}
        />
      )
    }

    return (
      <FormattedMessage
        id='trial-subscription-details-card.description.default'
        defaultMessage='Free <strong>{trialLength}-day</strong> trial. After the trial is over, you will get charged for any usage through {channel}.{br} To end the trial or charges thereafter, please delete your resources.'
        values={{
          trialLength: DEFAULT_TRIAL_MP_DAYS,
          strong: (content) => <strong>{content}</strong>,
          channel: getMarketplaceDisplayName(channel),
          br: <br />,
        }}
      />
    )
  }

  const renderTrialSummaryButton = (onClick?: MouseEventHandler<HTMLButtonElement>) => (
    <CustomerOnlyAction>
      <EuiButton
        onClick={onClick}
        data-test-id='billing-details-summary.trial-summary-button'
        color='text'
      >
        <FormattedMessage
          id='billing-details-summary.trial-summary-button'
          defaultMessage='View trial summary'
        />
      </EuiButton>
    </CustomerOnlyAction>
  )

  const renderOverviewFooter = () => {
    if (isAdminConsole) {
      return renderTrialSummaryButton()
    }

    return (
      <TrialSummary>{({ triggerModal }) => renderTrialSummaryButton(triggerModal)}</TrialSummary>
    )
  }

  return (
    <EuiFlexGroup gutterSize='m'>
      <EuiFlexItem grow={9}>
        <SubscriptionOverviewPanel
          title={
            <FormattedMessage
              id='trial-subscription-details-card.active-trial.title'
              defaultMessage='Trial'
            />
          }
          content={trialDescription()}
          footer={renderOverviewFooter()}
        />
        <EuiSpacer size='m' />
        <FeaturesOverview />
        <EuiSpacer size='m' />
        <PrepaidAccountDetailsPanel hasBorder={true} organizationId={organizationId} />
        <EuiSpacer size='m' />
        <EuiShowFor sizes={['xs', 's', 'm']}>
          <EuiFlexItem grow={3}>
            <SuggestedContentPanel
              hasBorder={true}
              data-test-id='billing-details-summary.suggested-content'
              showEcuMessage={true}
            />
          </EuiFlexItem>
        </EuiShowFor>
      </EuiFlexItem>
      <EuiShowFor sizes={['l', 'xl']}>
        <EuiFlexItem grow={3}>
          <SuggestedContentPanel
            hasBorder={true}
            data-test-id='billing-details-summary.suggested-content'
            showEcuMessage={true}
          />
        </EuiFlexItem>
      </EuiShowFor>
    </EuiFlexGroup>
  )
}

export default TrialOverview
