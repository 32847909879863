/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { Fragment } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { upperFirst, capitalize } from 'lodash'

import {
  EuiBetaBadge,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHorizontalRule,
  EuiIcon,
  EuiListGroup,
  EuiListGroupItem,
  EuiSpacer,
  EuiText,
  EuiTextAlign,
  EuiTitle,
  useEuiTheme,
} from '@elastic/eui'

import type { SolutionType } from './types'
import type { FunctionComponent, ReactNode } from 'react'

type Props = {
  label: SolutionType
  description: ReactNode
  features: ReactNode[]
  logo: string
  title: ReactNode
  onNext: (label: SolutionType) => void
  buttonDisabled?: boolean
  dataAttributePrefix: string
}

const SelectSolutionCard: FunctionComponent<Props> = ({
  label,
  description,
  features,
  logo,
  title,
  onNext,
  buttonDisabled = false,
  dataAttributePrefix,
}) => {
  const { formatMessage } = useIntl()

  const { euiTheme } = useEuiTheme()

  const isElasticsearch = label === 'elasticsearch'

  return (
    <EuiCard
      paddingSize='xl'
      textAlign='left'
      titleSize='xs'
      title={
        <EuiFlexGroup responsive={false} justifyContent='center' alignItems='center'>
          <EuiFlexItem grow={false}>
            <EuiIcon size='xl' type={logo ? logo : `logo${upperFirst(label)}`} />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiTitle size='xs'>
              <h2>{title ? title : label}</h2>
            </EuiTitle>
            <EuiText size='xs' color='subdued'>
              <p>{description}</p>
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
      }
      titleElement='span'
      footer={
        <EuiButton
          disabled={buttonDisabled}
          onClick={() => onNext(label)}
          data-test-subj={`${dataAttributePrefix}${label}`}
          fullWidth={true}
          color='primary'
          aria-label={formatMessage(
            {
              id: 'select-solution.next.aria-label',
              defaultMessage: 'Next step {label}',
            },
            {
              label: capitalize(label),
            },
          )}
        >
          <FormattedMessage id='select-solution.select-next' defaultMessage='Next' />
        </EuiButton>
      }
    >
      <EuiTextAlign textAlign='center'>
        <EuiHorizontalRule margin='l' />

        {!isElasticsearch && (
          <Fragment>
            <EuiBetaBadge
              label={capitalize(
                formatMessage({
                  id: 'select-solution.includes-elasticsearch-badge',
                  defaultMessage: 'Includes elasticsearch',
                }),
              )}
            />
            <EuiSpacer size='m' />
          </Fragment>
        )}
        <EuiText size='s'>
          <EuiListGroup style={{ marginLeft: 0 }} maxWidth='65ch' flush={true}>
            {features.map((desc, key) => (
              <EuiListGroupItem
                iconProps={{ color: euiTheme.colors.success }}
                wrapText={true}
                label={<EuiText size='s'>{desc}</EuiText>}
                iconType='check'
                key={key}
              />
            ))}
          </EuiListGroup>
        </EuiText>
      </EuiTextAlign>
      <EuiSpacer size='m' />
    </EuiCard>
  )
}

export default SelectSolutionCard
