/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import { sumBy } from 'lodash'

import type { AccessorFn } from '@elastic/charts'

import { colorForInstances } from '@modules/billing-lib/colorGenerator'
import type { ChartItemType } from '@modules/billing-api/customTypes'

import { ProductType } from '@/components/User/BillingUsage/types'

import { getAvailableChartValueTypes } from '../../BillingUsageOverviewV2/utils'
import {
  ColorKeyForOtherDeployments,
  ColorKeyForOtherProjects,
  VisibleDeployments,
} from '../../constants'

import type { ChartItem } from '@/components/User/BillingUsage/types'

type Map = Record<string, number>

export const prepareAggregatedInstanceDataAccessors = () => {
  const aggregatedValues = ['aggregated_deployment', 'aggregated_project']
  const accessors: AccessorFn[] = []
  aggregatedValues.map((aggregatedValue) => {
    const accessor: AccessorFn = ({ values }) => {
      const items = values.filter(({ type }) => `${getInstanceTypeKey(type)}` === aggregatedValue)
      accessor.fieldName = aggregatedValue
      return sumBy(items, 'value') || 0
    }

    accessors.push(accessor)
  })

  return accessors
}

export const getTopListOfInstancesByUsage = (
  chartData: ChartItem[],
): { topInstances: string[]; hasMore: boolean } => {
  const usageByInstance: Map = {}

  chartData.forEach(({ values }) => {
    values.forEach(({ id, value, name }) => {
      const key = constructKey(id, name)

      usageByInstance[key] = (usageByInstance[key] || 0) + value
    })
  })

  // Sort prepared map based on usage and return it as a list of instances
  const instances = Object.entries(usageByInstance)
    .sort((a, b) => b[1] - a[1])
    .map((item) => item[0])

  return {
    topInstances: instances.slice(0, VisibleDeployments),
    hasMore: instances.length > VisibleDeployments,
  }
}

export const prepareInstanceDataAccessors = ({
  topInstances,
  hasMore,
  type,
}: {
  topInstances: string[]
  hasMore: boolean
  type?: ProductType
}) => {
  const accessors: AccessorFn[] = []

  if (!type) {
    return accessors
  }

  topInstances.map((instance) => {
    const accessor: AccessorFn = ({ values }) => {
      const item = values.find(({ id, name }) => constructKey(id, name) === instance)

      // fieldName must be unique thats we we cannot rely only on name and instance is build based on an id_name pair
      accessor.fieldName = instance

      return item?.value || 0
    }

    accessors.push(accessor)
  })

  // we need to add one more accessor `Other` which is a sum of the rest
  if (hasMore) {
    const accessor: AccessorFn = ({ values }) => {
      // filter out top instances
      const items = values.filter(({ id, name }) => !topInstances.includes(constructKey(id, name)))

      accessor.fieldName = {
        [ProductType.Deployments]: ColorKeyForOtherDeployments,
        [ProductType.Projects]: ColorKeyForOtherProjects,
      }[type]

      return sumBy(items, 'value') || 0
    }

    accessors.push(accessor)
  }

  return accessors
}

const getInstanceTypeKey = (type: string): string =>
  `aggregated_${type === 'deployment' ? type : 'project'}`

export const getNameFromYAccessor = (yAccessor) => {
  if (yAccessor.split('_')[0] === 'aggregated') {
    // check if we display charts for both deployments and projects
    return yAccessor.split('_')[1] === 'deployment' ? 'Hosted deployments' : 'Serverless projects'
  }

  // it removes ID from string and returns the rest as a name
  // example: a81554de1fca40cfb601278b5ee44d0c_keep_serverless-qa-obs-ux-mgmt -> keep_serverless-qa-obs-ux-mgmt
  return yAccessor.split(/_(.*)/)[1]
}

export const getColorFromYAccessor = (yAccessor): string => {
  const id = yAccessor.split('_')[0]

  if (id === 'aggregated') {
    // check if we display charts for both deployments and projects
    return yAccessor.split('_')[1] === 'deployment'
      ? colorForInstances.get(ColorKeyForOtherDeployments)
      : colorForInstances.get(ColorKeyForOtherProjects)
  }

  return colorForInstances.get(id)
}

const constructKey = (id: string, name: string): string => (name ? `${id}_${name}` : `${id}_${id}`)

export const getFilteredData = ({ data, productTypes, solutions }) => {
  const availableValueTypes = getAvailableChartValueTypes(productTypes, solutions)

  return data.map(({ values, ...rest }) => ({
    ...rest,
    values: values.filter(({ type }) => availableValueTypes.includes(type as ChartItemType)),
  }))
}
