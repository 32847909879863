/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import moment from 'moment'

import { ViewBy, ChartType, ProductType, Solution, GroupBy } from '../../types'

import type { Moment } from 'moment'

export enum Action {
  SET_DATE_RANGE = 'set-date-range',
  SET_VIEW_BY = 'set-view-by',
  SET_CHART_TYPE = 'set-chart-type',
  SET_INSTANCE_ID = 'set-instance-id',
  SET_PRODUCT_TYPES = 'set-product-types',
  SET_SOLUTIONS = 'set-solutions',
  SET_GROUP_BY = 'set-group-by',
  SET_CUMULATIVE_VIEW = 'set-cumulative-view',
  RESET_FILTERS = 'reset-filters',
}

export interface ReducerState {
  startDate: Moment
  endDate: Moment
  viewBy: ViewBy
  chartType: ChartType
  productTypes: ProductType[]
  solutions: Solution[]
  instanceId?: string
  groupBy: GroupBy
  cumulativeView: boolean
}

interface SetDateRangeAction {
  type: Action.SET_DATE_RANGE
  payload: { startDate: Moment; endDate: Moment }
}

interface SetViewByAction {
  type: Action.SET_VIEW_BY
  payload: { viewBy: ViewBy }
}

interface SetChartTypeAction {
  type: Action.SET_CHART_TYPE
  payload: { chartType: ChartType }
}

interface SetInstanceIdAction {
  type: Action.SET_INSTANCE_ID
  payload: { instanceId: string | undefined }
}

interface SetProductTypeAction {
  type: Action.SET_PRODUCT_TYPES
  payload: { productTypes: ProductType[] }
}

interface SetSolutionsAction {
  type: Action.SET_SOLUTIONS
  payload: { solutions: Solution[] }
}

interface ResetFilters {
  type: Action.RESET_FILTERS
}

interface SetGroupByAction {
  type: Action.SET_GROUP_BY
  payload: { groupBy: GroupBy }
}

interface SetCumulativeViewAction {
  type: Action.SET_CUMULATIVE_VIEW
  payload: { cumulativeView: boolean }
}

export type Actions =
  | SetDateRangeAction
  | SetViewByAction
  | SetChartTypeAction
  | SetInstanceIdAction
  | SetProductTypeAction
  | SetSolutionsAction
  | SetGroupByAction
  | ResetFilters
  | SetCumulativeViewAction

export const defaultFilters = {
  startDate: moment.utc().startOf('month'),
  endDate: moment.utc(),
  viewBy: ViewBy.DAY,
  chartType: ChartType.BAR,
  productTypes: [ProductType.Deployments, ProductType.Projects],
  solutions: [Solution.Security, Solution.Observability, Solution.Search],
  groupBy: GroupBy.Product,
  cumulativeView: false,
}

export function filtersReducer(state: ReducerState, action: Actions): ReducerState {
  switch (action.type) {
    case Action.SET_DATE_RANGE:
      return {
        ...state,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
      }
    case Action.SET_VIEW_BY:
      return {
        ...state,
        viewBy: action.payload.viewBy,
      }
    case Action.SET_CHART_TYPE:
      return {
        ...state,
        chartType: action.payload.chartType,
      }
    case Action.SET_INSTANCE_ID:
      return {
        ...state,
        instanceId: action.payload.instanceId,
      }
    case Action.SET_PRODUCT_TYPES:
      return {
        ...state,
        productTypes: action.payload.productTypes,
      }
    case Action.SET_SOLUTIONS:
      return {
        ...state,
        solutions: action.payload.solutions,
      }
    case Action.RESET_FILTERS:
      return {
        ...state,
        productTypes: defaultFilters.productTypes,
        solutions: defaultFilters.solutions,
      }
    case Action.SET_GROUP_BY:
      return {
        ...state,
        groupBy: action.payload.groupBy,
      }
    case Action.SET_CUMULATIVE_VIEW:
      return {
        ...state,
        cumulativeView: action.payload.cumulativeView,
      }
    default:
      return state
  }
}
