/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'

import type {
  DeploymentTemplateInfoV2,
  GlobalDeploymentTemplateInfo,
  Provider,
} from '@modules/cloud-api/v1/types'
import type {
  PlatformId,
  RegionId,
  VersionNumber,
  Region,
  StackDeploymentCreateRequest,
} from '@modules/ui-types'
import type { DeepPartial } from '@modules/ts-essentials'
import { useProfile } from '@modules/profile-lib/hooks'

// eslint-disable-next-line import/no-restricted-paths
import { getDeploymentVersionSetter } from '@/lib/stackDeployments/crud'

import SelectOptions from './SelectOptions'
import TrialLimitMessage from './TrialLimitMessage'

import type { FunctionComponent } from 'react'

type Props = {
  availableVersions: string[]
  deploymentTemplates?: DeploymentTemplateInfoV2[] | null
  editorState: StackDeploymentCreateRequest
  globalDeploymentTemplates?: GlobalDeploymentTemplateInfo[] | null
  onChange: (
    changes: DeepPartial<StackDeploymentCreateRequest>,
    settings?: { shallow?: boolean },
  ) => void
  onDeploymentCreation: () => Promise<void>
  providerIds: PlatformId[]
  providers: Provider[]
  region?: Region
  setGlobalTemplate: (globalTemplate: GlobalDeploymentTemplateInfo) => void
  setRegion: (changes: { regionId: RegionId; stackVersion?: VersionNumber | null }) => void
  trialMaxedOut: boolean
  whitelistedVersions: string[]
  onDeploymentCreationLoading?: (isLoading: boolean) => void
}

const CreateDeploymentEditor: FunctionComponent<Props> = (props) => {
  const {
    availableVersions,
    deploymentTemplates,
    editorState,
    globalDeploymentTemplates,
    onChange,
    onDeploymentCreation,
    providerIds,
    providers,
    region,
    setGlobalTemplate,
    setRegion,
    trialMaxedOut,
    whitelistedVersions,
    onDeploymentCreationLoading,
  } = props
  const profile = useProfile()

  return (
    <div>
      {trialMaxedOut && <TrialLimitMessage trialLength={profile?.trial_length} />}

      <SelectOptions
        availableVersions={availableVersions}
        deploymentTemplates={deploymentTemplates}
        editorState={editorState}
        globalDeploymentTemplates={globalDeploymentTemplates}
        onDeploymentCreation={onDeploymentCreation}
        providerIds={providerIds}
        providers={providers}
        region={region}
        setGlobalTemplate={setGlobalTemplate}
        setRegion={setRegion}
        setVersion={getDeploymentVersionSetter({ editorState, onChange })}
        trialMaxedOut={trialMaxedOut}
        whitelistedVersions={whitelistedVersions}
        onDeploymentCreationLoading={onDeploymentCreationLoading}
      />
    </div>
  )
}

export default CreateDeploymentEditor
