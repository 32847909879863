/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiButtonGroup,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiSpacer,
  EuiTitle,
} from '@elastic/eui'

import EcuHelpTooltip from '@/components/EcuHelp/EcuHelpTooltip'

import type { LineItemByFilter } from '../utils'
import type { ReactNode } from 'react'
import type { FilterState } from '..'

interface Props {
  children?: ReactNode
  onFilterChange?: (id: string, value: FilterState) => void
  filter?: FilterState
  hasBorder?: boolean
  lineItemsByFilter?: LineItemByFilter
}

const PrepaidBalancePanel = ({
  children,
  hasBorder,
  filter,
  onFilterChange,
  lineItemsByFilter,
}: Props) => {
  const renderPanelTitle = () => (
    <EuiTitle size='xxxs'>
      <EuiFlexGroup gutterSize='none' alignItems='center' responsive={false}>
        <EuiFlexItem grow={false}>
          <h6>
            <FormattedMessage
              id='billing-details-summary.filters.prepaid-account-credits'
              defaultMessage='AVAILABLE CREDITS'
            />
            <EcuHelpTooltip />
          </h6>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiTitle>
  )

  const getTotalString = (type: FilterState): string => {
    if (!lineItemsByFilter) {
      return ''
    }

    return `(${lineItemsByFilter[type].length})`
  }

  const renderFilterButtons = () => {
    if (!filter || !onFilterChange) {
      return null
    }

    const filterButtons = [
      {
        id: 'all',
        'data-test-subj': 'filter-button-all',
        label: (
          <FormattedMessage
            id='billing-details-summary.filters.all-label'
            defaultMessage='All {total}'
            values={{ total: getTotalString('all') }}
          />
        ),
      },
      {
        id: 'active',
        'data-test-subj': 'filter-button-active',
        label: (
          <FormattedMessage
            id='billing-details-summary.filters.active-label'
            defaultMessage='Active {total}'
            values={{ total: getTotalString('active') }}
          />
        ),
      },
      {
        id: 'expired',
        'data-test-subj': 'filter-button-expired',
        label: (
          <FormattedMessage
            id='billing-details-summary.filters.deactivated-label'
            defaultMessage='Deactivated {total}'
            values={{ total: getTotalString('expired') }}
          />
        ),
      },
      {
        id: 'future',
        'data-test-subj': 'filter-button-future',
        label: (
          <FormattedMessage
            id='billing-details-summary.filters.future-label'
            defaultMessage='Future {total}'
            values={{ total: getTotalString('future') }}
          />
        ),
      },
    ]

    return (
      <EuiButtonGroup
        legend='Filter line items'
        options={filterButtons}
        idSelected={filter}
        onChange={onFilterChange}
        type='single'
      />
    )
  }

  return (
    <EuiPanel
      grow={false}
      className='billing-panel'
      hasBorder={hasBorder}
      data-test-id='available-credits'
      paddingSize='l'
    >
      <EuiFlexGroup alignItems='center'>
        <EuiFlexItem>{renderPanelTitle()}</EuiFlexItem>
        <EuiFlexItem grow={false}>{renderFilterButtons()}</EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer size='m' />

      {children}
    </EuiPanel>
  )
}

export default PrepaidBalancePanel
