/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { FormattedMessage } from 'react-intl'
import { css, jsx } from '@emotion/react'

import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiPanel, EuiSpacer, EuiText } from '@elastic/eui'

import useBillingDetails from '@modules/billing-lib/billingDetails/useBillingDetails'
import { getMarketplaceDisplayName } from '@modules/billing-lib/billingDetails/utils'

import DocLink from '@/components/DocLink'

import { getSubscriptionTypeName } from '../utils'

const MonthlyOrDrawdownOverviewPanel = () => {
  const billingDetails = useBillingDetails()

  return (
    <EuiPanel
      hasShadow={false}
      paddingSize='l'
      hasBorder={true}
      borderRadius='m'
      data-test-id='monthly-plan-card'
    >
      <EuiFlexGroup direction='column' justifyContent='spaceBetween' css={css({ height: '100%' })}>
        <EuiFlexItem grow={10}>
          <EuiText size='m'>
            <h6>
              <FormattedMessage id='mp-billing-overview.plan' defaultMessage='Plan' />
            </h6>
          </EuiText>

          <EuiSpacer size='m' />

          <EuiText data-test-id='monthly-plan-card.title'>
            <h3>{getSubscriptionTypeName(billingDetails.data?.billing_model)}</h3>
          </EuiText>

          <EuiSpacer size='xs' />

          <EuiText size='s'>
            <FormattedMessage
              id='mp-billing-overview.monthly-drawdown.marketplace-info'
              defaultMessage={`Billed through {channel} account`}
              values={{
                channel: getMarketplaceDisplayName(billingDetails.data?.channel),
              }}
            />
          </EuiText>
        </EuiFlexItem>

        <EuiFlexItem>
          <DocLink link='billingPrepaidConsumptionModel' favorSaasContext={true}>
            <EuiText size='xs'>
              <FormattedMessage
                id='mp-billing-overview.monthly-drawdown.prepaid-plan'
                defaultMessage='Interested in our Prepaid Plan Discount?'
              />
            </EuiText>
          </DocLink>

          <EuiSpacer size='s' />

          <div>
            <EuiButton
              element='button'
              fill={true}
              href='https://www.elastic.co/contact?baymax=rtp&rogue=eswt-1165-a#sales'
              target='_blank'
            >
              <FormattedMessage
                id='mp-billing-overview.monthly-drawdown.contact-button'
                defaultMessage='Contact the sales team'
              />
            </EuiButton>
          </div>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPanel>
  )
}

export default MonthlyOrDrawdownOverviewPanel
