/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React from 'react'
import { stringify, parse } from 'query-string'
import { difference } from 'lodash'
import moment from 'moment'
import { FormattedMessage } from 'react-intl'

import { ViewBy, ChartType, ProductType, Solution } from '../../types'

import { defaultFilters } from './filtersReducer'
import { maxAvailableDate, minAvailableDate } from './constants'

import type { ChartItem } from '../../types'
import type { Moment } from 'moment'
import type { ReducerState } from './filtersReducer'

export const stringifyFilters = (filters: ReducerState): string =>
  stringify(
    {
      ...filters,
      startDate: filters.startDate.format('YYYY-MM-DD'),
      endDate: filters.endDate.format('YYYY-MM-DD'),
    },
    { arrayFormat: 'comma' },
  )

const validViewByOptions = Object.values(ViewBy)
const validChartTypeOptions = Object.values(ChartType)
const validProductTypesOptions = Object.values(ProductType)
const validSolutionsOptions = Object.values(Solution)

const clearInvalidFilters = (filters: Partial<ReducerState>): Partial<ReducerState> => {
  const { viewBy, chartType, startDate, endDate, productTypes, solutions } = filters
  const cleanOptions = { ...filters }

  if (viewBy && !validViewByOptions.includes(viewBy)) {
    delete cleanOptions.viewBy
  }

  if (productTypes && difference(productTypes, validProductTypesOptions).length) {
    delete cleanOptions.productTypes
  }

  if (solutions && difference(solutions, validSolutionsOptions).length) {
    delete cleanOptions.solutions
  }

  // remove empty strings, null etc
  if (!filters.instanceId) {
    delete cleanOptions.instanceId
  }

  if (chartType && !validChartTypeOptions.includes(chartType)) {
    delete cleanOptions.chartType
  }

  const parsedStartDate = moment(startDate)
  const parsedEndDate = moment(endDate)

  // if any of the dates is invalid -> let's just start on a clean state.
  if (!parsedStartDate.isValid() || !parsedEndDate.isValid()) {
    delete cleanOptions.startDate
    delete cleanOptions.endDate
  }

  return cleanOptions
}

export const parseFiltersQuery = (rawQueryString: string): ReducerState => {
  const filtersQuery = parse(rawQueryString, { arrayFormat: 'comma' })

  if (typeof filtersQuery.productTypes === 'string') {
    filtersQuery.productTypes = [filtersQuery.productTypes]
  }

  if (typeof filtersQuery.solutions === 'string') {
    filtersQuery.solutions = [filtersQuery.solutions]
  }

  const sanitizedFiltersQuery = clearInvalidFilters(filtersQuery)
  const filters = { ...defaultFilters, ...sanitizedFiltersQuery }
  filters.startDate = moment.utc(filters.startDate)
  filters.endDate = moment.utc(filters.endDate)
  filters.cumulativeView = Boolean(filters.cumulativeView)

  return filters
}

export const getValidationError = ({
  startDate,
  endDate,
  viewBy,
}: {
  startDate: Moment
  endDate: Moment
  viewBy: ViewBy
}): JSX.Element | null => {
  if (startDate.isBefore(minAvailableDate) || endDate.isBefore(minAvailableDate)) {
    return (
      <FormattedMessage
        id='billing-usage.validation-error.minDate'
        defaultMessage='The start date must be on or after 2021/01/01.'
      />
    )
  }

  if (startDate.isAfter(endDate, 'd')) {
    return (
      <FormattedMessage
        id='billing-usage.validation-error.startAfterEnd'
        defaultMessage={`The start date can't be after the end date.`}
      />
    )
  }

  if (endDate.isAfter(maxAvailableDate, 'd')) {
    return (
      <FormattedMessage
        id='billing-usage.validation-error.endDateInFuture'
        defaultMessage={`The end date can't be in the future.`}
      />
    )
  }

  if (viewBy === ViewBy.DAY && endDate.diff(startDate, 'years', true) > 1) {
    return (
      <FormattedMessage
        id='billing-usage.validation-error.maxDailyPeriod'
        defaultMessage={`The time range can't exceed 1 year when viewing by day.`}
      />
    )
  }

  if (viewBy === ViewBy.MONTH && endDate.diff(startDate, 'years', true) > 3) {
    return (
      <FormattedMessage
        id='billing-usage.validation-error.maxMonthlyPeriod'
        defaultMessage={`The time range can't exceed 3 years when viewing by month.`}
      />
    )
  }

  return null
}

export const getCumulativeViewData = (data: ChartItem[]) =>
  data.reduce((acc, curr, index) => {
    const sumOfValues = [...(acc[index - 1]?.values || []), ...curr.values]
    // we want to sum up values with the same id to reduce the amount of data processed
    const arrayHashmap = sumOfValues.reduce((obj, item) => {
      if (!obj[item.id]) {
        obj[item.id] = item
      } else {
        obj[item.id] = { ...item, value: item.value + obj[item.id].value }
      }

      return obj
    }, {})

    const newItem = {
      timestamp: curr.timestamp,
      values: Object.values(arrayHashmap),
    }
    return [...acc, newItem]
  }, [] as ChartItem[])
