/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/*!
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@                                         @@@@
 *!   @@@   THIS FILE WAS AUTOGENERATED BY TOOL   @@@@
 *!   @@@   CHANGES MUST TARGET SCRIPTS/SWAGGER   @@@@
 *!   @@@                                         @@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!
 */

import {
  _buildQueryString,
  _emptyParams,
  _encode,
  _getBasePath,
  _getRawBasePath,
} from './urls-utils'

/**
 * GET /api/v1/deployments/templates
 * Operation: `get-deployment-templates-v2`
 * Retrieves all deployment templates.
 * @param {string} params.metadata An optional key/value pair in the form of (key:value) that will act as a filter and exclude any templates that do not have a matching metadata item associated.
 * @param {boolean} params.showInstanceConfigurations If true, will return details for each instance configuration referenced by the template. Default: true
 * @param {boolean} params.showMaxZones If true, will populate the max_zones field in the instance configurations. Only relevant if show_instance_configurations=true.
 * @param {string} params.stackVersion If present, it will cause the returned deployment templates to be adapted to return only the elements allowed in that version.
 * @param {boolean} params.hideDeprecated If true, templates flagged as deprecated will NOT be returned.
 * @param {boolean} params.showHidden If true, templates flagged as hidden will be returned.
 * @param {string} params.region Region of the deployment templates.
 * @see 200 The deployment templates were returned successfully. See {@link Array<DeploymentTemplateInfoV2>}
 * @see 400 The requested region was not found. (code: `templates.region_not_found`). See {@link BasicFailedReply}
 * @see 401 The user is not authorized to access requested region. (code: `templates.region_not_allowed`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getDeploymentTemplatesV2Url(params: GetDeploymentTemplatesV2UrlParams): string {
  const queryString = _buildQueryString({
    metadata: params.metadata,
    show_instance_configurations:
      params.showInstanceConfigurations === undefined ? true : params.showInstanceConfigurations,
    show_max_zones: params.showMaxZones === undefined ? false : params.showMaxZones,
    stack_version: params.stackVersion,
    hide_deprecated: params.hideDeprecated === undefined ? false : params.hideDeprecated,
    show_hidden: params.showHidden === undefined ? false : params.showHidden,
    region: params.region,
  })
  const path = `/deployments/templates`
  return `${_getRawBasePath()}${path}${queryString}`
}

export interface GetDeploymentTemplatesV2UrlParams {
  metadata?: string | null
  showInstanceConfigurations?: boolean | null
  showMaxZones?: boolean | null
  stackVersion?: string | null
  hideDeprecated?: boolean | null
  showHidden?: boolean | null
  region: string
}

/**
 * POST /api/v1/deployments/templates
 * Operation: `create-deployment-template-v2`
 * Creates a deployment template.
 * @param {boolean} params.validateOnly If true, the deployment template definition will be validated but the template will not be created.
 * @param {string} params.region Region of the deployment template.
 * @see Accepts a request payload of type {@link DeploymentTemplateRequestBody}
 * @see 200 The request was valid (used when validate_only is true). See {@link IdResponse}
 * @see 201 The deployment definition was valid and the template has been created. See {@link IdResponse}
 * @see 400 The requested region is not supported. (code: `templates.region_not_found`). See {@link BasicFailedReply}
 * @see 401 The user is not authorized to access requested region. (code: `templates.region_not_allowed`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function createDeploymentTemplateV2Url(params: CreateDeploymentTemplateV2UrlParams): string {
  const queryString = _buildQueryString({
    validate_only: params.validateOnly === undefined ? false : params.validateOnly,
    region: params.region,
  })
  const path = `/deployments/templates`
  return `${_getRawBasePath()}${path}${queryString}`
}

export interface CreateDeploymentTemplateV2UrlParams {
  validateOnly?: boolean | null
  region: string
}

/**
 * GET /api/v1/deployments/templates/{template_id}
 * Operation: `get-deployment-template-v2`
 * Retrieves a deployment template by id.
 * @param {string} params.templateId The identifier for the deployment template.
 * @param {boolean} params.showInstanceConfigurations If true, will return details for each instance configuration referenced by the template. Default: true
 * @param {boolean} params.showMaxZones If true, will populate the max_zones field in the instance configurations. Only relevant if show_instance_configurations=true.
 * @param {string} params.stackVersion If present, it will cause the returned deployment template to be adapted to return only the elements allowed in that version.
 * @param {string} params.region Region of the deployment template.
 * @see 200 The deployment template was found and returned successfully. See {@link DeploymentTemplateInfoV2}
 * @see 400 The requested region is not supported. (code: `templates.region_not_found`). See {@link BasicFailedReply}
 * @see 401 The user is not authorized to access requested region. (code: `templates.region_not_allowed`). See {@link BasicFailedReply}
 * @see 404 The deployment template specified by {template_id} cannot be found. (code: `templates.template_not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getDeploymentTemplateV2Url(params: GetDeploymentTemplateV2UrlParams): string {
  const queryString = _buildQueryString({
    show_instance_configurations:
      params.showInstanceConfigurations === undefined ? true : params.showInstanceConfigurations,
    show_max_zones: params.showMaxZones === undefined ? false : params.showMaxZones,
    stack_version: params.stackVersion,
    region: params.region,
  })
  const path = _encode`/deployments/templates/${params.templateId}`
  return `${_getRawBasePath()}${path}${queryString}`
}

export interface GetDeploymentTemplateV2UrlParams {
  templateId: string
  showInstanceConfigurations?: boolean | null
  showMaxZones?: boolean | null
  stackVersion?: string | null
  region: string
}

/**
 * PUT /api/v1/deployments/templates/{template_id}
 * Operation: `set-deployment-template-v2`
 * Creates or updates a deployment template.
 * @param {string} params.templateId The identifier for the deployment template.
 * @param {string} params.version If specified, checks for conflicts against the version of the template (returned in 'x-cloud-resource-version' of the GET request).
 * @param {boolean} params.createOnly If true, will fail if the deployment template already exists at the given id.
 * @param {boolean} params.validateOnly If true, the deployment template definition will be validated but the template will not be updated.
 * @param {string} params.region Region of the deployment template.
 * @see Accepts a request payload of type {@link DeploymentTemplateRequestBody}
 * @see 200 The deployment definition was valid and the template has been updated. See {@link IdResponse}
 * @see 201 The deployment definition was valid and the template was created. See {@link IdResponse}
 * @see 400 The requested region is not supported. (code: `templates.region_not_found`). See {@link BasicFailedReply}
 * @see 401 The user is not authorized to access requested region. (code: `templates.region_not_allowed`). See {@link BasicFailedReply}
 * @see 404 The deployment template specified by {template_id} cannot be found. (code: `templates.template_not_found`). See {@link BasicFailedReply}
 * @see 409 The version supplied in the request conflicted with the version found on the server. (code: `templates.version_conflict`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function setDeploymentTemplateV2Url(params: SetDeploymentTemplateV2UrlParams): string {
  const queryString = _buildQueryString({
    version: params.version,
    create_only: params.createOnly === undefined ? false : params.createOnly,
    validate_only: params.validateOnly === undefined ? false : params.validateOnly,
    region: params.region,
  })
  const path = _encode`/deployments/templates/${params.templateId}`
  return `${_getRawBasePath()}${path}${queryString}`
}

export interface SetDeploymentTemplateV2UrlParams {
  templateId: string
  version?: string | null
  createOnly?: boolean | null
  validateOnly?: boolean | null
  region: string
}

/**
 * DELETE /api/v1/deployments/templates/{template_id}
 * Operation: `delete-deployment-template-v2`
 * Deletes a deployment template by id.
 * @param {string} params.templateId The identifier for the deployment template.
 * @param {string} params.region Region of the deployment template.
 * @see 200 The deployment template was successfully deleted.
 * @see 400 The requested region is not supported. (code: `templates.region_not_found`). See {@link BasicFailedReply}
 * @see 401 The user is not authorized to access requested region. (code: `templates.region_not_allowed`). See {@link BasicFailedReply}
 * @see 404 The deployment template specified by {template_id} cannot be found. (code: `templates.template_not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function deleteDeploymentTemplateV2Url(params: DeleteDeploymentTemplateV2UrlParams): string {
  const queryString = _buildQueryString({
    region: params.region,
  })
  const path = _encode`/deployments/templates/${params.templateId}`
  return `${_getRawBasePath()}${path}${queryString}`
}

export interface DeleteDeploymentTemplateV2UrlParams {
  templateId: string
  region: string
}
