/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { useState, type FunctionComponent } from 'react'
import { useHistory, useParams } from 'react-router'
import { FormattedMessage } from 'react-intl'
import { jsx } from '@emotion/react'

import { EuiCallOut, EuiFlexGroup, EuiFlexItem } from '@elastic/eui'

import MigrationFromSiemChoice, {
  SplunkTrademarkMessage,
} from '@modules/discovery-questions-components/MigrationFromSiemOptions'
import SecurityUseCaseChoice from '@modules/discovery-questions-components/SecurityUseCases'
import SecurityUseCaseChoiceV5 from '@modules/discovery-questions-components/SecurityUseCasesV5'
import type { Region as StackDeploymentRegion } from '@modules/ui-types'
import {
  MIGRATION_FROM_SIEM_PREFIX,
  SECURITY_USE_CASE_PREFIX,
  SECURITY_USE_CASES_SIEM,
} from '@modules/discovery-questions-lib/constants'
import { useSolutionPickerVersion } from '@modules/solution-selector/hooks'
import { useProfile } from '@modules/profile-lib/hooks'

// eslint-disable-next-line import/no-restricted-paths
import { getMarketplace } from '@/lib/marketPlace'

import ExperienceLevel from '../discovery-questions-components/ExperienceLevel'
import SelectSearchProjectSubtype from '../discovery-questions-components/SelectSearchProjectSubtype'
import TrialIntent from '../discovery-questions-components/TrialIntent'
import { useAnswersState, useOnboardingToken } from '../discovery-questions-lib/hooks'
import {
  initializeSteps,
  RESOURCE_TYPES,
  Step,
  introduceIsSiemMigrationStepBeforeCSPRegion,
  introduceSecurityUseCaseStepBeforeCSPRegion,
  removeIsSiemMigrationStep,
  removeSecurityUseCaseStep,
} from '../discovery-questions-lib/steps'
import {
  isElasticsearchServerless,
  isUseCaseDetailWithMigrationFromSiem,
  ResourceType,
  toDefaultSolutionView,
} from '../discovery-questions-lib/utils'
import TrialFlowContainer from '../discovery-questions-pages/TrialFlowContainer'
import { useSessionStorageBackedState } from '../utils/hooks/useLocalStorage'

import SolutionsOnboardingV5 from './components/SolutionsV5'
import {
  fromMigrationFromSiemOptionToDiscoveryQuestionsStateType,
  fromMigrationFromSiemOptionToMigrationFromSiem,
  fromUpdateDiscoveryQuestionsToDiscoveryQuestionsStateTypeAdapter,
  fromUseCaseDetailWithMigrationFromSiemToMigrationFromSiemOption,
} from './adapters'
import CreateResource from './components/CreateResource'
import FullName from './components/FullName'
import HeaderOnboarding from './components/header'
import SolutionsOnboarding from './components/solutions'
import { ONBOARDING_COMBINE_STEPS, ONBOARDING_COMBINE_STEPS_WITH_SEARCH_TYPE } from './constants'
import {
  getProjectTypeFromUseCase,
  getStepUrl,
  getUseCaseFromProjectType,
  handleSetResourceType,
} from './helpers'
import { useCreateOnboardingProject } from './hooks/use-create-onboarding-project'
import { useEbtOnboarding } from './hooks/use-ebt-onboarding'
import { useRedirectToHome } from './hooks/use-redirect-to-home'
import { usePersistOnboardingQuestions } from './hooks/use-persist-onboarding-question'
import { useIsSecurityUseCaseQuestions } from './hooks/use-is-security-use-case-questions'

import type { Region } from '../cluster-user-api/v1/types'
import type { ElasticsearchOptimizedFor } from '../project-user-api/v1/types'

type OnboardingUrlParams = { questionId: Step; resourceType: ResourceType }

const OnboardingPage: FunctionComponent = () => {
  const [resourceCreationLaunched, setResourceCreationLaunched] = useState(false)
  const [resourceCreationLoading, setResourceCreationLoading] = useState(false)
  const [region, setRegion] = useState<Region>()
  const [errors, setErrors] = useState<React.ReactNode[]>([])
  const history = useHistory()

  const onboardingTokenParams = useOnboardingToken()
  const [onboardingToken, setOnboardingToken] = useState(onboardingTokenParams)
  const { reportEbtEvent } = useEbtOnboarding()
  const profile = useProfile()

  const { questionId: questionIdParams, resourceType: resourceTypeParams } =
    useParams<OnboardingUrlParams>()

  const [resourceType, setResourceType] = useState<ResourceType>(
    RESOURCE_TYPES.find((resource) => resource === resourceTypeParams) ?? RESOURCE_TYPES[0],
  )

  const [currentStepContext, setSteps] = useState(() =>
    initializeSteps({
      onboardingToken,
      resourceType,
      steps:
        resourceType === ResourceType.Serverless
          ? ONBOARDING_COMBINE_STEPS_WITH_SEARCH_TYPE
          : ONBOARDING_COMBINE_STEPS,
    }),
  )

  useRedirectToHome()

  const isSecurityUseCaseQuestions = useIsSecurityUseCaseQuestions()

  const { steps, getNextStep, initialState, getPrevStep } = currentStepContext

  // validate questionId from the URL against predefined ids, otherwise fallback to the first step
  const questionId = steps.find((step) => step === questionIdParams) ?? steps[0]
  const { answers, setAnswer } = useAnswersState({
    initialState:
      fromUpdateDiscoveryQuestionsToDiscoveryQuestionsStateTypeAdapter(
        initialState.discoveryQuestions,
      ) || {},
  })

  const [projectSubtype, setProjectSubtype] = useSessionStorageBackedState<
    ElasticsearchOptimizedFor | undefined
  >('project-subtype', initialState.additionalParameters?.optimized_for)

  const {
    experience_level: experienceLevel,
    trial_intent: trialIntent,
    use_case: useCase,
    use_case_detail: useCaseDetail,
    full_name: fullName,
    company,
  } = answers
  const projectType = getProjectTypeFromUseCase(useCase)

  const { putDiscoveryQuestions } = usePersistOnboardingQuestions({
    answers,
    setResourceCreationLaunched,
    setErrors,
  })
  const { launchProjectCreation } = useCreateOnboardingProject({
    answers,
    resourceType,
    setErrors,
    setResourceCreationLaunched,
    onboardingToken,
    projectSubtype,
  })
  const isSearchProjectServerless = isElasticsearchServerless(resourceType, useCase)

  const setResourceTypeHandler = (rt: ResourceType) => {
    const isSecurityUseCase = useCase ? isSecurityUseCaseQuestions(useCase) : false
    handleSetResourceType({
      isSearchProjectServerless,
      onboardingToken,
      setOnboardingToken,
      setSteps,
      setResourceType,
      rt,
      useCase,
      isSecurityUseCase,
      isSecurityMigration:
        isSecurityUseCase && useCaseDetail
          ? useCaseDetail.security.use_case === SECURITY_USE_CASES_SIEM
          : false,
    })
  }

  const solutionPickerVersion = useSolutionPickerVersion()

  const SolutionsOnboardingComponent =
    solutionPickerVersion === 'v5' ? SolutionsOnboardingV5 : SolutionsOnboarding

  const SecurityUseCaseChoiceComponent =
    solutionPickerVersion === 'v5' ? SecurityUseCaseChoiceV5 : SecurityUseCaseChoice

  function renderStep() {
    switch (questionId) {
      case Step.ExperienceLevel:
        return (
          <ExperienceLevel
            experienceLevel={experienceLevel}
            onChangeExperienceLevel={(experience_level) => {
              setAnswer({ experience_level })
            }}
            onSubmitExperienceLevel={(elValue) => {
              const nextStep = getNextStep(questionId)

              if (nextStep) {
                reportEbtEvent('experience_level', {
                  answer: elValue,
                  resource: resourceType,
                  marketplace: getMarketplace(profile?.domain),
                })
                history.push(
                  getStepUrl({ resourceType: resourceTypeParams, step: nextStep, onboardingToken }),
                )
              }
            }}
          />
        )
      case Step.TrialIntent:
        return (
          <TrialIntent
            trialIntent={trialIntent}
            onChangeTrialIntent={(trial_intent) => {
              setAnswer({ trial_intent })
            }}
            onSubmitTrialIntent={(tiValue) => {
              const nextStep = getNextStep(questionId)

              if (nextStep) {
                reportEbtEvent('trial_intent', {
                  answer: tiValue,
                  resource: resourceType,
                  marketplace: getMarketplace(profile?.domain),
                })

                history.push(
                  getStepUrl({ resourceType: resourceTypeParams, step: nextStep, onboardingToken }),
                )
              }
            }}
          />
        )
      case Step.UseCase:
        return (
          <SolutionsOnboardingComponent
            onNext={(projectTypePick) => {
              const useCaseUpdated = getUseCaseFromProjectType(projectTypePick)
              setAnswer({ use_case: useCaseUpdated })

              let nextStepContext = currentStepContext

              if (resourceType === ResourceType.Serverless && useCaseUpdated === 'search') {
                nextStepContext = initializeSteps({
                  onboardingToken,
                  steps: ONBOARDING_COMBINE_STEPS_WITH_SEARCH_TYPE,
                  resourceType,
                })
              } else if (isSecurityUseCaseQuestions(useCaseUpdated)) {
                nextStepContext = initializeSteps({
                  onboardingToken,
                  steps: introduceSecurityUseCaseStepBeforeCSPRegion(steps),
                  resourceType,
                })
              } else {
                nextStepContext = initializeSteps({
                  onboardingToken,
                  steps: ONBOARDING_COMBINE_STEPS,
                  resourceType,
                })
              }

              setSteps(nextStepContext)

              const nextStep = nextStepContext.getNextStep(questionId)

              if (nextStep) {
                reportEbtEvent('use_case', {
                  answer: useCaseUpdated,
                  resource: resourceType,
                  solution_picker_version: solutionPickerVersion,
                  marketplace: getMarketplace(profile?.domain),
                })
                history.push(
                  getStepUrl({ resourceType: resourceTypeParams, step: nextStep, onboardingToken }),
                )
              }
            }}
          />
        )
      case Step.CspRegion:
        return (
          <CreateResource
            solutionType={toDefaultSolutionView(answers.use_case)}
            hideSwitchToResource={resourceTypeParams === ResourceType.Stateful}
            isContinueStep={isSearchProjectServerless && !projectSubtype}
            onDeploymentCreation={({
              region: ebtRegion,
              version,
              platform,
              hardware,
            }: {
              region: StackDeploymentRegion
              version: string
              platform: string
              hardware: string
            }) => {
              reportEbtEvent('resource', {
                answer: {
                  region: ebtRegion,
                  version,
                  platform,
                  hardware,
                },
                resource: resourceType,
                solution_picker_version: solutionPickerVersion,
                marketplace: getMarketplace(profile?.domain),
              })
              return putDiscoveryQuestions()
            }}
            onRegionConfirmed={(updatedRegion) => {
              reportEbtEvent('resource', {
                answer: {
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  region: updatedRegion!,
                },
                resource: resourceType,
                solution_picker_version: solutionPickerVersion,
                marketplace: getMarketplace(profile?.domain),
              })

              if (isSearchProjectServerless && !projectSubtype) {
                setRegion(updatedRegion)
                history.push(
                  getStepUrl({
                    resourceType: resourceTypeParams,
                    step: Step.SearchProjectSubtype,
                    onboardingToken,
                  }),
                )
              } else {
                setResourceCreationLaunched(true)
                putDiscoveryQuestions(() => launchProjectCreation(updatedRegion))
              }
            }}
            resourceType={resourceType}
            resourceCreationLaunched={resourceCreationLaunched}
            switchToResource={setResourceTypeHandler}
            onResourceCreationLoading={(isLoading) => setResourceCreationLoading(isLoading)}
          />
        )
      case Step.SecurityUseCase:
        return (
          <SecurityUseCaseChoiceComponent
            securityUseCase={answers.use_case_detail?.security.use_case}
            onChangeSecurityUseCase={(value) => {
              setAnswer({
                use_case_detail: {
                  security: {
                    use_case: value,
                  },
                },
              })
            }}
            onSubmitSecurityUseCase={(value) => {
              reportEbtEvent(SECURITY_USE_CASE_PREFIX, {
                use_case: value,
                resource: resourceType,
                solution_picker_version: solutionPickerVersion,
              })

              let nextStepContext = currentStepContext

              if (value === SECURITY_USE_CASES_SIEM) {
                nextStepContext = initializeSteps({
                  onboardingToken,
                  steps: introduceIsSiemMigrationStepBeforeCSPRegion(steps),
                  resourceType,
                })
              }

              setSteps(nextStepContext)

              const nextStep = nextStepContext.getNextStep(questionId)

              if (nextStep) {
                history.push(
                  getStepUrl({ resourceType: resourceTypeParams, step: nextStep, onboardingToken }),
                )
              }
            }}
          />
        )
      case Step.IsSiemMigration:
        return (
          isUseCaseDetailWithMigrationFromSiem(answers.use_case_detail) && (
            <MigrationFromSiemChoice
              isMigratingFromSiem={fromUseCaseDetailWithMigrationFromSiemToMigrationFromSiemOption(
                answers.use_case_detail,
              )}
              onChangeMigratingFromSiem={(value) => {
                setAnswer(fromMigrationFromSiemOptionToDiscoveryQuestionsStateType(value))
              }}
              onSubmitMigratingFromSiem={(value) => {
                reportEbtEvent(MIGRATION_FROM_SIEM_PREFIX, {
                  ...fromMigrationFromSiemOptionToMigrationFromSiem(value),
                  resource: resourceType,
                })

                const nextStep = getNextStep(questionId)

                if (nextStep) {
                  history.push(
                    getStepUrl({
                      resourceType: resourceTypeParams,
                      step: nextStep,
                      onboardingToken,
                    }),
                  )
                }
              }}
            />
          )
        )
      case Step.SearchProjectSubtype:
        return (
          <SelectSearchProjectSubtype
            projectSubtype={projectSubtype}
            onChangeSearchProjectSubtype={(value: ElasticsearchOptimizedFor) =>
              setProjectSubtype(value)
            }
            onSearchProjectSubtypeCaseConfirmed={() => {
              reportEbtEvent('project_subtype', {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                answer: projectSubtype!,
                resource: resourceType,
                marketplace: getMarketplace(profile?.domain),
              })
              setResourceCreationLaunched(true)
              putDiscoveryQuestions(() => launchProjectCreation(region))
            }}
            isLaunchButton={true}
          />
        )
      default:
      case Step.FullName:
        return (
          <FullName
            resourceType={resourceType}
            fullName={fullName || ''}
            company={company || ''}
            onChangeFullName={(full_name) => setAnswer({ full_name })}
            onChangeCompany={(newCompany) => setAnswer({ company: newCompany })}
            setStep={() => {
              reportEbtEvent('full_name', {
                answer: null,
                resource: resourceType,
                marketplace: getMarketplace(profile?.domain),
              })
              const nextStep = getNextStep(questionId)

              if (nextStep) {
                history.push(
                  getStepUrl({ resourceType: resourceTypeParams, step: nextStep, onboardingToken }),
                )
              }
            }}
          />
        )
    }
  }

  return (
    <TrialFlowContainer
      data-test-id='discovery-questions.form'
      step={steps.indexOf(questionId)}
      totalSteps={steps.length}
      onGoBack={() => {
        const prevStep = getPrevStep(questionId)

        if (prevStep === null) {
          return
        }

        if (prevStep === Step.UseCase) {
          setAnswer({ use_case: undefined })
          setSteps(
            initializeSteps({
              onboardingToken,
              steps:
                steps.indexOf(questionId) >= 3 || isSearchProjectServerless
                  ? ONBOARDING_COMBINE_STEPS_WITH_SEARCH_TYPE
                  : ONBOARDING_COMBINE_STEPS,
              resourceType,
            }),
          )
        }

        if (questionId === Step.SearchProjectSubtype) {
          setProjectSubtype(undefined)
        }

        if (questionId === Step.SecurityUseCase) {
          setAnswer({ use_case_detail: undefined })
          setSteps(
            initializeSteps({
              onboardingToken,
              steps: removeSecurityUseCaseStep(steps),
              resourceType,
            }),
          )
        } else if (questionId === Step.IsSiemMigration) {
          setAnswer({ use_case_detail: { security: { use_case: SECURITY_USE_CASES_SIEM } } })
          setSteps(
            initializeSteps({
              onboardingToken,
              steps: removeIsSiemMigrationStep(steps),
              resourceType,
            }),
          )
        }

        history.push(
          getStepUrl({ resourceType: resourceTypeParams, step: prevStep, onboardingToken }),
        )
      }}
      header={
        <HeaderOnboarding
          hide={resourceTypeParams === ResourceType.Stateful}
          resourceType={resourceType}
          projectType={projectType}
        />
      }
      footnote={questionId === Step.IsSiemMigration && <SplunkTrademarkMessage />}
      resourceType={resourceType}
      hideBgImage={questionId === Step.UseCase}
      isBackButtonDisabled={resourceCreationLoading || resourceCreationLaunched}
    >
      <EuiFlexGroup className='fs-unmask' direction='column'>
        <EuiFlexItem>
          {errors.map((message) => (
            <EuiCallOut
              title={
                <FormattedMessage
                  id='onboarding-page.error-title'
                  defaultMessage='Sorry, we were unable to create your project'
                />
              }
              color='danger'
              iconType='error'
              onDismiss={() => setErrors([])}
            >
              {message}
            </EuiCallOut>
          ))}
        </EuiFlexItem>
        <EuiFlexItem>{renderStep()}</EuiFlexItem>
      </EuiFlexGroup>
    </TrialFlowContainer>
  )
}

export default OnboardingPage
