/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import { connect } from 'react-redux'

import { fetchDriftJwt as fetchDriftJwtAction } from '@/apps/userconsole/actions/profile'
import {
  checkIsProfileLoggedOutPublicPricingPage,
  getProfile,
} from '@/apps/userconsole/reducers/profile'
import { fetchDriftJwtRequest } from '@/apps/userconsole/reducers/registry'
import { getConfigForKey } from '@/selectors'

import { DriftChat } from './DriftChat'

import type { ReduxState, ThunkDispatch } from '@/types/redux'
import type { ComponentType } from 'react'
import type { ConsumerProps, DispatchProps, StateProps } from './types'

const mapStateToProps = (state: ReduxState): StateProps => {
  const profile = getProfile(state)

  return {
    chatUrl: `${getConfigForKey(state, 'DRIFT_CHAT_URL_BASE')}/drift-iframe.html`,
    fetchDriftJwtRequestState: fetchDriftJwtRequest(state),
    profile: checkIsProfileLoggedOutPublicPricingPage(profile) ? null : profile,
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
  fetchDriftJwt: () => dispatch(fetchDriftJwtAction()),
})

export function wrapDriftChat(
  Component: ComponentType<StateProps & DispatchProps & ConsumerProps>,
): ComponentType<ConsumerProps> {
  return connect<StateProps, DispatchProps, ConsumerProps>(
    mapStateToProps,
    mapDispatchToProps,
  )(Component)
}

export default wrapDriftChat(DriftChat)
