/* eslint-disable @elastic/eui/href-or-on-click */
/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useParams } from 'react-router'

import { EuiButton, EuiCallOut, EuiFlexGroup, EuiLoadingSpinner } from '@elastic/eui'

import VideoPlayer from '@modules/VideoPlayer'
import TrialFlowContainer from '@modules/discovery-questions-pages/TrialFlowContainer'
import type { UrlParams } from '@modules/project-creation-components/types'
import { isProjectType } from '@modules/project-creation-lib/types'
import { makeSsoUrl } from '@modules/project-lib/kibanaLinks'
import { useGetProjectQuery } from '@modules/project-user-lib/hooks/get'
import { useIsProjectInitializing } from '@modules/project-user-lib/hooks/status'
import { getVideoBySolution } from '@modules/project-user-lib/util'
import { getKibanaProjectDeepLink } from '@modules/discovery-questions-lib/steps'
import { useOnboardingToken, useUseCaseDetails } from '@modules/discovery-questions-lib/hooks'
import { StepLayout } from '@modules/cui/Step/StepLayout'
import { useProfile } from '@modules/profile-lib/hooks'

// eslint-disable-next-line import/no-restricted-paths
import { getMarketplace } from '@/lib/marketPlace'

import { useEbtOnboarding } from '../onboarding-combine-flow/hooks/use-ebt-onboarding'
import { ResourceType } from '../discovery-questions-lib/utils'

import type { FunctionComponent } from 'react'

const ProjectInitializationScreen: FunctionComponent = () => {
  const { solution, id } = useParams<UrlParams>()
  const profile = useProfile()
  const onboardingToken = useOnboardingToken()
  const useCaseDetails = useUseCaseDetails()
  const kibanaDeepLink = getKibanaProjectDeepLink(onboardingToken, useCaseDetails)
  const { reportEbtEvent } = useEbtOnboarding()
  const resourceType = ResourceType.Serverless

  const { data: projectData, isSuccess: isProjectCreatedSuccessfully } = useGetProjectQuery(
    isProjectType(solution) ? solution : 'elasticsearch', // unconditional hook sanity
    id || '', // unconditional hook sanity
    { enabled: Boolean(isProjectType(solution) && id) },
  )

  const isInitializing = useIsProjectInitializing(projectData, false)

  if (!id || !solution || !isProjectType(solution)) {
    return null
  }

  if (!isInitializing) {
    if (!isProjectCreatedSuccessfully) {
      return renderProjectCreationErrorCallout()
    }

    reportEbtEvent('kibana', {
      answer: '',
      resource: resourceType,
      marketplace: getMarketplace(profile?.domain),
    })

    const ssoURL = makeSsoUrl(projectData, { kibanaDeepLink, onboardingToken })

    if (ssoURL === undefined) {
      return renderSsoUrlCallout()
    }

    window.location.href = ssoURL
  }

  return (
    <TrialFlowContainer resourceType={resourceType}>
      <StepLayout
        title={
          <EuiFlexGroup>
            <EuiLoadingSpinner size='xl' />
            <FormattedMessage
              id='discovery-questions.project-initialization.title'
              defaultMessage='Hang tight! We are setting up your environment.'
            />
          </EuiFlexGroup>
        }
        description={
          <FormattedMessage
            id='discovery-questions.project-initialization.description'
            defaultMessage='This process usually takes 3-5 minutes.'
          />
        }
      >
        <VideoPlayer uuid={getVideoBySolution(solution)} muted={true} autoplay={true} />
      </StepLayout>
    </TrialFlowContainer>
  )

  function renderProjectCreationErrorCallout(): React.ReactElement<any, any> | null {
    return (
      <EuiCallOut title='Sorry, there was an error' color='danger' iconType='error'>
        <FormattedMessage
          id='discovery-questions.project-initialization.error.generic'
          defaultMessage='We were unable to create your project. Please try again later.'
        />
      </EuiCallOut>
    )
  }

  function renderSsoUrlCallout(): React.ReactElement<any, any> | null {
    if (!isProjectCreatedSuccessfully) {
      return null // should not happen, just for TS to be happy
    }

    return (
      <EuiCallOut title='This was unexpected' color='warning' iconType='warning'>
        <p>
          <FormattedMessage
            id='discovery-questions.project-initialization.error.redirect'
            defaultMessage='We were unable to redirect you automatically. Click the button below to open your project'
          />
        </p>
        <EuiButton
          onClick={() => {
            reportEbtEvent('kibana', {
              answer: '',
              resource: ResourceType.Serverless,
              marketplace: getMarketplace(profile?.domain),
            })
          }}
          href={makeSsoUrl(projectData, { kibanaDeepLink, onboardingToken })}
          fill={true}
        >
          <FormattedMessage
            id='discovery-questions.project-initialization.open-project'
            defaultMessage='Open project'
          />
        </EuiButton>
      </EuiCallOut>
    )
  }
}

export default ProjectInitializationScreen
