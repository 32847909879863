/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { Fragment, useContext } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiConfirmModal } from '@elastic/eui'

import { LogoutContext } from '@modules/logout/context'

export const ErrorNotification = () => {
  const { logoutContext, setLogoutContext } = useContext(LogoutContext)

  if (!logoutContext.logoutError) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <Fragment />
  }

  const closeModal = () => setLogoutContext({})

  return (
    <EuiConfirmModal
      maxWidth='600px'
      title={
        <FormattedMessage
          id='global-error-notification.title'
          defaultMessage='Something went wrong'
        />
      }
      onCancel={closeModal}
      onConfirm={closeModal}
      buttonColor='danger'
      confirmButtonText={
        <FormattedMessage id='global-error-notification.dismiss' defaultMessage='Dismiss' />
      }
    >
      <FormattedMessage
        id='global-error-notification.message'
        defaultMessage='We were unable to process your request. Try again later or contact Elastic support for assistance.'
      />
    </EuiConfirmModal>
  )
}
