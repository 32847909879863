/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'

type Listener = () => void

export interface Scheduler {
  clear: () => void
  pause: () => void
  register: (listener: Listener, opts?: { immediate?: boolean }) => () => void
  run: () => void
  start: () => void
  stop: () => void
}

export const SchedulerContext = React.createContext<Scheduler>(scheduler())

type TimerID = string | number | NodeJS.Timeout | undefined

export default function scheduler({ interval = 30000 } = {}): Scheduler {
  const listeners: Listener[] = []

  let timerId: TimerID

  function run() {
    listeners.forEach((listener) => listener())
  }

  function schedule() {
    clearTimeout(timerId)
    timerId = setTimeout(() => {
      try {
        run()
      } finally {
        schedule()
      }
    }, interval)
  }

  function pause() {
    clearTimeout(timerId)
  }

  function clear() {
    listeners.length = 0
  }

  return {
    register(fn: Listener, { immediate = true } = {}) {
      if (immediate) {
        fn()
      }

      listeners.push(fn)
      let isSubscribed = true

      return function unsubscribe() {
        if (!isSubscribed) {
          return
        }

        isSubscribed = false

        const index = listeners.indexOf(fn)
        listeners.splice(index, 1)
      }
    },
    start: schedule,
    stop() {
      pause()
      clear()
    },
    run,
    pause,
    clear,
  }
}
