/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

import {
  EuiButtonIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLink,
  EuiPopover,
  EuiText,
  EuiTitle,
} from '@elastic/eui'

import { useToggle } from '@modules/utils/hooks/useToggle'
import { CuiRouterLink } from '@modules/cui/RouterButton'

import { deploymentsUrl } from '@/lib/urlBuilder'

import type { WrappedComponentProps } from 'react-intl'
import type { FunctionComponent } from 'react'

type Props = WrappedComponentProps

const HostedDeploymentsTileTitle: FunctionComponent<Props> = ({ intl: { formatMessage } }) => {
  const [isPopoverOpen, togglePopover, setPopoverOpen] = useToggle()

  return (
    <EuiFlexGroup gutterSize='xs' alignItems='center' responsive={false}>
      <EuiFlexItem grow={0}>
        <CuiRouterLink
          to={deploymentsUrl()}
          color='text'
          data-test-id='portal-ess-title-link'
          className='cloud-portal-tile-title'
        >
          <EuiTitle size='xs'>
            <h2>
              <FormattedMessage id='hosted-deployments.title' defaultMessage='Hosted deployments' />
            </h2>
          </EuiTitle>
        </CuiRouterLink>
      </EuiFlexItem>
      <EuiFlexItem grow={0}>
        <EuiPopover
          button={
            <EuiButtonIcon
              iconType='questionInCircle'
              onClick={togglePopover}
              aria-label={formatMessage({
                id: 'hosted-deployments.title.info',
                defaultMessage: 'more information about deployments',
              })}
            />
          }
          anchorPosition='upCenter'
          isOpen={isPopoverOpen}
          closePopover={() => setPopoverOpen(false)}
        >
          <EuiText style={{ width: 330 }} size='s'>
            <p>
              <FormattedMessage
                id='hosted-deployments.title-popover'
                defaultMessage='Hosted deployments are cloud instances of the Elastic Stack. They include all Search, Observability, and Security capabilities. They are highly customizable and you control most settings. You pay based on their size. <link>Learn more</link>'
                values={{
                  link: (content) => (
                    <EuiLink href='https://ela.st/dedicated-deployment-usage-info' target='_blank'>
                      {content}
                    </EuiLink>
                  ),
                }}
              />
            </p>
          </EuiText>
        </EuiPopover>
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}

export default injectIntl(HostedDeploymentsTileTitle)
