/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { css, jsx } from '@emotion/react'
import { type ReactNode, type FunctionComponent, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiButtonEmpty, EuiFlexGroup, EuiFlexItem, EuiIcon, useEuiTheme } from '@elastic/eui'

import { CuiThemedIcon } from '@modules/cui/ThemedIcon'
import StepPanel from '@modules/cui/Step/StepPanel'
import history from '@modules/utils/history'
import type { ResourceType } from '@modules/discovery-questions-lib/utils'
import { useConfig } from '@modules/cui/ConfigContext'

import elasticLogo from './files/elastic-logo-H-color.svg'
import elasticLogoDark from './files/elastic-logo-H-color-dark.svg'
import RightSidePanelContent from './RightSidePanelContent'

export interface TrialFlowContainerProps {
  step?: number
  totalSteps?: number
  children: JSX.Element
  onGoBack?: () => void
  'data-test-id'?: string
  header?: ReactNode
  footnote?: ReactNode
  hideBgImage?: boolean
  goBackHome?: boolean
  resourceType: ResourceType
  isBackButtonDisabled?: boolean
}

const TrialFlowContainer: FunctionComponent<TrialFlowContainerProps> = ({
  step,
  totalSteps,
  children,
  onGoBack,
  'data-test-id': dataTestId,
  header,
  footnote,
  hideBgImage = false,
  goBackHome = false,
  resourceType,
  isBackButtonDisabled,
}) => {
  const {
    euiTheme: {
      breakpoint: { m: euiBreakpointM },
      size: { m: euiSizeM, xl: euiSizeXL },
      colors: { lightestShade, emptyShade, backgroundBaseSubdued },
    },
    colorMode,
  } = useEuiTheme()

  const backgroundColors = {
    amsterdam: colorMode === 'LIGHT' ? lightestShade : emptyShade,
    borealis: backgroundBaseSubdued,
  }

  const theme = useConfig('FEATURE_ENABLE_BOREALIS_THEME') ? 'borealis' : 'amsterdam'

  const backgroundColor = backgroundColors[theme]

  return (
    <EuiFlexGroup
      data-test-id={dataTestId}
      justifyContent='flexStart'
      css={css({
        [`@media(max-width: ${euiBreakpointM})`]: {
          padding: euiSizeM,
        },
        backgroundColor,
      })}
    >
      <EuiFlexItem grow={10}>
        <div>
          <CuiThemedIcon
            size='xxl'
            css={css({ width: 'auto', height: '39px', margin: euiSizeXL })}
            lightType={elasticLogo}
            darkType={elasticLogoDark}
          />
        </div>
        <StepPanel
          step={step}
          totalSteps={totalSteps}
          header={header}
          isPanelWidthLarger={hideBgImage}
        >
          {children}
        </StepPanel>
        <EuiFlexGroup alignItems='center'>
          <div>
            {step !== 0 && onGoBack && (
              <EuiButtonEmpty
                disabled={isBackButtonDisabled}
                onClick={() => {
                  onGoBack()
                }}
                css={{ margin: euiSizeXL }}
              >
                <p>
                  {header ? (
                    <Fragment>
                      <EuiIcon type='arrowLeft' />
                      <FormattedMessage id='trial-flow.go-back' defaultMessage='Back' />
                    </Fragment>
                  ) : (
                    <FormattedMessage
                      id='trial-flow.go-back-to-previous-step'
                      defaultMessage='Go back'
                    />
                  )}
                </p>
              </EuiButtonEmpty>
            )}
          </div>
          <div>
            {step !== 0 && goBackHome && (
              <EuiButtonEmpty
                onClick={() => {
                  history.push('/home')
                }}
                css={{ margin: euiSizeXL }}
              >
                <p>
                  <FormattedMessage
                    id='trial-flow.go-back-home'
                    defaultMessage='Go to Cloud Home'
                  />
                </p>
              </EuiButtonEmpty>
            )}
          </div>
          {footnote}
        </EuiFlexGroup>
      </EuiFlexItem>
      {!hideBgImage && (
        <EuiFlexItem grow={5}>
          <RightSidePanelContent resourceType={resourceType} />
        </EuiFlexItem>
      )}
    </EuiFlexGroup>
  )
}

export default TrialFlowContainer
