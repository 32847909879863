/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { defineMessages } from 'react-intl'

const messages = defineMessages({
  plan: {
    id: 'billing-overview.overview-card.plan',
    defaultMessage: 'PLAN',
  },
  prepaidBalance: {
    id: 'billing-overview.overview-card.prepaid-balance',
    defaultMessage: 'Prepaid Balance',
  },
  remainingBalance: {
    id: 'billing-overview.overview-card.remaining-balance',
    defaultMessage: `Available credits`,
  },
  initialBalance: {
    id: 'billing-overview.overview-card.initial-balance',
    defaultMessage: 'Starting balance',
  },
  remaining: {
    id: 'billing-overview.overview-card.remaining',
    defaultMessage: '{remainingValue} active credits remaining',
  },
  balancePopover: {
    id: 'billing-overview.overview-card.balance-popover',
    defaultMessage:
      'For more details about your prepaid account, go to <subscriptionLink>Subscription</subscriptionLink>.',
  },
  initialBalancePopover: {
    id: 'billing-overview.overview-card.initial-balance-popover',
    defaultMessage:
      'This amount does not include <subscriptionLink>future credits</subscriptionLink>.',
  },
  ecuUnit: {
    id: 'elastic-consumption-units',
    defaultMessage: 'ECU',
  },
  popoverStartingBalanceAriaLabel: {
    id: 'billing-overview.overview-card.starting-balance-aria',
    defaultMessage: 'Show starting balance details',
  },
  popoverActiveBalanceAriaLabel: {
    id: 'billing-overview.overview-card.active-balance-aria',
    defaultMessage: 'Show balance details',
  },
})

export default messages
