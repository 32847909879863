/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/*!
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@                                         @@@@
 *!   @@@   THIS FILE WAS AUTOGENERATED BY TOOL   @@@@
 *!   @@@   CHANGES MUST TARGET SCRIPTS/SWAGGER   @@@@
 *!   @@@                                         @@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!
 */

import {
  _buildQueryString,
  _emptyParams,
  _encode,
  _getBasePath,
  _getRawBasePath,
} from './urls-utils'

/**
 * GET /api/v1/regions/{region_id}/stack/instance-types
 * Operation: `get-instance-types`
 * Retrieves a list of all instance types.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 List of node types. See {@link Array<InstanceTypeResource>}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getInstanceTypesUrl(params: GetInstanceTypesUrlParams): string {
  const path = _encode`/${params.regionId}/stack/instance-types`
  return `${_getBasePath()}${path}`
}

export interface GetInstanceTypesUrlParams {
  regionId: string
}

/**
 * GET /api/v1/regions/{region_id}/stack/versions
 * Operation: `get-version-stacks`
 * By default, retrieves only the available Elastic Stack versions. To retrieve all of the Elastic Stack versions, use the `show_deleted parameter`.
 * @param {boolean} params.showDeleted Whether to show deleted stack versions or not.
 * @param {boolean} params.showUnusable Whether to show versions that are unusable by the authenticated user.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 The list of all available Elastic Stack versions are retrieved, including the template version and structure. See {@link StackVersionConfigs}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getVersionStacksUrl(params: GetVersionStacksUrlParams): string {
  const queryString = _buildQueryString({
    show_deleted: params.showDeleted === undefined ? false : params.showDeleted,
    show_unusable: params.showUnusable === undefined ? false : params.showUnusable,
  })
  const path = _encode`/${params.regionId}/stack/versions`
  return `${_getBasePath()}${path}${queryString}`
}

export interface GetVersionStacksUrlParams {
  showDeleted?: boolean | null
  showUnusable?: boolean | null
  regionId: string
}

/**
 * POST /api/v1/regions/{region_id}/stack/versions
 * Operation: `update-stack-packs`
 * Creates or updates an Elastic Stack pack and template. The endpoint supports `multipart/form-data` requests, as well as `application/zip` and `application/octet-stream` requests with a binary body. The maximum size of the payload is 1Mb. When the archive contains an Elastic Stack configuration that is available through the API, the configuration and template are overwritten.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see Accepts a request payload of type {@link File}
 * @see 200 The Elastic Stack pack successfully processed. See {@link StackVersionArchiveProcessingResult}
 * @see 400 * There is an error in the file. Choose a different file, then try again. (code: `stackpack.bad_archive`)
 * Some unexpected error occurred. (code: `stackpack.unknown_internal_error`). See {@link StackVersionArchiveProcessingResult}
 * @return {string} A fully-interpolated and escaped URL
 */
export function updateStackPacksUrl(params: UpdateStackPacksUrlParams): string {
  const path = _encode`/${params.regionId}/stack/versions`
  return `${_getBasePath()}${path}`
}

export interface UpdateStackPacksUrlParams {
  regionId: string
}

/**
 * GET /api/v1/regions/{region_id}/stack/versions/access
 * Operation: `get-version-access-management`
 * Lists existing AccessManagement for stack versions.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 The AccessManagement data was successfully retrieved. See {@link AccessManagementResponse}
 * @see 500 Bad AccessManagement data was already on the server. See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getVersionAccessManagementUrl(params: GetVersionAccessManagementUrlParams): string {
  const path = _encode`/${params.regionId}/stack/versions/access`
  return `${_getBasePath()}${path}`
}

export interface GetVersionAccessManagementUrlParams {
  regionId: string
}

/**
 * POST /api/v1/regions/{region_id}/stack/versions/access
 * Operation: `create-version-access-management`
 * Inserts a new AccessManagement for stack versions. Overwrites existing data.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see Accepts a request payload of type {@link CreateAccessManagementRequest}
 * @see 201 The AccessManagement data was inserted successfully. See {@link AccessManagementResponse}
 * @see 400 Bad AccessManagement data was sent. See {@link BasicFailedReply}
 * @see 500 Bad AccessManagement data was already on the server. See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function createVersionAccessManagementUrl(
  params: CreateVersionAccessManagementUrlParams,
): string {
  const path = _encode`/${params.regionId}/stack/versions/access`
  return `${_getBasePath()}${path}`
}

export interface CreateVersionAccessManagementUrlParams {
  regionId: string
}

/**
 * PATCH /api/v1/regions/{region_id}/stack/versions/access
 * Operation: `patch-version-access-management`
 * Updates new AccessManagement for stack versions. Overwrites existing data.
 * @param {integer} params.version If specified then checks for conflicts against the version stored in the persistent store (returned in 'x-cloud-resource-version' of the GET request).
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see Accepts a request payload of type {@link UpdateAccessManagementRequest}
 * @see 200 The AccessManagement data was updated successfully. See {@link AccessManagementResponse}
 * @see 400 Bad AccessManagement data was sent. See {@link BasicFailedReply}
 * @see 500 Bad AccessManagement data was already on the server. See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function patchVersionAccessManagementUrl(
  params: PatchVersionAccessManagementUrlParams,
): string {
  const queryString = _buildQueryString({
    version: params.version,
  })
  const path = _encode`/${params.regionId}/stack/versions/access`
  return `${_getBasePath()}${path}${queryString}`
}

export interface PatchVersionAccessManagementUrlParams {
  version?: number | null
  regionId: string
}

/**
 * POST /api/v1/regions/{region_id}/stack/versions/access/blacklisted-upgrades
 * Operation: `add-blacklisted-upgrade`
 * Adds a blacklisted upgrade version-from matcher to multiple version-to matchers. If the provided version-from matcher already has existing version-to matchers, this is appended. If it does not, a new list is started.
 * @param {integer} params.version If specified then checks for conflicts against the version stored in the persistent store (returned in 'x-cloud-resource-version' of the GET request).
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see Accepts a request payload of type {@link BlacklistedUpgrade}
 * @see 200 The AccessManagement data was updated successfully. See {@link AccessManagementResponse}
 * @see 400 Bad AccessManagement data was sent. See {@link BasicFailedReply}
 * @see 500 Bad AccessManagement data was already on the server. See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function addBlacklistedUpgradeUrl(params: AddBlacklistedUpgradeUrlParams): string {
  const queryString = _buildQueryString({
    version: params.version,
  })
  const path = _encode`/${params.regionId}/stack/versions/access/blacklisted-upgrades`
  return `${_getBasePath()}${path}${queryString}`
}

export interface AddBlacklistedUpgradeUrlParams {
  version?: number | null
  regionId: string
}

/**
 * DELETE /api/v1/regions/{region_id}/stack/versions/access/blacklisted-upgrades/{from_version_matcher}
 * Operation: `remove-blacklisted-upgrade`
 * Removes a blacklisted upgrade version matcher by version-from matcher.
 * @param {integer} params.version If specified then checks for conflicts against the version stored in the persistent store (returned in 'x-cloud-resource-version' of the GET request).
 * @param {string} params.fromVersionMatcher A matcher expression for upgrade-from versions to de-blacklist.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 The AccessManagement data was updated successfully. See {@link AccessManagementResponse}
 * @see 400 Bad AccessManagement data was sent. See {@link BasicFailedReply}
 * @see 500 Bad AccessManagement data was already on the server. See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function removeBlacklistedUpgradeUrl(params: RemoveBlacklistedUpgradeUrlParams): string {
  const queryString = _buildQueryString({
    version: params.version,
  })
  const path = _encode`/${params.regionId}/stack/versions/access/blacklisted-upgrades/${params.fromVersionMatcher}`
  return `${_getBasePath()}${path}${queryString}`
}

export interface RemoveBlacklistedUpgradeUrlParams {
  version?: number | null
  fromVersionMatcher: string
  regionId: string
}

/**
 * PUT /api/v1/regions/{region_id}/stack/versions/access/blacklisted-upgrades/{from_version_matcher}/{to_version_matcher}
 * Operation: `add-blacklisted-upgrade-pair`
 * Adds a blacklisted upgrade version matcher pair. If the provided version-from matcher already has existing version-to matchers, this is appeneded. If it does not, a new list is started.
 * @param {integer} params.version If specified then checks for conflicts against the version stored in the persistent store (returned in 'x-cloud-resource-version' of the GET request).
 * @param {string} params.fromVersionMatcher A matcher expression for upgrade-from versions to blacklist.
 * @param {string} params.toVersionMatcher A matcher expression for upgrade-to versions to blacklist.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 The AccessManagement data was updated successfully. See {@link AccessManagementResponse}
 * @see 400 Bad AccessManagement data was sent. See {@link BasicFailedReply}
 * @see 500 Bad AccessManagement data was already on the server. See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function addBlacklistedUpgradePairUrl(params: AddBlacklistedUpgradePairUrlParams): string {
  const queryString = _buildQueryString({
    version: params.version,
  })
  const path = _encode`/${params.regionId}/stack/versions/access/blacklisted-upgrades/${params.fromVersionMatcher}/${params.toVersionMatcher}`
  return `${_getBasePath()}${path}${queryString}`
}

export interface AddBlacklistedUpgradePairUrlParams {
  version?: number | null
  fromVersionMatcher: string
  toVersionMatcher: string
  regionId: string
}

/**
 * DELETE /api/v1/regions/{region_id}/stack/versions/access/blacklisted-upgrades/{from_version_matcher}/{to_version_matcher}
 * Operation: `remove-blacklisted-upgrade-pair`
 * Removes a blacklisted upgrade-to version matcher from the given upgrade-from version matcher. If upgrade-from matcher no longer has any upgrade-to version matchers, it too is removed. .
 * @param {integer} params.version If specified then checks for conflicts against the version stored in the persistent store (returned in 'x-cloud-resource-version' of the GET request).
 * @param {string} params.fromVersionMatcher A matcher expression for upgrade-from versions to un-blacklist.
 * @param {string} params.toVersionMatcher A matcher expression for upgrade-to versions to un-blacklist.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 The AccessManagement data was updated successfully. See {@link AccessManagementResponse}
 * @see 400 Bad AccessManagement data was sent. See {@link BasicFailedReply}
 * @see 500 Bad AccessManagement data was already on the server. See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function removeBlacklistedUpgradePairUrl(
  params: RemoveBlacklistedUpgradePairUrlParams,
): string {
  const queryString = _buildQueryString({
    version: params.version,
  })
  const path = _encode`/${params.regionId}/stack/versions/access/blacklisted-upgrades/${params.fromVersionMatcher}/${params.toVersionMatcher}`
  return `${_getBasePath()}${path}${queryString}`
}

export interface RemoveBlacklistedUpgradePairUrlParams {
  version?: number | null
  fromVersionMatcher: string
  toVersionMatcher: string
  regionId: string
}

/**
 * GET /api/v1/regions/{region_id}/stack/versions/access/organizations/{organization_id}
 * Operation: `get-version-organization-access-management`
 * Find a AccessManagement for stack versions for an organization.
 * @param {string} params.organizationId Organization id whom you would like to retrieve AccessManagement data for.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 The Organization-specific AccessManagement data was retrieved successfully. See {@link OrganizationSpecificAccessManagementResponse}
 * @see 400 Organization not found. (code: `organization.not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getVersionOrganizationAccessManagementUrl(
  params: GetVersionOrganizationAccessManagementUrlParams,
): string {
  const path = _encode`/${params.regionId}/stack/versions/access/organizations/${params.organizationId}`
  return `${_getBasePath()}${path}`
}

export interface GetVersionOrganizationAccessManagementUrlParams {
  organizationId: string
  regionId: string
}

/**
 * PUT /api/v1/regions/{region_id}/stack/versions/access/organizations/{organization_id}
 * Operation: `upsert-version-organization-access-management`
 * Inserts or updates a new AccessManagement for stack versions for an organization.
 * @param {string} params.organizationId Organization id whom you would like to retrieve AccessManagement data for.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see Accepts a request payload of type {@link OrganizationSpecificAccessManagementData}
 * @see 200 The Organization-specific AccessManagement data was inserted successfully. See {@link OrganizationSpecificAccessManagementResponse}
 * @see 400 Organization not found. (code: `organization.not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function upsertVersionOrganizationAccessManagementUrl(
  params: UpsertVersionOrganizationAccessManagementUrlParams,
): string {
  const path = _encode`/${params.regionId}/stack/versions/access/organizations/${params.organizationId}`
  return `${_getBasePath()}${path}`
}

export interface UpsertVersionOrganizationAccessManagementUrlParams {
  organizationId: string
  regionId: string
}

/**
 * PUT /api/v1/regions/{region_id}/stack/versions/access/{version}
 * Operation: `add-whitelisted-version`
 * Adds a whitelisted version to Access Management.
 * @param {integer} params.versionQuery If specified then checks for conflicts against the version stored in the persistent store (returned in 'x-cloud-resource-version' of the GET request).
 * @param {string} params.version An Elastic Stack version.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 The AccessManagement data was updated successfully. See {@link AccessManagementResponse}
 * @see 400 Bad AccessManagement data was sent. See {@link BasicFailedReply}
 * @see 500 Bad AccessManagement data was already on the server. See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function addWhitelistedVersionUrl(params: AddWhitelistedVersionUrlParams): string {
  const queryString = _buildQueryString({
    version: params.versionQuery,
  })
  const path = _encode`/${params.regionId}/stack/versions/access/${params.version}`
  return `${_getBasePath()}${path}${queryString}`
}

export interface AddWhitelistedVersionUrlParams {
  versionQuery?: number | null
  version: string
  regionId: string
}

/**
 * DELETE /api/v1/regions/{region_id}/stack/versions/access/{version}
 * Operation: `remove-whitelisted-version`
 * Removes a whitelisted version from Access Management.
 * @param {integer} params.versionQuery If specified then checks for conflicts against the version stored in the persistent store (returned in 'x-cloud-resource-version' of the GET request).
 * @param {string} params.version An Elastic Stack version.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 The AccessManagement data was updated successfully. See {@link AccessManagementResponse}
 * @see 400 Bad AccessManagement data was sent. See {@link BasicFailedReply}
 * @see 500 Bad AccessManagement data was already on the server. See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function removeWhitelistedVersionUrl(params: RemoveWhitelistedVersionUrlParams): string {
  const queryString = _buildQueryString({
    version: params.versionQuery,
  })
  const path = _encode`/${params.regionId}/stack/versions/access/${params.version}`
  return `${_getBasePath()}${path}${queryString}`
}

export interface RemoveWhitelistedVersionUrlParams {
  versionQuery?: number | null
  version: string
  regionId: string
}

/**
 * GET /api/v1/regions/{region_id}/stack/versions/{version}
 * Operation: `get-version-stack`
 * Retrieves the Elastic Stack version and template.
 * @param {string} params.version The Elastic Stack version. For example, `5.3.1` or `5.0.0-RC4`.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 The Elastic Stack version is found. See {@link StackVersionConfig}
 * @see 404 The Elastic Stack version can't be found. (code: `stackpack.version_not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getVersionStackUrl(params: GetVersionStackUrlParams): string {
  const path = _encode`/${params.regionId}/stack/versions/${params.version}`
  return `${_getBasePath()}${path}`
}

export interface GetVersionStackUrlParams {
  version: string
  regionId: string
}

/**
 * PUT /api/v1/regions/{region_id}/stack/versions/{version}
 * Operation: `update-version-stack`
 * Updates the Elastic Stack version configuration.
 * @param {string} params.version The Elastic Stack version. For example, `5.3.1` or `5.0.0-RC4`.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see Accepts a request payload of type {@link StackVersionConfigPost}
 * @see 200 The Elastic Stack version successfully updated. See {@link StackVersionConfig}
 * @see 404 The Elastic Stack version can't be found. (code: `stackpack.missing_platform_version`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function updateVersionStackUrl(params: UpdateVersionStackUrlParams): string {
  const path = _encode`/${params.regionId}/stack/versions/${params.version}`
  return `${_getBasePath()}${path}`
}

export interface UpdateVersionStackUrlParams {
  version: string
  regionId: string
}

/**
 * DELETE /api/v1/regions/{region_id}/stack/versions/{version}
 * Operation: `delete-version-stack`
 * Uses the `deleted` flag, which removes only that version of the Elastic Stack from the list of available versions. To restore the version, send an update request. For more information, see the PUT request.
 * @param {string} params.version The Elastic Stack version. For example, `5.3.1` or `5.0.0-RC4`.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 The `deleted` flag is applied to the specified Elastic Stack version.
 * @see 404 The Elastic Stack version can't be found. (code: `stackpack.version_not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function deleteVersionStackUrl(params: DeleteVersionStackUrlParams): string {
  const path = _encode`/${params.regionId}/stack/versions/${params.version}`
  return `${_getBasePath()}${path}`
}

export interface DeleteVersionStackUrlParams {
  version: string
  regionId: string
}
