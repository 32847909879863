/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { upperFirst, capitalize } from 'lodash'

import {
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHorizontalRule,
  EuiIcon,
  EuiListGroup,
  EuiListGroupItem,
  EuiText,
  EuiTextAlign,
  EuiTitle,
  useEuiTheme,
} from '@elastic/eui'

import type { SolutionType } from './types'
import type { FunctionComponent, ReactNode } from 'react'

type Props = {
  label: SolutionType
  description: ReactNode
  features: ReactNode[]
  logo: string
  title: ReactNode
  onNext: (label: SolutionType) => void
  buttonDisabled?: boolean
  dataAttributePrefix: string
}

const SelectSolutionCardV5: FunctionComponent<Props> = ({
  label,
  description,
  features,
  logo,
  title,
  onNext,
  buttonDisabled = false,
  dataAttributePrefix,
}) => {
  const { formatMessage } = useIntl()

  const { euiTheme } = useEuiTheme()

  return (
    <EuiCard
      paddingSize='l'
      textAlign='left'
      titleElement='span'
      title={<div />}
      footer={
        <EuiButton
          disabled={buttonDisabled}
          onClick={() => onNext(label)}
          data-test-subj={`${dataAttributePrefix}${label}`}
          fullWidth={true}
          color='primary'
          aria-label={formatMessage(
            {
              id: 'select-solution.next.aria-label',
              defaultMessage: 'Next step {label}',
            },
            {
              label: capitalize(label),
            },
          )}
        >
          <FormattedMessage id='select-solution.select-next' defaultMessage='Next' />
        </EuiButton>
      }
    >
      <EuiFlexGroup direction='column' gutterSize='m'>
        <EuiFlexGroup alignItems='center' justifyContent='center'>
          <EuiIcon size='xl' type={logo ? logo : `logo${upperFirst(label)}`} />
        </EuiFlexGroup>
        <EuiFlexGroup alignItems='center' justifyContent='center'>
          <EuiTitle size='xs'>
            <h2>{title ? title : label}</h2>
          </EuiTitle>
        </EuiFlexGroup>
        <EuiFlexItem>
          <EuiHorizontalRule margin='xs' />
        </EuiFlexItem>
        <EuiText size='s'>
          <p>{description}</p>
        </EuiText>
        <EuiTextAlign textAlign='center'>
          <EuiText size='s'>
            <EuiListGroup style={{ marginLeft: 0 }} maxWidth='65ch' flush={true}>
              {features.map((desc, key) => (
                <EuiListGroupItem
                  iconProps={{ color: euiTheme.colors.success }}
                  wrapText={true}
                  label={<EuiText size='s'>{desc}</EuiText>}
                  iconType='check'
                  key={key}
                />
              ))}
            </EuiListGroup>
          </EuiText>
        </EuiTextAlign>
      </EuiFlexGroup>
    </EuiCard>
  )
}

export default SelectSolutionCardV5
