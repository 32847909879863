/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import { applyParameters } from '@modules/query/helpers'

import { GroupBy } from '../../../types'

import type { Moment } from 'moment'

const dateFormat = 'YYYY-MM-DD'

interface DownloadCSVArgs {
  organizationId: string
  startDate: Moment
  endDate: Moment
  instanceId?: string
  serverlessGroupByParam?: 'product' | 'product_family'
  groupBy: GroupBy
}

const downloadCSV = async ({
  organizationId,
  startDate,
  endDate,
  instanceId,
  serverlessGroupByParam,
  groupBy,
}: DownloadCSVArgs): Promise<void> => {
  let url

  if (instanceId) {
    url = getCostsItemsByInstanceUrlV2({
      startDate,
      endDate,
      organizationId,
      instanceId,
    })
  } else {
    url = {
      [GroupBy.Product]: getProductViewCsvUrl({
        startDate,
        endDate,
        organizationId,
        serverlessGroupByParam,
      }),
      [GroupBy.Instance]: getInstancesCostsByOrganizationUrl({
        startDate,
        endDate,
        organizationId,
      }),
    }[groupBy]
  }

  const response = await fetch(url, {
    method: 'GET',
    headers: { Accept: 'text/csv' },
  })

  if (response.status < 200 || response.status >= 300) {
    throw new Error('Could not download CSV')
  }

  const blob = await response.blob()
  const link = document.createElement('a')
  const fileName = getFileName({ startDate, endDate, instanceId, groupBy })

  link.href = window.URL.createObjectURL(blob)
  link.download = `${fileName}.csv`
  link.click()
  link.remove()
}

const getInstancesCostsByOrganizationUrl = ({ organizationId, startDate, endDate }): string =>
  applyParameters('/api/v2/billing/organizations/{organization_id}/costs/instances', {
    pathParameters: {
      organization_id: organizationId,
    },
    queryParameters: {
      from: startDate?.startOf('day').utc().format(),
      to: endDate?.endOf('day').utc().format(),
    },
  })

const getCostsItemsByInstanceUrlV2 = ({ organizationId, startDate, endDate, instanceId }): string =>
  applyParameters(
    '/api/v2/billing/organizations/{organization_id}/costs/instances/{instance_id}/items',
    {
      pathParameters: {
        organization_id: organizationId,
        instance_id: instanceId,
      },
      queryParameters: {
        from: startDate?.startOf('day').utc().format(),
        to: endDate?.endOf('day').utc().format(),
      },
    },
  )

const getProductViewCsvUrl = ({
  organizationId,
  startDate,
  endDate,
  serverlessGroupByParam,
}): string =>
  applyParameters('/api/v2/billing/organizations/{organization_id}/costs/items', {
    pathParameters: {
      organization_id: organizationId,
    },
    queryParameters: {
      from: startDate?.startOf('day').utc().format(),
      to: endDate?.endOf('day').utc().format(),
      serverless_group_by: serverlessGroupByParam,
    },
  })

const getFileName = ({
  startDate,
  endDate,
  instanceId,
  groupBy,
}: Omit<DownloadCSVArgs, 'organizationId'>): string => {
  const formattedStartDate = startDate.format(dateFormat)
  const formattedEndDate = endDate.format(dateFormat)

  if (instanceId) {
    return `costs_${instanceId}_${formattedStartDate}_${formattedEndDate}`
  }

  return `costs_${groupBy}s_${formattedStartDate}_${formattedEndDate}`
}

export default downloadCSV
