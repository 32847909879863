/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { parse, stringify } from 'query-string'
import { useContext } from 'react'

import Feature from '@modules/utils/feature'
import { loginUrl } from '@modules/auth/urls'
import { rootUrl } from '@modules/utils/rootUrls'
import { LogoutContext } from '@modules/logout/context'
import { hasUnexpiredSession } from '@modules/auth/auth'
import { useConfig } from '@modules/cui/ConfigContext'
import { useServerLogout } from '@modules/logout/hooks'
import { captureApmError } from '@modules/utils/apm'

import { clearAuthToken } from '@/actions/auth/auth'

interface LocationState {
  from?: string
}

const getSearchQuery = (search: string) => {
  const query = parse(search.slice(1))

  function getStringQuery(key: string) {
    return typeof query[key] === 'string' ? query[key] : undefined
  }

  return {
    fromURI: getStringQuery('fromURI'),
    invalidateIdPSession: getStringQuery('invalidateIdPSession')
      ? getStringQuery('invalidateIdPSession') === 'true'
      : true,
    redirectTo: getStringQuery('redirectTo'),
  }
}

export const getRedirectUriAfterLogout = ({
  isHeroku,
  redirectTo,
  fromURI,
}: {
  isHeroku: boolean
  redirectTo: string | undefined
  fromURI: string | undefined
}) => {
  const loggedOutUrl = isHeroku ? rootUrl() : loginUrl()

  const params = new URLSearchParams()
  redirectTo && params.set('redirectTo', redirectTo)
  fromURI && params.set('fromURI', fromURI)

  return params.toString() ? `${loggedOutUrl}?${params}` : loggedOutUrl
}

export const useLogout = () => {
  const isSessionExpired = !hasUnexpiredSession()
  const { serverLogout } = useServerLogout({})
  const { setLogoutContext } = useContext(LogoutContext)
  const dispatch = useDispatch()
  const history = useHistory<LocationState | undefined>()
  const { search, state } = history.location
  const { fromURI, redirectTo, invalidateIdPSession } = getSearchQuery(search)
  const isOktaAuthEnabled = Boolean(useConfig(Feature.oktaAuthenticationEnabled))
  const isHeroku = useConfig(`APP_FAMILY`) === `heroku`
  const redirectUriAfterLogout = getRedirectUriAfterLogout({ isHeroku, redirectTo, fromURI })
  const oktaBaseUrl = useConfig(`OKTA_URL`)

  function goToLoggedOutPage() {
    // Reload to ensure the redux state is cleared
    window.location.replace(redirectUriAfterLogout)
  }

  const browserLogout = () => {
    const createOktaRedirectUrl = (oktaRedirectUrl?: string) => {
      if (oktaRedirectUrl && parse(oktaRedirectUrl).protocol) {
        return oktaRedirectUrl
      }

      const { origin, protocol, hostname, port } = window.location
      const redirectUrl = origin || `${protocol}//${hostname}${port ? `:${port}` : ``}`
      return oktaRedirectUrl ? `${redirectUrl}${oktaRedirectUrl}` : redirectUrl
    }

    function logoutThroughOkta() {
      const oktaSignoutQuery = stringify({
        fromURI: createOktaRedirectUrl(redirectUriAfterLogout),
      })
      const oktaSignoutUrl = `${oktaBaseUrl}/login/signout?${oktaSignoutQuery}`

      window.location.replace(oktaSignoutUrl)
    }

    dispatch(clearAuthToken())

    /*If users are coming from Okta (there's a fromURI parameter),
     * and we can assume Okta has already checked and there wasn't a SSO session cookie for them.
     * So we just logout and redirect to logged out url with the fromURI parameter
     */
    if (isOktaAuthEnabled && !fromURI && invalidateIdPSession) {
      logoutThroughOkta()
    } else {
      goToLoggedOutPage()
    }
  }

  const onServerLogout = () => {
    serverLogout(undefined, {
      onSuccess: () => {
        browserLogout()
        setLogoutContext({})
      },
      onError: (error: Error) => {
        captureApmError(error)
        setLogoutContext({ logoutError: true })

        if (isSessionExpired) {
          goToLoggedOutPage()
          return
        }

        if (state?.from && !state.from.match(/^\/logout/)) {
          history.goBack()
          return
        }

        history.push(rootUrl())
      },
    })
  }

  return { logout: onServerLogout }
}
