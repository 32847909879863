/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'
import { Link } from 'react-router-dom'

import { EuiFlexGroup, EuiFlexItem, EuiLink, EuiSpacer, EuiText } from '@elastic/eui'

import type { DeploymentSearchResponse } from '@modules/cloud-api/v1/types'
import { CuiTable } from '@modules/cui/Table'
import type { CuiTableColumn } from '@modules/cui/Table'
import PrivacySensitiveContainer from '@modules/cui/PrivacySensitiveContainer'
import history from '@modules/utils/history'
import { getShortPlatformNameByRegionId } from '@modules/utils/platform'
import { getKibanaRedirectPageUrl } from '@modules/kibana-redirect/url'

import { hasMismatchingVersions } from '@/lib/stackDeployments/upgradesToSliders'
import { deploymentUrl, deploymentsUrl } from '@/lib/urlBuilder'
import {
  getVersion,
  getRegionId,
  getFirstEsClusterFromGet,
} from '@/lib/stackDeployments/selectors/fundamentals'
import { getDisplayName } from '@/lib/stackDeployments/selectors/display'
import { isDeploymentAvailableInKibana } from '@/lib/stackDeployments/selectors/available'

import { sortDeploymentsByRamAndStatus } from '../../../../lib/stackDeployments/sorting'
import PartialUpgradeTooltip from '../../../Deployment/DeploymentVersion/UpgradableDeploymentVersion/PartialUpgradeTooltip'
import DeploymentRegion from '../../../StackDeploymentSearch/Deployment/DeploymentRegion'
import PortalDeploymentStatus from '../PortalDeploymentStatus'
import { useOnboardingTokenFromProfile } from '../../../../../modules/discovery-questions-lib/hooks'

import type { FunctionComponent } from 'react'

interface Props {
  deployments: DeploymentSearchResponse[] | null
}

const messages = defineMessages({
  name: {
    id: `portal-deployments-table.status-name`,
    defaultMessage: `Deployment`,
  },
  status: {
    id: `deployments-table.status-label`,
    defaultMessage: `Status`,
  },
  version: {
    id: `deployments-table.version-label`,
    defaultMessage: `Version`,
  },
  quickLink: {
    id: `portal-deployments-table.actions`,
    defaultMessage: `Actions`,
  },
  region: {
    id: `portal-deployments-table.cloud-region`,
    defaultMessage: `Cloud provider & region`,
  },
})

const PortalDeployments: FunctionComponent<Props> = ({ deployments }) => {
  const { formatMessage } = useIntl()
  const onboardingToken = useOnboardingTokenFromProfile(deployments?.length)

  const TABLE_SIZE = 5

  const nameColumn: CuiTableColumn<DeploymentSearchResponse> = {
    label: formatMessage(messages.name),
    setScopeRow: true,
    render: (deployment: DeploymentSearchResponse) => {
      const displayName = getDisplayName({ deployment })

      const isDeploymentAvailable = isDeploymentAvailableInKibana(deployment)
      const href = getKibanaRedirectPageUrl(`deployment`, deployment.id, onboardingToken)

      return (
        <PrivacySensitiveContainer>
          <EuiText size='s'>
            {isDeploymentAvailable ? (
              <EuiLink color='primary' href={href} data-test-id='deployments-table-deployment-name'>
                {displayName}
              </EuiLink>
            ) : (
              displayName
            )}
          </EuiText>
        </PrivacySensitiveContainer>
      )
    },
    sortKey: [`name`, `id`],
    textOnly: false,
    width: `30%`,
  }

  const columns: Array<CuiTableColumn<DeploymentSearchResponse>> = [
    nameColumn,
    {
      label: <FormattedMessage id='deployments-table.status' defaultMessage='Status' />,
      render: (deployment: DeploymentSearchResponse) => (
        <PortalDeploymentStatus deployment={deployment} />
      ),
      textOnly: false,
      width: `175px`,
    },
    {
      label: formatMessage(messages.version),
      render: (deployment: DeploymentSearchResponse) => {
        const esResource = getFirstEsClusterFromGet({ deployment })
        const version = esResource && getVersion({ deployment })

        return version ? (
          <EuiFlexGroup gutterSize='none'>
            <EuiFlexItem style={{ whiteSpace: `nowrap` }}>{version}</EuiFlexItem>
            {hasMismatchingVersions({ deployment }) && (
              <EuiFlexItem>
                <PartialUpgradeTooltip deployment={deployment} />
              </EuiFlexItem>
            )}
          </EuiFlexGroup>
        ) : null
      },
      width: `15%`,
    },
    {
      label: formatMessage(messages.region),
      render: (deployment: DeploymentSearchResponse) => {
        const regionId = getRegionId({ deployment })

        return (
          <EuiFlexGroup gutterSize='none' responsive={false} alignItems='center'>
            <EuiFlexItem>
              {getShortPlatformNameByRegionId(regionId)}
              {' - '}
              <DeploymentRegion regionId={regionId} />
            </EuiFlexItem>
          </EuiFlexGroup>
        )
      },
      width: `25%`,
      textOnly: false,
    },
    {
      label: formatMessage(messages.quickLink),
      render: (deployment: DeploymentSearchResponse) => (
        <DeploymentActions deployment={deployment} onboardingToken={onboardingToken} />
      ),
      width: `12em`,
      align: 'right',
      textOnly: false,
    },
  ]

  return (
    <Fragment>
      <CuiTable<DeploymentSearchResponse>
        getRowId={(deployment) => deployment.id}
        rows={
          deployments ? sortDeploymentsByRamAndStatus(deployments).slice(0, TABLE_SIZE) : undefined
        }
        columns={columns}
        initialLoading={!deployments}
      />
      {deployments && deployments.length > TABLE_SIZE ? (
        <Fragment>
          <EuiSpacer size='m' />
          <EuiFlexGroup alignItems='center' justifyContent='center'>
            <EuiFlexItem grow={false}>
              <Link to={deploymentsUrl()}>
                <FormattedMessage
                  id='portal-deployment-list'
                  defaultMessage='View all deployments'
                />
              </Link>
            </EuiFlexItem>
          </EuiFlexGroup>
        </Fragment>
      ) : null}
    </Fragment>
  )
}

const DeploymentActions = ({
  deployment,
  onboardingToken,
}: {
  deployment: DeploymentSearchResponse
  onboardingToken?: string
}) => {
  const isDeploymentAvailable = isDeploymentAvailableInKibana(deployment)
  const href = getKibanaRedirectPageUrl(`deployment`, deployment.id, onboardingToken)

  return (
    <EuiFlexGroup gutterSize='l' justifyContent='flexEnd' alignItems='baseline' responsive={false}>
      <EuiFlexItem grow={false}>
        {isDeploymentAvailable && (
          <EuiLink href={href}>
            <FormattedMessage id='deployments-table.open' defaultMessage='Open' />
          </EuiLink>
        )}
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiLink
          onClick={() => history.push(deploymentUrl(deployment.id))}
          href={deploymentUrl(deployment.id)}
        >
          <FormattedMessage id='deployments-table.manage' defaultMessage='Manage' />
        </EuiLink>
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}

export default PortalDeployments
