/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/*!
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@                                         @@@@
 *!   @@@   THIS FILE WAS AUTOGENERATED BY TOOL   @@@@
 *!   @@@   CHANGES MUST TARGET SCRIPTS/SWAGGER   @@@@
 *!   @@@                                         @@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!
 */

import {
  _buildQueryString,
  _emptyParams,
  _encode,
  _getBasePath,
  _getRawBasePath,
} from './urls-utils'

/**
 * GET /api/v1/regions/{region_id}/platform/configuration/snapshots/repositories
 * Operation: `get-snapshot-repositories`
 * Retrieves a list of available snapshot repository configurations. To access the configuration contents, you must have privileged permissions.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 List of snapshot repository configs. See {@link RepositoryConfigs}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getSnapshotRepositoriesUrl(params: GetSnapshotRepositoriesUrlParams): string {
  const path = _encode`/${params.regionId}/platform/configuration/snapshots/repositories`
  return `${_getBasePath()}${path}`
}

export interface GetSnapshotRepositoriesUrlParams {
  regionId: string
}

/**
 * GET /api/v1/regions/{region_id}/platform/configuration/snapshots/repositories/{repository_name}
 * Operation: `get-snapshot-repository`
 * Retrieves the snapshot repository configuration. To access the configuration contents, you must have privileged permissions.
 * @param {string} params.repositoryName The name of the snapshot repository configuration.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 Retrieve snapshot repository config. See {@link RepositoryConfig}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getSnapshotRepositoryUrl(params: GetSnapshotRepositoryUrlParams): string {
  const path = _encode`/${params.regionId}/platform/configuration/snapshots/repositories/${params.repositoryName}`
  return `${_getBasePath()}${path}`
}

export interface GetSnapshotRepositoryUrlParams {
  repositoryName: string
  regionId: string
}

/**
 * PUT /api/v1/regions/{region_id}/platform/configuration/snapshots/repositories/{repository_name}
 * Operation: `set-snapshot-repository`
 * Creates or updates the snapshot repository configuration.
 * @param {integer} params.version Checks for conflicts against the metadata version, then returns the value in the `x-cloud-resource-version` header.
 * @param {string} params.repositoryName The name of the snapshot repository configuration.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see Accepts a request payload of type {@link SnapshotRepositoryConfiguration}
 * @see 200 New snapshot repository config. See {@link RepositoryConfig}
 * @return {string} A fully-interpolated and escaped URL
 */
export function setSnapshotRepositoryUrl(params: SetSnapshotRepositoryUrlParams): string {
  const queryString = _buildQueryString({
    version: params.version,
  })
  const path = _encode`/${params.regionId}/platform/configuration/snapshots/repositories/${params.repositoryName}`
  return `${_getBasePath()}${path}${queryString}`
}

export interface SetSnapshotRepositoryUrlParams {
  version?: number | null
  repositoryName: string
  regionId: string
}

/**
 * DELETE /api/v1/regions/{region_id}/platform/configuration/snapshots/repositories/{repository_name}
 * Operation: `delete-snapshot-repository`
 * Deletes the snapshot repository configuration.
 * @param {string} params.repositoryName The name of the snapshot repository configuration.
 * @param {boolean} params.cleanupDeployments Removes references to this snapshot repository configuration and disables snapshots on the clusters that were referencing this configuration. If a request is made to delete a repository configuration that has already been deleted and this parameter is set to true and clusters still reference the configuration, then the request will have the side effects of removing references and disabling snapshots for clusters that reference the previously deleted configuration.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 successful operation
 * @see 202 Delete snapshot repository config
 * @see 500 Failed to delete references and disable snapshots in one or more referencing clusters. See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function deleteSnapshotRepositoryUrl(params: DeleteSnapshotRepositoryUrlParams): string {
  const queryString = _buildQueryString({
    cleanup_deployments:
      params.cleanupDeployments === undefined ? false : params.cleanupDeployments,
  })
  const path = _encode`/${params.regionId}/platform/configuration/snapshots/repositories/${params.repositoryName}`
  return `${_getBasePath()}${path}${queryString}`
}

export interface DeleteSnapshotRepositoryUrlParams {
  repositoryName: string
  cleanupDeployments?: boolean | null
  regionId: string
}
