/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'
import { parse } from 'query-string'

import { withTransaction } from '@elastic/apm-rum-react'

import Logout from './Logout'

import type { StateProps, ConsumerProps } from './types'
import type { ReduxState } from '@/types/redux'

const mapStateToProps = (_: ReduxState, { location }: ConsumerProps): StateProps => {
  const { search } = location
  const { redirectTo, reason, invalidateIdPSession } = parse(search.slice(1))

  return {
    isUnauthorised: reason === 'unauthorised',
    redirectTo: typeof redirectTo === `string` ? redirectTo : undefined,
    invalidateIdPSession:
      typeof invalidateIdPSession === `string` ? invalidateIdPSession === 'true' : true,
  }
}

export default connect<StateProps, ConsumerProps>(mapStateToProps)(
  withTransaction('Logout', `component`)(Logout),
)
