/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/*!
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@                                         @@@@
 *!   @@@   THIS FILE WAS AUTOGENERATED BY TOOL   @@@@
 *!   @@@   CHANGES MUST TARGET SCRIPTS/SWAGGER   @@@@
 *!   @@@                                         @@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!
 */

import {
  _buildQueryString,
  _emptyParams,
  _encode,
  _getBasePath,
  _getRawBasePath,
} from './urls-utils'

/**
 * GET /api/v1/regions/{region_id}/platform/configuration/trust-relationships
 * Operation: `get-trust-relationships`
 * List trust relationships.
 * @param {boolean} params.includeCertificate Whether to include the public CA certificates in the response.
 * @param {string} params.filter Which trust relationships to return in the response. Defaults to `all`. Default: all
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 The list of trust relationships. See {@link TrustRelationshipsListResponse}
 * @see 401 You are not authorized to perform this action. See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getTrustRelationshipsUrl(params: GetTrustRelationshipsUrlParams): string {
  const queryString = _buildQueryString({
    include_certificate:
      params.includeCertificate === undefined ? false : params.includeCertificate,
    filter: params.filter === undefined ? `all` : params.filter,
  })
  const path = _encode`/${params.regionId}/platform/configuration/trust-relationships`
  return `${_getBasePath()}${path}${queryString}`
}

export interface GetTrustRelationshipsUrlParams {
  includeCertificate?: boolean | null
  filter?: string | null
  regionId: string
}

/**
 * POST /api/v1/regions/{region_id}/platform/configuration/trust-relationships
 * Operation: `create-trust-relationship`
 * Creates a trust relationship.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see Accepts a request payload of type {@link TrustRelationshipCreateRequest}
 * @see 201 The request was valid and a new trust relationship was created. See {@link TrustRelationshipCreateResponse}
 * @see 400 The trust relationship request had errors. See {@link BasicFailedReply}
 * @see 401 You are not authorized to perform this action. See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function createTrustRelationshipUrl(params: CreateTrustRelationshipUrlParams): string {
  const path = _encode`/${params.regionId}/platform/configuration/trust-relationships`
  return `${_getBasePath()}${path}`
}

export interface CreateTrustRelationshipUrlParams {
  regionId: string
}

/**
 * GET /api/v1/regions/{region_id}/platform/configuration/trust-relationships/{trust_relationship_id}
 * Operation: `get-trust-relationship`
 * Retrieves information about a trust relationship.
 * @param {string} params.trustRelationshipId Identifier for the trust relationship.
 * @param {boolean} params.includeCertificate Whether to include the public CA certificates in the response.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 The trust relationship info response. See {@link TrustRelationshipGetResponse}
 * @see 401 You are not authorized to perform this action. See {@link BasicFailedReply}
 * @see 404 The trust relationship specified by {trust_relationship_id} cannot be found. (code: `trust_relationships.not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getTrustRelationshipUrl(params: GetTrustRelationshipUrlParams): string {
  const queryString = _buildQueryString({
    include_certificate:
      params.includeCertificate === undefined ? false : params.includeCertificate,
  })
  const path = _encode`/${params.regionId}/platform/configuration/trust-relationships/${params.trustRelationshipId}`
  return `${_getBasePath()}${path}${queryString}`
}

export interface GetTrustRelationshipUrlParams {
  trustRelationshipId: string
  includeCertificate?: boolean | null
  regionId: string
}

/**
 * PUT /api/v1/regions/{region_id}/platform/configuration/trust-relationships/{trust_relationship_id}
 * Operation: `update-trust-relationship`
 * Updates a trust relationship.
 * @param {string} params.trustRelationshipId Identifier for the trust relationship.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see Accepts a request payload of type {@link TrustRelationshipUpdateRequest}
 * @see 200 The request was valid and the trust relationship was updated. See {@link TrustRelationshipUpdateResponse}
 * @see 400 The trust relationship request had errors. See {@link BasicFailedReply}
 * @see 401 You are not authorized to perform this action. See {@link BasicFailedReply}
 * @see 404 The trust relationship specified by {trust_relationship_id} cannot be found. (code: `trust_relationships.not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function updateTrustRelationshipUrl(params: UpdateTrustRelationshipUrlParams): string {
  const path = _encode`/${params.regionId}/platform/configuration/trust-relationships/${params.trustRelationshipId}`
  return `${_getBasePath()}${path}`
}

export interface UpdateTrustRelationshipUrlParams {
  trustRelationshipId: string
  regionId: string
}

/**
 * DELETE /api/v1/regions/{region_id}/platform/configuration/trust-relationships/{trust_relationship_id}
 * Operation: `delete-trust-relationship`
 * Deletes a trust relationship.
 * @param {string} params.trustRelationshipId Identifier for the trust relationship.
 * @param {integer} params.version If specified then checks for conflicts against the version stored in the persistent store (returned in 'x-cloud-resource-version' of the GET request).
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see 200 The request was valid and the trust relationship was deleted.
 * @see 401 You are not authorized to perform this action. See {@link BasicFailedReply}
 * @see 404 The trust relationship specified by {trust_relationship_id} cannot be found. (code: `trust_relationships.not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function deleteTrustRelationshipUrl(params: DeleteTrustRelationshipUrlParams): string {
  const queryString = _buildQueryString({
    version: params.version,
  })
  const path = _encode`/${params.regionId}/platform/configuration/trust-relationships/${params.trustRelationshipId}`
  return `${_getBasePath()}${path}${queryString}`
}

export interface DeleteTrustRelationshipUrlParams {
  trustRelationshipId: string
  version?: number | null
  regionId: string
}
