/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiIcon,
  EuiSuperSelect,
  EuiToolTip,
} from '@elastic/eui'

import { withErrorBoundary } from '@modules/cui/Boundary'
import type { PlatformId } from '@modules/ui-types'
import { getPlatformInfoById } from '@modules/utils/platform'
import type { PlatformInfo } from '@modules/utils/platform'

import type { WrappedComponentProps } from 'react-intl'

interface Props extends WrappedComponentProps {
  platform: PlatformId
  availablePlatforms: PlatformId[]
  onChange: (platform: string) => void
  restoreFromSnapshot: boolean
  disabled?: boolean
  prepend?: boolean
  'aria-labelledby'?: string
}

class SelectCloudPlatform extends Component<Props> {
  render() {
    const {
      restoreFromSnapshot,
      prepend = true,
      intl: { formatMessage },
      platform,
      availablePlatforms,
      onChange,
      disabled,
    } = this.props
    const label = formatMessage({
      id: 'select-platform-label',
      defaultMessage: 'Cloud provider',
    })

    const platformOptions =
      availablePlatforms &&
      availablePlatforms
        .map((key) => getPlatformInfoById(key))
        .map((platform) => ({
          inputDisplay: this.renderPlatformOption(platform),
          value: platform.id,
          'data-test-id': `cloud-platform-${platform.id}`,
        }))

    const select = (
      <EuiSuperSelect
        fullWidth={true}
        popoverProps={{ css: { '.euiFormControlLayout__prepend': { width: 180 } } }}
        prepend={prepend ? label : undefined}
        options={platformOptions}
        valueOfSelected={platform}
        onChange={(platform) => onChange(platform)}
        disabled={restoreFromSnapshot || disabled}
        data-test-id='platform-combobox'
        aria-label={this.props['aria-labelledby'] ?? label}
      />
    )

    return restoreFromSnapshot ? (
      <EuiToolTip
        display='block'
        position='top'
        content={
          <FormattedMessage
            defaultMessage='You cannot change the cloud provider as you are restoring data from a snapshot.'
            id='select-cloud-provider-disabled-tooltip'
          />
        }
      >
        {select}
      </EuiToolTip>
    ) : (
      select
    )
  }

  renderPlatformOption(platform: PlatformInfo) {
    return (
      <EuiFlexGroup
        data-test-id={`cloud-platform-option-${platform.id}`}
        gutterSize='s'
        alignItems='center'
        responsive={false}
      >
        <EuiFlexItem grow={false}>
          <EuiIcon size='m' type={platform.iconType} />
        </EuiFlexItem>

        <EuiFlexItem>
          <EuiText style={{ paddingLeft: 0 }} size='s'>
            {platform.title}
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
    )
  }
}

export default withErrorBoundary(injectIntl(SelectCloudPlatform))
