/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/*!
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@                                         @@@@
 *!   @@@   THIS FILE WAS AUTOGENERATED BY TOOL   @@@@
 *!   @@@   CHANGES MUST TARGET SCRIPTS/SWAGGER   @@@@
 *!   @@@                                         @@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!
 */

import {
  _buildQueryString,
  _emptyParams,
  _encode,
  _getBasePath,
  _getRawBasePath,
} from './urls-utils'

/**
 * POST /api/v1/authorization/_check
 * Operation: `check-authorization`
 * Checks whether the current authenticated session has the authorization presented.
 * @see Accepts a request payload of type {@link AuthzCheckRequest}
 * @see 200 Authorization check results retrieved. See {@link AuthzCheckResponse}
 * @see 400 * Requested resource type not supported. (code: `authz.check.unsupported_kind`)
 * The owner provided could not be parsed as a valid owner ID. (code: `authz.check.unparseable_owner`)
 * Authorization request had too many checks. (code: `authz.check.too_many_checks`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function checkAuthorizationUrl(): string {
  const path = `/authorization/_check`
  return `${_getRawBasePath()}${path}`
}

/**
 * GET /api/v1/authorization/roles
 * Operation: `get-authorization-roles`
 * Returns the roles that are visible to the current user.
 * @param {boolean} params.showHidden If true, hidden Roles are returned.
 * @see 200 The roles that are visible to the current user. See {@link AuthzRoles}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getAuthorizationRolesUrl(
  params: GetAuthorizationRolesUrlParams = _emptyParams,
): string {
  const queryString = _buildQueryString({
    show_hidden: params.showHidden === undefined ? false : params.showHidden,
  })
  const path = `/authorization/roles`
  return `${_getRawBasePath()}${path}${queryString}`
}

export interface GetAuthorizationRolesUrlParams {
  showHidden?: boolean | null
}
