/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { useEffect, useState } from 'react'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'

import {
  EuiButtonEmpty,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutFooter,
  EuiFlyoutHeader,
  EuiForm,
  EuiFormRow,
  EuiLink,
  EuiSpacer,
  EuiText,
  EuiTitle,
} from '@elastic/eui'

import genericMessages from '@modules/project-lib/genericMessages'
import type { ProjectApiError } from '@modules/ui-types/projects'
import { usePatchProjectMutation } from '@modules/project-user-lib/hooks/update'
import type { AnyUserProject, AnyUserProjectApplicationKey } from '@modules/project-user-api/types'
import { CuiTable, type CuiTableColumn } from '@modules/cui/Table'
import { useFlagsWhenLoaded } from '@modules/launchdarkly'

// eslint-disable-next-line import/no-restricted-paths
import SpinButton from '@/components/SpinButton'

const APPLICATIONS_TO_SHOW_BASE: AnyUserProjectApplicationKey[] = ['elasticsearch', 'kibana', 'apm']

type LinkInfo = { application: AnyUserProjectApplicationKey; endpoint: string }

type Props = {
  project: AnyUserProject
  closeFlyout: () => void
}

const messages = defineMessages({
  aliasNameRequirements: {
    id: 'project-alias.endpoint-help-text',
    defaultMessage:
      "Use 'a-z', '0-9', or '-'. The alias must start with a letter and end with a letter or a number.",
  },
})

const extractAliasError = (message: string) => {
  const regex = /Error at "\/alias": (.*)/
  const match = message.match(regex)
  return match && match[1]
}

const ProjectAliasEditorFlyout = ({ project, closeFlyout }: Props) => {
  const [_, flags] = useFlagsWhenLoaded()

  const showMotelEndpoint = flags.showMotelEndpoint

  const APPLICATIONS_TO_SHOW: AnyUserProjectApplicationKey[] = showMotelEndpoint
    ? [...APPLICATIONS_TO_SHOW_BASE, 'ingest']
    : APPLICATIONS_TO_SHOW_BASE

  const { formatMessage } = useIntl()

  const [editedAlias, setEditedAlias] = useState(project.alias)

  useEffect(() => {
    setEditedAlias(project.alias)
  }, [project.alias])

  const onClose = () => {
    closeFlyout()
    setEditedAlias(project.alias)
  }

  const {
    isLoading,
    isError,
    error: rawError,
    isSuccess,
    mutate,
  } = usePatchProjectMutation(project.type)

  const updateProjectAlias = (alias: string) => {
    mutate({
      id: project.id,
      body: { alias },
    })
  }

  useEffect(() => {
    isSuccess && closeFlyout()
  }, [isSuccess, closeFlyout])

  const getFormattedError = (error: ProjectApiError | null) => {
    if (!error) {
      return null
    }

    if (typeof error === 'string') {
      if (error.includes(`doesn't match the regular expression`)) {
        return formatMessage(messages.aliasNameRequirements)
      }

      const otherAliasError = extractAliasError(error)

      if (otherAliasError) {
        return otherAliasError
      }

      return error
    }

    const { errors = [] } = error

    if (!errors.length) {
      return null
    }

    return errors.map((e) => e.message).join(' ')
  }

  const applicationsWithEndpoints = APPLICATIONS_TO_SHOW.reduce((acc, application) => {
    const endpoint = project.endpoints[application]

    if (endpoint) {
      acc.push({ application, endpoint })
    }

    return acc
  }, [] as LinkInfo[])

  const columns: Array<CuiTableColumn<LinkInfo>> = [
    {
      label: <FormattedMessage id='alias-table.application-column' defaultMessage='Application' />,
      sortKey: 'name',
      render: (link) => {
        const name =
          link.application === 'ingest'
            ? formatMessage({
                id: 'projects.messages.openTelemetryTechnicalPreview',
                defaultMessage: 'Managed OTLP (technical preview)',
              })
            : formatMessage(genericMessages[link.application])

        return name
      },
      width: '190px',
    },
    {
      label: <FormattedMessage id='alias-table.url-column' defaultMessage='URL' />,
      sortKey: 'url',
      render: (link) => (
        <EuiLink href={link.endpoint} external={true} target='_blank'>
          {link.endpoint}
        </EuiLink>
      ),
    },
  ]

  return (
    <EuiFlyout size='m' ownFocus={true} onClose={onClose}>
      <EuiFlyoutHeader hasBorder={true}>
        <EuiTitle size='m'>
          <h2>
            <FormattedMessage
              id='project.connection-alias-title'
              defaultMessage='Connection alias'
            />
          </h2>
        </EuiTitle>
      </EuiFlyoutHeader>
      <EuiFlyoutBody>
        <EuiText size='s'>
          <FormattedMessage
            id='project-alias.custom-endpoint-message'
            defaultMessage='Customize the ID portion of the endpoint URLs for all applications in the project. The name must be unique for each region, across all accounts.'
          />
        </EuiText>

        <EuiSpacer size='l' />

        <EuiForm>
          <EuiFormRow
            error={getFormattedError(rawError)}
            isInvalid={isError}
            label={
              <FormattedMessage
                id='project-alias.connection-alias-title'
                defaultMessage='Connection alias'
              />
            }
            helpText={isError ? null : formatMessage(messages.aliasNameRequirements)}
          >
            <EuiFieldText
              placeholder='myproject'
              isInvalid={isError}
              value={editedAlias}
              onChange={(e) => {
                const alias = e.target.value.toLowerCase()
                setEditedAlias(alias)
              }}
            />
          </EuiFormRow>
        </EuiForm>

        <EuiSpacer size='l' />

        <CuiTable<LinkInfo>
          emptyMessage='empty'
          rows={applicationsWithEndpoints}
          columns={columns}
        />
      </EuiFlyoutBody>

      <EuiFlyoutFooter>
        <EuiFlexGroup gutterSize='m' alignItems='center' justifyContent='spaceBetween'>
          <EuiFlexItem grow={false}>
            <EuiButtonEmpty onClick={onClose}>
              <FormattedMessage {...genericMessages.cancel} />
            </EuiButtonEmpty>
          </EuiFlexItem>

          <EuiFlexItem grow={false}>
            <SpinButton
              size='s'
              disabled={false}
              color='primary'
              spin={isLoading}
              onClick={() => updateProjectAlias(editedAlias)}
            >
              <FormattedMessage
                id='project-alias.flyout-update-alias'
                defaultMessage='Update alias'
              />
            </SpinButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlyoutFooter>
    </EuiFlyout>
  )
}

export default ProjectAliasEditorFlyout
