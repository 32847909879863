/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { Fragment, useState } from 'react'
import { useHistory } from 'react-router'

import { useSaasUser } from '@modules/profile-lib/hooks'
import useUsageMode from '@modules/billing-lib/usageMode/useUsageMode'
import {
  useListElasticsearchProjectsQuery,
  useListObservabilityProjectsQuery,
  useListSecurityProjectsQuery,
} from '@modules/project-user-lib/hooks/list'
import type {
  ElasticsearchProject,
  ObservabilityProject,
  SecurityProject,
} from '@modules/project-user-api/v1/types'

// eslint-disable-next-line import/no-restricted-paths
import StatefulTrialSummaryModal from '@/apps/userconsole/components/Billing/StatefulTrialSummaryModal/TrialSummaryModal'
// eslint-disable-next-line import/no-restricted-paths
import { billingOverviewUrl } from '@/apps/userconsole/urls'

import { useListDeploymentsQuery } from '../cloud-lib/deployments/hooks'

import UnifiedTrialSummaryModal from './UnifiedTrialSummaryModal'

import type { DeploymentsListResponse } from '../cloud-api/v1/types'
import type { ReactNode } from 'react'

type Props = {
  /** A trigger element that is responsible for firing the modal. If not
   * provided, the modal will open on mount. */
  children?: (props: { triggerModal: () => void }) => ReactNode | null
  /** A courtesy callback for the modal closing; the modal state is internal to
   * this component so this is provided for side-effects only. */
  onClose?: () => void
}

const TrialSummary: React.FunctionComponent<Props> = ({ children, onClose }) => {
  const saasUser = useSaasUser()
  const history = useHistory()
  const { mode: trialModeFromUsage, isReady } = useUsageMode(saasUser?.user.organization_id)
  const elasticsearchQuery = useListElasticsearchProjectsQuery()
  const observabilityQuery = useListObservabilityProjectsQuery()
  const securityQuery = useListSecurityProjectsQuery()

  const [isModalOpen, setIsModalOpen] = useState(!children)

  const deploymentQuery = useListDeploymentsQuery({
    staleTime: 5 * 60 * 1000,
    enabled: true,
    keepPreviousData: true,
  })

  if (!saasUser?.user) {
    return null
  }

  if (saasUser.subscription.is_paying) {
    return null
  }

  if (
    securityQuery.isLoading ||
    observabilityQuery.isLoading ||
    elasticsearchQuery.isLoading ||
    !isReady
  ) {
    return null
  }

  const projects: Array<ElasticsearchProject | ObservabilityProject | SecurityProject> = (
    [] as Array<ElasticsearchProject | ObservabilityProject | SecurityProject>
  ).concat(
    elasticsearchQuery.data?.items || [],
    observabilityQuery.data?.items || [],
    securityQuery.data?.items || [],
  )

  const isTrialConsideredServerless =
    // either there is a project that we can see right now, or
    projects.length > 0 ||
    // there is historical usage of a project
    trialModeFromUsage === 'serverless' ||
    trialModeFromUsage === 'hybrid'

  const renderModal = () => {
    if (!isModalOpen) {
      return null
    }

    if (isAllowToRedirectToBillingOverview(projects, deploymentQuery.data?.deployments ?? [])) {
      setIsModalOpen(false)
      history.push(billingOverviewUrl())
    }

    return isTrialConsideredServerless ? (
      <UnifiedTrialSummaryModal
        saasUser={saasUser}
        onClose={() => {
          setIsModalOpen(false)
          onClose?.()
        }}
      />
    ) : (
      <StatefulTrialSummaryModal
        closeModal={() => {
          setIsModalOpen(false)
          onClose?.()
        }}
      />
    )
  }

  const renderTrigger = () => {
    if (!children) {
      return null
    }

    return children({
      triggerModal: () => {
        setIsModalOpen(true)
      },
    })
  }

  return (
    <Fragment>
      {renderTrigger()}
      {renderModal()}
    </Fragment>
  )
}

export default TrialSummary

export const isAllowToRedirectToBillingOverview = (
  projects: Array<ElasticsearchProject | ObservabilityProject | SecurityProject>,
  deployments: DeploymentsListResponse['deployments'],
): boolean => {
  if (projects.length > 1 || (projects.length >= 1 && deployments.length >= 1)) {
    return true
  }

  return false
}
