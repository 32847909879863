/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React from 'react'
import { FormattedMessage } from 'react-intl'

import type { BillingDetails } from '@modules/billing-api/customTypes'

export const getSubscriptionTypeName = (billing_model: BillingDetails['billing_model']) => {
  if (!billing_model) {
    return ''
  }

  return (
    {
      monthly: (
        <FormattedMessage id='subscription-type.monthly.name' defaultMessage='Pay-as-you-go' />
      ),
      drawdown: <FormattedMessage id='subscription-type.drawdown.name' defaultMessage='Prepaid' />,
      inactive: <FormattedMessage id='subscription-type.inactive.name' defaultMessage='Inactive' />,
      prepaid_consumption: (
        <FormattedMessage
          id='subscription-type.prepaid_consumption.name'
          defaultMessage='Prepaid'
        />
      ),
      annual: <FormattedMessage id='subscription-type.annual.name' defaultMessage='Annual' />,
      annual_overages: (
        <FormattedMessage id='subscription-type.annual_overages.name' defaultMessage='Annual' />
      ),
    }[billing_model] || ''
  )
}
