/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiButton } from '@elastic/eui'

import history from '@modules/utils/history'

import { createDeploymentUrl } from '@/lib/urlBuilder'

import type { FC } from 'react'

export interface Props {
  canCreateDeployment: boolean
  isDisabled?: boolean
  onClickFallback?: () => void
}

const CreateDeploymentButton: FC<Props> = ({
  canCreateDeployment,
  isDisabled,
  onClickFallback,
}) => (
  <EuiButton
    color='primary'
    fill={true}
    size='s'
    isDisabled={isDisabled}
    onClick={canCreateDeployment ? () => history.push(createDeploymentUrl()) : onClickFallback}
    data-test-id='create-deployment-link'
  >
    <FormattedMessage
      id='portal-deployments.create-link'
      defaultMessage='Create hosted deployment'
    />
  </EuiButton>
)

export default CreateDeploymentButton
