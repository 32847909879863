/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/*!
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@                                         @@@@
 *!   @@@   THIS FILE WAS AUTOGENERATED BY TOOL   @@@@
 *!   @@@   CHANGES MUST TARGET SCRIPTS/SWAGGER   @@@@
 *!   @@@                                         @@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!
 */

import {
  _buildQueryString,
  _emptyParams,
  _encode,
  _getBasePath,
  _getRawBasePath,
} from './urls-utils'

/**
 * GET /api/v1/account
 * Operation: `get-current-account`
 * Fetch current account information.
 * @see 200 Account fetched successfully. See {@link AccountResponse}
 * @see 404 Account not found. (code: `accounts.not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getCurrentAccountUrl(): string {
  const path = `/account`
  return `${_getRawBasePath()}${path}`
}

/**
 * PUT /api/v1/account
 * Operation: `update-current-account`
 * Updates the current account.
 * @see Accepts a request payload of type {@link AccountUpdateRequest}
 * @see 200 Account updated successfully. See {@link AccountResponse}
 * @see 404 Account not found. (code: `accounts.not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function updateCurrentAccountUrl(): string {
  const path = `/account`
  return `${_getRawBasePath()}${path}`
}

/**
 * PATCH /api/v1/account
 * Operation: `patch-current-account`
 * Updates the current account.
 * @see Accepts a request payload of type {@link string}
 * @see 200 Account updated successfully. See {@link AccountResponse}
 * @see 404 Account not found. (code: `accounts.not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function patchCurrentAccountUrl(): string {
  const path = `/account`
  return `${_getRawBasePath()}${path}`
}
