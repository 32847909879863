/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { noop } from 'lodash'

import {
  EuiModal,
  EuiModalFooter,
  EuiFlexItem,
  EuiFlexGroup,
  EuiButtonEmpty,
  EuiButton,
  EuiSpacer,
  EuiCallOut,
  EuiLink,
  withEuiTheme,
} from '@elastic/eui'

import type { BillingSubscriptionLevel } from '@modules/ui-types'
import { CuiAlert } from '@modules/cui/Alert'
import { isPrepaidConsumptionCustomer } from '@modules/billing-lib/billingDetails/utils'
import { defaultSubscriptionLevelByUser } from '@modules/billing-lib/utils'
import type { Subscription } from '@modules/ui-types/billing'

import { addUpdateBillingLevelToast, addPendingBillingLevelToast } from '@/lib/toasts'
import { isSelectedSubscriptionOutOfCompliance, isSelectionUpgrade } from '@/lib/usage'

import ConfirmSubscriptionModal from './ConfirmSubscriptionModal'
import SelectSubscriptionBody from './SelectSubscriptionBody'
import messages, { usageLevelName } from './messages'

import type { ReactElement } from 'react'
import type { Props } from './types'

type State = {
  selectedSubscription: BillingSubscriptionLevel
  isConfirmationModalOpen: boolean
}

class SelectSubscriptionModal extends Component<Props, State> {
  state: State = {
    selectedSubscription: this.getSelectedSubscription(),
    isConfirmationModalOpen: false,
  }

  componentDidMount(): void {
    const {
      usageDetails,
      fetchUsageDetails,
      fetchAccountActivity,
      profile: { organization_id: organizationId } = {},
    } = this.props

    if (!usageDetails && organizationId) {
      fetchUsageDetails({ organizationId })
    }

    if (organizationId) {
      fetchAccountActivity(organizationId)
    }
  }

  render(): ReactElement | null {
    const {
      closeModal,
      profile,
      updateBillingLevelRequest,
      onSeeReviewChanges,
      usageDetails,
      fetchUsageDetailsRequest,
      billingDetails,
      theme,
      overrideUsageLevel,
    } = this.props

    if (fetchUsageDetailsRequest.inProgress || !usageDetails) {
      return null
    }

    if (fetchUsageDetailsRequest.error) {
      return (
        <EuiCallOut color='danger'>
          <FormattedMessage {...messages.usageDetailsErrorMessage} />
        </EuiCallOut>
      )
    }

    const { selectedSubscription, isConfirmationModalOpen } = this.state
    const resolvedUsageLevel = overrideUsageLevel || usageDetails.usage_level

    const selectionOutOfCompliance = isSelectedSubscriptionOutOfCompliance({
      selectedSubscription,
      usageLevel: resolvedUsageLevel,
    })
    const isUpgrade = isSelectionUpgrade({
      selectedSubscription,
      currentSubscription: profile.level,
    })
    const isPrepaidConsumption = isPrepaidConsumptionCustomer(billingDetails.data!)
    const disabledSubscriptions: BillingSubscriptionLevel[] = isPrepaidConsumption
      ? ['standard']
      : []

    if (isConfirmationModalOpen) {
      return (
        <ConfirmSubscriptionModal
          firstBillingCycle={usageDetails.first_billing_cycle}
          isUpgrade={isUpgrade}
          updateBillingLevelRequest={updateBillingLevelRequest}
          selectedSubscription={selectedSubscription}
          onConfirmSubscription={() => this.onSaveBillingLevel()}
          closeModal={() => this.setState({ isConfirmationModalOpen: false })}
        />
      )
    }

    return (
      <EuiModal
        onClose={closeModal}
        data-test-id='update-subscription-modal'
        maxWidth={theme.euiTheme.breakpoint.xl}
        style={{ maxBlockSize: `calc(100vw - 16px)` }}
      >
        <SelectSubscriptionBody
          selectedSubscription={selectedSubscription}
          onSelectSubscription={(subscription: Subscription) =>
            this.onSelectSubscription(subscription)
          }
          onSeeReviewChanges={onSeeReviewChanges}
          disabledSubscriptions={disabledSubscriptions}
        />
        <EuiModalFooter>
          <EuiFlexGroup gutterSize='xs' direction='column' responsive={false}>
            {resolvedUsageLevel && selectionOutOfCompliance && (
              <EuiFlexItem>
                <EuiCallOut
                  data-test-id='usage-warning'
                  iconType='alert'
                  color='warning'
                  title={
                    <FormattedMessage
                      {...messages.usageWarning}
                      values={{
                        usageLevel: <FormattedMessage {...usageLevelName[resolvedUsageLevel]} />,
                        seeRequiredChanges: !overrideUsageLevel && (
                          <EuiLink onClick={onSeeReviewChanges ? () => onSeeReviewChanges() : noop}>
                            <FormattedMessage {...messages.seeRequiredChanges} />
                          </EuiLink>
                        ),
                      }}
                    />
                  }
                />
              </EuiFlexItem>
            )}

            <EuiFlexItem>
              <EuiFlexGroup justifyContent='flexEnd'>
                <EuiFlexItem grow={false}>
                  <EuiButtonEmpty
                    onClick={() => closeModal()}
                    data-test-id='update-subscription-modal.cancel-button'
                  >
                    <FormattedMessage {...messages.cancel} />
                  </EuiButtonEmpty>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiButton
                    disabled={selectionOutOfCompliance}
                    data-test-id='save-subscription'
                    onClick={() => this.setState({ isConfirmationModalOpen: true })}
                    fill={true}
                  >
                    <FormattedMessage {...messages.save} />
                  </EuiButton>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>

            {updateBillingLevelRequest.error && (
              <EuiFlexItem>
                <EuiSpacer size='m' />
                <CuiAlert data-test-id='update-billing-level-error' type='danger'>
                  {updateBillingLevelRequest.error}
                </CuiAlert>
              </EuiFlexItem>
            )}
          </EuiFlexGroup>
        </EuiModalFooter>
      </EuiModal>
    )
  }

  getSelectedSubscription(): BillingSubscriptionLevel {
    const { profile, upgradeLevel } = this.props

    return (
      upgradeLevel ||
      profile.pending_level ||
      profile.level ||
      defaultSubscriptionLevelByUser({ profile })
    )
  }

  onSaveBillingLevel(): void {
    const { updateBillingLevel, closeModal } = this.props
    const { selectedSubscription } = this.state

    updateBillingLevel({ level: selectedSubscription })
      .then(() => {
        const { profile } = this.props

        closeModal()
        this.setState({ isConfirmationModalOpen: false })

        if (profile.pending_level) {
          addPendingBillingLevelToast()
        } else {
          addUpdateBillingLevelToast()
        }
      })
      .catch(() => {
        this.setState({ isConfirmationModalOpen: false })
      })
  }

  onSelectSubscription(subscription: Subscription): void {
    this.setState({ selectedSubscription: subscription.value })
  }
}

export default withEuiTheme(SelectSubscriptionModal)
