/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { jsx } from '@emotion/react'
import { useIntl } from 'react-intl'

import { type SecurityUseCaseOption } from '@modules/discovery-questions-lib/types'
import {
  SECURITY_USE_CASE_PREFIX,
  SECURITY_USE_CASES_CLOUD_SEC,
  SECURITY_USE_CASES_EDR,
  SECURITY_USE_CASES_SIEM,
  SECURITY_USE_CASES_SOMETHING_ELSE,
} from '@modules/discovery-questions-lib/constants'
import { StepLayout } from '@modules/cui/Step/StepLayout'

import CheckableCardFieldset from './CheckableCardFieldset'

import type { FunctionComponent } from 'react'

type Props = {
  securityUseCase: SecurityUseCaseOption | undefined
  onChangeSecurityUseCase: (value: SecurityUseCaseOption) => void
  onSubmitSecurityUseCase: (value: SecurityUseCaseOption) => void
}

const SecurityUseCaseChoiceV5: FunctionComponent<Props> = ({
  securityUseCase,
  onChangeSecurityUseCase,
  onSubmitSecurityUseCase,
}: Props) => {
  const { formatMessage } = useIntl()

  const options = [
    {
      label: {
        id: 'discovery-questions-security_use_case.radio-1.v5',
        defaultMessage: 'SIEM and Security Analytics ',
      },
      value: SECURITY_USE_CASES_SIEM,
    },
    {
      label: {
        id: 'discovery-questions-security_use_case.radio-2.v5',
        defaultMessage: 'Cloud security: CSPM, KSPM, Vulnerability management',
      },
      value: SECURITY_USE_CASES_CLOUD_SEC,
    },
    {
      label: {
        id: 'discovery-questions-security_use_case.radio-3.v5',
        defaultMessage: 'Endpoint Detection and Response',
      },
      value: SECURITY_USE_CASES_EDR,
    },
    {
      label: {
        id: 'discovery-questions-security_use_case.radio-4.v5',
        defaultMessage: 'Something else',
      },
      value: SECURITY_USE_CASES_SOMETHING_ELSE,
    },
  ] as const

  const title = formatMessage({
    id: `discovery-questions-security_use_case.title.v5`,
    defaultMessage: `Which of the following is your primary interest?`,
  })

  return (
    <StepLayout title={title}>
      <CheckableCardFieldset<SecurityUseCaseOption>
        data-test-id='signup.discovery-questions.security_use_case'
        selectedValue={securityUseCase}
        prefix={SECURITY_USE_CASE_PREFIX}
        options={options}
        onChange={onChangeSecurityUseCase}
        onSubmit={onSubmitSecurityUseCase}
      />
    </StepLayout>
  )
}

export default SecurityUseCaseChoiceV5
