/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiImage,
  EuiLink,
  EuiPageHeader,
  EuiSkeletonText,
  EuiSpacer,
  EuiText,
  EuiTitle,
} from '@elastic/eui'

import ProjectList from '@modules/project-user-components/ProjectList'
import { projectsCrumbs } from '@modules/project-user-pages/breadcrumbs'
import Breadcrumbs from '@modules/cui/Breadcrumbs/Breadcrumbs'
import usePermissions from '@modules/permissions-lib/hooks'
import SupplyProjectsMetadata from '@modules/project-user-components/SupplyProjectsMetadata'
import aerospaceIllustration from '@modules/project-user-lib/images/illustration-aerospace-128.svg'
import CreateProjectButton from '@modules/project-user-components/CreateProjectButton'

type Props = unknown

const UserProjectsPage: React.FunctionComponent<Props> = () => {
  const { hasPermissions: hasAnyCreatePermission } = usePermissions(
    [
      { type: 'project-elasticsearch', action: 'create' },
      { type: 'project-observability', action: 'create' },
      { type: 'project-security', action: 'create' },
    ],
    'some',
  )

  const title = (
    <FormattedMessage id='projects.page.list.title' defaultMessage='Serverless projects' />
  )

  return (
    <div>
      <Breadcrumbs breadcrumbs={projectsCrumbs()} />

      <SupplyProjectsMetadata>
        {({ projectsLength, isReady }) => {
          if (!isReady) {
            return (
              <Fragment>
                <EuiPageHeader
                  bottomBorder={true}
                  pageTitle={title}
                  rightSideGroupProps={{ gutterSize: 'm' }}
                />
                <EuiSpacer size='xl' />
                <EuiSkeletonText />
              </Fragment>
            )
          }

          if (projectsLength === 0) {
            return (
              <Fragment>
                <EuiPageHeader bottomBorder={true} pageTitle={title} />
                <EuiSpacer size='xxl' />
                <EuiFlexGroup direction='column' gutterSize='s' alignItems='center'>
                  <EuiFlexItem>
                    <EuiImage size='s' src={aerospaceIllustration} alt='' />
                  </EuiFlexItem>
                  <EuiSpacer size='s' />
                  <EuiFlexItem>
                    <EuiTitle size='s'>
                      <h2>
                        <FormattedMessage
                          id='projects.page.list.empty.title'
                          defaultMessage='You have no serverless projects yet'
                        />
                      </h2>
                    </EuiTitle>
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiText textAlign='center' style={{ maxWidth: '40em' }}>
                      <FormattedMessage
                        id='projects.page.list.empty.body'
                        defaultMessage='Run Elasticsearch, Observability, or Security projects without having to manage the underlying Elastic Stack, and pay based on your usage. <link>Learn more</link>'
                        values={{
                          link: (content) => (
                            <EuiLink href='https://ela.st/serverless-learn-more' target='_blank'>
                              {content}
                            </EuiLink>
                          ),
                        }}
                      />
                    </EuiText>
                  </EuiFlexItem>
                  <EuiSpacer size='xs' />
                  <EuiFlexItem>{hasAnyCreatePermission && <CreateProjectButton />}</EuiFlexItem>
                </EuiFlexGroup>
              </Fragment>
            )
          }

          return (
            <Fragment>
              <EuiPageHeader
                bottomBorder={true}
                pageTitle={title}
                rightSideGroupProps={{ gutterSize: 'm' }}
                rightSideItems={hasAnyCreatePermission ? [<CreateProjectButton />] : undefined}
              />
              <EuiSpacer size='l' />
              <ProjectList />
            </Fragment>
          )
        }}
      </SupplyProjectsMetadata>
    </div>
  )
}

export default UserProjectsPage
