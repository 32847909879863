/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React from 'react'
import { FormattedMessage } from 'react-intl'

import type { SolutionType } from './types'
import type { ReactNode } from 'react'

type SolutionMessage = {
  label: SolutionType
  logo: string
  title: ReactNode
  description: ReactNode
  features: ReactNode[]
}

export function useSolutionOptionsV5(): SolutionMessage[] {
  return [
    {
      label: 'elasticsearch',
      logo: 'logoElasticsearch',
      title: <FormattedMessage defaultMessage='Elasticsearch' id='select-solution.title-es-v5' />,
      description: (
        <FormattedMessage
          defaultMessage='For developers to build search and vector database applications directly with Elasticsearch.'
          id='select-solution.description-es-v5'
        />
      ),
      features: [
        <FormattedMessage
          defaultMessage='Vector and data store'
          id='select-solution.description-es-feature0-v5'
        />,
        <FormattedMessage
          defaultMessage='ML model catalog and inference service'
          id='select-solution.description-es-feature1-v5'
        />,
        <FormattedMessage
          defaultMessage='AI Playground'
          id='select-solution.description-es-feature2-v5'
        />,
        <FormattedMessage
          defaultMessage='API first platform'
          id='select-solution.description-es-feature3-v5'
        />,
      ],
    },
    {
      label: 'observability',
      logo: 'logoObservability',

      title: (
        <FormattedMessage
          defaultMessage='Logs & Observability with Elasticsearch'
          id='select-solution.title-elastic-for-observability-v5'
        />
      ),

      description: (
        <FormattedMessage
          defaultMessage='For SRE, IT Ops, and developers to analyze logs, metrics, and traces to understand the health of applications and infrastructure.'
          id='select-solution.description-observability-v5'
        />
      ),
      features: [
        <FormattedMessage
          defaultMessage='Search and analyze logs'
          id='select-solution.description-observability-feature0-v5'
        />,
        <FormattedMessage
          defaultMessage='Metrics and infrastructure monitoring'
          id='select-solution.description-observability-feature1-v5'
        />,
        <FormattedMessage
          defaultMessage='Service Level Objectives (SLOs)'
          id='select-solution.description-observability-feature2-v5'
        />,
        <FormattedMessage
          defaultMessage='Synthetics monitoring'
          id='select-solution.description-observability-feature3-v5'
        />,
      ],
    },
    {
      label: 'security',
      logo: 'logoSecurity',
      title: (
        <FormattedMessage
          defaultMessage='Security with Elasticsearch'
          id='select-solution.title-elastic-for-security-v5'
        />
      ),

      description: (
        <FormattedMessage
          defaultMessage='For security operations teams to hunt, detect, investigate, and respond to security threats. '
          id='select-solution.description-security-v5'
        />
      ),

      features: [
        <FormattedMessage
          defaultMessage='Security logs'
          id='select-solution.description-security-feature0-v5'
        />,
        <FormattedMessage
          defaultMessage='SIEM'
          id='select-solution.description-security-feature1-b-v5'
        />,
        <FormattedMessage
          defaultMessage='Endpoint Security'
          id='select-solution.description-security-feature2-b-v5'
        />,
        <FormattedMessage
          defaultMessage='Cloud protection'
          id='select-solution.description-security-feature3-v5'
        />,
      ],
    },
  ]
}
