/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { Component } from 'react'

import { EuiText, EuiFlexGroup, EuiFlexItem, EuiIcon } from '@elastic/eui'

import type { DeploymentTemplateInfoV2 } from '@modules/cloud-api/v1/types'
import type { PlatformId, Region } from '@modules/ui-types'
import { getRegionDisplayName } from '@modules/utils/region'
import { getPlatform, getPlatformInfoById } from '@modules/utils/platform'
import type { PlatformInfo } from '@modules/utils/platform'

import type { RegionState } from '@/reducers/providers'

export type Props = {
  region: Region
  version: string | null
  getRegionsByProvider: (provider: string) => RegionState[] | null
  availablePlatforms: PlatformId[]
  deploymentTemplate?: DeploymentTemplateInfoV2
}

class SettingsSummary extends Component<Props> {
  render() {
    const { version } = this.props

    if (!version) {
      return null
    }

    const { availablePlatforms, region } = this.props

    const platform = getPlatform(region.id)
    const selectedPlatformInfo = availablePlatforms
      .map((key) => getPlatformInfoById(key))
      .find((provider) => provider.id === platform)

    return (
      <EuiFlexGroup gutterSize='s' responsive={false}>
        {selectedPlatformInfo && this.renderProviderIcon(selectedPlatformInfo)}
        <EuiFlexItem grow={false}>
          <EuiFlexGroup gutterSize='none' direction='column' responsive={false}>
            <EuiFlexItem>
              <EuiFlexGroup responsive={false} gutterSize='xs'>
                {this.renderProvider(selectedPlatformInfo)}
                {this.renderRegion()}
              </EuiFlexGroup>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiFlexGroup responsive={false} gutterSize='xs'>
                {this.renderHardwareProfile()}
                <EuiFlexItem grow={false}>
                  <EuiText size='s' color='subdued'>
                    {version}
                  </EuiText>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    )
  }

  renderProviderIcon(selectedPlatformInfo: PlatformInfo) {
    return (
      <EuiFlexItem style={{ justifyContent: 'center' }} grow={false}>
        <EuiIcon
          type={selectedPlatformInfo.iconType}
          aria-label={selectedPlatformInfo.title}
          size='xxl'
        />
      </EuiFlexItem>
    )
  }

  renderProvider(selectedPlatformInfo) {
    if (!selectedPlatformInfo) {
      return null
    }

    return (
      <EuiFlexItem grow={false}>
        <EuiText size='s'>{selectedPlatformInfo.shortTitle}</EuiText>
      </EuiFlexItem>
    )
  }

  renderRegion() {
    const { region, getRegionsByProvider } = this.props

    const platform = getPlatform(region.id)
    const availableRegions = getRegionsByProvider(platform)

    if (!availableRegions) {
      return null
    }

    const displayRegion = availableRegions.find(
      (availableRegion) => availableRegion.identifier === region.id,
    )

    if (!displayRegion) {
      return null
    }

    const displayName = getRegionDisplayName({ region: displayRegion, hideFlagEmoji: true })

    return (
      <EuiFlexItem grow={false}>
        <EuiText size='s'>{displayName}</EuiText>
      </EuiFlexItem>
    )
  }

  renderHardwareProfile() {
    const { deploymentTemplate } = this.props

    if (!deploymentTemplate) {
      return null
    }

    return (
      <EuiFlexItem data-test-id='advanced-settings-hardware-profile' grow={false}>
        <EuiText size='s' color='subdued'>
          {deploymentTemplate.name},
        </EuiText>
      </EuiFlexItem>
    )
  }
}

export default SettingsSummary
