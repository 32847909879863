/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import cx from 'classnames'
import React, { Component, Fragment } from 'react'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'
// eslint-disable-next-line no-restricted-imports
import { withLDConsumer } from 'launchdarkly-react-client-sdk'

import { EuiSpacer } from '@elastic/eui'
import { withTransaction } from '@elastic/apm-rum-react'

import { asAjaxRequestError } from '@modules/utils/ajax'
import { hasUnexpiredSession, updateAuthExpirationFromToken } from '@modules/auth/auth'
import LandingPageOuterContainer from '@modules/access-management-components/LandingPageOuterContainer'
import LandingPageInnerContainer from '@modules/access-management-components/LandingPageInnerContainer'

import { marketplaceConversionUrl } from '@/lib/urlBuilder'
import ClearPartnerSignupLocationStateOnRefresh from '@/apps/userconsole/components/PartnerSignup/GCPMarketplaceSignup/PartnerSignupRegistration/ClearPartnerSignupLocationStateOnRefresh'
import MarketplaceExistingUserNotice from '@/components/UserAuthNotice/MarketplaceExistingUserNotice'
import AppLoadingRoot from '@/components/AppLoadingRoot'

import LandingPage from '../LandingPage'
import GovCloudGate from '../GovCloudGate'
import UserAuthNotice from '../UserAuthNotice/UserAuthNotice'
import GovCloudNotice from '../UserAuthNotice/GovcloudNotice'
import { getConfigForKey } from '../../store'
import { buildRegisterUrl } from '../../lib/urlUtils'

import EmailVerification from './EmailVerification'
import PasswordBasedLogin from './PasswordBasedLogin'

import type { AllProps as Props, State } from './types'
import type { WrappedComponentProps } from 'react-intl'
import type { ReactElement } from 'react'

const messages = defineMessages({
  login: {
    id: `cloud-login-page-default-title`,
    defaultMessage: `Log in`,
  },
  loginLong: {
    id: `cloud-login-page-default-title-long`,
    defaultMessage: `Log in to your account`,
  },
  mfa: {
    id: `login-page.title-mfa`,
    defaultMessage: `Multifactor authentication`,
  },
  training: {
    id: `cloud-login-page.training.sub-title`,
    defaultMessage: `Official instructor-led, virtual, on-demand training for the Elastic Stack.`,
  },
  community: {
    id: `cloud-login-page.community.sub-title`,
    defaultMessage: `Connect with the Elastic community.`,
  },
  support: {
    id: `cloud-login-page.support.sub-title`,
    defaultMessage: `Support from the creators of the Elastic Stack.`,
  },
  marketplaceConversion: {
    id: `cloud-login-page.marketplace-conversion.sub-title`,
    defaultMessage: `Elastic Cloud powered by GCP marketplace.`,
  },
})

class UserconsoleLogin extends Component<Props & WrappedComponentProps, State> {
  state: State = {
    isUnverifiedUser: false,
    unverifiedUserEmail: '',
    loadedFlags: false,
  }

  componentDidMount() {
    if (this.hasRedirectOnMount()) {
      this.redirectOnMount()
      return
    }

    this.onLoadedFlags()
  }

  componentDidUpdate() {
    this.onLoadedFlags()
  }

  render() {
    if (this.hasRedirectOnMount()) {
      return null // the flow almost instantly redirects away from this page
    }

    const { loginRequest } = this.props

    const classes = cx({ 'login-form-sso': false })

    if (this.isEssUserconsole()) {
      return this.renderUserConsoleFlow()
    }

    return (
      <LandingPage scrollPage={true} loading={loginRequest.inProgress} className={classes}>
        {this.renderContent()}
      </LandingPage>
    )
  }

  renderContent() {
    const { location, loginAndRedirect, mfa } = this.props
    const { search } = location

    // This is also displayed if all auth methods are disabled - because what else are we supposed to do?
    return (
      <PasswordBasedLogin
        location={location}
        loginAndRedirect={loginAndRedirect}
        canSwitchMethod={false}
        registerUrl={buildRegisterUrl({ search })}
        onUnverifiedUser={this.onUnverifiedUser}
        mfa={mfa}
        isFlowV2={this.isFlowV2()}
      />
    )
  }

  renderUserConsoleFlow() {
    const shouldResetPassword = this.shouldResetPassword()
    const { isUnverifiedUser, unverifiedUserEmail, loadedFlags } = this.state
    const { isLaunchDarklyActivated } = this.props

    if (shouldResetPassword) {
      return (
        <EmailVerification
          onCancel={this.onCancelEmailVerification}
          email={unverifiedUserEmail}
          isPasswordNotice={true}
        />
      )
    }

    if (isUnverifiedUser) {
      return (
        <EmailVerification onCancel={this.onCancelEmailVerification} email={unverifiedUserEmail} />
      )
    }

    if (isLaunchDarklyActivated && !loadedFlags) {
      return <AppLoadingRoot />
    }

    return (
      <Fragment>
        <LandingPageOuterContainer
          pageContext={{
            name: 'login',
            contextSwitchLocationState: this.getContextSwitchLocationState(),
          }}
          isFlowV2={this.isFlowV2()}
        >
          <LandingPageInnerContainer
            title={this.renderFormTitle()}
            subtitle={this.renderFormSubTitle()}
            panelProps={{
              'aria-live': 'polite',
            }}
            isFlowV2={this.isFlowV2()}
          >
            {this.renderContextMessage()}
            {this.renderContent()}

            <GovCloudGate reverse={true}>
              <EuiSpacer size='m' />

              <GovCloudNotice />
            </GovCloudGate>
          </LandingPageInnerContainer>
        </LandingPageOuterContainer>

        {this.isFromMarketplaceConversionUrl() && <ClearPartnerSignupLocationStateOnRefresh />}
      </Fragment>
    )
  }

  renderFormTitle(): ReactElement | null {
    const { mfa } = this.props

    const showMfaTitle = mfa.mfa_required

    if (showMfaTitle) {
      return null
    }

    const message = this.isFlowV2() ? messages.loginLong : messages.login

    return <FormattedMessage data-test-id={messages.login.id} {...message} />
  }

  renderFormSubTitle(): ReactElement | null {
    const { mfa, source, fromURI } = this.props

    const showMfaTitle = mfa.mfa_required

    if (showMfaTitle || (!source && !fromURI)) {
      return null
    }

    if (this.isFromMarketplaceConversionUrl()) {
      return (
        <FormattedMessage
          data-test-id={messages.marketplaceConversion.id}
          {...messages.marketplaceConversion}
        />
      )
    }

    const subtitle = source && messages[source]

    if (subtitle) {
      return <FormattedMessage data-test-id={subtitle.id} {...subtitle} />
    }

    return null
  }

  renderContextMessage(): ReactElement | null {
    const { partner, source } = this.props

    if (!source && !partner) {
      return null
    }

    if (partner) {
      return <MarketplaceExistingUserNotice partner={partner} />
    }

    return (
      <div className='trial-message-login'>
        <UserAuthNotice source={source} />

        <EuiSpacer size='m' />
      </div>
    )
  }

  isFlowV2() {
    const { flags, isGovCloud } = this.props
    return this.isEssUserconsole() && !!flags?.signUpFlowV2 && !isGovCloud
  }

  isEssUserconsole() {
    return (
      getConfigForKey(`APP_PLATFORM`) === `saas` && getConfigForKey(`APP_NAME`) === `userconsole`
    )
  }

  getContextSwitchLocationState = (): { hasExistingSubscription: boolean } | undefined => {
    const { history } = this.props
    const {
      location: { state: locationState },
    } = history

    return locationState
  }

  isFromMarketplaceConversionUrl() {
    return this.props.fromURI?.includes(marketplaceConversionUrl()) ?? false
  }

  onUnverifiedUser = (email) => {
    this.setState({ isUnverifiedUser: true, unverifiedUserEmail: email })
  }

  onCancelEmailVerification = () => {
    const { resetLoginRequest } = this.props
    resetLoginRequest()

    this.setState({ isUnverifiedUser: false, unverifiedUserEmail: '' })
  }

  shouldResetPassword() {
    const { loginRequest } = this.props
    const { error, inProgress } = loginRequest

    if (inProgress) {
      return false
    }

    if (!error) {
      return false
    }

    const errorReasons = asAjaxRequestError(error)?.body?.errors ?? []
    const resetPassword = errorReasons.some((each) => each.code === `auth.expired_credentials`)

    return resetPassword
  }

  hasRedirectOnMount() {
    const { newBearerToken } = this.props

    // See `redirectOnMount` method below
    return Boolean(newBearerToken) || hasUnexpiredSession()
  }

  redirectOnMount() {
    const { fromURI, logout, redirectAfterLogin, redirectTo, newBearerToken } = this.props

    /*If users land on '/login' path and are coming from Okta (there's a fromURI parameter),
     * and we can assume Okta has already checked and there wasn't a SSO session cookie for them.
     * Even if we have a non-expired JWT token, user needs to re-authenticate.
     */
    if (fromURI) {
      logout()
      return
    }

    /* Allows us to receive Basic authentication requests.
     * 1. Server request to https://user:pass@cloud.elastic.co/login/_basic
     * 2. Server redirects to https://cloud.elastic.co/login#bearer_token=$API_BEARER_TOKEN
     * 3. Client persists the bearer token
     */
    if (newBearerToken) {
      updateAuthExpirationFromToken(newBearerToken)
    }

    /* Besides being useful when we receive a Basic authentication token,
     * the original — and still intended — purpose of this redirect is
     * to not challenge authenticated users with a Login screen.
     */
    const hasSession = hasUnexpiredSession()

    if (hasSession) {
      redirectAfterLogin(redirectTo)
    }
  }

  onLoadedFlags() {
    const { isLaunchDarklyActivated, ldClient } = this.props
    const { loadedFlags } = this.state

    if (isLaunchDarklyActivated && !loadedFlags) {
      ldClient?.waitUntilReady().then(() => this.setState({ loadedFlags: true }))
    }
  }
}

export default withTransaction(
  `UserconsoleLogin`,
  `component`,
)(injectIntl(withLDConsumer()(UserconsoleLogin)))
