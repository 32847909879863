/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/*!
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@                                         @@@@
 *!   @@@   THIS FILE WAS AUTOGENERATED BY TOOL   @@@@
 *!   @@@   CHANGES MUST TARGET SCRIPTS/SWAGGER   @@@@
 *!   @@@                                         @@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!
 */

import {
  _buildQueryString,
  _emptyParams,
  _encode,
  _getBasePath,
  _getRawBasePath,
} from './urls-utils'

/**
 * PUT /api/v1/saas/billing/lineitems/activate
 * Operation: `activate-line-items`
 * Activate the line items assigned to a user.
 * @see Accepts a request payload of type {@link ActivateLineItemsRequest}
 * @see 200 Line items successfully activated.
 * @see 400 Invalid parameters were supplied. (code: `billing_service.invalid_request`). See {@link BasicFailedReply}
 * @see 500 Internal server error. (code: `billing_service.failed_request`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function activateLineItemsUrl(): string {
  const path = `/saas/billing/lineitems/activate`
  return `${_getRawBasePath()}${path}`
}

/**
 * GET /api/v1/saas/billing/{organization_id}
 * Operation: `get-organization-billing-details`
 * Retrieves billing details of an organization.
 * @param {string} params.organizationId Identifier for the organization.
 * @see 200 The details of an organization. See {@link OrganizationBillingDetails}
 * @see 403 The current user does not have access to the requested organization. (code: `organization.invalid_access`). See {@link BasicFailedReply}
 * @see 404 The organization was not found. (code: `organization.not_found`). See {@link BasicFailedReply}
 * @see 500 An error occurred when fetching prepaid users for the organization. (code: `billing_service.failed_request`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getOrganizationBillingDetailsUrl(
  params: GetOrganizationBillingDetailsUrlParams,
): string {
  const path = _encode`/saas/billing/${params.organizationId}`
  return `${_getRawBasePath()}${path}`
}

export interface GetOrganizationBillingDetailsUrlParams {
  organizationId: string
}

/**
 * POST /api/v1/saas/billing/{organization_id}/_credit
 * Operation: `credit-organization`
 * Add an ad hoc credit for an organization.
 * @param {string} params.organizationId Identifier for the organization.
 * @see Accepts a request payload of type {@link CreditRequest}
 * @see 200 Credit was accepted.
 * @see 403 The current user does not have access to the requested organization. (code: `organization.invalid_access`). See {@link BasicFailedReply}
 * @see 404 Organization not found. (code: `organization.not_found`). See {@link BasicFailedReply}
 * @see 500 Invalid parameters were supplied. (code: `billing_service.failed_request`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function creditOrganizationUrl(params: CreditOrganizationUrlParams): string {
  const path = _encode`/saas/billing/${params.organizationId}/_credit`
  return `${_getRawBasePath()}${path}`
}

export interface CreditOrganizationUrlParams {
  organizationId: string
}

/**
 * PUT /api/v1/saas/billing/{organization_id}/_deactivate
 * Operation: `deactivate-organization`
 * Deactivate an organization so it is not billed and can no longer use ESS resources.
 * @param {string} params.organizationId Identifier for the organization.
 * @see Accepts a request payload of type {@link DeactivateOrgRequest}
 * @see 200 Organization successfully deactivated.
 * @see 400 Invalid parameters were supplied. (code: `billing_service.invalid_request`). See {@link BasicFailedReply}
 * @see 403 The current user does not have access to the requested organization. (code: `organization.invalid_access`). See {@link BasicFailedReply}
 * @see 404 Organization not found. (code: `organization.not_found`). See {@link BasicFailedReply}
 * @see 500 Internal server error. (code: `billing_service.failed_request`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function deactivateOrganizationUrl(params: DeactivateOrganizationUrlParams): string {
  const path = _encode`/saas/billing/${params.organizationId}/_deactivate`
  return `${_getRawBasePath()}${path}`
}

export interface DeactivateOrganizationUrlParams {
  organizationId: string
}

/**
 * POST /api/v1/saas/billing/{organization_id}/_retry_past_due_invoices
 * Operation: `retry-past-due-invoices`
 * Retry past due invoices.
 * @param {string} params.organizationId Identifier for the organization.
 * @see 200 The retry attempt was successful
 * @see 404 Organization not found. (code: `organization.not_found`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function retryPastDueInvoicesUrl(params: RetryPastDueInvoicesUrlParams): string {
  const path = _encode`/saas/billing/${params.organizationId}/_retry_past_due_invoices`
  return `${_getRawBasePath()}${path}`
}

export interface RetryPastDueInvoicesUrlParams {
  organizationId: string
}

/**
 * POST /api/v1/saas/billing/{organization_id}/_set_to_direct
 * Operation: `set-to-direct`
 * Set an organization from reseller to direct.
 * @param {string} params.organizationId Identifier for the organization.
 * @param {string} params.asOf Optional datetime for the transition to occur. Default is the start of the next month.
 * @see 200 Organization was set to direct.
 * @see 400 Invalid parameters were supplied. (code: `billing_service.invalid_request`). See {@link BasicFailedReply}
 * @see 403 The current user does not have access to the requested organization. (code: `organization.invalid_access`). See {@link BasicFailedReply}
 * @see 404 Organization not found. (code: `organization.not_found`). See {@link BasicFailedReply}
 * @see 500 An error occurred during this request. (code: `billing_service.failed_request`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function setToDirectUrl(params: SetToDirectUrlParams): string {
  const queryString = _buildQueryString({
    as_of: params.asOf,
  })
  const path = _encode`/saas/billing/${params.organizationId}/_set_to_direct`
  return `${_getRawBasePath()}${path}${queryString}`
}

export interface SetToDirectUrlParams {
  organizationId: string
  asOf?: string | null
}

/**
 * PUT /api/v1/saas/billing/{organization_id}/activate
 * Operation: `activate-organization`
 * Activate an organization to be billed and to use ESS resources.
 * @param {string} params.organizationId Identifier for the organization.
 * @see Accepts a request payload of type {@link ActivateOrgRequest}
 * @see 200 Organization successfully activated.
 * @see 400 Invalid parameters were supplied. (code: `billing_service.invalid_request`). See {@link BasicFailedReply}
 * @see 403 The current user does not have access to the requested organization. (code: `organization.invalid_access`). See {@link BasicFailedReply}
 * @see 404 Organization not found. (code: `organization.not_found`). See {@link BasicFailedReply}
 * @see 500 Internal server error. (code: `billing_service.failed_request`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function activateOrganizationUrl(params: ActivateOrganizationUrlParams): string {
  const path = _encode`/saas/billing/${params.organizationId}/activate`
  return `${_getRawBasePath()}${path}`
}

export interface ActivateOrganizationUrlParams {
  organizationId: string
}

/**
 * GET /api/v1/saas/billing/{organization_id}/history
 * Operation: `get-billing-history`
 * Retrieves the billing history for the organization.
 * @param {string} params.organizationId Identifier for the organization.
 * @see 200 The billing history associated to an organization. See {@link BillingHistory}
 * @see 403 The current user does not have access to the requested organization. (code: `organization.invalid_access`). See {@link BasicFailedReply}
 * @see 404 The organization was not found. (code: `organization.not_found`). See {@link BasicFailedReply}
 * @see 500 An error occurred fetching the billing history associated with the organization. (code: `billing_service.failed_request`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getBillingHistoryUrl(params: GetBillingHistoryUrlParams): string {
  const path = _encode`/saas/billing/${params.organizationId}/history`
  return `${_getRawBasePath()}${path}`
}

export interface GetBillingHistoryUrlParams {
  organizationId: string
}

/**
 * GET /api/v1/saas/billing/{organization_id}/history/{statement_number}
 * Operation: `get-billing-history-by-statement`
 * Retrieves the billing history by statement for the organization.
 * @param {string} params.organizationId Identifier for the organization.
 * @param {string} params.statementNumber The Statement number.
 * @see 200 The billing history by statement associated to an organization. See {@link BillingHistory}
 * @see 403 The current user does not have access to the requested organization. (code: `organization.invalid_access`). See {@link BasicFailedReply}
 * @see 404 The organization was not found. (code: `organization.not_found`). See {@link BasicFailedReply}
 * @see 500 An error occurred when fetching the billing history by statement associated with the organization. (code: `billing_service.failed_request`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getBillingHistoryByStatementUrl(
  params: GetBillingHistoryByStatementUrlParams,
): string {
  const path = _encode`/saas/billing/${params.organizationId}/history/${params.statementNumber}`
  return `${_getRawBasePath()}${path}`
}

export interface GetBillingHistoryByStatementUrlParams {
  organizationId: string
  statementNumber: string
}

/**
 * GET /api/v1/saas/billing/{organization_id}/lineitems
 * Operation: `get-line-items`
 * Retrieves the line items of for an organization.
 * @param {string} params.organizationId Identifier for the organization.
 * @param {string} params.at Only returns line items active at the given time, if specified. Defaults to all line items.
 * @see 200 The line items associated to an organization. See {@link LineItems}
 * @see 403 The current user does not have access to the requested organization. (code: `organization.invalid_access`). See {@link BasicFailedReply}
 * @see 404 Organization not found. (code: `organization.not_found`). See {@link BasicFailedReply}
 * @see 500 Error fetching the line items associated to the organization. (code: `billing_service.failed_request`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getLineItemsUrl(params: GetLineItemsUrlParams): string {
  const queryString = _buildQueryString({
    at: params.at,
  })
  const path = _encode`/saas/billing/${params.organizationId}/lineitems`
  return `${_getRawBasePath()}${path}${queryString}`
}

export interface GetLineItemsUrlParams {
  organizationId: string
  at?: string | null
}

/**
 * GET /api/v1/saas/billing/{organization_id}/marketplaces/azure/subscription
 * Operation: `get-marketplace-billing-details`
 * Retrieves Azure marketplace subscription billing details for a given organization.
 * @param {string} params.organizationId Identifier for the organization.
 * @see 200 The marketplace subscription billing details of an organization. See {@link AzureMarketplaceSubscription}
 * @see 403 The current user does not have access to the requested organization. (code: `organization.invalid_access`). See {@link BasicFailedReply}
 * @see 404 The organization was not found. (code: `organization.not_found`). See {@link BasicFailedReply}
 * @see 500 An error occurred for this request. (code: `billing_service.failed_request`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getMarketplaceBillingDetailsUrl(
  params: GetMarketplaceBillingDetailsUrlParams,
): string {
  const path = _encode`/saas/billing/${params.organizationId}/marketplaces/azure/subscription`
  return `${_getRawBasePath()}${path}`
}

export interface GetMarketplaceBillingDetailsUrlParams {
  organizationId: string
}

/**
 * GET /api/v1/saas/billing/{organization_id}/prepaids
 * Operation: `get-prepaids`
 * Retrieves subscriptions and premium products for an organization.
 * @param {string} params.organizationId Identifier for the organization.
 * @param {string} params.start If specified, returns active or future prepaids as of this date. Defaults to all prepaids.
 * @see 200 The prepaids associated with an organization. See {@link Prepaids}
 * @see 403 The current user does not have access to the requested organization. (code: `organization.invalid_access`). See {@link BasicFailedReply}
 * @see 404 The organization was not found. (code: `organization.not_found`). See {@link BasicFailedReply}
 * @see 500 An error occurred when fetching prepaid users for the organization. (code: `billing_service.failed_request`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getPrepaidsUrl(params: GetPrepaidsUrlParams): string {
  const queryString = _buildQueryString({
    start: params.start,
  })
  const path = _encode`/saas/billing/${params.organizationId}/prepaids`
  return `${_getRawBasePath()}${path}${queryString}`
}

export interface GetPrepaidsUrlParams {
  organizationId: string
  start?: string | null
}

/**
 * PUT /api/v1/saas/billing/{organization_id}/set_payment_method
 * Operation: `set-payment-method`
 * Set organization payment method to credit card or invoice.
 * @param {string} params.organizationId Identifier for the organization.
 * @see Accepts a request payload of type {@link SetPaymentRequest}
 * @see 200 Payment method was accepted.
 * @see 403 The current user does not have access to the requested organization. (code: `organization.invalid_access`). See {@link BasicFailedReply}
 * @see 404 Organization not found. (code: `organization.not_found`). See {@link BasicFailedReply}
 * @see 500 Invalid parameters were supplied. (code: `billing_service.failed_request`). See {@link BasicFailedReply}
 * @return {string} A fully-interpolated and escaped URL
 */
export function setPaymentMethodUrl(params: SetPaymentMethodUrlParams): string {
  const path = _encode`/saas/billing/${params.organizationId}/set_payment_method`
  return `${_getRawBasePath()}${path}`
}

export interface SetPaymentMethodUrlParams {
  organizationId: string
}
