/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/*!
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@                                         @@@@
 *!   @@@   THIS FILE WAS AUTOGENERATED BY TOOL   @@@@
 *!   @@@   CHANGES MUST TARGET SCRIPTS/SWAGGER   @@@@
 *!   @@@                                         @@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 *!
 */

import {
  _buildQueryString,
  _emptyParams,
  _encode,
  _getBasePath,
  _getRawBasePath,
} from './urls-utils'

/**
 * GET /api/v1/saas/organizations/{organization_id}
 * Operation: `get-saas-organization`
 * Retrieves an Elastic Cloud organization.
 * @param {string} params.organizationId Identifier for the Organization.
 * @param {string} params.include Comma delimited list of optional properties to include in the output.
 * @see 200 The Elastic Cloud organization. See {@link SaasOrganizationResponse}
 * @return {string} A fully-interpolated and escaped URL
 */
export function getSaasOrganizationUrl(params: GetSaasOrganizationUrlParams): string {
  const queryString = _buildQueryString({
    include: params.include,
  })
  const path = _encode`/saas/organizations/${params.organizationId}`
  return `${_getRawBasePath()}${path}${queryString}`
}

export interface GetSaasOrganizationUrlParams {
  organizationId: string
  include?: string | null
}

/**
 * POST /api/v1/saas/organizations/{organization_id}/_add_to_serverless_waitlist
 * Operation: `request-organization-serverless-waitlist`
 * Add an organization to the serverless waitlist, and send a confirmation email.
 * @param {string} params.organizationId Identifier for the Organization.
 * @see Accepts a request payload of type {@link ServerlessWaitlistRequest}
 * @see 200 requests serverless waitlist successfully
 * @return {string} A fully-interpolated and escaped URL
 */
export function requestOrganizationServerlessWaitlistUrl(
  params: RequestOrganizationServerlessWaitlistUrlParams,
): string {
  const path = _encode`/saas/organizations/${params.organizationId}/_add_to_serverless_waitlist`
  return `${_getRawBasePath()}${path}`
}

export interface RequestOrganizationServerlessWaitlistUrlParams {
  organizationId: string
}

/**
 * POST /api/v1/saas/organizations/{organization_id}/_convert_from_trial
 * Operation: `convert-saas-organization-from-trial`
 * Convert an Elastic Cloud organization from trial to paying customer.
 * @param {string} params.organizationId Identifier for the Organization.
 * @see 200 The organization was successfully converted
 * @return {string} A fully-interpolated and escaped URL
 */
export function convertSaasOrganizationFromTrialUrl(
  params: ConvertSaasOrganizationFromTrialUrlParams,
): string {
  const path = _encode`/saas/organizations/${params.organizationId}/_convert_from_trial`
  return `${_getRawBasePath()}${path}`
}

export interface ConvertSaasOrganizationFromTrialUrlParams {
  organizationId: string
}

/**
 * POST /api/v1/saas/organizations/{organization_id}/_extend_trial
 * Operation: `extend-saas-organization-trial`
 * Extend the trial end date for an Elastic Cloud organization by the specified duration.
 * @param {string} params.organizationId Identifier for the Organization.
 * @see Accepts a request payload of type {@link ExtendTrialRequest}
 * @see 200 The trial was successfully extended
 * @return {string} A fully-interpolated and escaped URL
 */
export function extendSaasOrganizationTrialUrl(
  params: ExtendSaasOrganizationTrialUrlParams,
): string {
  const path = _encode`/saas/organizations/${params.organizationId}/_extend_trial`
  return `${_getRawBasePath()}${path}`
}

export interface ExtendSaasOrganizationTrialUrlParams {
  organizationId: string
}

/**
 * POST /api/v1/saas/organizations/{organization_id}/_regenerate_invoices
 * Operation: `regenerate-invoices`
 * Regenerates invoices for an Elastic Cloud organization.
 * @param {string} params.organizationId Identifier for the Organization.
 * @see 200 The invoices were successfully regenerated
 * @return {string} A fully-interpolated and escaped URL
 */
export function regenerateInvoicesUrl(params: RegenerateInvoicesUrlParams): string {
  const path = _encode`/saas/organizations/${params.organizationId}/_regenerate_invoices`
  return `${_getRawBasePath()}${path}`
}

export interface RegenerateInvoicesUrlParams {
  organizationId: string
}

/**
 * PUT /api/v1/regions/{region_id}/saas/organizations/{organization_id}/_update_subscription_level
 * Operation: `update-organization-subscription-level`
 * Update subscription level for deployments that are owned by this organization.
 * @param {string} params.organizationId Identifier for the Organization.
 * @param {string} params.regionId Injected by `yarn generate-swagger-contracts`.
 * @see Accepts a request payload of type {@link UpdateSubscriptionLevelRequest}
 * @see 200 Updated the subscription level successfully
 * @return {string} A fully-interpolated and escaped URL
 */
export function updateOrganizationSubscriptionLevelUrl(
  params: UpdateOrganizationSubscriptionLevelUrlParams,
): string {
  const path = _encode`/${params.regionId}/saas/organizations/${params.organizationId}/_update_subscription_level`
  return `${_getBasePath()}${path}`
}

export interface UpdateOrganizationSubscriptionLevelUrlParams {
  organizationId: string
  regionId: string
}
