/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { useMemo } from 'react'
import { injectIntl } from 'react-intl'
import moment from 'moment'
import { round } from 'lodash'

import { Axis, BarSeries, Chart, LineSeries, Position, ScaleType, Settings } from '@elastic/charts'
import { EuiLoadingChart } from '@elastic/eui'

import { withErrorBoundary } from '@modules/cui/Boundary'
import { useElasticChartsTheme } from '@modules/cui/charts/hooks'

import EmptyDataPrompt from '@/components/User/BillingUsage/BillingUsageOverviewV2/EmptyDataPrompt'
import { IconType } from '@/components/User/BillingUsage/BillingUsageOverviewV2/types'

import { ChartType, ViewBy } from '../../types'
import ChartTooltip from '../ChartTooltip'
import useFilters from '../Filters/useFilters'
import { getCumulativeViewData } from '../Filters/utils'

import {
  getColorFromYAccessor,
  getFilteredData,
  getTopListOfInstancesByUsage,
  getNameFromYAccessor,
  prepareAggregatedInstanceDataAccessors,
  prepareInstanceDataAccessors,
} from './utils'

import type { Props } from './types'
import type { WrappedComponentProps } from 'react-intl'
import type { FunctionComponent } from 'react'

const chartComponent = {
  [ChartType.BAR]: BarSeries,
  [ChartType.LINE]: LineSeries,
}

const viewByFormat = {
  [ViewBy.DAY]: 'MMM DD',
  [ViewBy.MONTH]: 'MMM YYYY',
}

const stackAccessors = {
  [ChartType.BAR]: ['timestamp'],
  [ChartType.LINE]: [],
}

const DeploymentsChart: FunctionComponent<Props & WrappedComponentProps> = ({
  data = [],
  instanceTypes,
  isLoading = false,
}) => {
  const { filters } = useFilters()
  // nosemgrep
  const ChartComponent = chartComponent[filters.chartType]
  const baseTheme = useElasticChartsTheme()

  const filteredData = useMemo(
    () =>
      getFilteredData({
        data,
        productTypes: filters.productTypes,
        solutions: filters.solutions,
      }),
    [data, filters.productTypes, filters.solutions],
  )
  const chartData = useMemo(() => {
    if (filters.cumulativeView) {
      return getCumulativeViewData(filteredData)
    }

    return filteredData
  }, [filters.cumulativeView, filteredData])

  // Because the x-axis is ordinal, we need to define the domain.
  const xDomain = useMemo(() => chartData.map(({ timestamp }) => timestamp), [chartData])

  const yAccessors = useMemo(() => {
    const aggregated = instanceTypes.length === 2

    if (aggregated) {
      return prepareAggregatedInstanceDataAccessors()
    }

    const { topInstances, hasMore } = getTopListOfInstancesByUsage(filteredData)

    return prepareInstanceDataAccessors({ topInstances, hasMore, type: filters.productTypes[0] })
  }, [filteredData, filters.productTypes, instanceTypes.length])

  if (isLoading) {
    return <EuiLoadingChart size='xl' />
  }

  return yAccessors.length === 0 ? (
    <EmptyDataPrompt
      iconType={filters.chartType === ChartType.BAR ? IconType.BAR_CHART : IconType.LINE_CHART}
    />
  ) : (
    <Chart size={['100%', '100%']}>
      <Settings baseTheme={baseTheme} xDomain={xDomain} />
      <ChartTooltip />
      <Axis
        id='amount'
        position={Position.Left}
        gridLine={{ visible: true }}
        ticks={6}
        tickFormat={(d) => String(round(Math.abs(d), 4).toFixed(4))} // the same format as in the table below the graph
        title='ECU'
      />
      <Axis
        id='timestamp'
        position={Position.Bottom}
        tickFormat={(d) => moment.unix(d).utc().format(viewByFormat[filters.viewBy])}
        showOverlappingTicks={false}
        showOverlappingLabels={false}
        ticks={4}
      />
      <ChartComponent
        id='usage'
        name={({ yAccessor }) => getNameFromYAccessor(yAccessor) || 'Other'}
        xScaleType={ScaleType.Ordinal}
        yScaleType={ScaleType.Linear}
        xAccessor='timestamp'
        yAccessors={yAccessors}
        stackAccessors={stackAccessors[filters.chartType]}
        splitSeriesAccessors={[]}
        data={chartData}
        color={({ yAccessor }) => getColorFromYAccessor(yAccessor)}
      />
    </Chart>
  )
}

export default injectIntl(withErrorBoundary(DeploymentsChart))
