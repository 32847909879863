/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { PureComponent } from 'react'

import { EuiSkeletonText } from '@elastic/eui'

import type {
  AccountCosts,
  AccountCostsSummary,
  AsyncRequestState,
  UserProfile,
} from '@modules/ui-types'
import { CuiAlert } from '@modules/cui/Alert'
import { isTrialNotStartedUser } from '@modules/billing-lib/utils'

import TrialNotStarted from '../TrialNotStarted'
import TrialStarted from '../TrialStarted'
import CostAnalysisWhenSubscribed from '../CostAnalysisWhenSubscribed'

import type { ReactElement } from 'react'

export interface Props {
  accountCosts: AccountCosts
  accountCostOverview: AccountCostsSummary
  fetchAccountCostsOverviewIfNeeded: ({ organizationId }: { organizationId: string }) => void
  fetchAccountCostsOverviewRequest: AsyncRequestState
  fetchDeploymentsCostsRequest: AsyncRequestState
  profile: UserProfile
}

class CostOverview extends PureComponent<Props> {
  componentDidMount(): void {
    const { fetchAccountCostsOverviewIfNeeded, profile } = this.props
    const { organization_id } = profile

    if (!isTrialNotStartedUser(profile)) {
      fetchAccountCostsOverviewIfNeeded({ organizationId: organization_id! })
    }
  }

  render(): ReactElement {
    const { fetchAccountCostsOverviewRequest, accountCostOverview, profile } = this.props

    if (fetchAccountCostsOverviewRequest.error) {
      return <CuiAlert type='error'>{fetchAccountCostsOverviewRequest.error}</CuiAlert>
    }

    if (
      fetchAccountCostsOverviewRequest.inProgress ||
      (!accountCostOverview && !isTrialNotStartedUser(profile))
    ) {
      return <EuiSkeletonText lines={6} />
    }

    return this.renderUserContextOverview()
  }

  renderUserContextOverview(): ReactElement {
    const { accountCostOverview, profile } = this.props

    if (isTrialNotStartedUser(profile)) {
      return <TrialNotStarted />
    }

    if (profile.inTrial) {
      return <TrialStarted profile={profile} />
    }

    return (
      <CostAnalysisWhenSubscribed accountCostOverview={accountCostOverview} profile={profile} />
    )
  }
}

export default CostOverview
