/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'

import { EuiBadge, EuiButtonIcon } from '@elastic/eui'

import type { Invoice } from '@modules/cloud-api/v1/types'
import type { CuiTableColumn } from '@modules/cui/Table'
import type { Props as CuiTableProps } from '@modules/cui/Table/types'
import CuiPrice from '@modules/cui/formatters/CuiPrice'

import BillingDocuments from '../BillingDocuments'
import BillingHistoryPanel from '../BillingHistoryPanel'
import { toBillingPeriodFormat } from '../lib/date'
import { getBillingPdfs } from '../lib/getBillingPdfs'

import type { ReactElement } from 'react'

export interface Props {
  invoices: Invoice[]
  loading: boolean
  title?: ReactElement
}

interface GridColumns {
  initialSort: CuiTableColumn<Invoice>
  initialSortDirection: CuiTableProps<Invoice>['initialSortDirection']
  columns: Array<CuiTableColumn<Invoice>>
}

const BillingInvoices = ({ invoices, loading, title }: Props) => {
  const history = useHistory()
  const { formatMessage } = useIntl()

  const getGridColumns = (): GridColumns => {
    const billingPeriodColumn: CuiTableColumn<Invoice> = {
      label: <FormattedMessage id='billing-invoices.documents' defaultMessage='Billing period' />,
      render: ({ period_start_date, period_end_date, number }) => (
        <span data-test-id={`billing-period-${number}`}>
          {toBillingPeriodFormat({
            periodStartDate: period_start_date,
            periodEndDate: period_end_date,
          })}
        </span>
      ),
      sortKey: [
        ({ period_start_date }) => new Date(period_start_date),
        ({ period_end_date }) => new Date(period_end_date),
      ],
    }

    const columns: Array<CuiTableColumn<Invoice>> = [
      billingPeriodColumn,
      {
        label: (
          <FormattedMessage
            id='billing-documents.invoice-amount'
            defaultMessage='Invoiced amount (pre-tax)'
          />
        ),
        render: ({ invoiced_amount_in_cents }) => (
          <CuiPrice
            value={Math.max(0, invoiced_amount_in_cents)}
            unit='cents'
            currency='usd'
            data-test-id='invoiced-amount'
          />
        ),
        sortKey: `invoiced_amount_in_cents`,
      },
      {
        label: (
          <FormattedMessage id='billing-invoices.download-pdf' defaultMessage='Download PDF' />
        ),
        render: ({ invoice_pdf_download, external_invoice_pdf_download, number, issue_date }) =>
          getBillingPdfs({
            invoice_pdf_download,
            external_invoice_pdf_download,
            number,
            issue_date,
          }),
        sortKey: `number`,
      },
      {
        label: <FormattedMessage id='billing-documents.status' defaultMessage='Status' />,
        render: ({ status }) => {
          if (status === `paid`) {
            return (
              <EuiBadge color='default'>
                <FormattedMessage id='billing-documents.paid' defaultMessage='Paid' />
              </EuiBadge>
            )
          }

          return (
            <EuiBadge color='warning'>
              <FormattedMessage id='billing-documents.pending' defaultMessage='Pending' />
            </EuiBadge>
          )
        },
        sortKey: `status`,
        width: `10%`,
      },
      {
        label: <FormattedMessage id='billing-documents.actions' defaultMessage='Actions' />,
        render: ({ period_start_date, period_end_date }) => (
          <EuiButtonIcon
            aria-label={formatMessage({
              id: 'billing-documents.view-usage',
              defaultMessage: 'View usage',
            })}
            data-test-subj='billing-history.usage-link'
            onClick={() =>
              history.push({
                pathname: 'usage',
                search: `?startDate=${period_start_date}&endDate=${period_end_date}`,
              })
            }
            iconType='visLine'
          />
        ),
        actions: true,
        align: `center`,
        width: `80px`,
      },
    ]

    return { columns, initialSort: billingPeriodColumn, initialSortDirection: 'desc' }
  }

  const { columns, initialSort, initialSortDirection } = getGridColumns()

  return (
    <BillingHistoryPanel title={title} data-test-id='billing-invoices-panel'>
      <BillingDocuments
        initialLoading={loading}
        documents={invoices}
        columns={columns}
        initialSort={initialSort}
        initialSortDirection={initialSortDirection}
      />
    </BillingHistoryPanel>
  )
}

export default BillingInvoices
